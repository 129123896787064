define('userAssignView',[
  'module',
  'app',
  'marionette',
  'template!userAssignTpl',
  'underscore',
  'jquery',
  'settings',
  'imageUtils',
  'graphicalRequestKanbanAssignPopupView'
], function (
  module,
  App,
  Marionette,
  tabsBaseTpl,
  _,
  $,
  Settings,
  imageUtils,
  PopupView
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: tabsBaseTpl,

    ui: {
      assignTo: '.js-assign-to'
    },

    events: {
      'click @ui.assignTo': '_onAssignTo'
    },


    serializeData: function () {
      var templateData = {};
      templateData.userImg = this._getUserImg();
      return templateData;
    },

    onShow: function () {
    },

    refresh: function (user) {
      this.options.user = user;
      this.render();
    },

    _onAssignTo: function (e) {
      e.preventDefault();
      var userLogonId = this.options.user ? this.options.user.logonId : null;

      $.when(App.request('kanban-tickets:get-users'))
        .done(_.bind(function (_users) {
          this.users = this._parseUsers(_.sortBy(_users, function (user) {
            return user.firstName.toLowerCase();
          }));
          this._showAssignPopup(userLogonId);
        }, this));
    },

    _parseUsers: function (_users) {
      this.users = _.map(_users, function (user) {
        if (user.firstName && user.lastName) {
          user.fullName = user.firstName + ' ' + user.lastName;
        } else if (user.firstName || user.lastName) {
          user.fullName = user.firstName ? user.firstName : user.lastName;
        } else if (user.nickName) {
          user.fullName = user.nickName;
        } else {
          user.fullName = user.logonId;
        }
        return user;
      });
      return this.users;
    },

    _showAssignPopup: function (userLogonId) {
      var popupView = new PopupView(
        {users: this.users, assignedUser: userLogonId}
      );

      popupView.show({
        title: 'Assign To',
        className: 'vectorizationAssignTo',
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default',
            callback: function () {
              return true;
            }
          },
          success: {
            label: 'Assign',
            className: 'btn-primary',
            callback: _.bind(function () {
              if (popupView.selectedUser) {
                if (popupView.selectedUser.get('secId')) {
                  this.refresh(popupView.selectedUser.toJSON());
                  return this.trigger('selected-user-modified', popupView.selectedUser.get('secId'));
                } else {
                  this.refresh();
                  return this.trigger('unassign-user', popupView.selectedUser.get('secId'));
                }
              } else {
                return true;
              }
            }, this)
          }
        }
      });
    },

    _getUserImg: function () {
      var user = this.options.user;
      if (user && user.logo) {
        return Settings.url('file', user.logo, {size: 'thumbnail'});
      } else if (user && user.logonId) {
        return imageUtils.getLogo(null, user.logonId, null, 'circle');
      } else {
        return null;
      }
    }
  });
});
