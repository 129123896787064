/**
 * Created by OLD on 20/08/2015.
 */
define('graphicalRequestAllInOneShowController',[
  'module',
  'jquery',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'graphicalRequestAllInOneShowView',
  'graphicalRequestAllInOnePopupView',
  'galleryView',
  'fileExtensionData',
  'bootbox',
  'rolesMixin',
  'entities/tickets',
  'entities/caccounts/credits'
], function (
  module,
  $,
  _,
  Backbone,
  App,
  Settings,
  ImageUtils,
  View,
  PopupView,
  GalleryView,
  FileExtensionData,
  bootbox,
  RolesMixin
) {
  'use strict';

  var GraphicalRequestAllInOneTableController = {
    showAllInOne: function (options) {
      var that = this,
        contentView = App.regions.getRegion('main').currentView,
        view;

      if (contentView) {
        $.when(App.request('caccount-credits:get-balance'))
          .done(function (balance) {
            view = new View({balance: balance});
            that.balance = balance;
            that.view = view;

            view.listenTo(view, 'onCreateNew', _.bind(that.onCreateNew, that));
            view.listenTo(view, 'onGridLoaded', _.bind(that.onGridLoaded, that));
            view.listenTo(view, 'tickets:show', _.bind(that.onTicketsShow, that));
            view.listenTo(view, 'tickets:update:tariffScale', _.bind(that.onTariffScaleChange, that));
            view.listenTo(view, 'ticket:update:urgency', _.bind(that.onUpdateUrgency, that));
            view.listenTo(view, 'onNavigateToCredit', _.bind(that.onNavigateToCredit, that));

            App.off('loader:hide');
            App.on('loader:hide', _.bind(that.onHideLoader, that));
            contentView.getRegion('content').show(view);
            if (options && options.isNew === true) {
              that.onCreateNew();
            }
          });
      }
    },

    onCreateNew: function () {
      var that = this,
        popupView = new PopupView({
          balance: this.balance,
          outputFileCollection: new Backbone.Collection(FileExtensionData.extensions)
        });

      popupView.show({
        title: _.i18n('popup.request.new.title'),
        className: 'vectorizationAddNew'
      });
      popupView.listenTo(popupView, 'popup:view:close',
        _.bind(that.createTicket, that));
      popupView.listenTo(popupView, 'gallery:init',
        _.bind(that.initGallery, that));
      popupView.listenTo(popupView, 'onNavigateToCredit',
        _.bind(that.onNavigateToCredit, that));
      that.popupView = popupView;
    },

    onNavigateToCredit: function () {
      var caSecId = Settings.get('currentCAccount').secId;

      if (this.popupView) {
        this.popupView.hide();
      }
      App.navigate('/caccount/' + caSecId + '/credits', {trigger: true});
      App.trigger('vectorization:open-add-credit-popup');
    },

    initGallery: function () {
      var fileUid = this.popupView.requestUid,
        fileSrc = Settings.url(
          'x2png',
          'filerequest/vectorize/preview/big/' + fileUid
        ),
        data = [];

      this.popupView.ui.loader.show();
      data.push(fileSrc);
      App.regions.getRegion('zoom').show(new GalleryView({data: data}));
    },

    encodeImage: function (ticketName, type) {
      this.conversionType = type;
      this.view.triggerMethod('showMessage', 'primary', _.i18n('common.loading'));
      if (this.popupView && ticketName) {
        ImageUtils.encodeFileToBase64(
          this.popupView.imgOriginal,
          _.bind(this.onImageEncoded, this)
        );
      }
    },

    createTicket: function (ticketName, conversionType) {
      this.view.triggerMethod('showMessage', 'primary', _.i18n('common.loading'));
      if (this.popupView && ticketName) {
        var files = [{
            content: this.popupView.imgOriginalBase64.split(',')[1],
            extension: this.popupView.extension || '',
            name: this.popupView.fileName || ''
          }],
          ticketData = {
            name: this.popupView.ui.ticketName.val(),
            content: this.popupView.ui.ticketMessage.val() + '\n\n' + this.popupView.ui.ticketOutputColorDetail.val(),
            urgent: this.popupView.ui.ticketUrgent.prop('checked'),
            files: files,
            configuration: {
              outputType: this.popupView.storedExtension.extension || ''
            }
          },
          newTicketRequest = App.request('tickets:send-new', ticketData, conversionType);

        this.popupView.hide();
        $.when(newTicketRequest).done(_.bind(function (resp) {
          if (resp.status === 500) {
            bootbox.alert(_.i18n('ticket.errorOccuredTryAgainOrSendAMail'), function () {
              return;
            });
          } else {
            App.trigger('graphical_service_all_in_one:show', resp.responseJSON.secId);
          }
        }, this));
      }
    },

    onTariffScaleChange: function (ticketSecID, tariffScale) {
      var that = this;
      $.when(App.request('tickets:update:tariffScale', ticketSecID, tariffScale)).done(function () {
        that.view.updatePagination();
      });
    },

    onGridLoaded: function () {
      var that = this,
        data = {
          url: App.request('tickets:get-collection-url'),
          rowCountUrl: App.request('tickets:row-count-url'),
          displayRowToolTip: true
        };
      that.view.displayGrid(data, true);
    },

    onTicketsShow: function (secId) {
      if (Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR]) {
        App.trigger('graphical_service_kanban:show:details', secId, true);
      } else {
        App.trigger('graphical_service_all_in_one:show', secId);
      }
    },

    onHideLoader: function () {
      this.popupView.ui.loader.hide();
    },

    onUpdateUrgency: function (ticketCID, checked) {
      var that = this;
      $.when(App.request('ticket:update:urgency', ticketCID, checked)).done(function () {
        that.view.updatePagination();
      });
    }
  };

  module.exports = GraphicalRequestAllInOneTableController;
});
