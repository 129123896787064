/**
 * Created by BFR on 12/12/2017.
 */
define('plivoUtils',[
    'module',
    'app',
    'logger',
    'jquery',
    'underscore',
    'settings',
    'entities/customers/contacts'
], function (module,
             App,
             Logger,
             $,
             _,
             Settings) {
    'use strict';


    var PlivoUtils = {
        plivoPhoneNumber:null,
        customerToSwitchTo:null,
        displayContainer:false,
        displayIncoming:false,
        eventCall: [],
        bell: new Audio('https://s3.eu-central-1.amazonaws.com/email-images-compuzz/ring_ring.mp3'),

        refreshInterface: function() {
          this.callEventCall();
          if (this.displayContainer) {
              $('#btn-container').show();
          }
          if (this.displayIncoming) {
              $('#incoming_callbox').show('slow');
          }
        },

        findConnectedUsers: function() {
            var url = Settings.url('compuzzApi', '/plivo/onlineusers'),
            defer = $.Deferred();
            $.ajax({
                method: 'get',
                url: url,
                contentType: 'application/json',
                success: function(users) {
                    defer.resolve(users);
                },
                error: function() {
                    defer.fail();
                }
            });
            return defer.promise();
        },

        forwardCall: function(to) {
            var url = Settings.url('compuzzPlivo', '/forward/init'),
            defer = $.Deferred();
            $.ajax({
                method: 'POST',
                url: url,
                data: JSON.stringify({
                    callUid:  this.plivoWebSdk.client.callUUID,
                    forwardTo: to,
                    from: this.plivoWebSdk.client.userName
                }),
                contentType: 'application/json',
                success: function(users) {
                    defer.resolve(users);
                }
            });
    
            return defer.promise();
        },

        // Make outgoing calls
        callNumber: function(number) {
            // The destination phone number to connect the call to
            if (this.isNotEmpty(number)) {
                $('#status_txt').text('Calling..');
                $('#plivo-phone-number').html(number);
                App.request('customers:contacts:get-by-phone', encodeURI(number))
                  .done(_.bind(function(result){
                      if (result && result.responseText) {
                          this.customerToSwitchTo = result.responseText;
                          $('.js-show-customer-offers').attr('data-route','#customers/' + this.customerToSwitchTo + '/offers');
                          $('.js-show-customer-offers').show();
                      } else {
                          Settings.setToMemory('plivoCurrentCallNumber', number);
                          $('.js-link-phone-to-customer').attr('data-phoneNumber',number);
                          $('.js-link-phone-to-customer').show();
                      }
                      // Connect the call

                      var extraHeaders = {'X-PH-CaccountSecId': Settings.get('currentCAccount').secId
                            .replace(/_/g,'ZZ').replace(/-/g, 'YY'),
                          'X-PH-Lang': Settings.get('lang'), 'X-PH-UserPid': Settings.get('currentUser').secId
                            .replace(/_/g,'ZZ').replace(/-/g, 'YY') };
                      this.plivoWebSdk.client.call(number.replace(/ /g, ''), extraHeaders);
                      $('#make_call').text('End');
                      this.showPlivoHeader('out');
                      this.displayContainer = true;
                  }, this));

            } else {
                $('#status_txt').text('Invalid Destination');
            }
        },

        call: function () {
            if ($('#make_call').text() === 'Call') {
                // The destination phone number to connect the call to
                var dest = $('#to').val();
                if (this.isNotEmpty(dest)) {
                    $('#status_txt').text('Calling..');
                    // Connect the call
                    this.plivoWebSdk.client.call(dest);
                    $('#make_call').text('End');
                } else {
                    $('#status_txt').text('Invalid Destination');
                }
            } else if ($('#make_call').text() === 'End') {
                $('#status_txt').text('Ending..');
                // Hang up the call
                this.plivoWebSdk.client.hangup();
                $('#make_call').text('Call');
                $('#status_txt').text('Ready');
            }
        },

        // Login with SIP Endpoint
        login: function () {
            // SIP Endpoint username and password
            var username = Settings.get('plivoUser');
            var password = Settings.get('plivoPassword');

            // Login
            this.plivoWebSdk.client.login(username, password);
        },

        logout: function() {
            $('#status_txt').text('Logged out');
            this.plivoWebSdk.client.logout();
        },

        isNotEmpty: function(n) {
            return n.length > 0;
        },

        callEventCall: function() {
            for (var i = 0 ; i < this.eventCall.length ; i++) {
                this.eventCall[i]();
            }
        },

        onCalling: function() {
            console.log('onCalling');
            $('#status_txt').text('Connecting....');            
        },

        onMediaPermission: function(result) {
            if (result) {
                console.log('get media permission');
            } else {
                alert('you don\'t allow media permission, you will can\'t make a call until you allow it');
            }
        },

        webrtcNotSupportedAlert: function() {
            $('#txtStatus').text('');
            alert('Your browser doesn\'t support WebRTC. You need Chrome 25 to use this demo');
        },

        onLogin: function() {
            $('#status_txt').text('Logged in');
            $('#logout_box').show();

            //this.plivoWebSdk.client.setRingTone('https://s3.eu-central-1.amazonaws.com/email-images-compuzz/ring_ring.mp3');
            //this.plivoWebSdk.setRingToneBack('https://s3.eu-central-1.amazonaws.com/email-images-compuzz/ring_ring.mp3');
        },

        onLoginFailed: function() {
            $('#status_txt').text('Login Failed');
        },

        onCallRemoteRinging: function() {
            $('#status_txt').text('Ringing..');
        },

        onCallAnswered: function() {
            Settings.setToMemory('plivoCurrentCallNumber',this.plivoPhoneNumber);
            this.stopBell();
            this.callEventCall();
            console.log('onCallAnswered');
            $('#status_txt').text('Call Answered');
            this.showPlivoHeader('out');
            this.displayContainer = true;
        },

        onReady: function() {
            console.log('onReady...');
            this.login();
        },

        playBell: function() {
          this.bell.play();
          this.bell.onended = _.bind(function() {
              this.bell.play();
          }, this);
        },

        stopBell: function() {
            this.bell.onended = null;
            if (this.bell.stop) {
                this.bell.stop();
            }
        },

        // Incoming calls
        /*jshint camelcase:false */
        onIncomingCall: function(account_name, extraHeaders) {
            this.callEventCall();
            this.playBell();
            console.log('onIncomingCall:' + account_name);
            console.log('account=' + extraHeaders['X-Ph-Caccount']);
            console.log('extraHeaders=');
            for (var key in extraHeaders) {
                console.log('key='+key+'.val='+extraHeaders[key]);
            }
            $('#status_txt').text('Incoming Call From: ' + account_name.split('@')[0]);
            $('#plivo-phone-number').html(extraHeaders['X-Ph-Caccount'] + ' : ' +
                (extraHeaders['X-Ph-Countrycode']?(extraHeaders['X-Ph-Countrycode'] + ' : '):'') +
                (extraHeaders['X-Ph-Customer']?extraHeaders['X-Ph-Customer']:account_name.split('@')[0]));
            this.showPlivoHeader('in');
            var phoneNumber = account_name.split('@')[0];
            App.request('customers:contacts:get-by-phone', encodeURI(phoneNumber))
              .done(_.bind(function(result) {
                  if (result && result.responseText) {
                      this.customerToSwitchTo = result.responseText;
                      $('.js-show-customer-offers').attr('data-route','#customers/' + this.customerToSwitchTo + '/offers');
                      $('.js-show-customer-offers').show();
                  } else {
                      Settings.setToMemory('plivoCurrentCallNumber', phoneNumber);
                      $('.js-link-phone-to-customer').attr('data-phoneNumber',phoneNumber);
                      $('.js-link-phone-to-customer').show();
                  }


                  this.displayNotification('New Incomoing call');
              },this));
        },

        displayNotification: function(title, message) {
            var hideDelay = 30000;
            if (window.Notification && window.Notification.permission === 'granted') {
                var options = {
                    body: message?message.replace(new RegExp('<br>', 'g'), ' '):'',
                    icon: Settings.url('base', Settings.get('defaultLogo'))
                };

                var notification = new window.Notification(title, options);
                notification.onclick = function () {
                    window.focus();
                };
                notification.onshow = function() {
                    window.setTimeout(function(){
                        notification.close();
                    }, hideDelay);
                };
            }
        },

        onCallTerminated: function() {
            console.log('onCallTerminated');
            this.customerToSwitchTo = null;
            this.plivoPhoneNumber = null;
            this.hidePlivoHeader('out');
            this.displayContainer = false;
            this.hideLinkButtons();
        },

        answer: function() {
            this.plivoPhoneNumber = Settings.getFromMemory('plivoCurrentCallNumber');
            this.stopBell();
            console.log('answering');
            $('#status_txt').text('Answering....');
            this.plivoWebSdk.client.answer();
            $('#status_txt').text('Answered the Call');
            this.hidePlivoHeader('in');
            this.displayIncoming = false;
        },

        // Reject incoming calls
        reject: function() {
            this.stopBell();
            $('#status_txt').text('Rejected the Call');
            this.plivoWebSdk.client.reject();
            this.hidePlivoHeader('in');
            this.displayIncoming = false;
            $('#status_txt').text('Ready');
            this.hideLinkButtons();

        },

        ignore: function() {
            this.stopBell();
            $('#status_txt').text('Ignore the Call');
            this.plivoWebSdk.client.ignore();
            this.hidePlivoHeader('in');
            this.displayIncoming = false;
            $('#status_txt').text('Ready');
            this.hideLinkButtons();

        },

        mute: function() {
            this.plivoWebSdk.client.mute();
            $('#linkUnmute').show();
            $('#linkMute').hide();
         },

        unmute: function() {
            this.plivoWebSdk.client.unmute();
            $('#linkMute').show();
            $('#linkUnmute').hide();
        },

        hangup: function() {
            this.hidePlivoHeader('out');
            $('#status_txt').text('Hanging up..');
            this.plivoWebSdk.client.hangup();
            this.displayContainer = false;
            this.displayIncoming = false;
            $('#status_txt').text('Ready');
            this.hideLinkButtons();

        },

        onCallFailed: function() {
            console.log('canceled');
            this.hidePlivoHeader('out');
            this.displayContainer = false;
            this.displayIncoming = false;
            this.customerToSwitchTo = null;
            this.plivoPhoneNumber = null;
            $('#status_txt').text('Ready');
            this.hideLinkButtons();

        },

        dtmf: function(digit) {
            console.log('send dtmf='+digit);
            this.plivoWebSdk.client.sendDtmf(digit);
        },

        plivoWebSdk: null,

        init: function() {
            if (Settings.get('plivoUser') && Settings.get('plivoPassword')) {
                var defaultSettings = {
                    debug: 'ERROR',
                    permOnClick: true,
                    codecs: ['OPUS','PCMU'],
                    enableIPV6: false,
                    audioConstraints: {
                        optional: [ {
                                googAutoGainControl: false
                            },
                            {googEchoCancellation:false}
                            ]},
                    enableTracking: true};

                this.plivoWebSdk = new window.Plivo(defaultSettings);
                this.plivoWebSdk.client.on('onReady', _.bind(this.onReady, this));
                this.plivoWebSdk.client.on('onWebrtcNotSupported', _.bind(this.webrtcNotSupportedAlert, this));
                this.plivoWebSdk.client.on('onLogin', _.bind(this.onLogin, this));
                this.plivoWebSdk.client.on('onLoginFailed', _.bind(this.onLoginFailed, this));
                this.plivoWebSdk.client.on('onCallRemoteRinging', _.bind(this.onCallRemoteRinging, this));
                this.plivoWebSdk.client.on('onIncomingCallCanceled', _.bind(this.onCallFailed, this));
                this.plivoWebSdk.client.on('onCallFailed', _.bind(this.onCallFailed, this));
                this.plivoWebSdk.client.on('onCallAnswered', _.bind(this.onCallAnswered, this));
                this.plivoWebSdk.client.on('onCallTerminated', _.bind(this.onCallTerminated, this));
                this.plivoWebSdk.client.on('onCalling', _.bind(this.onCalling, this));
                this.plivoWebSdk.client.on('onIncomingCall', _.bind(this.onIncomingCall, this));
                this.plivoWebSdk.client.on('onMediaPermission', _.bind(this.onMediaPermission, this));
                //this.plivoWebSdk.client.on('mediaMetrics', this.mediaMetrics);
                //this.plivoWebSdk.client.on('audioDeviceChange', this.audioDeviceChange);
                this.plivoWebSdk.client.setRingTone(false);
                this.plivoWebSdk.client.setRingToneBack(true);
                console.log('initPhone ready!');

                this.onReady();
            }
        },

        showPlivoHeader: function(target) {

            $('.js-tickets-header').css({ top: '140px' });

            $('#header-plivo-phone-number').hide();
            $('#header-plivo-btn-call').hide();
            $('.btn-header-phone-number').addClass('on-the-phone');
            switch(target) {
                case 'out':
                    $('#btn-container').show();
                    $('#plivo-sub-header').find('.out-icon-call').show();
                    break;
                case 'in':
                    $('#incoming_callbox').show('slow');
                    $('#plivo-sub-header').find('.in-icon-call').show();
                break;
            }
            if (this.customerToSwitchTo !== null) {
                $('.js-show-customer-offers').attr('data-route','#customers/' + this.customerToSwitchTo + '/offers');
                $('.js-show-customer-offers').show();
            } else if (this.plivoPhoneNumber !== null){
                Settings.setToMemory('plivoCurrentCallNumber', this.plivoPhoneNumber);
                $('.js-link-phone-to-customer').attr('data-phoneNumber',this.plivoPhoneNumber);
                $('.js-link-phone-to-customer').show();
            }
            window.setTimeout(function(){
                $('#plivo-sub-header').show();
                }, 1000);


        },

        hidePlivoHeader: function(target) {
            this.hideLinkButtons();
            this.stopBell();
            $('#plivo-sub-header').hide();
            $('.js-tickets-header').css({ top: '75px' });

            $('.btn-header-phone-number').removeClass('on-the-phone');
            $('#header-plivo-phone-number').show();
            $('#header-plivo-btn-call').show();
            switch(target) {
                case 'out':
                    $('#btn-container').hide();
                    $('#plivo-sub-header').find('.out-icon-call').hide();
                break;
                case 'in':
                    $('#incoming_callbox').hide();
                    $('#plivo-sub-header').find('.in-icon-call').hide();
                break;
            }
        },
        hideLinkButtons: function() {
            $('.js-show-customer-offers').hide();
            $('.js-link-phone-to-customer').hide();
            Settings.setToMemory('plivoCurrentCallNumber', null);
        }
    };

    module.exports = PlivoUtils;
});
