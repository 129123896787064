/**
 * Created by RKL on 21/08/2015.
 */
define('customersMainRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'underscore',
  'marionette',
  'app',
  'customersTabs',
  'customerContactsDetailsData',
  'itemPagesNameMixin',
  'entities/customers/customers'
], function(
  require,
  exports,
  module,
  Logger,
  _,
  Marionette,
  App,
  CustomersTabs,
  CustomerContactsDetailsData,
  ItemPagesNameMixin
) {
  'use strict';

  var CustomersRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'customers/code/:code(/:tab)': 'showByCode',
      'customers(/:id)(/:tab)(/:secId)(/:reload)': 'showView',
      'customers/:id/contacts/:secId/details/:tabName': 'showContactsDetailsShow',
      'customers(/:id)(/:tab)/:secId/variation/:varSecId(/:reload)': 'showVariationPopup'
    }
  });

  var API = {
    showByCode: function (code, tab) {
      this.customer = App.request('customers:entity:end-user-id', code);
      this.customer.fetch().done(_.bind(function (customer) {
        if (tab) {
          App.navigate('customers/' + customer.secId + '/' + tab, {trigger: true});
        } else {
          this.showView(customer.secId, tab ? tab : 'profile');
        }
      }, this));
    },

    showVariationPopup: function(id, tab, secId, varSecId, reload) {
      require([
        'customersShowController',
        'customersListController',
        'contentShowController'
      ], function(
          ShowController,
          ListController,
          ContentShowController
      ) {
        ContentShowController.showContent().done(function() {
          var data;
          data = {
            id: id,
            tab: _.findWhere(CustomersTabs, {name: tab}),
            secId: secId,
            varSecId: varSecId,
            type: 'customer',
            reload: reload === 'reload'
          };
          App.startSubModule('customersMain');
          if (id) {
            ShowController.fetchCustomer(data);
          } else {
            ListController.showCustomersList();
          }

          App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
        });
      });
    },

    showView: function(id, tab, secId, reload) {
      require([
        'customersShowController',
        'customersListController',
        'contentShowController'
      ], function(
        ShowController,
        ListController,
        ContentShowController
      ) {
        ContentShowController.showContent().done(function() {
          var data;
          if (secId === 'reload') {
            data = {
              id: id,
              tab:  _.findWhere(CustomersTabs, {name: tab}),
              type: 'customer',
              reload: true
            };
          } else {
            data = {
              id: id,
              tab: _.findWhere(CustomersTabs, {name: tab}),
              secId: secId,
              type: 'customer',
              reload: reload !== null
            };
          }
          App.startSubModule('customersMain');
          if (id) {
            $('#customer-comments-sidebar-region').show();
            ShowController.fetchCustomer(data);
          } else {
            ListController.showCustomersList();
          }
          
          App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
        });
      });
    },

    showContactsDetailsShow: function(id, contactSecId, tab) {
      require([
        'customerContactsDetailsController',
        'contentShowController'
      ], function(
        DetailsShowController,
        ContentShowController
      ) {
        ContentShowController.showContent().done(function() {
          var data;
          data = {
            id: id,
            tab: _.findWhere(CustomerContactsDetailsData, {name: tab}),
            secId: contactSecId,
            type: 'customer'
          };
           if (id) {
            DetailsShowController.fetchContact(data);
          } else {
            DetailsShowController.showContact(data);
          }
          App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
        });
      });
    }
  };

  App.on('customers:show', function(id, tab) {
    var linkId = id ? '/' + id : '',
      tabLink = tab ? '/' + tab : '';

    App.navigate('customers' + linkId + tabLink);
    API.showView(id, tab);
  });

  App.on('before:start', function() {
    new CustomersRouter({
      controller: API
    });
  });

  module.exports = CustomersRouter;
});
