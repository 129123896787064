/**
 * Created by RKL on 21/08/2015.
 */
define('ordersShowController',[
  'module',
  'logger',
  'underscore',
  'app',
  'ordersShowView',
  'ordersShowViewDesign2',
  'jquery',
  'controllerMixin',
  'rolesMixin',
  'settings',
  'entities/orders'
], function(
  module,
  Logger,
  _,
  App,
  View,
  ViewDesign2,
  $,
  ControllerMixin,
  RolesMixin,
  Settings
) {
  'use strict';

  var OrdersController = _.extend({
    showOrders: function(data, ViewInstance, showComments) {
      if (!showComments) {
        $('#customer-comments-sidebar-region').hide();
      }
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
          this.createView(data, ViewInstance || ViewDesign2);
      } else {
          this.createView(data, ViewInstance || View);
      }


      this.subscribeEvents();
      this.showRegion();
    },

    createView: function(data, ViewInstance) {
      var params = data ? {
        endUserSecId: data.model.get('secId')
      } : undefined; 
      var view = new ViewInstance(params);
      this.view = view;
      this.data = data;
    },

    showRegion: function() {
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    },

    subscribeEvents: function() {  
      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      this.view.listenTo(this.view, 'onLoadSelectedOrders', _.bind(this.onLoadSelectedOrders, this));
      this.view.listenTo(this.view, 'order:update', _.bind(this.onOrderUpdate, this));
    },

    onGridLoaded: function() {
      var fetchStatuses = App.request('order:statuses', false);
      $.when(fetchStatuses).done(_.bind(function(statuses) {
          this.initializeGrid(this.formatedStatus(statuses));
      }, this));
    },

    formatedStatus: function(statuses) {
        var selectDefault = this.view.selectDefault,
            gridStatuses = selectDefault + ':' + selectDefault;

        statuses.each(function(status) {
            gridStatuses += ';' + status.get('statusConst') + ':' +
                status.get('status');
        });

        return gridStatuses;
    },

    initializeGrid: function(gridStatuses) {
      var data = {
          url: App.request('order:get-collection-url'),
          rowCountUrl: App.request('order:get-count-url'),
          statuses: gridStatuses
        };
        this.view.displayGrid(data,true);
    },

    onLoadSelectedOrders: function(first, last, params) {
      $.when(App.request('order:get-collection-to-page', params))
          .done(_.bind(function(orders) {
            this.view.triggerMethod('selectOrders', first, last, orders);
            $('#content-blinder').hide();
          }, this))
          .fail(function() {
            $('#content-blinder').hide();
          });
    },

    onOrderUpdate: function(data) {
      $.when(App.request('order:update', data));
    }
  }, ControllerMixin);

  module.exports = OrdersController;
});
