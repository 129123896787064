
define('template!mailTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="loading-block js-loading-block">\n\t<span class="loading-message js-loading-message label"></span>\n</div>\n<div class="row tickets-message-container">\n    ';
 if (direction === 'from') { ;
__p += '\n        <div class="col-xs-3 col-sm-1 avatar-section p-r-0">\n            <i class="mdi-action-account-circle avatar-messages-block"></i>\n            <p class="first-name hidden-xs" title="' +
__e( from ) +
'" data-toggle="tooltip" data-placement="right">\n                ' +
__e( from ) +
'\n            </p>\n        </div>\n        <div class="col-xs-8 col-sm-7 message-section p-l-0">\n            <div class="row">\n                <div class="col-xs-12 date-container">\n                    <i class="mdi-action-alarm"></i>\n                    <span class="m-time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n                    <i class="mdi-action-today"></i>\n                    <span class="m-day">' +
((__t = ( day )) == null ? '' : __t) +
'</span>\n                </div>\n            </div>\n            <div class="row">\n                <div class="col-xs-12">\n                    <div class="well message-container col-xs-12">\n                        <iframe class="message-iframe col-xs-12"\n                                style="width: 100%; border: 0; display: block;">\n                            ' +
((__t = ( contentDoc )) == null ? '' : __t) +
'\n                        </iframe>\n                    </div>\n                </div>\n            </div>\n        </div>\n        ';
 } else { ;
__p += '\n        <div class="col-xs-offset-1 col-sm-offset-4 col-xs-8 col-sm-7 message-section p-r-0">\n            <div class="row">\n                <div class="col-xs-12 date-container">\n                    <i class="mdi-action-alarm"></i>\n                    <span class="m-time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n                    <i class="mdi-action-today"></i>\n                    <span class="m-day">' +
((__t = ( day )) == null ? '' : __t) +
'</span>\n                </div>\n            </div>\n            <div class="row">\n                <div class="col-xs-12">\n                    <div class="well message-container col-xs-12">\n                        <iframe class="message-iframe col-xs-12"\n                                style="width: 100%; border: 0; display: block;">\n                            ' +
((__t = ( contentDoc )) == null ? '' : __t) +
'\n                        </iframe>\n                        <hr>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="col-xs-3 avatar-section p-l-0 col-sm-1">\n            <i class="mdi-action-account-circle avatar-messages-block"></i>\n            <p class="first-name hidden-xs" title="' +
__e( from ) +
'" data-toggle="tooltip" data-placement="right">\n                ' +
__e( from ) +
'\n            </p>\n        </div>\n    ';
 } ;
__p += '\n</div>';

}
return __p
};});

