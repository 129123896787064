/**
 * Created by ASK on 28/0/2021.
 */
define('pimProductsListController',[
  'module',
  'logger',
  'underscore',
  'app',
  'jquery',
  'bootbox',
  'pimProductsListView',
  'settings',
  'entities/pim_product',
  'entities/caccounts/share'
], function (
  module,
  Logger,
  _,
  App,
  $,
  bootbox,
  View
) {
  'use strict';

  var PimProductsController =
    {
      showProducts: function () {
        var view = new View();
        this.view = view;

        view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
        view.listenTo(view, 'onUse', _.bind(this.onUseProduct, this));
        view.listenTo(view, 'onDeleteResellerProduct', _.bind(this.onDeleteResellerProduct, this));
        App.regions.getRegion('main').currentView.getRegion('content').show(view);
      },

      onGridLoaded: function () {
        App.request('caccounts:get-share-from-sorted').done(_.bind(function (list) {
          var selectDefault = this.view.selectDefault,
            gridStatuses = selectDefault + ':' + selectDefault;

          _.each(list, function (status) {
            gridStatuses += ';' + status.caccountFrom.secId + ':' +
              status.caccountFrom.code;
          });

          var data = {
            url: App.request('pim:products:get-collection-url'),
            rowCountUrl: App.request('pim:products:row-count-url'),
            manufacturers: gridStatuses
          };

          this.view.displayGrid(data, true);
        }, this));

      },

      onUseProduct: function (productId, displayWarning) {
        var that = this,
          useRequest;

        function useProduct() {
          useRequest = App.request('pim:products:use', productId);
          $.when(useRequest).done(function () {
            that.view.updatePagination('reloadGrid');
          });
        }

        if (displayWarning) {
          bootbox.confirm(_.i18n('warning.usePimProductAgain'), function (result) {
            if (result) {
              useProduct();
            }
          });
        } else {
          useProduct();
        }
      },

      onDeleteResellerProduct: function (productId, productCode) {
        var that = this,
          deleteRequest;

        bootbox.confirm(_.i18n('warning.deletePimProduct') + ' ' + productCode, function (result) {
          if (result) {
            deleteRequest = App.request('pim:products:delete', productId);
            $.when(deleteRequest).done(function () {
              that.view.updatePagination('reloadGrid');
            });
          }
        });
      }
    };

  module.exports = PimProductsController;
});
