define( 'entities/mails',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var Mail = Backbone.Model.extend({
    defaults: {
    },
    idAttribute: 'secId',

    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/gmail/' + this.get('secId'));
      return Backbone.Model.prototype.fetch.call(this);
    }
  });

  /**
   * Collection
   */
  var MailsCollection = Backbone.Collection.extend( {
      model: Mail
  });

  /**
   * REST API
   */
  var API = {
    getMailsCollectionByEndUserSecId: function(endUserSecId) {
      var mailsCollection = new MailsCollection();
      mailsCollection.url = Settings.url('compuzz', 'endusers.json/' + endUserSecId + '/mails');
      return mailsCollection;
    },

    getMailsCollectionByEndUserContactSecId: function(endUserContactSecId) {
        var mailsCollection = new MailsCollection();
        mailsCollection.url = Settings.url('compuzz', 'v2/endusercontact/' + endUserContactSecId + '/mails');
        return mailsCollection;
    },

    sendMail: function(mail) {
      var defer = $.Deferred();
      $.ajax({
          type: 'POST',
          url: Settings.url('compuzz', 'v2/gmail/sendmail'),
          data: JSON.stringify(mail),
          processData: false,
          contentType: 'application/json',
          success: function() {
              defer.resolve();
          }
      });

      return defer.promise();
    },

    getMailsCollectionUrl: function() {
      var params = {};
      return Settings.url('compuzz', 'v2/gmail/', params);
    },

    getMailsCountUrl: function() {
      var params = '';
      return 'v2/gmail/rowCount' + params;
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('mails:find-by-enduser', function(endUserSecId) {
    return API.getMailsCollectionByEndUserSecId(endUserSecId);
  });

  App.reqres.setHandler('mails:find-by-endusercontact', function(endUserContactSecId) {
    return API.getMailsCollectionByEndUserContactSecId(endUserContactSecId);
  });

  App.reqres.setHandler('mails:send', function(mail) {
      return API.sendMail(mail);
  });

  App.reqres.setHandler('mail:model', function(modelJSON) {
    return new Mail(modelJSON);
  });

  App.reqres.setHandler('mails:get-collection-url', function() {
    return API.getMailsCollectionUrl();
  });

  App.reqres.setHandler('mails:get-count-url', function() {
    return API.getMailsCountUrl();
  });
});
