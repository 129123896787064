define('numbersListView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!numbersListTpl',
    'datePickerPopoverMixin'
  ], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl,
    datePickerPopoverMixin
  ) {
    'use strict';
  
    var NumbersListView = JqGridView.extend({
      template: viewTpl,
      filtersName: 'numbersListFilters',
  
      triggers: {
          'click .new-phonenumber-button': 'addNewPhoneNumber' //Caught by controller
      },

      ui: function() {
        return _.extend({
          jqGrid: '#jq-grid-list',
          jqGridPager: '#jq-grid-pager-list'
        }, this.gridUi);
      },
  
      events: function() {
        return _.extend({
        }, this.gridEvents);
      },

      pagination: function() {
        return {
          first: 0,
          rowCount: 0,
          pageSize: Settings.get('perPage15'),
          currentPage: 1
        };
      },
      
      gridOptions: function(data) {
        var defaultsOptions = this.gridInitOptions(data);
        this.sidx = 'userTimestamp.creatDate';
        this.sord = 'desc';
        var options = {
            sortname: this.sidx,
            sortorder: this.sord,
            colModel: [
              {
                label: '',
                name: 'secId',
                key: true,
                hidden: true
              },
              {
                  label: _.i18n('common.country'),
                  name: 'refCountry',
                  classes: 'cell-link',
                  formatter:  this.defaultFormatter,
                  index: 'refCountry',
                  search: false,
                  jsonmap: 'refCountry.name'
              },
              {
                label: _.i18n('phone.number.number'),
                name: 'number',
                classes: 'cell-link',
                formatter:  this.defaultFormatter,
                index: 'number',
                search: false
              }
            ],
            serializeGridData: function(postData) {
                if (postData.numberDate) {
                  postData.dateFrom = datePickerPopoverMixin.parseDateFrom(postData.numberDate);
                  postData.dateTo = datePickerPopoverMixin.parseDateTo(postData.numberDate);
                } else {
                  delete postData.dateFrom;
                  delete postData.dateTo;
                  delete postData.date;
                }
      
                if (postData.sidx === 'numberDate') {
                  postData.sidx = 'userTimestamp.creatDate';
                }
      
                defaultsOptions.serializeGridData.apply(this, arguments);
      
                return postData;
              }
          };
  
        options = _.defaults(options, defaultsOptions);
  
        return options;
      }
    });
  
    module.exports = NumbersListView;
  });
