define('popupPrintAreaView',[
  'module',
  'marionette',
  'app',
  'backbone',
  'underscore',
  'jquery',
  'colorpicker',
  'settings',
  'dialogFormView',
  'dynamicTextView',
  'template!popupCreatePrintAreaTpl',
  'entities/product_share',
  'entities/product_translation'
], function (
  module,
  Marionette,
  App,
  Backbone,
  _,
  $,
  colorpicker,
  Settings,
  DialogFormView,
  DynamicTextView,
  popupCreateAreaTpl
) {
  'use strict';

  module.exports = DialogFormView.extend({
    template: popupCreateAreaTpl,

    ui: {
      colorBtn: '.colorpick',
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      bgOptionColor: '.bg-option-color'
    },

    regions: {
      nameRegion: '.name-region'
    },

    events: {
      'click @ui.colorBtn': 'onShowColorpicker',
      'click .js-cancel-popup': 'hide',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange',
      'click @ui.confirm': 'onConfirm',
      'click @ui.cancel': 'onCancel',
      'change input[type=radio]': 'onRadioBackgoundChange',
      'change @ui.bgOptionColor': 'selectOptionCategory'
    },

    modelEvents: {
      sync: 'onSave'
    },

    initialize: function () {
      this.modelBackup = this.model.clone();
      this.modelBackup.collection = this.model.collection;
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      if (templateData.refBackgroundProductOptionCategory) {
        templateData.bgColorMode = 3;
      } else if (templateData.refBackgroundColor !== null && templateData.refBackgroundColor !== undefined) {
        templateData.bgColorMode = 2;
      } else {
        templateData.bgColorMode = 1;
      }

      templateData.color = null;
      if (templateData.refBackgroundColor && templateData.refBackgroundColor.indexOf('#') === 0) {
        templateData.color = templateData.refBackgroundColor;
      }

      templateData.optionCategories = [];

      _.each(this.model.collection.modelProduct.get('productOptionCategories').models, function (poc) {
        templateData.optionCategories.push({
          secId: poc.get('secId'),
          name: poc.get('name').getTextInLocale(Settings.get('lang'))
        });
      });

      return templateData;
    },

    onRadioBackgoundChange: function (e) {
      var $target = $(e.currentTarget);
      if ($target.is(':checked')) {
        switch ($target.val()) {
          case 'transparent':
            this.model.set('refBackgroundColor', null);
            this.model.set('refBackgroundProductOptionCategory', null);
            break;
          case 'color':
            this.model.set('refBackgroundProductOptionCategory', null);
            this.model.set('refBackgroundColor', '#000000');
            break;
          case 'option-color':
            this.model.set('refBackgroundColor', null);
            this.selectOptionCategory();
            break;
        }
        this.render();
      }
    },

    onShowColorpicker: function (event) {
      var target = $(event.currentTarget),
        newColor;

      target.colorpicker();
      target.colorpicker('show');
      target.on('changeColor.colorpicker', _.bind(function (event) {
        newColor = event.color.toHex();
        this.model.set('refBackgroundColor', newColor);
      }, this));
    },

    selectOptionCategory: function () {
      var current = _.find(this.model.collection.modelProduct.get('productOptionCategories').models, _.bind(function (m) {
        return m.get('secId') === this.ui.bgOptionColor.find('option:selected').val();
      }, this));
      this.model.set('refBackgroundProductOptionCategory', current);
    },

    onRender: function () {
      $.material.init();
      this.nameDtView = new DynamicTextView({model: this.model.get('name'), noSaving: true});
      this.getRegion('nameRegion').show(this.nameDtView);
    },

    onConfirm: function () {
      //var dtIt = App.request('products-translation:dynamictext-model', this.selectedIt.get('name'));
      //this.model.set('name', dtIt.getTextInLocale(Settings.get('lang')));
      this.model.save();
    },

    onEscape: function () {
      if (!this.newItem) {
        this.model = null;
      }
    },

    onCancel: function () {
      if (!this.newItem) {
        this.model = null;
      }
      this.hide();
    },

    onSave: function () {
      this.triggerMethod('saved');
      this.model = this.modelBackup;
      this.hide();
    }
  });
});
