
define('template!produceProofsheetTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="well full-height">\n  <iframe class="iframeCompuzz" id="iframeCompuzz" src="' +
((__t = ( url )) == null ? '' : __t) +
'" frameborder="0" seamless>\n  </iframe>\n</div>';

}
return __p
};});

