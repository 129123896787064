define('notificationsView',[
  'module',
  'app',
  'marionette',
  'settings',
  'template!notificationsViewTpl',
  'dateUtils'
], function (
  module,
  App,
  Marionette,
  Settings,
  notificationsViewTpl,
  dateFormatter
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    tagName: 'li',
    template: notificationsViewTpl,

    ui: {
      deleteNotification: '.delete-notification'
    },

    events: {
      'click @ui.deleteNotification': 'onClickDeleteNotification'
    },

    onClickDeleteNotification: function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.triggerMethod('notifications:delete');
    },

    serializeData: function () {
      var tplData = this.model.toJSON();
      if (tplData.creatDate) {
        tplData.creatDate = dateFormatter.toDateTimeFormatString(tplData.dateDue);
      }
      if (tplData.content && tplData.content.length > 100) {
        tplData.content = tplData.content.substr(0, 100);
      }
      return tplData;
    }
  });
});
