/**
 * Created by BFR on 23/09/2021.
 */
define('cAccountInfoView',[
  'module',
  'logger',
  'marionette',
  'underscore',
  'template!cAccountInfoTpl'
], function(
  module,
  Logger,
  Marionette,
  _,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
    }
  });
});
