define('leadsTabs',[
  'underscore',
  'rolesMixin'
], function (_,
             RolesMixin) {
  'use strict';

  var customersTabs = [
    {
      route: 'profile',
      name: 'profile',
      role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE,
      title: _.i18n('endUsersDetail.profile.tabTitle')
    },
    {
      route: 'contacts',
      name: 'contacts',
      role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS,
      title: _.i18n('endUsersDetail.contacts.tabTitle')
    },
    {
      route: 'calls',
      name: 'calls',
      role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CALLS,
      title: _.i18n('endUsersDetail.calls.tabTitle')
    }
  ];

  return customersTabs;
});
