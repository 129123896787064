
define('template!popupCreateProductTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'>\n    <div class="error-msg"></div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      ';
 if (!readOnly) { ;
__p += '\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.code') + '/' + _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="code"\n                 placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n        </div>\n        <div class="col-xs-7 name-region p-t-5"></div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 description-region p-t-5"></div>\n      </div>\n      ';
 } ;
__p += '\n      ';
 if (allowEditComment) { ;
__p += '\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <textarea type="text" class="form-control floating-label js-info-input" data-field-name="comment"\n                    placeholder="' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'" style="height: 270px;">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      ';
 if (!readOnly) { ;
__p += '\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.width') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="width"\n                 placeholder="' +
((__t = ( _.i18n('common.width') )) == null ? '' : __t) +
'" value="' +
((__t = ( width )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.height') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="height"\n                 placeholder="' +
((__t = ( _.i18n('common.height') )) == null ? '' : __t) +
'" value="' +
((__t = ( height )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.deepness') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="deepness"\n                 placeholder="' +
((__t = ( _.i18n('common.deepness') )) == null ? '' : __t) +
'" value="' +
((__t = ( deepness )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.weight') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="weight"\n                 placeholder="' +
((__t = ( _.i18n('common.weight') )) == null ? '' : __t) +
'" value="' +
((__t = ( weight )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.minimumOrderQuantity') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="minimumOrderQuantity"\n                 placeholder="' +
((__t = ( _.i18n('common.minimumOrderQuantity') )) == null ? '' : __t) +
'" value="' +
((__t = ( minimumOrderQuantity )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.realSupplier') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="realSupplier"\n                 placeholder="' +
((__t = ( _.i18n('common.realSupplier') )) == null ? '' : __t) +
'" value="' +
((__t = ( realSupplier )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      <div class="form-group" data-field-name="dtpLevel">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.dtpLevel') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input js-popup-input" data-field-name="dtpLevel" value="' +
((__t = ( dtpLevel )) == null ? '' : __t) +
'"\n                  placeholder="' +
((__t = ( _.i18n('common.dtpLevel') )) == null ? '' : __t) +
'">\n            <option value="">None</option>\n            <option value="1" ';
 if (1 === dtpLevel) { ;
__p += ' selected ';
 } ;
__p += ' >1 Low</option>\n            <option value="2" ';
 if (2 === dtpLevel) { ;
__p += ' selected ';
 } ;
__p += ' >2 Medium</option>\n            <option value="3" ';
 if (3 === dtpLevel) { ;
__p += ' selected ';
 } ;
__p += ' >3 Hard</option>\n          </select>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

