/**
 * Created by BFR on 07/10/2021.
 */
define('adminTasksController',[
  'module',
  'jquery',
  'underscore',
  'app',
  'adminTasksView',
  'settings',
  'bootbox',
  'entities/admin/tasks'
], function (
  module,
  $,
  _,
  App,
  View,
  Settings,
  bootbox
) {
  'use strict';

  module.exports = {

    onGridLoaded: function () {
      var data = {
        url: App.request('tasks:get-url'),
        rowCountUrl: App.request('tasks:row-count-url')
      };

      this.view.displayGrid(data, true);

      if (this.editTaskSecId) {
        this.view.onEditItem(this.editTaskSecId);
      }
    },

    _showView: function (secId) {
      this.view = new View({});
      this.editTaskSecId = secId;
      this.view.listenTo(this.view, 'tasks:remove', _.bind(this.onDelete, this));
      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    },

    onDelete: function (secId) {
      bootbox.confirm(_.i18n('customer.confirmDelete'), _.bind(function (result) {
        if (result) {
          App.request('tasks:delete', secId)
            .done(_.bind(function () {
              this.view.updatePagination(true);
            }, this));
        }
      }, this));
    }
  };
});
