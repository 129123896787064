/**
 * Created by BFR on 06/12/2019.
 */
define('trackingTabController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'trackingTabView',
  'trackingCreateEditLayoutView',
  'entities/tracking'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  CreateEditLayoutView
) {
  'use strict';

  var TrackingTabController = {
    showTab: function(orderModel, region) {
      this.model = orderModel;

      this.view = new View();

      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      this.view.listenTo(this.view, 'onCreateTracking', _.bind(this.onCreateTracking, this));
      this.view.listenTo(this.view, 'onDelete', _.bind(this.onDeleteTracking, this));

      region.show(this.view);
    },

    onGridLoaded:function() {
      var data = {
          url: App.request('tracking-tab:get-url', this.model.get('purchaseOrderId')),
          rowCountUrl: App.request('tracking-tab:row-count-url', this.model.get('purchaseOrderId'))
        };

      this.view.displayGrid(data);
    },

    onCreateTracking: function() {
        var createEditView = new CreateEditLayoutView({
            trackingModel: App.request('tracking-tab:new-model'),
            poi: this.model.get('purchaseOrderId'),
            newItem: true
        });
        createEditView.show({
            title: _.i18n('common.new')
        });
    },

    onDeleteTracking: function(trackingId) {
      var that = this,
        deleteRequest;

      bootbox.confirm(_.i18n('warning.deleteTracking'), function (result) {
        if (result) {
          deleteRequest = App.request('tracking-tab:delete', undefined, trackingId);
          $.when(deleteRequest).done(function() {
            that.view.updatePagination('reloadGrid');
          });
        }
      });
    }
  };

  module.exports = TrackingTabController;
});

