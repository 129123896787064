define('customerTemplatesPopupVariationsView',[
  'module',
  'logger',
  'template!customerTemplatesPopupVariationsTpl',
  'app',
  'settings',
  'dialogView',
  'template!customerCommonPopupFromTemplateItemTpl',
  'underscore',
  'emptyView',
  'customerTemplatesItemView',
  'customerTemplatesNewItemView',
  'customerTemplatesCollectionView',
  'noItemsView',
  'marionette',
  'template!elementsCollectionTpl',
  'customersCopyPasteMix',
  'entities/caccounts/templates'
], function(
  module,
  Logger,
  templatePopupVariationsTpl,
  App,
  Settings,
  DialogView,
  fromTemplateItemTpl,
  _,
  EmptyView,
  ItemView,
  NewItemView,
  ElementsView,
  NoItemsView,
  Marionette,
  templateTpl,
  CopyPasteMix
) {
  'use strict';

  var LoaderView =  Marionette.ItemView.extend({
    template: _.template('Loading...'),
    tagName: 'div',
    className: 'loader display-inline-block empty-page-loader m-0'
  });

  var TemplateCollectionView = Marionette.CollectionView.extend({


    getChildView: function (item) {
      if (item === this.fakeLoadingTemplate) {
        return LoaderView;
      } else if (item === this.fakeNewTemplate) {
        return NewItemView;
      } else {
        return ItemView;
      }
    },



    emptyView: NoItemsView,
    template: templateTpl,

    className: 'from-template-item-list',

    events: {
      'scroll': '_onScroll'
    },

    allCollection: undefined,

    fakeNewTemplate: undefined,
    fakeLoadingTemplate: undefined,

    initialize: function () {
      this.allCollection = this.collection.clone();
      this.collection.reset(this.collection.slice(0, 6));

      //Should I display the (Add template button) ?
      this.fakeNewTemplate = this.collection.findWhere({isFakeAddTemplate : true});

      //Create another fake template at the end that will be rendered by a Loader
      this.fakeLoadingTemplate = this.collection.add({});
      this._onLazyLoadThrottled = _.throttle(_.bind(this._onLazyLoad, this), 300);
    },

    onAttach: function() {
      setTimeout(_.bind(this._onLazyLoad, this), 50);
    },

    onShow: function() {
      this.on('childview:templates:unselect', _.bind(this.unselectChildren, this));
      App.off('copy-paste:unselectAll:fromVariations');
      App.on('copy-paste:unselectAll:fromVariations', _.bind(this.unselectAll, this));
    },

    unselectAll: function() {
        this.children.each(_.bind(function (childView) {
          childView.$el.removeClass('selected');
        }, this));
    },

    unselectChildren: function(view, model, shifted) {
      if (shifted) {
        this.shiftedMultiselection(model);
      } else {
        this.children.each(_.bind(function (childView) {
          childView.triggerMethod('unselectChildren', model);
        }, this));
      }
    },

    shiftedMultiselection: function(model) {
      var selectionIndex = this.collection.indexOf(model),
          viewModel,
          index,
          selected,
          firstIndex,
          lastIndex,
          flag;
        // find first - last selections items
        this.children.each(_.bind(function (childView) {
          viewModel = childView.model;
          index = this.collection.indexOf(viewModel);
          selected = childView.$el.hasClass('selected');

          if (viewModel.get('isFakeAddTemplate')) return;
          if (selected) {
            if (index <= selectionIndex && !firstIndex) {
              firstIndex = index;
            } 
            if (index >= selectionIndex && !flag) {
              if (firstIndex === selectionIndex) {
                lastIndex = index;
              } else {
                lastIndex = index;
                flag = true;
              }
            }
          }
        }, this));
        // select needed
        this.children.each(_.bind(function (childView) {
          selected = childView.$el.hasClass('selected');
          viewModel = childView.model;
          index = this.collection.indexOf(viewModel);

          if (viewModel.get('isFakeAddTemplate')) return;
          if (selected) {
            if (index < firstIndex) {
              childView.triggerMethod('unselectChildren', null);
            }
            if (index > lastIndex) {
              childView.triggerMethod('unselectChildren', null);
            }
          } else {
            if (index > firstIndex && index < lastIndex) {
              childView.triggerMethod('selectChildren');
            }
          }
        }, this));
    }, 

    _onLazyLoad: function() {
      var lastIndex = this.collection.length - 1;
      if(this._checkLoaderIsVisible()) {
        this.collection.add(this.allCollection.at(lastIndex), {at: lastIndex});
        setTimeout(_.bind(this._onLazyLoad, this), 50);
      }
      if(this.allCollection.length < this.collection.length) {
        this.collection.pop();
        this.$el.off('scroll');
      }
    },

    _onLazyLoadThrottled: undefined,

    _checkLoaderIsVisible: function() {
      var scrollContainer = this.$el,
          height = scrollContainer.height() || 75,
          scrollBlock, top;
      if(!this.children.findByModel(this.fakeLoadingTemplate)) {
        return;
      }

      scrollBlock = this.children.findByModel(this.fakeLoadingTemplate).$el;
      top = scrollBlock.offset().top - scrollContainer.offset().top;

      if (top < height) {
        return true;
      }
    },

    _onScroll: function() {
      this._onLazyLoadThrottled();
    },

    childViewOptions: function() {
      return {
        customerSecId: this.options.customerSecId,
        groupModel: this.options.groupModel,
        showFromProduct: this.options.showFromProduct,
        subdomain: this.options.subdomain,
        showVariationsButton: false,
        templateParent: this.options.templateParent
      };
    }
  });

  var TemplatePopupVariationsView = DialogView.extend({
    template: templatePopupVariationsTpl,

    ui: {
      createProgress: '.variant-upload-progress',
      blinder:        '.variant-blinder'
    },

    regions: {
      templates: '.js-templates-region',
      customerActions: '.js-action-buttons-region'
    },

    templatesCollection: undefined,
    templatesCollectionView: undefined,
    templateParentSecId: undefined,

    initialize: function(options) {
      if (options.variations) {
        this.templatesCollection = options.variations;
      }
      if (options.templateParentSecId) {
        this.templateParentSecId = options.templateParentSecId;
        Settings.set('lastVariationsParent', this.templateParentSecId);
      } else {
        Settings.set('lastVariationsParent', null);
      }
      _.extend(this, CopyPasteMix);
    },

    onShowProgressBar: function() {
      this.ui.createProgress.css('visibility', 'visible');
      this.ui.blinder.show();
    },

    onHideProgressBar: function() {
      this.ui.createProgress.css('visibility', 'hidden');
      this.ui.blinder.hide();
    },

    onShow: function () {
      this.$el.closest('.modal-content').width('700px');
      this.prepareCopyPasteActionView('fromVariations');
      if (this.templatesCollection) {
        this._showTemplates();
      } else {
        Logger.log(module,'Please set templatesCollection in options');
      }
      $.material.init();
    },

    onHide: function() {
      this.offCopyPasteEvents();
    },

    onSave: function(models) {
      if (this.templatesCollectionView) {
        this.templatesCollectionView.collection.add(models);
      }
    },

    _showLoadingTemplates: function() {
      this.getRegion('templates').show(new EmptyView());
    },

    _showTemplates: function() {
      this.templatesCollectionView = new TemplateCollectionView({
        collection: this.options.variations,
        customerSecId: this.options.customerSecId,
        groupModel: this.options.groupModel,
        showFromProduct: this.options.showFromProduct,
        templateParent : this.options.templateParent,
        subdomain: this.options.subDomain
      });

      this.getRegion('templates').show(this.templatesCollectionView);
    },

    onDestroy: function() {
      Settings.set('lastVariationsParent', null);
      if(this.options &&
         this.options.variations) {
        this.options.variations.reset();
      }
      DialogView.prototype.onDestroy.call(this);
    }
  });

  module.exports = TemplatePopupVariationsView;
});
