/**
 * Created by GSP on 28/08/2017.
 */
define('callsListController',[
  'module',
  'underscore',
  'app',
  'callsListView',
  'settings',
  'controllerMixin',
  'entities/phone/calls'
], function (
  module,
  _,
  App,
  View,
  Settings,
  ControllerMixin
) {
  'use strict';

  var CallsListController = _.extend({

    show: function (data, ViewInstance) {
      this.createView(data, ViewInstance || View);
      this.subscribeEvents();
      this.showRegion();
    },

    createView: function (data, ViewInstance) {
      var params = data ? {
        endUserSecId: data.model.get('secId')
      } : undefined;
      var view = new ViewInstance(params);
      this.view = view;
      this.data = data;
    },

    showRegion: function () {
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    },

    subscribeEvents: function () {
      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      this.view.listenTo(this.view, 'calls:download', _.bind(this.onDownload, this));
    },

    onGridLoaded: function () {
      this.initializeGrid();
    },

    initializeGrid: function () {
      var data = {
        url: App.request('calls:get-collection-url'),
        rowCountUrl: App.request('calls:row-count-url')
      };

      this.view.displayGrid(data, true);
    },

    onDownload: function (secId) {
      App.request('calls:records', secId).done(function (record) {
        window.open(record.value, '_blank');
      });
    }
  }, ControllerMixin);

  module.exports = CallsListController;
});
