/**
 * Created by OLD on 28/05/2019.
 */
define('offersKanbanMain',[
  'module',
  'logger',
  'offersKanbanRouter'
], function (
  module,
  Logger
) {
    'use strict';

    var OffersKanbanMain = {
      start: function () {
        Logger.log(module, 'starting OffersKanbanModule');
      },

      stop: function () {
        Logger.log(module, 'stopping OffersKanbanModule');
      }
    };

    module.exports = OffersKanbanMain;
  });
