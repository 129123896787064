define('notificationCenter',[
    'module',
    'settings',
    'underscore',
    'jquery',
    'logger',
    'marionette',
    'rolesMixin',
    'notify'
], function (module, Settings, _, $, Logger, Marionette, RolesMixin) {
    'use strict';

    var events = {
        DELETE_ORDER: 'notificationCenter:deleteOrder',
        CHANGE_INFO_ORDER: 'notificationCenter:changeInfoOrder',
        CHANGE_INFO_TICKET: 'notificationCenter:changeInfoTicket',
        NEW_VECTORISATION_TICKET_MESSAGE: 'notificationCenter:newVectorizeTicketMessage',
        NEW_VECTORISATION_TICKET: 'notificationCenter:newVectorizeTicket',
        NEW_CONVERSION_TICKET_MESSAGE: 'notificationCenter:newConversionTicketMessage',
        NEW_CONVERSION_TICKET: 'notificationCenter:newConversionTicket'
    };

    var instance = null;

    var NotificationCenter = Marionette.Object.extend({

        cAccountSecId: null,
        request: null,
        connection: null,

        setupWebSocket: function () {
            var webSocketUrl = Settings.compuzzAwsWs + '?cAccountUid=' + this.cAccountSecId + '&user=' +
                Settings.get('currentUser').logonId + '&userSecId=' + Settings.get('currentUser').secId +
                '&tokenId=' + Settings.get('tokenId');
            this.connection = new WebSocket(webSocketUrl);

            this.connection.onopen = function() {
                console.log('Connected to WebSocket server.');
            };

            this.connection.onmessage = _.bind(this.onMessage, this);

            this.connection.onerror = function(error) {
                console.error('WebSocket Error: ' + error);
            };

            this.connection.onclose = _.bind(function() {
                console.log('WebSocket connection closed. Attempting to reconnect...');
                setTimeout(_.bind(function() {
                    this.setupWebSocket();
                }, this), 3000); // Reconnect after 3 seconds
            }, this);
        },

        subscribe: function (cAccountSecId) {
            if (this.cAccountSecId !== cAccountSecId) {
                this.cAccountSecId = cAccountSecId;
                this.setupWebSocket();
            }
        },

        unSubscribe: function () {
            if (this.connection) {
                this.connection.close();
            }
        },

        onMessage: function (response) {
            try {
                var message = JSON.parse(response.data);
                if (this._events && this._events['notificationCenter:' + message.type]) {
                    this.triggerMethod('notificationCenter:' + message.type, message);
                } else {
                    if (!Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR] ||
                        Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR_VIEW_ALL]) {
                        this.processNotification(message);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },

        processNotification: function (notification) {
            switch (notification.type) {
                case 'newVectorizeTicket':
                    this.displayNotification(_.i18n('notification.newvectorizeticket'), notification.content.name,
                        '#vectorization/' + notification.content.secId);
                    break;
                case 'newVectorizeTicketMessage':
                    this.displayNotification(_.i18n('notification.newvectorizeticketmessage'), notification.content.content,
                        '#vectorization/' + notification.content.ticketSecId);
                    break;
                case 'newConversionTicket':
                    this.displayNotification(_.i18n('notification.newconversionticket'), notification.content.name,
                        '#vectorization/' + notification.content.secId);
                    break;
                case 'newConversionTicketMessage':
                    this.displayNotification(_.i18n('notification.newconversionticketmessage'), notification.content.content,
                        '#vectorization/' + notification.content.ticketSecId);
                    break;
                case 'MissingBillingInfo':
                    /*
                    this.displayNotification(_.i18n('notification.missingBillingInfo.title'),
                        _.i18n('notification.missingBillingInfo.message'),
                        '#caccount/' +  Settings.get('currentCAccount').secId, 5000, 'danger', true);
                    */
                    break;
            }
        },

        removeListener: function (view, event, callback) {
            this.off(event, callback, view);
        },

        addListener: function (view, event, callback) {
            this.on(event, callback, view);
            view.listenTo(view, 'before:destroy', _.bind(function () {
                this.off(event, callback, view);
            }, this));
        },

        displayNotification: function (title, message, urlFromFragment, hideDelay, type, forceJsNotification) {
            var fullUrl = document.location.origin + document.location.pathname + urlFromFragment;
            if (_.isUndefined(hideDelay) || !_.isNumber(hideDelay)) {
                hideDelay = 5000;
            }
            if (window.Notification && window.Notification.permission === 'granted' && !forceJsNotification) {
                var options = {
                    body: message ? message.replace(new RegExp('<br>', 'g'), ' ') : '',
                    icon: Settings.url('base', Settings.get('defaultLogo'))
                };

                var notification = new window.Notification(title, options);
                notification.onclick = function () {
                    window.location = fullUrl;
                    window.focus();
                };
                notification.onshow = function () {
                    window.setTimeout(function () {
                        notification.close();
                    }, hideDelay);
                };
            } else {
                $.notify({
                    title: title,
                    message: message,
                    url: fullUrl,
                    target: '_self',
                    autoHide: true,
                    autoHideDelay: hideDelay
                }, {
                    type: type || 'info',
                    placement: {
                        from: 'top',
                        align: 'center'
                    }
                });
            }
        }
    });

    var getInstance = function () {
        if (!instance) {
            instance = new NotificationCenter();
        }
        return instance;
    };

    module.exports = NotificationCenter;
    module.exports.getInstance = getInstance;
    module.exports.events = events;
});

