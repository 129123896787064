/**
 * Created by OLD on 30/10/2015.
 */
define('translationsRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
    'app',
    'itemPagesNameMixin'
], function(
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,ItemPagesNameMixin
) {
  'use strict';

  var TranslationsRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'translations': 'showTranslations'
    },
    onRoute: function() {
      $('#customer-comments-sidebar-region').hide();
    }
  });

  var API = {
    showTranslations: function() {
      require([
        'contentShowController',
        'translationsTableController'
      ], function(ContentShowController, TranslationsTableController) {
        ContentShowController.showContent().done(function() {
          TranslationsTableController.showTranslations();
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_TRANSLATIONS);
        });
      });
    }
  };

  App.on('translations:show', function() {
    App.navigate('translations');
    API.showTranslations();
  });

  App.on('before:start', function() {
    new TranslationsRouter({
      controller: API
    });
  });

  module.exports = TranslationsRouter;
});
