/**
 * Created by RKL on 07/09/2015.
 */
define('leadsListView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'jqgridActionsButtonFormatterActions',
  'jqgridActionsButtonFormatterView',
  'app',
  'imageUtils',
  'settings',
  'jqgridView',
  'template!leadsListTpl',
  'leadCreateEditLayoutView',
  'plivoUtils',
  'entities/customers/customers'
], function (
  module,
  Marionette,
  _,
  Backbone,
  availableActions,
  ActionButtonsView,
  App,
  imageUtils,
  Settings,
  JqGridView,
  viewTpl,
  CreateEditLayoutView,
  PlivoUtils
) {
  'use strict';

  var LeadsListView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'leadsListFilters',

    regions: {
      actions: '.js-action-buttons-region'
    },

    onShow: function () {
      var actionsButtonView = this._getActionButtonsView();

      this.listenTo(actionsButtonView, 'action', this._onAction);

      this.getRegion('actions').show(actionsButtonView);
    },

    _getActionButtonsView: function () {
      var actions = ['import', 'export', 'exportContacts', 'importContacts'],
        actionsCollection = new Backbone.Collection();
      _.each(actions, function (action) {
        actionsCollection.push(availableActions[action]);
      });

      return new ActionButtonsView({
        collection: actionsCollection,
        dontShowIcons: true
      });
    },

    _onAction: function (action) {
      switch (action) {
        case 'import':
          this.$('#endUser-excel-input').trigger('click');
          break;
        case 'importContacts':
          this.$('#endUserContact-excel-input').trigger('click');
          break;
        case 'export':
          window.open(Settings.url('compuzz', 'endusers.json/excel'), '_self');
          break;
        case 'exportContacts':
          window.open(Settings.url('compuzz', 'endusers.json/contacts/excel'), '_self');
          break;
      }
    },

    onDeleteEndUser: function (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.trigger('leads:remove', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    pagination: function () {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage15'),
        currentPage: 1
      };
    },

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        importBtn: '.endUser-import',
        uploadEndUsers: '.js-upload-endUsers',
        uploadEndUsersContacts: '.js-upload-endUsers-contacts',
        exportBtn: '.endUser-export',
        phoneNumber: '.cell-phone'
      }, this.gridUi);
    },

    events: function () {
      return _.extend({
        'click .js-add-new': 'onAddNew',
        'change @ui.uploadEndUsers': 'onUploadEndUsers',
        'change @ui.uploadEndUsersContacts': 'onUploadEndUsersContacts',
        'click .delete-user-link': 'onDeleteEndUser',
        'click @ui.phoneNumber': 'onPhoneNumberClick'
      }, this.gridEvents);
    },

    gridOptions: function (data) {
      var defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.name'),
              name: 'name',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: true,
              index: 'name',
              width: 55
            }, {
              label: _.i18n('common.code'),
              name: 'code',
              formatter: this.defaultFormatter,
              classes: 'agents-link',
              search: true,
              index: 'code',
              width: 55
            }, {
              label: _.i18n('common.tel'),
              name: 'tel',
              formatter: this.phoneFormatter,
              classes: 'agents-link',
              search: false,
              index: 'tel',
              width: 55
            }, {
              label: _.i18n('common.city'),
              name: 'city',
              formatter: this.defaultFormatter,
              classes: 'agents-link',
              search: true,
              index: 'city',
              width: 50
            }, {
              label: _.i18n('common.countryCode'),
              name: 'countryCode',
              formatter: this.defaultFormatter,
              classes: 'agents-link',
              search: true,
              index: 'countryCode',
              width: 45
            }, {
              label: _.i18n('endUser.firstContactPhone'),
              name: 'firstContactPhone',
              formatter: this.dateTimeFormatter,
              classes: 'agents-link',
              search: true,
              index: 'firstContactPhone',
              width: 55,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
              }
            }, {
              label: _.i18n('endUser.firstContactMail'),
              name: 'firstContactMail',
              formatter: this.dateTimeFormatter,
              classes: 'agents-link',
              search: true,
              index: 'firstContactMail',
              width: 55,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
              }
            }, {
              label: _.i18n('endUser.firstContactVoiceMail'),
              name: 'firstContactVoiceMail',
              formatter: this.dateTimeFormatter,
              classes: 'agents-link',
              search: true,
              index: 'firstContactVoiceMail',
              width: 55,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
              }
            }, {
              label: ' ',
              name: 'delete',
              search: false,
              classes: 'delete-user-link delete-link',
              formatter: this.deleteIconFormatter,
              width: 20
            }
          ],

          onSelectRow: function (rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && !$(e.target).hasClass('cell-phone') && e.type === 'click') {
              App.trigger('leads:show', rowid);
            }
          }
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onAddNew: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var lead = App.request('customer:model');
      lead.set('userType', 'lead');
      var createEditView = new CreateEditLayoutView({
        leadModel: lead,
        newItem: true,
        countries: this.options.countries
      });
      createEditView.show({
        title: _.i18n('leads.new')
      });
    },

    onUploadEndUsers: function (e) {
      var that = this,
        target = e.target,
        fileInfo;

      if (e && e.target && e.target.files && e.target.files[0]) {
        fileInfo = imageUtils.getFileInfo(target, 0);

        if (fileInfo) {
          imageUtils.encodeInputFileToBase64(target, 0, _.bind(function (imgBytes) {
            fileInfo.base64Value = imgBytes;
            that.uploadExcel(fileInfo);
          }, this));
        }
      }
    },

    onUploadEndUsersContacts: function (e) {
      var that = this,
        target = e.target,
        fileInfo;

      if (e && e.target && e.target.files && e.target.files[0]) {
        fileInfo = imageUtils.getFileInfo(target, 0);

        if (fileInfo) {
          imageUtils.encodeInputFileToBase64(target, 0, _.bind(function (imgBytes) {
            fileInfo.base64Value = imgBytes;
            that.uploadExcelContacts(fileInfo);
          }, this));
        }
      }
    },

    uploadExcel: function (fileInfo) {
      this.ui.uploadEndUsers.val('');
      this.trigger('customers:import-excel', fileInfo);
    },

    uploadExcelContacts: function (fileInfo) {
      this.ui.uploadEndUsersContacts.val('');
      this.trigger('customers:import-excel-contacts', fileInfo);
    },

    onPhoneNumberClick: function (e) {
      var target = $(e.currentTarget);
      PlivoUtils.callNumber(target.text());
    }
  });

  module.exports = LeadsListView;
});
