/**
 * Created by GSP on 29/08/2017.
 */
define('customerContactsCallsTabController',[
    'module',
    'underscore',
    'marionette',
    'app',
    'customerCallsTabController',
    'entities/phone/calls'
  ], function(
    module,
    _,
    Marionnette,
    App,
    CustomerCallsTabController
  ) {
    'use strict';
  
    var CustomersContactCallsTabController =  _.extend(CustomerCallsTabController.getInstance(), {

      initializeGrid: function() {
          var data = {
            url: App.request(
              'calls:get-collection-url',
              {endUserContactSecId: this.data.model.get('secId')}
            ),
            rowCountUrl: App.request(
              'calls:row-count-url',
              {endUserContactSecId: this.data.model.get('secId')}
            )
          };
          this.view.displayGrid(data);
      }
  
    });
  
    module.exports = CustomersContactCallsTabController;
  });
