define( 'entities/tracking',[
  'app',
  'underscore',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  _,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var Tracking = Backbone.Model.extend({
    defaults: {
      carrier: 'dpd',
      trackingNumber: '',
      trackingStatus: '',
      trackingUrl:'',
      destinationType:'tocustomer'
    },

    idAttribute: 'secId',

    url: function() {
        return Settings.url('compuzz2', 'commands.json/undefined/tracking/'+this.get('secId'));
    },

    postUrl: function(poi) {
        return Settings.url('compuzz2', 'commands.json/' + poi + '/tracking');
    },

    save: function(poi) {
        this.url = this.postUrl(poi);
        return Backbone.Model.prototype.save.call(this);
    }

  });

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function(poi) {
      return 'commands.json/' + poi + '/tracking/rowCount';
    },

    getTrackingUrl: function(poi) {
      return Settings.url('compuzz2', 'commands.json/' + poi + '/tracking');
    },

    deleteTrackingItem: function(poi, trackingSecId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/' + poi + '/tracking/' + trackingSecId);

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function() {
          defer.resolve();
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('tracking-tab:row-count-url', function(poi) {
      return API.getRowCountUrl(poi);
  });

  App.reqres.setHandler('tracking-tab:get-url', function(poi) {
    return API.getTrackingUrl(poi);
  });

  App.reqres.setHandler('tracking-tab:new-model', function(val) {
    var newOne = new Tracking(val);
    return newOne;
  });

  App.reqres.setHandler('tracking-tab:delete', function(poi, trackingSecId) {
    return API.deleteTrackingItem(poi, trackingSecId);
  });
});

