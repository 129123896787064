/**
 * Created by JDE on 07/01/2016.
 */
define('phoneNumberSelectDialogView',[
    'underscore',
    'app',
    'settings',
    'module',
    'template!phoneNumberSelectDialogTpl',
    'template!phoneNumberSelectDialogPhoneNumberTpl',
    'dialogFormView',
    'jqgridView'
], function (_,
             App,
             Settings,
             module,
             viewTpl,
             phonenumberTpl,
             DialogFormView,
             JqGridView) {
    'use strict';

    var PhoneNumberSelectionView = JqGridView.extend({
        template: phonenumberTpl,
        filtersName: 'phoneNumbersSelectionListFilters',

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list-dialog',
                jqGridPager: '#jq-grid-pager-list-dialog'
            }, this.gridUi);
        },

        imagePopoverContainer: '.product-selection-dialog',

        events: function() {
            return _.extend({
                }, this.gridEvents);
        },

        serializeData: function () {
            this.selectedPhoneNumbers = Settings.get('selectedPhoneNumbers') || [];
            return {type: 'phoneNumber'};
        },

        gridOptions: function (data) {
            var that = this,
                defaultsOptions, options;

            defaultsOptions = this.gridInitOptions(data);
            options = {
                colModel: [
                    {label: '', name: 'secId', key: true, hidden: true},
                    {
                        label: _.i18n('phone.number.prefix'),
                        name: 'numberPrefix',
                        search: true,
                        index: 'numberPrefix',
                        classes: 'cell-link',
                        formatter: this.defaultFormatter
                    },
                    {
                        label: _.i18n('phone.number.number'),
                        name: 'number',
                        search: true,
                        index: 'number',
                        sorttype: 'integer',
                        classes: 'cell-link',
                        formatter: this.defaultFormatter
                    },
                    {
                        label: _.i18n('phone.number.number'),
                        name: 'number',
                        search: true,
                        index: 'number',
                        sorttype: 'integer',
                        classes: 'cell-link',
                        formatter: this.defaultFormatter
                    }
                ],

                serializeGridData: _.bind(function (postData) {
                    if (postData.phoneNumberCode) {
                        postData.code = postData.phoneNumberCode;
                    }

                    if (postData.phoneNumberName) {
                        postData.name = postData.phoneNumberName;
                    }

                    defaultsOptions.serializeGridData.apply(this, arguments);

                    return postData;
                }, this),

                gridComplete: function() {
                    that.onGridComplete();
                    that.hidePopovers();
                    that.attachPopover();
                    that.checkPhoneNumbersSelection();
                }
            };

            options = _.defaults(options, defaultsOptions);

            return options;
        }
    });

    var PopupPhoneNumberSelectView = DialogFormView.extend({
        template: viewTpl,

        className: 'product-selection-dialog',

        regions: {
            phoneNumberSelection: '.select-phonenumber-region'
        },

        ui: {
            'leave' : '.leave-button',
             'blinder':'#dialog-blinder'
        },

        events: {
            'click @ui.leave' : 'leavePopup'
        },

        onShow: function () {
            App.regions.addRegions(this.regions);
            this.phoneNumberSelectionView = new PhoneNumberSelectionView();
            this.phoneNumberSelectionView.listenTo(this.phoneNumberSelectionView, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            App.regions.getRegion('phoneNumberSelection').show(this.phoneNumberSelectionView);
        },

       onBlind: function() {
            this.ui.blinder.show();
        },

        onBlindEnd: function() {
            this.ui.blinder.hide(); 
        },

        onGridLoaded: function () {
            var data = {
                url: App.request('phone-number:get-collection-rent-url'),
                rowCountUrl: App.request('phone-number:row-count-rent-url'),
                multiSelect: this.options.multiSelect
            };
            this.phoneNumberSelectionView.displayGrid(data);
        },

        leavePopup: function() {
            this.hide();
        }

    });

    module.exports = PopupPhoneNumberSelectView;
});
