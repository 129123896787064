
define('template!adminTasksCreateEditTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n    <div class="error-msg"></div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group checkbox-group" data-field-name="done">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("task.done") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 m-t--7 m-b--5">\n          <input type="checkbox" style="width: 20px; height: 40px;" id="done" class="done" ' +
((__t = ( done?'checked':'')) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="comment">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <textarea type="text" class="form-control floating-label js-info-input" data-field-name="comment"\n                    placeholder="' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'" style="height: 200px;">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n        </div>\n      </div>\n      <div class="form-group checkbox-group" data-field-name="notifyOnDone">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("task.notifyOnDone") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 m-t--7 m-b--5">\n          <input type="checkbox" style="width: 20px; height: 40px;" id="notifyOnDone" class="notifyOnDone" ' +
((__t = (
          notifyOnDone?'checked':'')) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="userGroup">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('task.userGroup') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input js-popup-input" data-field-name="userGroup" value="' +
((__t = ( userGroup )) == null ? '' : __t) +
'"\n                  placeholder="' +
((__t = ( _.i18n('task.userGroup') )) == null ? '' : __t) +
'">\n            ';
 _.each(availableTaskTypes, function(avTakType) { ;
__p += '\n            <option value="' +
((__t = ( avTakType )) == null ? '' : __t) +
'"\n            ';
 if (!userGroup || userGroup === avTakType) { ;
__p += ' selected ';
 } ;
__p += '>' +
((__t = ( avTakType )) == null ? '' : __t) +
'</option>\n            ';
 }); ;
__p += '\n          </select>\n        </div>\n      </div>\n      <div class="form-group js-users" style="margin-bottom: 0px;">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("task.notifyUser") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 js-select-notify-users-region"></div>\n      </div>\n      <div class="form-group js-users">\n        <label class="col-xs-offset-3 col-xs-4 control-label" style="text-align: left;">\n          <a class="normal-text clickable js-assign-to-me-notify">' +
((__t = ( _.i18n("task.notifyMe") )) == null ? '' : __t) +
'</a>\n        </label>\n        <label class="col-xs-5 control-label">\n          <a class="normal-text clickable js-unassign-notify">' +
((__t = ( _.i18n("task.notifyNobody") )) == null ? '' : __t) +
'</a>\n        </label>\n      </div>\n      <div class="form-group" data-field-name="dateDue">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('task.dateDue') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="datetime-local"\n                 class="form-control js-datePicker date-due js-info-input-date inputBackground"\n                 data-field-name="dateDue"\n                 style="width: auto;"\n                 placeholder="" value="' +
((__t = ( dateDue )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="taskType">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('task.taskType') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input js-popup-input" data-field-name="taskType" value="' +
((__t = ( taskType )) == null ? '' : __t) +
'"\n                  placeholder="' +
((__t = ( _.i18n('task.taskType') )) == null ? '' : __t) +
'">\n            ';
 _.each(availableTaskTypes, function(avTakType) { ;
__p += '\n            <option value="' +
((__t = ( avTakType )) == null ? '' : __t) +
'"\n            ';
 if (!taskType || taskType === avTakType) { ;
__p += ' selected ';
 } ;
__p += '>' +
((__t = ( avTakType )) == null ? '' : __t) +
'</option>\n            ';
 }); ;
__p += '\n          </select>\n        </div>\n      </div>\n      <div class="form-group js-users" style="margin-bottom: 0px;">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("task.assign") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 js-select-users-region"></div>\n      </div>\n      <div class="form-group js-users">\n        <label class="col-xs-offset-3 col-xs-4 control-label" style="text-align: left;">\n          <a class="normal-text clickable js-assign-to-me">' +
((__t = ( _.i18n("common.assignToMe") )) == null ? '' : __t) +
'</a>\n        </label>\n        <label class="col-xs-5 control-label">\n          <a class="normal-text clickable js-unassign">' +
((__t = ( _.i18n("common.unAssign") )) == null ? '' : __t) +
'</a>\n        </label>\n      </div>\n      ';
 if (refCommandLine) { ;
__p += '\n      <div class="form-group checkbox-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("task.refCommandLine") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 p-t-7">\n          <a href="/#orders/' +
((__t = ( refCommandLine.purchaseOrderId )) == null ? '' : __t) +
'" class="clickable"><label>' +
((__t = (
            refCommandLine.purchaseOrderId
            )) == null ? '' : __t) +
'</label></a>\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      ';
 if (refEndUser) { ;
__p += '\n      <div class="form-group checkbox-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("task.refEndUser") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 p-t-7">\n          <a href="/#customers/' +
((__t = ( refEndUser.secId )) == null ? '' : __t) +
'" class="clickable"><label>' +
((__t = ( refEndUser.code )) == null ? '' : __t) +
'</label></a>\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      ';
 if (refProduct) { ;
__p += '\n      <div class="form-group checkbox-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("task.refProduct") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 p-t-7">\n          <a href="/#product/' +
((__t = ( refProduct.secId )) == null ? '' : __t) +
'" class="clickable"><label>' +
((__t = ( refProduct.cCode )) == null ? '' : __t) +
'</label></a>\n        </div>\n      </div>\n      ';
 } ;
__p += '\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

