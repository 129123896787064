
define('template!numbersListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n    <div class="well jq-grid-table-page">\n        <h2>' +
((__t = ( _.i18n('phone.numbers.list') )) == null ? '' : __t) +
'</h2>\n        <!--<div class="form-group add-new-block">\n            <a class="btn new-phonenumber-button add-btn-text">\n                <i class="mdi-content-add"></i>\n                <span>' +
((__t = ( _.i18n('phone.number.new') )) == null ? '' : __t) +
'</span>\n            </a>\n        </div>-->\n      <div>   \n        <table id="jq-grid-list" class="numbers__table" role="grid"></table>\n        <div id="jq-grid-pager-list"></div>\n      </div>\n    </div>\n  </div>';

}
return __p
};});

