define('entities/customers/customers',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function (
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var Customer = Backbone.Model.extend({
    defaults: {
      aProfile: null,
      cAccountId: null,
      city: null,
      code: null,
      comment: null,
      companyName: null,
      companyNumber: null,
      countryCode: null,
      email: null,
      emailCustomerService: null,
      encodedValue: null,
      endUserId: null,
      extension: null,
      fax: null,
      firstName: null,
      houseNumber: null,
      lastName: null,
      locales: null,
      login: null,
      logoUid: null,
      name: null,
      password: null,
      refAgent: null,
      secId: null,
      street: null,
      tel: null,
      userType: null,
      vatNumber: null,
      webSite: null,
      workName: null,
      zip: null,
      addressL1: null,
      addressL2: null,
      other: null,
      subjectToVat: false,
      priceListCode: null,
      subscribed: true,
      refDeliveryAddress: {},
      mainActivity: null,
      subActivity: null,
      nbrEmployees: null,
      siret: null,
      firstContactPhone: null,
      firstContactMail: null,
      firstContactVoiceMail: null
    },

    setAsCollection: function (isCollection, isCompuLogo) {
      var that = this,
        params = {
          value: isCollection ? 'true' : 'false',
          isCompuLogo: isCompuLogo ? 'true' : 'false'
        },
        url = Settings.url('compuzz', 'endusers.json/' + this.get('secId') + '/setascollection', params);

      $.ajax({
        url: url,
        method: 'PUT',
        success: function (resp) {
          that.set(resp);
          that.trigger('sync');
        },
        error: function (resp) {
          that.trigger('error', resp);
        }
      });
    },

    postUrl: function () {
      return Settings.url('compuzz2', 'endusers.json');
    },

    deleteUrl: function () {
      return Settings.url('compuzz2', 'endusers.json/' + this.get('secId'));
    },

    save: function () {
      this.url = this.postUrl();
      this.set('code', this.get('endUserId'));
      return Backbone.Model.prototype.save.call(this);
    },

    getLanguage: function (language) {
      var locales = language || this.get('locales');
      if (locales) {
        if (locales.length > 2) {
          locales = locales.substr(0, 2);
        }
      } else {
        locales = 'fr';
      }
      return locales;
    },

    getCustomerNumber: function () {
      if (this.get('secId')) {
        return 'N°: ' + (2000000000 + Number(this.get('secId').split('_')[0]));
      }
      return 'N/A';
    },

    idAttribute: 'secId'
  });

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function (lead) {
      if (lead) {
        return 'endusers.json/rowCount?userType=lead';
      } else {
        return 'endusers.json/rowCount';
      }
    },

    getCustomersUrl: function (lead) {
      var data = {};
      if (lead) {
        data.userType = 'lead';
      }
      return Settings.url('compuzz2', 'endusers.json/endusers', data);
    },

    getCustomerModel: function (id) {
      var customer = new Customer();

      customer.url = Settings.url('compuzz2', 'endusers.json/' + id);

      return customer;
    },

    getCustomerModelByEndUserId: function (endUserId) {
      var customer = new Customer();

      customer.url = Settings.url('compuzz2', 'endusers.json/code/' + endUserId);

      return customer;
    },

    updatePhoneNumber: function (endUserSecId, phoneNumber) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'endusers.json/' + endUserSecId + '/phone/' + phoneNumber);

      $.ajax({
        type: 'PUT',
        url: url,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    isUserNotified: function (endUserSecId) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'endusers.json/' + endUserSecId + '/isUserNotified');

      $.ajax({
        type: 'GET',
        url: url,
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    notifyMe: function (endUserSecId, notify) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'endusers.json/' + endUserSecId + '/getNotified', {notified: notify ? 'true' : 'false'});

      $.ajax({
        type: 'PUT',
        url: url,
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('customers:row-count-url', function (lead) {
    return API.getRowCountUrl(lead);
  });

  App.reqres.setHandler('customers:get-url', function (lead) {
    return API.getCustomersUrl(lead);
  });

  App.reqres.setHandler('customers:entity', function (id) {
    return API.getCustomerModel(id);
  });

  App.reqres.setHandler('customers:entity:end-user-id', function (endUserId) {
    return API.getCustomerModelByEndUserId(endUserId);
  });

  App.reqres.setHandler('customer:model', function (modelJSON) {
    return new Customer(modelJSON);
  });

  App.reqres.setHandler('customer:update-phone', function (endUserSecId, phoneNumber) {
    return API.updatePhoneNumber(endUserSecId, phoneNumber);
  });

  App.reqres.setHandler('customer:is-user-notified', function (endUserSecId) {
    return API.isUserNotified(endUserSecId);
  });

  App.reqres.setHandler('customer:notify-me', function (endUserSecId, notify) {
    return API.notifyMe(endUserSecId, notify);
  });
});

