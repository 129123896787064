/**
 * Created by BFR on 14/04/2021.
 */
define('adminMainRouter',[
  'underscore',
  'require',
  'jquery',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin'
], function(
  _,
  require,
  $,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin
) {
  'use strict';

  var AdminRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'admin/imprintTechnologies': 'showImprintTechnologiesAdmin',
      'admin/glossaries': 'showGlossariesAdmin',
      'admin/tasks(/:secId)': 'showTasksAdmin'
    },
    onRoute: function() {
    }
  });

  var API = {

    showTasksAdmin: function (secId) {
      require([
        'adminTasksController',
        'contentShowController'
      ], function (AdminTasksController,
                   ContentShowController) {
        ContentShowController.showContent().done(function () {
          AdminTasksController._showView(secId);
          App.startSubModule('adminMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_TASKS);
        });
      });
    },

    showImprintTechnologiesAdmin: function () {
      require([
        'adminImprintTechnologiesController',
        'contentShowController'
      ], function (AdminImprintTechnologiesController,
                   ContentShowController) {
        ContentShowController.showContent().done(function () {
          AdminImprintTechnologiesController._showView();
          App.startSubModule('adminMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_IMPRINT_TECHNOLOGIES);
        });
      });
    },

    showGlossariesAdmin: function() {
      require([
        'adminGlossariesController',
        'contentShowController'
      ], function(AdminGlossariesController,
                  ContentShowController) {
        ContentShowController.showContent().done(function() {
          AdminGlossariesController._showView();
          App.startSubModule('adminMain');
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_IMPRINT_GLOSSARIES);
        });
      });
    }
  };

  App.on('admin:show', function() {
    App.navigate('admin');
    //API.showSuperAdmin();
  });

  App.on('before:start', function() {
    new AdminRouter({
      controller: API
    });
  });

  module.exports = AdminRouter;
});
