/**
 * Created by bfr on 13/10/2021.
 */
define('tasksCreatorBehavior',[
  'app',
  'module',
  'underscore',
  'jquery',
  'marionette',
  'adminTasksCreateEditView',
  'entities/admin/tasks',
  'entities/kanban_tickets'
], function (
  App,
  module,
  _,
  $,
  Marionette,
  CreateEditLayoutView
) {
  'use strict';

  module.exports = Marionette.Behavior.extend({

    events: {
      'click .create-task': 'createTask'
    },

    ui: {},

    editTask: function (model) {
      this.loadUsersAndOpenPopup(model);
    },

    createTask: function () {
      var model = App.request('tasks:model');

      if (this.options.refCommandLine) {
        model.set('refCommandLine', {purchaseOrderId: this.options.refCommandLine.get('purchaseOrderId')});
      }

      if (this.options.refEndUser) {
        model.set('refEndUser', {
          secId: this.options.refEndUser.get('secId'),
          code: this.options.refEndUser.get('code')
        });
      }

      if (this.options.refProduct) {
        model.set('refProduct', {
          secId: this.options.refProduct.get('secId'),
          cCode: this.options.refProduct.get('cCode')
        });
      }

      this.loadUsersAndOpenPopup(model);
    },

    loadUsersAndOpenPopup: function (model) {
      if (_.isEmpty(this.users) || !this.users) {
        $.when(App.request('kanban-tickets:get-users'))
          .done(_.bind(function (_users) {
            this.users = this._parseUsers(_users);
            this.showEditPopup(model, this.users);
          }, this));
      } else {
        this.showEditPopup(model, this.users);
      }
    },

    _parseUsers: function (_users) {
      this.users = _.map(_users, function (user) {
        if (user.firstName && user.lastName) {
          user.fullName = user.firstName + ' ' + user.lastName;
        } else if (user.firstName || user.lastName) {
          user.fullName = user.firstName ? user.firstName : user.lastName;
        } else if (user.nickName) {
          user.fullName = user.nickName;
        } else {
          user.fullName = user.logonId;
        }
        user.fullName = user.fullName.replace('>', '').trim();
        return user;
      });
      this.users = _.sortBy(this.users, 'fullName');
      return this.users;
    },

    showEditPopup: function (model, users) {
      var createEditView = new CreateEditLayoutView({
        model: model,
        users: users
      });
      createEditView.show({
        title: _.i18n('common.new'),
        className: 'createTaskPopup'
      });
    }
  });
});
