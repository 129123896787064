/**
 * Created by RKL on 21/08/2015.
 */
define('customerProdTemplatesTabController',[
  'module',
  'logger',
  'underscore',
  'app',
  'customerOrdersTabController',
  'customerProductTemplateShowView',
  'productSelectDialogView',
  'entities/customers/product_templates',
  'entities/product'
], function(
  module,
  Logger,
  _,
  App,
  CustomerOrderViewController,
  View,
  ProductSelectDialog
) {
  'use strict';

  var CustomerProductTemplateController = _.extend(CustomerOrderViewController.getInstance(),{

    /**
     * Enable to render the tab
     * @param {any} data  used for initialize
     * @return {void}
     */
    showTab: function (data) {
      CustomerOrderViewController.showTab.call(this, data, View);
    },

    /**
     * Enabel to instantiate the view
     * @param {any} data used for initialize
     * @param {Function} ViewInstance instance of the view
     * @return {void}
     */
    createView: function(data, ViewInstance) {
      CustomerOrderViewController.createView.call(this,data, ViewInstance);
    },

    /**
     * Push the view into the content region
     * @return {void}
     */
    showRegion: function() {
     App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(this.view);
    },

    /**
     * Add listeners on the view
     * @return {void}
     */
    subscribeEvents: function() {
      CustomerOrderViewController.subscribeEvents.call(this);
      this.view.listenTo(this.view, 'create:item', _.bind(this.onCreateItem, this));
    },

    /**
     * Enable to display the grid
     * @param {string} gridStatuses
     * @return {void}
     */
    initializeGrid: function(gridStatuses) {
        var data = {
          url: App.request('customer:product-template-get-collection-url', this.data.model.get('code') ),
          rowCountUrl: App.request('customer:product-template-get-count-url', this.data.model.get('code') ),
          statuses: gridStatuses
      };

        this.view.displayGrid(data);
    },

   /**
    * Occurs when we try to create a new product template
    * @return {void}
    */
    onCreateItem: function() {
     this.productSelectDialog = new ProductSelectDialog({
       multiSelect: false,
       displayLeaveButton: false
     });
     this.productSelectDialog.listenTo(this.productSelectDialog, 'products-selected', _.bind(this.onProductsSelected, this));
     this.productSelectDialog.listenTo(this.productSelectDialog, 'onLoadSelectedProducts',
       _.bind(this.onLoadSelectedProducts, this));
     this.productSelectDialog.show({
       title: _.i18n('dialog.product.select'),
       className: 'product-selection-dialog'
     });
   },

    /**
     * Occurs when you have selected product
     * @param {Array} products Selected products model
     * @return {void}
     */
    onProductsSelected: function (products) {
          var selectedProductsSecId = _.pluck(products, 'rowid').shift();
          var product = App.request('products:model', {secId: selectedProductsSecId });
          product.fetch().done(_.bind(function() {
            App.navigate(
            'orderprocess/' + product.get('code') + '/' + this.data.model.get('code') + '/template',
            {trigger: true});
           }, this));
    },

    /**
     * Enable to maintain selection betwen the page
     * @param {number} first index of first product item selected
     * @param {number} last index of last product item selected
     * @param {any} params additional data
     */
    onLoadSelectedProducts: function(first, last, params) {
        this.productSelectDialog.triggerMethod('blind');
        App.request('products:collection').fetch(params)
            .done(_.bind(function(products) {
                var secIds = _.pluck(products, 'secId');
                this.productSelectDialog.triggerMethod('selectProducts', first, last, secIds);
                this.productSelectDialog.triggerMethod('blind:end');
            }, this))
            .fail(function() {
              this.productSelectDialog.triggerMethod('blind:end');
            });
    }
  });

  module.exports = CustomerProductTemplateController;
});
