
define('template!adminGlossariesCreateEditTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group" data-field-name="code">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="code" placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 name-region">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="type">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input js-popup-input" data-field-name="type" value="' +
((__t = ( type )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'">\n            <option value="category" ';
  if (type === 'category') { ;
__p += ' selected ';
 } ;
__p += ' >category</option>\n            <option value="color" ';
  if (!type || type === 'color') { ;
__p += ' selected ';
 } ;
__p += ' >color</option>\n            <option value="impt" ';
  if (type === 'impt') { ;
__p += ' selected ';
 } ;
__p += ' >impt</option>\n            <option value="material" ';
  if (type === 'material') { ;
__p += ' selected ';
 } ;
__p += ' >material</option>\n            <option value="option" ';
  if (type === 'option') { ;
__p += ' selected ';
 } ;
__p += ' >option</option>\n            <option value="other" ';
  if (type === 'other') { ;
__p += ' selected ';
 } ;
__p += ' >other</option>\n            <option value="paName" ';
  if (type === 'paName') { ;
__p += ' selected ';
 } ;
__p += ' >paName</option>\n          </select>\n        </div>\n      </div>\n\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

