
define('template!userAssignTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<span class="assigned-face-block clickable js-assign-to">\n  ';
 if (userImg && userImg.indexOf('data:image') === -1) { ;
__p += '\n    <div class="assigned-user-logo" style="background-image:url(' +
((__t = ( userImg )) == null ? '' : __t) +
')"></div>\n  ';
 } else if (userImg && userImg.indexOf('data:image') !== -1) { ;
__p += '\n    <img class="logo-holder js-assigned-user-no-logo" src="' +
((__t = ( userImg )) == null ? '' : __t) +
'"/>\n  ';
 } else { ;
__p += '\n    <i class="mdi-action-face-unlock"></i>\n  ';
 } ;
__p += '\n</span>';

}
return __p
};});

