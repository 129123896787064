/**
 * Created by BFR on 26/07/2021.
 */
define('adminGlossariesView',[
  'module',
  'jquery',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'imageUtils',
  'settings',
  'jqgridView',
  'template!adminGlossariesTpl',
  'adminGlossariesCreateEditView'
], function (
  module,
  $,
  Marionette,
  _,
  Backbone,
  App,
  imageUtils,
  Settings,
  JqGridView,
  viewTpl,
  CreateEditLayoutView
) {
  'use strict';

  module.exports = JqGridView.extend({
    template: viewTpl,
    filtersName: 'glossariesFilters',

    pagination: function () {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage15'),
        currentPage: 1
      };
    },

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list'
      }, this.gridUi);
    },

    events: function () {
      return _.extend({
        'click .js-add-new': 'onAddNew',
        'click .delete-glossary-link': 'onDeleteGlossary'
      }, this.gridEvents);
    },

    gridOptions: function (data) {
      var defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.code'),
              name: 'code',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: true,
              index: 'code',
              sortable: false,
              width: 55
            }, {
              label: 'EN',
              name: 'refDynamicText',
              formatter: this.dynamicTextFormaterEn,
              classes: 'cell-link',
              search: false,
              index: 'refDynamicText',
              sortable: false,
              width: 55
            }, {
              label: 'FR',
              name: 'refDynamicText',
              formatter: this.dynamicTextFormaterFr,
              classes: 'cell-link',
              search: false,
              index: 'refDynamicText',
              sortable: false,
              width: 55
            }, {
              label: 'DE',
              name: 'refDynamicText',
              formatter: this.dynamicTextFormaterDe,
              classes: 'cell-link',
              search: false,
              index: 'refDynamicText',
              sortable: false,
              width: 55
            }, {
              label: 'NL',
              name: 'refDynamicText',
              formatter: this.dynamicTextFormaterNl,
              classes: 'cell-link',
              search: false,
              index: 'refDynamicText',
              sortable: false,
              width: 55
            }, {
              label: _.i18n('common.type'),
              name: 'type',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: true,
              index: 'type',
              sortable: false,
              width: 30,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: ':' + _.i18n('common.all') +
                  ';color:color;impt:impt;material:material;other:other;paName:paName'
              }
            }, {
              label: ' ',
              name: 'delete',
              search: false,
              classes: 'delete-glossary-link delete-link',
              formatter: this.deleteIconFormatter,
              width: 20
            }
          ],

          onSelectRow: _.bind(function (rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              this.onEditItem(rowid);
            }
          }, this)
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onDeleteGlossary: function (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.trigger('glossaries:remove', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    onGridFilterChange: function (e) {
      this.onGridFilterChangeV2(e);
    },

    onEditItem: function (secId) {
      var m = App.request('glossaries:model', {secId: secId});
      m.fetch().done(_.bind(function () {
        this.createEditView = new CreateEditLayoutView({
          itModel: m,
          newItem: false
        });
        this.createEditView.show({
          title: _.i18n('common.edit')
        });
        this.createEditView.listenTo(
          this.createEditView,
          'onSave',
          _.bind(this.refreshGrid, this)
        );
      }, this));
    },

    refreshGrid: function () {
      this.updatePagination(true);
    },

    onAddNew: function (e) {
      e.preventDefault();
      e.stopPropagation();
      this.createEditView = new CreateEditLayoutView({
        itModel: App.request('glossaries:model'),
        newItem: true
      });
      this.createEditView.show({
        title: _.i18n('common.new')
      });
      this.createEditView.listenTo(
        this.createEditView,
        'onSave',
        _.bind(this.refreshGrid, this)
      );
    }
  });
});
