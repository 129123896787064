/**
 * Created by RKL on 21/08/2015.
 */
define('customerProductTemplateShowView',[
  'module',
  'underscore',
  'backbone',
  'jqgridView',
  'dateUtils',
  'urlUtil',
  'app',
  'ordersShowView',
  'settings',
  'template!customerProductTemplateViewTpl'
], function(
  module,
  _,
  Backbone,
  JqGridView,
  dateConverter,
  UrlUtil,
  App,
  OrdersView,
  Settings,
  viewTpl
) {
  'use strict';

  var ProductTemplateView =  OrdersView.extend({
    template: viewTpl,
    filtersName: 'productTemplateFilters',

   triggers: {
      'click a.new-productTemplate-button' : 'create:item'
    }
  });

  module.exports = ProductTemplateView;
});
