
define('template!customerCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      ';
 if (newItem) { ;
__p += '\n          <div class="form-group" data-field-name="endUserId">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.endUserId') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n              <input type="text" class="form-control floating-label js-info-input js-end-user-id"\n                     data-field-name="endUserId" placeholder="' +
((__t = ( _.i18n('common.endUserId') )) == null ? '' : __t) +
'"\n                     value="' +
((__t = ( endUserId )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('endUser.duplicatedEndUserIdError') )) == null ? '' : __t) +
'"\n                     data-placement="top">\n            </div>\n          </div>\n        ';
 } ;
__p += '\n      <div class="form-group" data-field-name="name">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="row">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('userInfo.logo') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="profile-logo-container">\n            <div class="_texture-bg _keep-img-link profile-img-block">\n              <img class="logo-holder js-logo-holder clickable transparent-bg">\n            </div>\n            <div class="logo-holder js-logo-holder col-xs-2 clickable default-upload-image hidden"></div>\n            <div class="logo-editor">\n              <label for="change-logo-input">\n                <i class="mdi-file-file-upload clickable"></i>\n              </label>\n              <label>\n                <i class=\'mdi-action-delete js-remove-logo clickable\'></i>\n              </label>\n              <input type="file" class="hidden js-change-logo" id="change-logo-input" accept="image/*">\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="firstName">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="firstName" placeholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( firstName )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="lastName">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="lastName" placeholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( lastName )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="addressL1">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="addressL1" placeholder="' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'" value="' +
((__t = ( addressL1 )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="addressL2">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="addressL2" placeholder="' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'" value="' +
((__t = ( addressL2 )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="col-xs-4 p-l-0" data-field-name="zip">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="zip" placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( zip )) == null ? '' : __t) +
'">\n          </div>\n          <div class="col-xs-8 p-r-0" data-field-name="city">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="city" placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( city )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n      </div>\n\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-countries-region"></div>\n        </div>\n      </div>\n      <div class="form-group checkbox-group" data-field-name="subscribed">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("common.subscribed") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 m-t--7 m-b--5">\n          <input type="checkbox" style="width: 20px; height: 40px; " id="subscribed" class="subscribed" ' +
((__t = ( subscribed?'checked':'')) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="form-group form-email" data-field-name="email">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" id="emailInput" class="form-control floating-label js-info-input" data-field-name="email" placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'" value="' +
((__t = ( email )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="webSite">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.webSite') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="webSite" placeholder="' +
((__t = ( _.i18n('common.webSite') )) == null ? '' : __t) +
'" value="' +
((__t = ( webSite )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="tel">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="tel" placeholder="' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'" value="' +
((__t = ( tel )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n        <div class="form-group" data-field-name="companyNumber">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.companyNumber') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="companyNumber"\n                   placeholder="' +
((__t = ( _.i18n('common.companyNumber') )) == null ? '' : __t) +
'" value="' +
((__t = ( companyNumber )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n      <div class="form-group checkbox-group" data-field-name="subjectToVat">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("common.subjectToVat") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-2 m-t--7 m-b--5">\n          <input type="checkbox" style="width: 20px; height: 40px; " id="subjectToVat" class="subjectToVat" ' +
((__t = (
          subjectToVat?'checked':'')) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="siret">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.siret') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" autocomplete="no" class="form-control floating-label js-info-input siret-input"\n                 data-field-name="siret" placeholder="' +
((__t = ( _.i18n('common.siret') )) == null ? '' : __t) +
'" value="' +
((__t = ( siret )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group vat-area" data-field-name="vatNumber">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.vatNumber') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-7">\n          <input type="text" class="form-control floating-label js-info-input uppercase-input vat-input"\n                 id="vat-input"\n                 data-field-name="vatNumber" placeholder="' +
((__t = ( _.i18n('common.vatNumberWithEntry') )) == null ? '' : __t) +
'"\n                 value="' +
((__t = ( vatNumber )) == null ? '' : __t) +
'">\n          <span class="checking-vat-number" style="display:none;color:#85ac85;animation: fade 1s forwards;">' +
((__t = ( _.i18n('common.checking') )) == null ? '' : __t) +
'</span>\n          <span class="valid-vat-number" style="display:none;color:green;">' +
((__t = ( _.i18n('common.ok') )) == null ? '' : __t) +
'</span>\n          <span class="invalid-vat-number"\n                style="display:none;color:orange;">' +
((__t = ( _.i18n('common.invalidVatNumber') )) == null ? '' : __t) +
'</span>\n          <span class="vat-server-not-reachable" style="display:none;color:orange;">' +
((__t = ( _.i18n('common.vat-server-not-reachable') )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="col-xs-2 p-l-0">\n          <button class="btn-vat-from-siret" style="font-size: 10px;">Calc from siret</button>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="subActivity">\n        <label class="col-xs-3 control-label" for="select-sub-activity">' +
((__t = ( _.i18n('endUsersDetail.subActivity')
          )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input id="select-sub-activity" value="' +
((__t = ( subActivity )) == null ? '' : __t) +
'"\n                 class="form-control js-info-input js-popup-input js-sub-activity-input" data-field-name="subActivity">\n          <div class="sub-activity-autocomplete"></div>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="mainActivity">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('endUsersDetail.mainActivity') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select name="" id="preselect-activite-sousClasse" data-field-name="mainActivity"\n                  class="form-control js-info-input js-popup-input js-activity-input">\n            <option value="-1"></option>\n            ';
 _.each(mainActivities, function(activity) { ;
__p += '\n            <option data-class="' +
((__t = ( (activity.code) )) == null ? '' : __t) +
'"\n            ' +
((__t = ( mainActivity === activity.value ? 'selected' : '' )) == null ? '' : __t) +
'>' +
((__t = ( activity.value )) == null ? '' : __t) +
'</option>\n            ';
 }); ;
__p += '\n          </select>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="comment">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('endUsersDetail.endUserComments') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="comment"\n                    placeholder="' +
((__t = ( _.i18n('endUsersDetail.endUserComments') )) == null ? '' : __t) +
'">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n        </div>\n      </div>\n      <!--language-->\n      <div class="form-group" data-field-name="locales">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('endUsersDetail.language') )) == null ? '' : __t) +
'</label>\n        <div class="dropdown col-xs-3">\n          <button class="form-control dropdown-toggle dropdown__button" type="button" id="dropdownMenu-lang"\n                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n            <span class="pull-left dropdown__label lang-label">None</span>\n            <span class="caret pull-right m-t-5 dropdown__caret"></span>\n          </button>\n          <ul class="dropdown-menu dropdown-menu-lang" role="menu" aria-labelledby="dropdownMenu-lang">\n            <li role="presentation"><a role="menuitem" href="#">EN</a></li>\n            <li role="presentation"><a role="menuitem" href="#">FR</a></li>\n            <li role="presentation"><a role="menuitem" href="#">NL</a></li>\n            <li role="presentation"><a role="menuitem" href="#">DE</a></li>\n            <li role="presentation"><a role="menuitem" href="#">ES</a></li>\n            <li role="presentation"><a role="menuitem" href="#">IT</a></li>\n          </ul>\n        </div>\n      </div>\n      <!--PriceList-->\n        <div class="form-group">\n            <label class="col-xs-12 text-center">' +
((__t = ( _.i18n('endUsersDetail.deliveryAddress') )) == null ? '' : __t) +
'</label>\n            <button class="col-xs-5 btn btn-primary js-copy-address p-t-0 p-b-0">' +
((__t = ( _.i18n('endUsersDetail.copyInvoiceAddress') )) == null ? '' : __t) +
'</button>\n        </div>\n\n        <div class="form-group" data-field-name="name">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.name )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n\n        <div class="form-group" data-field-name="firstName">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="firstName" placeholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.firstName )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <div class="form-group" data-field-name="lastName">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="lastName" placeholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.lastName )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <div class="form-group" data-field-name="addressL1">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="addressL1" placeholder="' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.addressL1 )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <div class="form-group" data-field-name="addressL2">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="addressL2" placeholder="' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.addressL2 )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <div class="form-group" data-field-name="houseNumber">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.houseNumber') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="houseNumber" placeholder="' +
((__t = ( _.i18n('common.houseNumber') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.houseNumber )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n        <div class="form-group">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <div class="col-xs-4 p-l-0" data-field-name="zip">\n                    <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="zip" placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.zip )) == null ? '' : __t) +
'">\n                </div>\n                <div class="col-xs-8 p-r-0" data-field-name="city">\n                    <input type="text" class="form-control floating-label js-info-input-delivery" data-field-name="city" placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( refDeliveryAddress.city )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n        </div>\n\n        <div class="form-group">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <div class="js-select-countries-delivery-region"></div>\n            </div>\n        </div>\n\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

