/**
 * Created by BFR on 21/08/2017.
 */
define('graphicalRequestKanbanHistoryPopupView',[
    'underscore',
    'app',
    'settings',
    'module',
    'template!graphicalRequestKanbanHistoryTablePopupViewTpl',
    'template!graphicalRequestKanbanHistoryPopupViewTpl',
    'dialogFormView',
    'jqgridView',
    'entities/kanban_tickets'
], function (_,
             App,
             Settings,
             module,
             viewTpl,
             productTpl,
             DialogFormView,
             JqGridView
             ) {
    'use strict';

    var TicketHistoryTableView = JqGridView.extend({
        template: productTpl,
        filtersName: 'productsSelectionListFilters',

        ui: function () {
            return _.extend({
                jqGrid: '#jq-grid-list-dialog',
                jqGridPager: '#jq-grid-pager-list-dialog'
            }, this.gridUi);
        },

        imagePopoverContainer: '.ticket-history-dialog',

        events: function() {
            return _.extend({
            }, this.gridEvents);
        },

        serializeData: function () {
            return {type: 'product'};
        },

        gridOptions: function (data) {
            var that = this,
                defaultsOptions, options;

            defaultsOptions = this.gridInitOptions(data);
            options = {
                colModel: [
                    {label: '', name: 'secId', key: true, hidden: true},
                    {
                        label: _.i18n('ticketHistory.user'),
                        name: 'refUserpid',
                        align: 'left',
                        search: false,
                        classes: 'ticket-history-link',
                        formatter: this.userFormatter
                    },
                    {
                        label: _.i18n('ticketHistory.creatDate'),
                        name: 'creatDate',
                        align: 'left',
                        search: false,
                        classes: 'ticket-history-link',
                        formatter: this.dateTimeFormatter,
                        width: 190
                    },
                    {
                        label: _.i18n('ticketHistory.field'),
                        name: 'field',
                        align: 'left',
                        search: false,
                        classes: 'ticket-history-link',
                        formatter: _.bind(this.fieldFormatter, this)
                    },
                    {
                        label: _.i18n('ticketHistory.oldValue'),
                        name: 'oldValue.value',
                        search: false,
                        index: 'productCode',
                        classes: 'ticket-history-link',
                        formatter: _.bind(this.valueFormatter, this)
                    },
                    {
                        label: _.i18n('ticketHistory.newValue'),
                        name: 'value',
                        search: false,
                        classes: 'ticket-history-link',
                        formatter: _.bind(this.valueFormatter, this)
                    }
                ],

                gridComplete: function() {
                    that.onGridComplete();
                    that.hidePopovers();
                    that.attachPopover();
                }
            };

            options = _.defaults(options, defaultsOptions);
            options.sortname = 'creatDate';
            options.sortorder = 'desc';

            return options;
        },

        fieldFormatter: function(field, options) {
            field = _.i18n('ticketHistory.field.' + field);
            return this.defaultFormatter(field, options);
        },

        valueFormatter: function(value, options, object) {
            switch(object.field) {
                case 'refUser': return this.userFormatter(value);
                case 'urgent':
                case 'flagged':
                case 'ontop': return _.i18n('common.' + (value?'yes':'no'));
                case 'ticketState': return _.i18n('vectorization.status.' + value.toLowerCase());
                case 'ticketType':
                    switch(value.toLowerCase()) {
                        case 'vectorization': return _.i18n('vectorization');
                        case 'conversion': return _.i18n('conversion.title');
                        case 'remove_background': return _.i18n('backRemove.title');
                        case 'colorization': return _.i18n('colorization.title');
                    }
                    return _.i18n('ticketType.' + value.toLowerCase());
            }
            return this.defaultFormatter(value, options);
        },

        userFormatter: function(user) {
            if (user) {
                return '<span class="cell-default">' + user.firstName + ' ' + user.lastName +  '</span>';
            } else {
                return '<span class="cell-default"></span>';
            }
        }

    });

    var TicketHistoryView = DialogFormView.extend({
        template: viewTpl,

        className: 'product-selection-dialog',

        regions: {
            ticketHistoryRegion: '.select-product-region'
        },

        ui: {
            'leave' : '.leave-button',
            'blinder':'#dialog-blinder'
        },

        events: {
            'click @ui.leave' : 'leavePopup'
        },

        onShow: function () {
            App.regions.addRegions(this.regions);
            this.ticketHistoryTableView = new TicketHistoryTableView();
            this.ticketHistoryTableView.listenTo(this.ticketHistoryTableView, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            App.regions.getRegion('ticketHistoryRegion').show(this.ticketHistoryTableView);
        },

        serializeData: function() {
            if (typeof this.options.displayLeaveButton === 'boolean') {
                this.displayLeaveButton = this.options.displayLeaveButton;
            } else {
                this.displayLeaveButton = true;
            }
            return {displayLeaveButton: this.displayLeaveButton};
        },

        onBlind: function() {
            this.ui.blinder.show();
        },

        onBlindEnd: function() {
            this.ui.blinder.hide();
        },

        onGridLoaded: function () {
            var data = {
                url: App.request('kanban-tickets:history-get', this.options.ticketSecId),
                rowCountUrl: App.request('kanban-tickets:history-row-count', this.options.ticketSecId)
            };
            this.ticketHistoryTableView.displayGrid(data, true);
        },

        leavePopup: function() {
            this.hide();
        }
    });

    module.exports = TicketHistoryView;
});
