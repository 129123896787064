/**
 * Created by BFR on 18/02/2020.
 */
define('pimProductEditsTabView',[
  'module',
  'app',
  'jquery',
  'marionette',
  'underscore',
  'dynamicTextView',
  'template!pimProductEditsTabTpl',
  'editPimCatsView',
  'editPimImageView',
  'editPimPrintAreasView',
  'settings',
  'contextMenu'
], function (
  module,
  App,
  $,
  Marionette,
  _,
  DynamicTextView,
  viewTpl,
  EditPimCatsView,
  EditPimImageView,
  EditPimPrintAreasView,
  Settings
) {
  'use strict';

  var PimProductEditsView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'overview',

    regions: {
      nameRegion: '.name-region',
      editCats: '.edit-cats',
      editPrintAreas: '.edit-printareas',
      editImage: '.edit-image'
    },

    ui: {},

    events: {
      'click .btn-generate-textile-pas': 'onGeneratePaClick'
    },

    onShow: function () {
      this.editPimCatsView = new EditPimCatsView({
        pimProduct: this.model,
        collection: this.model.get('data').get('productOptionCategories')
      });

      this.getRegion('editCats').show(this.editPimCatsView);

      this.editPimPrintAreasView = new EditPimPrintAreasView({
        pimProduct: this.model,
        collection: this.model.get('data').get('printAreas')
      });

      this.getRegion('editPrintAreas').show(this.editPimPrintAreasView);

      this.editPimImageView = new EditPimImageView({model: this.model});
      this.getRegion('editImage').show(this.editPimImageView);

      var that = this;
      this.$el.contextMenu({
        selector: '.context-menu-pz',
        callback: function () {
        },
        items: {
          image: {
            name: 'Use as catalogue picture',
            callback: function () {
              that.selectImage(this.find('img').attr('data-link'));
            }
          }
        },
        trigger: 'right'
      });

      this.$el.contextMenu({
        selector: '.context-menu-option',
        callback: function () {
        },
        items: {
          image: {
            name: 'Use as catalogue picture',
            callback: function () {
              that.selectImage(this.data('link'));
            }
          },
          setDefault: {
            name: 'Set as default',
            callback: function () {
              that.setDefaultImage(this.data('index'));
            }
          }
        },
        trigger: 'right'
      });
    },

    serializeData: function () {
      var templateData = this.model.toJSON();

      //-------------     
      templateData.name = '';
      _.each(templateData.data.name.values, function (value, lang) {
        if (Settings.get('lang')) {
          if (lang === Settings.get('lang')) {
            templateData.name = value;
          }
        }
      });

      return templateData;
    },

    /*onGeneratePaClick: function () {
      $('.js-loading-block').show();
      var updateDataRequest = App.request('products:generate-printareas', this.options.model.get('secId'), 'textile');
      $.when(updateDataRequest).done(_.bind(function () {
        $('.js-loading-block').hide();
        this.trigger('product-overview:reload');
      }, this));
    },*/

    setDefaultImage: function (index) {
      this.model.getCurrentResProduct().set('defaultIndexPicture', index);
      $.when(this.model.getCurrentResProduct().save()).done(_.bind(function () {
        this.editPimCatsView.render();
      }, this));
    },

    selectImage: function (url) {

      var formData = new FormData(),
        productRequest;

      var fd = {};

      fd.imageUrl = url;

      _.each(fd, function (value, key) {
        formData.append(key, value);
      });

      productRequest = App.request('pim:products:update-catalog-picture-from-url', this.model.getCurrentResProduct().get('id'), formData);
      $.when(productRequest)
        .done(_.bind(function (response) {
          this.model.getCurrentResProduct().set('catalogPictureUid', response.catalogPictureUid);
          //this.editPimImageView.render();
        }, this))
        .fail(_.bind(function () {
          this.editPimImageView.render();
        }, this));
    }
  });

  module.exports = PimProductEditsView;
});
