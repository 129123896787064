/**
 * Created by RKL on 21/08/2015.
 */
define('leadsMainRouter',[
  'require',
  'exports',
  'jquery',
  'module',
  'logger',
  'underscore',
  'marionette',
  'app',
  'leadsTabs',
  'customerContactsDetailsData',
  'itemPagesNameMixin',
  'entities/customers/customers'
], function (
  require,
  exports,
  $,
  module,
  Logger,
  _,
  Marionette,
  App,
  LeadsTabs,
  LeadContactsDetailsData,
  ItemPagesNameMixin
) {
  'use strict';

  var LeadsRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'leads/code/:code': 'showByCode',
      'leads(/:id)(/:tab)(/:secId)(/:reload)': 'showView',
      'leads(/:id)(/:tab)/:secId/variation/:varSecId(/:reload)': 'showVariationPopup'
    }
  });

  var API = {
    showByCode: function (code) {
      this.lead = App.request('customers:entity:end-user-id', code);
      this.lead.fetch().done(_.bind(function (lead) {
        this.showView(lead.secId, 'profile');
      }, this));
    },

    showVariationPopup: function (id, tab, secId, varSecId, reload) {
      require([
        'leadsShowController',
        'leadsListController',
        'contentShowController'
      ], function (
        ShowController,
        ListController,
        ContentShowController
      ) {
        ContentShowController.showContent().done(function () {
          var data;
          data = {
            id: id,
            tab: _.findWhere(LeadsTabs, {name: tab}),
            secId: secId,
            varSecId: varSecId,
            type: 'lead',
            reload: reload === 'reload'
          };
          App.startSubModule('leadsMain');
          if (id) {
            ShowController.fetchLead(data);
          } else {
            ListController.showLeadsList();
          }

          App.execute('set:active:nav:item', ItemPagesNameMixin.MY_LEADS);
        });
      });
    },

    showView: function (id, tab, secId, reload) {
      require([
        'leadsShowController',
        'leadsListController',
        'contentShowController'
      ], function (
        ShowController,
        ListController,
        ContentShowController
      ) {
        ContentShowController.showContent().done(function () {
          var data;
          if (secId === 'reload') {
            data = {
              id: id,
              tab: _.findWhere(LeadsTabs, {name: tab}),
              type: 'lead',
              reload: true
            };
          } else {
            data = {
              id: id,
              tab: _.findWhere(LeadsTabs, {name: tab}),
              secId: secId,
              type: 'lead',
              reload: reload !== null
            };
          }
          App.startSubModule('leadsMain');
          if (id) {
            $('#customer-comments-sidebar-region').show();
            ShowController.fetchLead(data);
          } else {
            ListController.showLeadsList();
          }

          App.execute('set:active:nav:item', ItemPagesNameMixin.MY_LEADS);
        });
      });
    }
  };

  App.on('leads:show', function (id, tab) {
    var linkId = id ? '/' + id : '',
      tabLink = tab ? '/' + tab : '';

    App.navigate('leads' + linkId + tabLink);
    API.showView(id, tab);
  });

  App.on('before:start', function () {
    new LeadsRouter({
      controller: API
    });
  });

  module.exports = LeadsRouter;
});
