define('produceProofsheetView',[
  'module',
  'marionette',
  'settings',
  'template!produceProofsheetTpl'
], function(
  module,
  Marionette,
  Settings,
  produceProofsheetTpl
) {
  'use strict';

  var ProduceProofsheetView = Marionette.ItemView.extend({
    template: produceProofsheetTpl,
    className: 'content full-height',

    serializeData: function() {
      var pluginUrl = Settings.url('compuzzHtmlCustomization',
        'oloSessionId/' + this.options.session.sessionId + '/productCode/' + this.model.get('productCode') +
        '/purchaseOrderId/' + this.model.get('purchaseOrderId'));
      return {
        url: pluginUrl.replace('localhost:8085', 'alpha.compuzz.com')
      };
    }
  });

  module.exports = ProduceProofsheetView;
});
