
define('template!leadsListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page">\n    <h2>' +
((__t = ( _.i18n('leads.title') )) == null ? '' : __t) +
'</h2>\n    <div class="sub-caccounts-table">\n      <div class="form-group add-new-block">\n        <a class="btn new-cAccount-button add-btn-text js-add-new">\n          <i class="mdi-content-add"></i>\n          <span>' +
((__t = ( _.i18n('leads.new') )) == null ? '' : __t) +
'</span>\n        </a>\n        <div class="js-action-buttons-region customers-action-button f-r"></div>\n        <!-- import -->\n        <input type="file"\n               id="endUser-excel-input"\n               class="hidden js-upload-endUsers " data-icon="true"\n               multiple="multiple"\n               accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>\n        <input type="file"\n               id="endUserContact-excel-input"\n               class="hidden js-upload-endUsers-contacts" data-icon="true"\n               multiple="multiple"\n               accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>\n        <!--<label for="endUser-excel-input" class="btn add-btn-text btn-sm endUser-import btn-link">-->\n        <!--<i class="mdi-content-add alligned-bottom"></i>-->\n        <!--<span class="alligned-bottom">' +
((__t = ( _.i18n("common.import") )) == null ? '' : __t) +
'</span>-->\n        <!--</label>-->\n        <!-- export -->\n        <!--<a href="" class="btn add-btn-text btn-sm endUser-export btn-link">-->\n        <!--<i class="mdi-content-add alligned-bottom"></i>-->\n        <!--<span class="alligned-bottom">' +
((__t = ( _.i18n("common.export") )) == null ? '' : __t) +
'</span>-->\n        <!--</a>-->\n      </div>\n      <table id="jq-grid-list" class="cacountTable" role="grid"></table>\n      <div id="jq-grid-pager-list"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

