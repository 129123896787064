define('entities/common',[
  'app',
  'jquery',
  'backbone',
  'underscore',
  'settings'
], function(
  App,
  $,
  Backbone,
  _,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  /**
   * REST API
   */
  var API = {
    getRowCount: function(urlParams,requestOnNewBackend) {
      var params = {},
        backendDomain= (requestOnNewBackend)?'compuzz2':'compuzz',
      defer = $.Deferred();

      _.each(urlParams.filters, function (item, key) {
        if (item) {
          params[key] = item;
        }
      });
       $.ajax({
        url: Settings.url(backendDomain, urlParams.rowCountUrl, params),
        success: function(result) {
          defer.resolve(result);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('common:get-row-count', function(urlParams,requestOnNewBackend) {
    return API.getRowCount(urlParams,requestOnNewBackend);
  });
});

