
define('template!orderDetailsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content well info-page detail-order">\n  <div class="container-fluid">\n    <div class="row">\n      <!--LEFT PART ORDER VIEWER -->\n      <div class="col-xs-12 col-lg-6">\n        <!--PRODUCT PREVIEW-->\n        <div class="row">\n          <div class="col-xs-12 js-images-slider-region"></div>\n        </div>\n      </div>\n      <!--RIGHT PART ORDER DETAIL -->\n      <div class="col-xs-12 col-lg-6">\n        <!--COMMENT -->\n        <div class="row">\n          <div class="col-xs-12 col-md-2 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
':</strong>\n            </p>\n          </div>\n          <div class="col-xs-12 col-md-10 p-l-0">\n            ';
 if (comments && comments.length > 0 && comments[0].notSend === true) { ;
__p += '\n            <i class="mdi mdi-content-create btn-edit-comment clickable"></i>\n            <i class="mdi mdi-action-delete btn-delete-comment clickable"></i>\n            ';
 } else { ;
__p += '\n            <i class="mdi mdi-av-playlist-add btn-add-comment clickable"></i>\n            ';
 } ;
__p += '\n            ';
 _.each(comments, function(comment) { ;
__p += '\n            <div class="col-xs-12 p-l-0 panel-comment">\n              <div class="col-xs-6 p-l-5">\n                <p class="p-t-5 normall-light-compuzz text-left p-comment m-b-0">' +
((__t = ( comment.msgDate )) == null ? '' : __t) +
'</p>\n              </div>\n              <div class="col-xs-6 p-l-0">\n                <div class="col-xs-6 p-l-0">\n                  <p class="p-t-5 normall-light-compuzz text-left p-comment">' +
((__t = ( comment.type )) == null ? '' : __t) +
'</p>\n                </div>\n                <div class="col-xs-6 p-l-0 p-r-0">\n                  <p class="p-t-5 normall-light-compuzz text-left p-comment pull-right">' +
((__t = ( comment.externalUserId
                    )) == null ? '' : __t) +
'</p>\n                </div>\n              </div>\n              <div class="col-xs-12 comment-title">\n                <p class="p-t-5 normall-light-compuzz text-left p-comment">' +
((__t = ( comment.title )) == null ? '' : __t) +
'</p>\n              </div>\n              <div class="col-xs-12 comment-content breakWord">\n                <p class="p-t-5 normall-light-compuzz text-left p-comment">' +
((__t = (
                  comment.content?comment.content.replace(/\n/g, '</br>').replace(/ /g, '&nbsp;'):'' )) == null ? '' : __t) +
'</p>\n              </div>\n            </div>\n            ';
 }) ;
__p += '\n          </div>\n        </div>\n        <!--ORDER TITLE-->\n        <div class="row">\n          ';
 if(Number(productStatus) > 0) { ;
__p += '\n          <i class=\'mdi-action-report-problem clickable\'\n             style="position: absolute; left:-50px; font-size: 55px; color:#ffd60c;"\n             title="' +
((__t = ( _.i18n('orders.productNotStatus0') )) == null ? '' : __t) +
'"></i>\n          ';
 } ;
__p += '\n          <div class="col-xs-12 orders-details-title">\n            <h3 class="ellipsis product-link clickable">' +
((__t = ( productBaseCode )) == null ? '' : __t) +
' (' +
((__t = ( supplierBaseCode )) == null ? '' : __t) +
') <strong>' +
((__t = (
              productName )) == null ? '' : __t) +
'</strong>\n              <i class="mdi mdi-editor-mode-edit btn-edit-product"></i>\n            </h3>\n          </div>\n        </div>\n        <!-- product owner-->\n        ';
 if(productOwner !== caccount.name) { ;
__p += '\n\n          <div class="row">\n            ';
 if (stock === null) { ;
__p += '\n            <div class="col-xs-12 col-md-2 minimal-title">\n              <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('offerList.productOwner') )) == null ? '' : __t) +
':</strong></p>\n            </div>\n            <div class="col-xs-12 col-md-9">\n              <p class="ellipsis p-t-5 normall-light-compuzz text-left">' +
((__t = ( productOwner )) == null ? '' : __t) +
'</p>\n            </div>\n            ';
 } else { ;
__p += '\n            <div class="col-xs-1 col-md-1 minimal-title">\n              <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('offerList.productOwner') )) == null ? '' : __t) +
':</strong></p>\n            </div>\n            <div class="col-xs-1 col-md-2">\n              <p class="ellipsis p-t-5 normall-light-compuzz text-left">' +
((__t = ( productOwner )) == null ? '' : __t) +
'</p>\n            </div>\n            <div class="col-xs-12 col-md-8 js-stock-container" style="font-size: 12px;">\n                  <span class="col-xs-3">' +
((__t = ( _.i18n('common.code'))) == null ? '' : __t) +
'</span>\n                  <span class="col-xs-2">' +
((__t = ( _.i18n('common.options'))) == null ? '' : __t) +
'</span>\n                  <span class="col-xs-2">' +
((__t = ( _.i18n('product.stock.currentqty'))) == null ? '' : __t) +
'</span>\n                  <span class="col-xs-2">' +
((__t = ( _.i18n('product.stock.nextdate'))) == null ? '' : __t) +
'</span>\n                  <span class="col-xs-1">' +
((__t = ( _.i18n('product.stock.nextqty'))) == null ? '' : __t) +
'</span>\n                  <span class="col-xs-2">' +
((__t = ( _.i18n('product.stock.position'))) == null ? '' : __t) +
'</span>\n               <div class="col-lg-12 js-stock-list" style="height:15px;overflow-y: auto;float:left;font-size: 12px;">\n                ';
 _.each(stock, function(st,index) { ;
__p += '\n                 <div class="row js-show-rows ' +
((__t = ( (index==0)?'js-first-row':'')) == null ? '' : __t) +
' " style="margin-bottom:0px;' +
((__t = ( (index!==0)?'display:none;':'cursor: pointer;')) == null ? '' : __t) +
'">\n                  <span class="col-md-3 normall-light-compuzz" style="font-size: 12px;' +
((__t = ( st.code===productCode?'background-color:#eee;':'')) == null ? '' : __t) +
'">' +
((__t = (st.code)) == null ? '' : __t) +
'</span>\n                  ';
 if (!st.noStockInfo) { ;
__p += '\n                  <span class="col-md-2 normall-light-compuzz" style="font-size: 12px;' +
((__t = ( st.code===productCode?'background-color:#eee;':'')) == null ? '' : __t) +
'">' +
((__t = (st.productOptions)) == null ? '' : __t) +
'</span>\n                  <span class="col-md-2 normall-light-compuzz" style="font-size: 12px;' +
((__t = ( st.code===productCode?'background-color:#eee;':'')) == null ? '' : __t) +
'' +
((__t = ((outOfStock && st.code===productCode)?'color:red;':'')) == null ? '' : __t) +
'">' +
((__t = (st.currentQuantity>0?st.currentQuantity:'&nbsp;')) == null ? '' : __t) +
'</span>\n                  <span class="col-md-2 normall-light-compuzz" style="font-size: 12px;' +
((__t = ( st.code===productCode?'background-color:#eee;':'')) == null ? '' : __t) +
'">' +
((__t = (st.newArrivalETA?st.newArrivalETA:'&nbsp;')) == null ? '' : __t) +
'</span>\n                  <span class="col-md-1 normall-light-compuzz" style="font-size: 12px;' +
((__t = ( st.code===productCode?'background-color:#eee;':'')) == null ? '' : __t) +
'">' +
((__t = (st.newArrivalQuantity>0?st.newArrivalQuantity:'&nbsp;')) == null ? '' : __t) +
'</span>\n                  <span class="col-md-2 normall-light-compuzz" style="font-size: 12px;' +
((__t = ( st.code===productCode?'background-color:#eee;':'')) == null ? '' : __t) +
'">' +
((__t = (st.stockPosition?st.stockPosition:'&nbsp;')) == null ? '' : __t) +
'</span>\n                  ';
 } else { ;
__p += '\n                  <span class="col-md-6 normall-light-compuzz" style="font-size: 12px;' +
((__t = ( st.code===productCode?'background-color:#eee;':'')) == null ? '' : __t) +
'">' +
((__t = ( _.i18n('product.stock.problem'))) == null ? '' : __t) +
'</span>\n                  ';
 } ;
__p += '\n                 </div>\n                 ';
 }); ;
__p += '\n\n                </div>\n            </div>\n            ';
 } ;
__p += '\n          </div>\n        ';
 } ;
__p += '\n        <!-- type-->\n        <div class="row">\n          <div class="col-xs-12 col-md-3 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-12 col-md-9">\n            <a id="order-btn-edit-type" class="flat-primary-text m-0 p-t-0 btn btn-flat btn-sm text-left">\n              <span class="span-ellipsis">\n                <span id=\'order-detail-tab-type\'>' +
((__t = ( type )) == null ? '' : __t) +
'</span>\n                <i class="mdi mdi-editor-mode-edit"></i>\n              </span>\n              <div class="ripple-container"></div>\n            </a>\n          </div>\n        </div>\n        <!--ORDER STATUS-->\n        <div class="row">\n          <div class="col-xs-12 col-md-3 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-12 col-md-9">\n            <a id="order-btn-edit-status" class="flat-primary-text m-0 p-t-0 btn btn-flat btn-sm text-left">\n              <span class="span-ellipsis">\n                <span id=\'order-detail-tab-status\'>' +
((__t = ( status )) == null ? '' : __t) +
'</span>\n                <i class="mdi mdi-editor-mode-edit"></i>\n              </span>\n              <div class="ripple-container"></div>\n            </a>\n          </div>\n        </div>\n        <!--ORDER CREATED DATE-->\n        <div class="row">\n          <div class="col-xs-12 col-md-3 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('common.date.create') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-12 col-md-9">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left">' +
((__t = ( createDate )) == null ? '' : __t) +
'</p>\n          </div>\n        </div>\n        <!--ORDER QUANTITY-->\n        <div class="row">\n          <div class="col-xs-3 col-md-3 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left" style="' +
((__t = (outOfStock?'color:red;':'')) == null ? '' : __t) +
'"><strong>' +
((__t = ( _.i18n('orders.totalQuantity') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-3 col-md-3">\n            <span class="ellipsis p-t-5 normall-light-compuzz text-left" style="vertical-align: super;">' +
((__t = ( quantity )) == null ? '' : __t) +
'</span>\n            <i class="mdi mdi-editor-mode-edit btn-edit-quantity clickable"></i>\n          </div>\n          <div class="col-xs-3 col-md-3 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('orders.weight') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-3 col-md-3" style="margin-top:5px;">\n            <span class="ellipsis p-t-5 normall-light-compuzz text-left" style="vertical-align: super;">' +
((__t = ( weight * quantity )) == null ? '' : __t) +
' ' +
((__t = ( weightUnit )) == null ? '' : __t) +
'</span>\n          </div>\n\n        </div>\n        <!--PRODUCT SINGLE OPTIONS-->\n        ';
 if(displaySingleOptions === true) { ;
__p += '\n        <div class="row">\n          <div class="col-xs-12 col-md-2 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('common.options') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-11 col-md-9">\n            <div class="row"></div>\n            ';
 _.each(options.single, function(option, index) { ;
__p += '\n            <div class="row h-divider">\n              <div class="col-xs-12 col-md-3">\n                <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( option.header )) == null ? '' : __t) +
'</strong></p>\n              </div>\n              ';
 if(option.name) { ;
__p += '\n              <div class="col-xs-12 col-md-9"><p class="ellipsis p-t-5 normall-light-compuzz text-left">' +
((__t = ( option.name )) == null ? '' : __t) +
'</p></div>\n              ';
 } else if(option.value) { ;
__p += '\n              <div class="col-xs-12 col-md-9"><p class="ellipsis p-t-5 normall-light-compuzz text-left">' +
((__t = ( option.value )) == null ? '' : __t) +
'</p></div>\n              ';
 } else { ;
__p += '\n              <div class="col-xs-12 col-md-9">\n                <div class="row">\n                  <div class="col-xs-12">\n                    <span class="display-inline-block color color-box" id="' +
((__t = ( option.objId )) == null ? '' : __t) +
'"></span>\n                    <span class="ellipsis p-t-5 normall-light-compuzz m-l-10 text-left">' +
((__t = ( option.colorName )) == null ? '' : __t) +
'</span>\n                  </div>\n                </div>\n              </div>\n              ';
 } ;
__p += '\n            </div>\n            ';
 }) ;
__p += '\n          </div>\n          ';
 if (showChangeOptions) { ;
__p += '\n          <div class="col-xs-1 col-md-1">\n            <i class="mdi mdi-editor-mode-edit btn-edit-options clickable"></i>\n          </div>\n          ';
 } ;
__p += '\n        </div>\n        ';
 } ;
__p += '\n        <!--PRODUCT MIXED OPTIONS-->\n        ';
 if(displayMixedOptions === true) { ;
__p += '\n        <div class="row">\n          <div class="col-xs-12 col-md-2">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('orders.mixedOptions') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-12 col-md-10">\n            <div class="table-responsive">\n              <table class="table table-striped">\n                <thead>\n                <tr>\n                  ';
 _.each(options.mixed.headers, function(header) { ;
__p += '\n                  <th>' +
((__t = ( header )) == null ? '' : __t) +
'</th>\n                  ';
 }) ;
__p += '\n                </tr>\n                </thead>\n                <tbody>\n                ';
 _.each(options.mixed.values, function(rowValues) { ;
__p += '\n                <tr>\n                  ';
 _.each(rowValues, function(rowValue) { ;
__p += '\n                    ';
 if(rowValue.name) { ;
__p += '\n                      <td>' +
((__t = ( rowValue.name )) == null ? '' : __t) +
'</td>\n                    ';
 } else if(rowValue.value) { ;
__p += '\n                      <td>' +
((__t = ( rowValue.value )) == null ? '' : __t) +
'</td>\n                    ';
 } else { ;
__p += '\n                      <td>\n                        <span class="orders-table-color color color-box" id="' +
((__t = ( rowValue.objId )) == null ? '' : __t) +
'"></span>\n                        <span class="orders-table-color-text">' +
((__t = ( rowValue.colorName )) == null ? '' : __t) +
'</span>\n                      </td>\n                    ';
 } ;
__p += '\n                  ';
}) ;
__p += '\n                </tr>\n                ';
 }) ;
__p += '\n                </tbody>\n              </table>\n              ';
 if (showChangeOptions) { ;
__p += '\n              <div class="col-xs-1 col-md-1">\n                <i class="mdi mdi-editor-mode-edit btn-edit-options clickable"></i>\n              </div>\n              ';
 } ;
__p += '\n            </div>\n          </div>\n        </div>\n        ';
 } ;
__p += '\n        <!--ORDER EXP SHIPPING DATE-->\n        <div class="row">\n          <div class="col-xs-6 col-md-3 minimal-title">\n              <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('order.expShippingDate') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-6 col-md-3">\n              <span class="ellipsis p-t-5 normall-light-compuzz text-left exp-shipping-date" style="vertical-align: super;">' +
((__t = ( expShippingDate )) == null ? '' : __t) +
'</span>\n              <i class="mdi mdi-editor-mode-edit btn-edit-exp-shipping-date clickable"></i>\n          </div>\n          <div class="col-xs-6 col-md-3 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('order.paymentMethod') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-6 col-md-3" style ="margin-top:5px">\n            <span class="ellipsis p-t-5 normall-light-compuzz text-left " style="vertical-align: super;">' +
((__t = ( paymentMethod )) == null ? '' : __t) +
'</span>\n          </div>\n\n        </div>\n        <!--VALIDATION DATE-->\n        <div class="row">\n          <div class="col-xs-6 col-md-3 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('order.validationDate') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-6 col-md-3">\n            <span class="ellipsis p-t-5 normall-light-compuzz text-left validation-date" style="vertical-align: super;">' +
((__t = ( validationDate )) == null ? '' : __t) +
'</span>\n            <i class="mdi mdi-editor-mode-edit btn-edit-validation-date clickable"></i>\n          </div>\n          <div class="col-xs-6 col-md-3 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('order.transactionNr') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-6 col-md-3" style ="margin-top:5px">\n            <span class="ellipsis p-t-5 normall-light-compuzz text-left " style="vertical-align: super;">\n              ';
 if (paymentTransactionLink) { ;
__p += '\n                <a href="' +
((__t = ( paymentTransactionLink)) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( paymentTransactionNr )) == null ? '' : __t) +
'</a>\n              ';
 } else if (paymentTransactionNr) { ;
__p += '\n                ' +
((__t = ( paymentTransactionNr.replace('"','') )) == null ? '' : __t) +
'\n              ';
 } ;
__p += '\n            </span>\n          </div>\n        </div>\n        <!--CUSTOM PRODUCT NAME-->\n        <div class="row">\n          <div class="col-xs-12 col-md-3 minimal-title">\n              <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('orders.name') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-12 col-md-9">\n              <span class="ellipsis p-t-5 normall-light-compuzz text-left order-name" style="vertical-align: super;">' +
((__t = ( name )) == null ? '' : __t) +
'</span>\n              <i class="mdi mdi-editor-mode-edit btn-edit-name clickable"></i>\n          </div>\n        </div>\n        <!--ORDER SUPPLIER NUMBER-->\n        <div class="row">\n          <div class="col-xs-12 col-md-3 minimal-title">\n            <p class="ellipsis p-t-5 normall-light-compuzz text-left"><strong>' +
((__t = ( _.i18n('orders.supplierNumber') )) == null ? '' : __t) +
':</strong></p>\n          </div>\n          <div class="col-xs-12 col-md-9">\n            <span class="ellipsis p-t-5 normall-light-compuzz text-left supplier-number">' +
((__t = ( supplierNumber )) == null ? '' : __t) +
'</span>\n            ';
 if (notDesign2) { ;
__p += '\n            <i class="mdi mdi-editor-mode-edit btn-edit-supplier-number clickable"></i>\n            ';
 } ;
__p += '\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="row">\n      <!--BUTTONS CONTAINER-->\n      <div id="order-btn" class="col-xs-12 col-lg-9 col-lg-offset-3">\n          <!--EDIT BUTTON-->\n            <button id="order-btn__edit" class="col-xs-12 col-md-3 col-lg-3 btn btn-flat flat-primary-text"><span class="span-ellipsis"><i class=" mdi mdi-editor-mode-edit"></i>' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</span><div class="ripple-container"></div></button>\n          <!--DOWNLOAD PROOF SHEET BUTTON-->\n            <a id="order-btn__download-prf" href="' +
((__t = ( proofSheetLink )) == null ? '' : __t) +
'" target="_blank" class=" col-xs-12 col-md-4 col-lg-4 btn btn-raised btn-primary primary-btn-text">\n              <span class="span-ellipsis primary-btn-text ">\n                <i class=" mdi mdi-file-file-download"></i>\n                <span>' +
((__t = (_.i18n('common.downloadProofsheet') )) == null ? '' : __t) +
'</span>\n              </span>\n              <div class="ripple-container"></div>\n            </a>\n          <!--SEND E-MAIL PROOF SHEET BUTTON-->\n        <!--<a id="order-btn__sent-prf" href="' +
((__t = ( sentProofShettetLink )) == null ? '' : __t) +
'" class="col-xs-12 col-md-4 col-lg-4 btn btn-raised btn-primary primary-btn-text">\n          <span class="span-ellipsis primary-btn-text ">\n            <i class=" mdi mdi-communication-email"></i>\n             <span>' +
((__t = (  _.i18n('common.sentProofsheet') )) == null ? '' : __t) +
'</span>\n          </span>\n          <div class="ripple-container"></div>\n        </a>-->\n  </div>\n</div>\n</div>\n</div>\n';

}
return __p
};});

