/**
 * Created by RKL on 07/09/2015.
 */
define('leadsShowView',[
  'module',
  'marionette',
  'underscore',
  'jquery',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'template!leadsViewTpl',
  'leadsTabs',
  'rolesMixin',
  'backToListBehavior',
  'leadsCopyPasteMix',
  'tasksCreatorBehavior',
  'plivoUtils'
], function (
  module,
  Marionette,
  _,
  $,
  Backbone,
  App,
  Settings,
  imageUtils,
  viewTpl,
  leadsTabs,
  RolesMixin,
  BackToListBehavior,
  CopyPasteMix,
  TasksCreatorBehavior,
  PlivoUtils
) {
  'use strict';

  var LeadsDetailsView = Marionette.LayoutView.extend({

    template: viewTpl,

    ui: {
      tab: '.customer-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder',
      name: '.p-name',
      code: '.p-code',
      phone: '.p-phone',
      email: '.p-email',
      collectionToggle: '.js-collection-toggle',
      compuLogoToggle: '.js-compuLogo-toggle',
      vatValid: '.p-vat-valid',
      linkPhoneButton: '.js-link-phone-to-customer'
    },

    events: {
      'click @ui.tab': 'onTabClick',
      'click @ui.linkPhoneButton': 'onLinkPhoneButton',
      'click @ui.phone': 'onPhoneNumberClick',
      'click .btn-convert-to-customer': 'onConvertToCustomerClick'
    },

    regions: {
      tabContent: '#customer-tab-content',
      leadActions: '.js-action-buttons-region'
    },

    behaviors: function () {
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: 'leads'
        },
        CreateTask: {
          behaviorClass: TasksCreatorBehavior,
          refEndUser: this.options.model
        }
      };
    },

    initialize: function () {
      _.extend(this, CopyPasteMix);
    },

    onPhoneNumberClick: function (e) {
      var target = $(e.currentTarget);
      PlivoUtils.callNumber(target.text());
    },

    onConvertToCustomerClick: function () {
      this.model.set('userType', 'normal');
      this.model.save().done(_.bind(function () {
        App.navigate('customers/' + this.model.get('secId'), {trigger: true});
      }, this));
    },

    serializeData: function () {
      var templateData = this.model.toJSON(),
        cAccountRoles = Settings.get('cAccountRoles');
      templateData.currentPlivoCall = Settings.getFromMemory('plivoCurrentCallNumber');
      templateData.isNew = this.options.data.id === 'new';
      if (this.options.data.id === 'new') {
        templateData.leadsTabs = [];
        var profileTab = _.findWhere(leadsTabs, {role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE});
        templateData.leadsTabs.push(profileTab);
      } else if (cAccountRoles) {
        templateData.leadsTabs = [];
        _.each(leadsTabs, function (tab) {
          if (cAccountRoles[tab.role] === true) {
            templateData.leadsTabs.push(tab);
          }
        });
      } else {
        templateData.leadsTabs = leadsTabs;
      }
      templateData.isCollection = this.model.get('userType') === 'collection';
      templateData.compulogoCollection = this.model.get('compulogoCollection');
      templateData.webshopUrl = null;
      if (Settings.get('webshopUrl')) {
        var locale;
        if (templateData.locales && (templateData.locales).length >= 2) {
          locale = (templateData.locales).substring(0, 2).toLowerCase();
        } else {
          locale = 'fr';
        }
        templateData.webshopUrl = Settings.get('webshopUrl') + '/' + locale +
          '/' + 'account' + '/' + templateData.secId;
      }
      templateData.design2 = Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2];
      templateData.customerNumber = this.model.getCustomerNumber();
      return templateData;
    },

    onRender: function () {
      this._setLogo(this.model.get('logoUid'), this.model.get('code'));

      this.$el.find('.area-compuLogo').toggleClass('sr-only', this.model.get('userType') !== 'collection');
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.customer-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function () {
      this.prepareCopyPasteActionView('fromCategory');

      $.material.init();
    },

    onTabClick: function (e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };

      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
      this.checkIfCopyPasteAvailabe();
    },

    onCollectionToggle: function (e) {
      var $target = $(e.currentTarget);
      var compuLogo = this.$el.find('.area-compuLogo');
      compuLogo.toggleClass('sr-only', !$target.prop('checked'));
      this.model.set('compulogoCollection', compuLogo.prop('checked'));
      this.model.setAsCollection($target.prop('checked'), compuLogo.prop('checked'));

    },

    onCompuLogoToggle: function (e) {
      var $target = $(e.currentTarget);
      this.model.set('compulogoCollection', ($target.prop('checked')));
      this.model.setAsCollection(true, $target.prop('checked'));
    },

    onHeaderChange: function (lead) {
      this.ui.name.text(lead.name);
      this.ui.phone.text(lead.tel ? lead.tel : '--');
      this.ui.email.text(lead.email ? lead.email : '--');
      this._setLogo(lead.logoUid, lead.code);
      if (lead.subjectToVat && lead.vatNumberChecked) {
        this.ui.vatValid.hide();
      } else {
        this.ui.vatValid.show();
      }
    },

    _setLogo: function (logoUid, code) {
      var logo = imageUtils.getLogo(logoUid, code);
      if (logo) {
        this.ui.logo.css('background-image', 'url(' + logo + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }
    },

    onLinkPhoneButton: function (e) {
      // link phone number to current lead
      console.log($(e.target).attr('data-phoneNumber'));
      var that = this, tel = $(e.target).attr('data-phoneNumber'), endUserSecId = this.model.get('secId');
      $.when(App.request('lead:update-phone', endUserSecId, tel)).done(function (resp) {
        if (resp.status === 200) {
          App.trigger('leads:show', that.model.get('secId'));
          $('.js-link-phone-to-customer').hide();
          Settings.setToMemory('plivoCurrentCallNumber', null);
        } else if (resp.status === 201) {
          var contact = JSON.parse(resp.responseText);
          if (contact.refAddress.tel === tel) {
            $('.js-link-phone-to-customer').hide();
            Settings.setToMemory('plivoCurrentCallNumber', null);
            App.navigate('leads/' + endUserSecId + '/contacts/' + contact.secId + '/details/info', {trigger: true});
          }
        }
      });
    }

  });

  module.exports = LeadsDetailsView;
});
