
define('template!notificationsViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="currentShareRequest task-notification" style="height: 30px;">\n    <div class="col-xs-8 p-t-7">\n        <label class="clickable">' +
((__t = ( content )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="col-xs-3 p-t-7">\n        <label class="clickable">' +
((__t = ( creatDate )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="col-xs-1">\n        <label class="pull-right remove-accept-icons">\n            <i class="mdi-action-delete delete-notification clickable p-t-5"></i>\n        </label>\n    </div>\n</div>\n';

}
return __p
};});

