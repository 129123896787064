define('attachmentsView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'dropzone',
  'settings',
  'genericAttachmentView',
  'template!attachmentsView'
], function(
  Module,  
  Backbone,
  Marionette,
  _, 
  Dropzone,
  Settings,
  AttachmentView,
  Tpl
) {
  'use strict';
  
  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    childView: AttachmentView,    
    childViewContainer: '.attachments-container',
    className: 'attachments-view',   
    pageSize: 10,
    first: 0,

    childViewOptions: function() {
      return {
        config: this.config
      };
    },

    initialize: function () {   
      this.config = _.extend({
        canEdit: true,
        canDelete: true,
        canDownload: true,
        canCopy: true,
        canZoom: true,
        canFlag: false
      }, this.setConfig());

      this.collection = new Backbone.Collection();
    },

    setConfig: function() { return {}; },   

    childEvents: {
      'edit': function(view) { 
        if(this.editItem)
          return this.editItem(view.model);
      },
      'delete': function(view) { 
        if(this.deleteItem)
          return this.deleteItem(view.model).done(_.bind(function() {
            this.collection.remove(view.model);
          }, this));
      },     
      'download': function(view) { 
        if(this.downloadItem)
          return this.downloadItem(view.model);
      },
      'copy': function(view) { 
        if(this.copyItem)
          return this.copyItem(view.model);
      }
    },

    ui: {   
      dropZone: '.drop-zone',
      dropZoneIcon: '.drop-zone svg',
      attachmentsContainer: 'attachments-container'
    },

    events: {  
      'click @ui.dropZoneIcon': function () { this.ui.dropZone.click(); }
    },  

    onShow: function() {
    
      this.observer = new window.IntersectionObserver(_.bind(function(aEntries) {
        if(aEntries.length > 0 && aEntries[0].isIntersecting)         
          this._fetch();          
      }, this), {
        root: this.ui.attachmentsContainer[0],
        rootMargin: '30px',
        threshold: 0.5
      });    

      this._fetch(); 
    },

    onRender: function() {
      var that = this;

      this.ui.dropZone.dropzone({
        url: Settings.url('compuzz'),
        autoProcessQueue: true,
        uploadMultiple: true,

        init: function () {          
          this.on('addedfile', function(file) {
            if(that.uploadItem) {
              that.uploadItem(file).done(function(attachment) {                
                that.collection.add(attachment, {at: 0});
              });              
            }              
            this.removeAllFiles();
          });        
        },
        dictDefaultMessage: _.i18n('common.file.drag.drop')
      });
    },
    
    _fetch: function() {

      if(!this.fetch)
        return;

      this.observer.disconnect(); 

      this.fetch(this.first, this.pageSize)
      .done(_.bind(function (items) {

        _.each(items, _.bind(function(item) {
          if(!this.collection.findWhere({id: item.get('id')})) 
            this.collection.add(item);
        }, this));        
        
        this.first = this.collection.length;
        if(items.length > 0) {
          var view = this.children.last();  
        
          if(view)
            this.observer.observe(view.$el[0]);
        }
        if (this.collection.length === 0) {
          this.trigger('empty');
        }
      }, this)); 
    },
    
    addAttachment: function(attachment) {
      this.collection.add(attachment, {at: 0});
    }
  });  
});

