define('customersContactsInfoTabView',[
  'module',
  'marionette',
  'template!customersContactsInfoTabTpl',
  'plivoUtils'
], function(
  module,
  Marionette,
  infoTabTpl,
  PlivoUtils
) {
  'use strict';

  var CustomersContactsInfoTabView = Marionette.ItemView.extend({
    template: infoTabTpl,

    triggers: {
      'click @ui.editBtn' : '_onEditContactInfo'
    },

    ui: {
      editBtn: '.js-edit'
    },

    events: {
      'click .phone-number': 'onPhoneNumberClick'
    },

    onPhoneNumberClick: function() {
      PlivoUtils.callNumber(this.model.get('tel'));
    },

    serializeData: function() {
      return this.model.toJSON();
    }
  });

  module.exports = CustomersContactsInfoTabView;
});
