define('entities/admin/imprint_technologies',[
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'backboneRelational',
  'entities/product_translation'
], function (
  App,
  $,
  _,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  app.ns.ImprintTechnology = Backbone.RelationalModel.extend({
    defaults: {
      code: '',
      colorModeType: 'quadri',
      maxNumberOfColors: 4,
      vrpWarningMessageFontSize: 20,
      vrpWarningMessageColor: '#FF0000',
      vrpWarningMessageBold: false,
      name: new app.ns.DynamicText(),
      description: new app.ns.DynamicText(),
      vrpWarningMessage: new app.ns.DynamicText(),
      individualPrinting: false,
      minStrokeWidth: 0,
      colorSeparation: false,
      colorSeparationCrop: false,
      filter: null
    },

    url: function () {
      return Settings.url('compuzz2', 'v2/imprinttechnologies/' + this.get('secId'));
    },

    postUrl: function () {
      return Settings.url('compuzz2', 'v2/imprinttechnologies');
    },

    save: function () {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    },

    idAttribute: 'secId',

    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasOne,
      key: 'description',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasOne,
      key: 'vrpWarningMessage',
      relatedModel: 'DynamicText'
    }]
  });

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function () {
      return 'v2/imprinttechnologies/rowCount';
    },

    getCustomersUrl: function () {
      return Settings.url('compuzz2', 'v2/imprinttechnologies');
    }
  };

  App.reqres.setHandler('imprint-technologies:model', function (imprintTechnologyJSON) {
    var tt;
    if (imprintTechnologyJSON) {
      tt = Backbone.Relational.store.find(app.ns.ImprintTechnology, imprintTechnologyJSON.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.ImprintTechnology(imprintTechnologyJSON);
    }
  });

  App.reqres.setHandler('imprint-technologies:entities', function () {
    return API.getCAccountEntities();
  });

  App.reqres.setHandler('imprint-technologies:row-count-url', function () {
    return API.getRowCountUrl();
  });

  App.reqres.setHandler('imprint-technologies:get-url', function () {
    return API.getCustomersUrl();
  });

});
