/**
 * Created by BFR on 24/02/2020.
 */
define('editPimPrintZoneView',[
  'module',
  'marionette',
  'jquery',
  'app',
  'underscore',
  'template!editPimPrintZoneTpl',
  'pimUtil'
], function (
  module,
  Marionette,
  $,
  App,
  _,
  viewTpl,
  PimUtil
) {
  'use strict';

  var EditPimPrintZoneView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'printzone',

    onShow: function () {

      var aImages = this.model.get('printPictures');

      if (aImages.length > 0) {
        var url = aImages[0].imageUrl;

        var content = '<div class="img-responsive"><img class="img-popover" src="' + url + '"/></div>';

        var target = this.$el.find('.image img');

        target.attr('data-content', content);
        target.popover({
          trigger: 'hover',
          placement: 'left',
          html: 'true',
          container: $('#content')
        }).data('bs.popover').tip().addClass('jqgrid-popover');
      }
    },

    onDestroy: function () {
      $('.jqgrid-popover').remove();
    },

    serializeData: function () {
      var templateData = {};

      var aNames = this.model.get('refImprintTechnology').name.values;
      templateData.name = PimUtil.getTranslation(aNames);
      templateData.imppCode = this.model.get('refImprintTechnology').code;
      templateData.width = this.model.get('width');
      templateData.height = this.model.get('height');
      templateData.setupCode = this.model.get('setupCode');
      templateData.unitCode = this.model.get('unitCode');
      templateData.imageUrl = '';
      if (this.model.get('refImprintTechnology') && this.model.get('refImprintTechnology').colorModeType === 'spot') {
        templateData.maxColors = this.model.get('maxColors');
      } else {
        templateData.maxColors = '';
      }
      if (this.model.get('refImprintTechnology') && this.model.get('refImprintTechnology').colorModeType === 'quadri') {
        templateData.digital = true;
      } else {
        templateData.digital = '';
      }
      if (this.model.get('refImprintTechnology') && this.model.get('refImprintTechnology').colorModeType === 'fixedFilling') {
        templateData.eng = true;
      } else {
        templateData.eng = '';
      }
      var aImages = this.model.get('printPictures');

      if (aImages.length > 0) {
        templateData.imageUrl = aImages[0].imageUrl;
      }

      templateData.allowUpload = false;

      if (this.options.pimProduct.getCurrentResProduct()) {
        templateData.allowUpload = true;
      }

      return templateData;
    }
  });

  module.exports = EditPimPrintZoneView;
});
