
define('template!adminGlossariesTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page">\n  \t<h2>' +
((__t = ( _.i18n('glossaries.title') )) == null ? '' : __t) +
'</h2>\n    <div class="sub-caccounts-table">\n      <div class="form-group add-new-block">\n            <a class="btn new-cAccount-button add-btn-text js-add-new">\n              <i class="mdi-content-add"></i>\n              <span>' +
((__t = ( _.i18n('common.new') )) == null ? '' : __t) +
'</span>\n            </a>\n      </div>\n      <table id="jq-grid-list" class="cacountTable" role="grid"></table>\n      <div id="jq-grid-pager-list"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

