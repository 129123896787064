/**
 * Created by OLD on 31/08/2015.
 */
define('customerAllMessagesTabView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'backbone',
  'app',
  'settings',
  'bootbox',
  'imageUtils',  
  'stateMessages',
  'customerAllMessagesMessagesView',
  'template!customerAllMessagesTabTpl',
  'notificationCenter',
  'attachmentCollectionView',
  'imageUtils',
  'rolesMixin',
  'tawlkToBehavior',
  'dropdownCompositeView',
  'zoomFullScreenBehavior'
], function(
  module,
  Marionette,
  $,
  _,
  Backbone,
  App,
  Settings,
  bootbox,
  ImageUtils,
  StateMessages,
  ItemView,
  viewTpl,
  NotificationCenter,
  AttachmentCollectionView,
  imageUtils,
  RolesMixin,
  TawlkToBehavior,
  DropdownCompositeView,
  ZoomFullScreenBehavior
) {
  'use strict';

  var CommandTicketsView = Marionette.CompositeView.extend({
    template: viewTpl,
    childViewContainer: '.messages-container',
    childView: ItemView,
    childViewOptions: {},

    ui: {
      content: '.command-tickets-content',
      preview: '.img-preview-m',
      navigateToPoi: '.js-poi'
    },


    childEvents: {
      'attachedViewShown' : 'checkIfNeedHeightScroll'
    },

    events: {
      'click @ui.navigateToPoi': 'onNavigateToPoi'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      },
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: true
      },
      ZoomFullScreenBehavior: {
        behaviorClass: ZoomFullScreenBehavior,
        target: '.js-image-cell',
        gallery: true
      }
    },

    attachHtml: function(collectionView, childView, index) {
      if (index === 0 || this.currentPoi !== childView.model.get('poi')) {
        collectionView.$el.find('.messages-container').append('<div style="margin-top:20px;text-align: center;">' +
          '<p class="js-poi" style="cursor:pointer;">'+childView.model.get('poi')+'</p></div>');
      }
      collectionView.$el.find('.messages-container').append(childView.el);
      this.currentPoi = childView.model.get('poi');
    },

    onRender: function() {
      if (this.options.csMsg) {
        $('#content-region').addClass('active-cs-message-tab');
      } else {
        $('#content-region').removeClass('active-cs-message-tab');
      }
    },

    onShow: function () {
      this.messagesCounter = 0;
      $.material.init();
      this.showAttachmentsView();
      if (this.$el.parent().parent().find('#customer-tab-content').length > 0) {
        this.customerTab = true;
        this.ui.content.css('top',0);
      }
      this.positionHeader();
      $(window).bind('resize.positionHeader', _.bind(this.positionHeader, this));
      App.on('sidebar:resize', this.positionHeader, this);
      App.on('sidebar:switch', this.positionHeader, this);
    },

    onTabClick: function(e) {
      Settings.set('lastMsgTab', $(e.currentTarget).data('name'));
    },

    onUploadImage: function(childView) {
      var formdata = new FormData(),
        file = childView.ui.uploadInput[0].files[0],
        request, params, src, link;

      formdata.append('file', file);
      request = App.request('userRequestFile:upload', formdata);
      childView.triggerMethod('showMessage', 'primary', _.i18n('common.loading'));

      $.when(request).done(function(resp) {
        params = { logoUID: resp };
        src = Settings.url('x2pdf', 'vectorteam/preparefile', params);

        link = window.document.createElement('a');
        link.href = src;
        link.download = 'download.pdf';
        link.click();

        childView.triggerMethod('hideMessage');
      });
    },

    onDeleteFileAttachment: function(childView, attId, ticketSecId, messageSecId) {
      this.trigger('tickets:delete:fileAttachment', attId, ticketSecId, messageSecId);
    },

    showAttachmentsView: function() {
      this.attachmentsView = new AttachmentCollectionView({el: this.ui.attachList});
      this.attachmentsView.render();
    },

    serializeData: function() {
      var templateData = this.collection.toJSON();


      this.childViewOptions.csMsg = this.options.csMsg;


      templateData.csMsg = this.options.csMsg;
      return templateData;
    },

    /* Scroll messages to the bottom of visible area*/
    checkIfNeedHeightScroll: function() {
      this.messagesCounter++;
      if (this.messagesCounter === this.collection.length) {
        this.heightScroll();
      }
    },

    heightScroll: function (target) {
      var content = this.ui.content;

      if (target === 'attachment:add') {
        content.addClass('m-b-45');
      } else {
        content.removeClass('m-b-45');
      }

      //Get the last message position and height
      var $lastMsg = this.$('.messages-container').find('.tickets-message-container' ).last();
      if ($lastMsg.length) {
        //Set the new height on the message container
        this.ui.content.css('height', $lastMsg.position().top +
          $lastMsg.height() - (this.customerTab?150:0)
        );
      }
      //Scroll at the bottom of the page
      var that = this;
      that.positionHeader();
    },

    positionHeader: function() {
      var $windowHeight = $(window).height() - $('.tab-layout-header').height() - 120 - (this.customerTab?150:0);
      this.ui.content.css('height',$windowHeight);
    },

    showAttach: function(file) {
      var attachmentModel = new Backbone.Model();

      attachmentModel.set('name', file.name);
      attachmentModel.set('extension', file.extension);
      this.attachmentsView.collection.add(attachmentModel);
      this.ui.attachBox.removeClass('hidden');
      if (this.fileLoaded < this.fileToLoad) {
        this.startMultiUpload(this.fileInputTmp);
      }
      else{
          this.ui.attach.val('');
      }
      this.heightScroll('attachment:add');
    },

    changeStatus: function(state) {
      this.ui.backToList.css('pointer-events', 'none');
      this.trigger('state:change', state);
    },


    onBeforeDestroy: function() {
      App.off('sidebar:resize', this.positionHeader, this);
      App.off('sidebar:switch', this.positionHeader, this);
      $(window).off('resize.positionHeader');
      if (this.attachmentsView) {
        this.attachmentsView.destroy();
      }
      if(this.changeStatusView) {
        this.attachmentsView.destroy();
      }
      if ($('#content-region').hasClass('active-cs-message-tab')) {
        $('#content-region').removeClass('active-cs-message-tab');
      }      
    },


    setCAccountWarning: function() {
        this.ui.cAccountMoreInfo.parent().addClass('caccount-more-info-warning');
        this.$('.caccount-more-info > i' ).removeClass('fa-info-circle' ).addClass('fa-exclamation-triangle');
    },

    onNavigateToPoi: function(e) {
      var route = App.getCurrentRoute() ===  'offers' ? App.getCurrentRoute() : 'offers/' + App.getCurrentRoute();
      route = route.split('offers').join('orders');
      App.navigate( 'orders/' + $(e.target).html(), {trigger: true});
    }

  });

  module.exports = CommandTicketsView;
});

