/**
 * Created by RKL on 13/08/2015.
 */
define('caccountShareView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'jqgridView',
  'stateMessages',
  'shareCreateEditLayoutView',
  'template!caccountShareTpl',
  'entities/caccounts/share'
], function (
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  JqGridView,
  StateMessages,
  CreateEditLayoutView,
  viewTpl
) {
  'use strict';

  var CAccountShareTabView = JqGridView.extend({
    template: viewTpl,

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-share-' + this.options.share,
        jqGridPager: '#jq-grid-pager-share-' + this.options.share,
        addShare: '.js-add-share',
        deleteShare: '.js-delete-share',
        loader: '.js-loading'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click @ui.deleteShare': 'onDeleteShare',
        'click @ui.addShare': 'onAddShare'
      }, this.gridEvents);
    },

    serializeData: function () {
      return this.options;
    },

    gridOptions: function(data) {
      var defaultsOptions = this.gridInitOptions(data),
        type = this.options.data.type,
        check = type === 'caccounts' || type === 'subcaccounts',
        options = {
          datatype: 'local',
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.code'),
              name: 'code',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'code'
            }, {
              label: _.i18n('common.name'),
              name: 'name',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'name'
            }, {
              label: _.i18n('cAccount.autoImport'),
              name: 'autoImport',
              classes: 'cell-link',
              formatter: this.checkBoxBaseFormatter,
              search: true,
              index: 'autoImport',
              width: 60,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
              }
            }, {
              label: _.i18n('cAccount.autoImportOutdated'),
              name: 'autoImportOutdated',
              classes: 'cell-link',
              formatter: this.checkBoxBaseFormatter,
              search: true,
              index: 'autoImportOutdated',
              width: 60,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
              }
            }, {
              label: _.i18n('cAccount.autoImportPrice'),
              name: 'autoImportPrice',
              classes: 'cell-link',
              formatter: this.checkBoxBaseFormatter,
              search: true,
              index: 'autoImportPrice',
              width: 60,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
              }
            }, {
              label: _.i18n('common.comment'),
              name: 'comment',
              classes: 'cell-link',
              width: 50,
              formatter: this.tooltipIconFormatter,
              search: true,
              index: 'comment'
            }
          ],

          onSelectRow: _.bind(function (rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              this.onEdit(rowid);
            }
          }, this)
        };

      if (check) {
        options.colModel.push({
          label: '',
          name: '',
          classes: 'delete-link js-delete-share',
          formatter: this.deleteIconFormatter,
          search: false,
          width: 50
        });
      }

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onEdit: function (secId) {
      var item = _.findWhere(this.gridArrayData, {secId: secId});
      var createEditView = new CreateEditLayoutView({
        customerModel: App.request('caccounts:share-model', item.item)
      });
      createEditView.show({
        title: _.i18n('common.edit')
      });
    },

    displayGrid: function (list, data) {
      var that = this,
        gridOptions;

      data.rowCount = 0;
      this.setFiltersName();
      gridOptions = this.gridOptions(data);

      that.ui.jqGrid.jqGrid(gridOptions);

      // activate the toolbar searching
      that.ui.jqGrid.jqGrid('filterToolbar');
      $(window).bind('resize', function() {
        that.ui.jqGrid.setGridWidth(that.$el.find('.jq-grid-table-page').width());
      });

      that.fetchGridData(list);
    },

    setFiltersName: function() {
      this.filtersName = 'caccountShare' + this.options.share + 'Filters';
    },

    fetchGridData: function(list) {
      this.gridArrayData = [];
      var type = this.options.share === 'to' ? 'caccountTo' : 'caccountFrom';

      _.each(list, _.bind(function (item, index) {
        this.gridArrayData.push({
          number: index + 1,
          code: item[type].code,
          name: item[type].name,
          autoImport: item[type].autoImport,
          autoImportPrice: item[type].autoImportPrice,
          autoImportOutdated: item[type].autoImportOutdated,
          secId: item[type].secId,
          comment: item.comment,
          item: item
        });
      }, this));

      // set the new data
      this.ui.jqGrid.jqGrid('clearGridData');
      this.ui.jqGrid.jqGrid('setGridParam', {data: this.gridArrayData});

      // refresh the grid
      this.ui.jqGrid.trigger('reloadGrid');
    },

    updatePagination: function() {},

    onAddShare: function() {
      this.trigger('onAddShare', this.options.share);
    },

    onDeleteShare: function(e) {
      var target = $(e.currentTarget),
        shareId = target.find('.cell-delete').data('row-id');

      this.trigger('onDeleteShare', shareId, this.options.share);
    }
  });

  module.exports = CAccountShareTabView;
});
