/**
 * Created by RKL on 21/08/2015.
 */
define('offersMainRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'contentShowController',
  'itemPagesNameMixin'
], function (
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ContentShowController,
  ItemPagesNameMixin
) {
  'use strict';

  var OffersRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'offers': 'showView'
    }
  });

  var API = {
    showView: function () {
      require([
        'offersShowController'
      ], function (ShowController) {
        ContentShowController.showContent().done(function () {
          App.startSubModule('offersMain');
          ShowController.showOffers();
          App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS_LIST);
        });
      });
    }
  };

  App.on('offers:show', function () {
    App.navigate('offers');
    API.showView();
  });

  App.on('before:start', function () {
    new OffersRouter({
      controller: API
    });
  });

  module.exports = OffersRouter;
});
