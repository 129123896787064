/**
 * Created by OLD on 31/08/2015.
 */
define('ticketsAllView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'backbone',
  'app',
  'settings',
  'bootbox',
  'imageUtils',  
  'stateMessages',
  'messagesAllView',
  'messageAllModel',
  'template!ticketsAllTpl',
  'notificationCenter',
  'changeStatusAllView',
  'ticketStatesAll',
  'attachmentCollectionView',
  'imageUtils',
  'rolesMixin',
  'translation',
  'tawlkToBehavior',
  'dropdownCompositeView',
  'zoomFullScreenBehavior',
  'tariffScaleData'
], function(
  module,
  Marionette,
  $,
  _,
  Backbone,
  App,
  Settings,
  bootbox,
  ImageUtils,
  StateMessages,
  ItemView,
  MessageModel,
  viewTpl,
  NotificationCenter,
  ChangeStatusView,
  ticketStates,
  AttachmentCollectionView,
  imageUtils,
  RolesMixin,
  Translation,
  TawlkToBehavior,
  DropdownCompositeView,
  ZoomFullScreenBehavior,
  tariffScales
) {
  'use strict';

  var TicketsGraphicalRequestAllInOneView = Marionette.CompositeView.extend({
    template: viewTpl,
    childViewContainer: '.messages-container',
    childView: ItemView,
    childViewOptions: {},

    ui: {
      sendMessage: '.js-send-new-m-b',
      content: '.tickets-content',
      textarea: '.create-new-message',
      attach: '.attach-input',
      attachBtn: '.attach-btn',
      attachBox: '.attachments-addon',
      attachList: '.attachments-addon-list',
      preview: '.img-preview-m',
      counter: '.counter-m',
      ticketState: '#ticket-state',
      backToList: '.back-to-list',
      subjectInput: '.subject-input',
      footer: '.js-footer',
      ticketsHeader: '.js-tickets-header',
      cAccountMoreInfo: '.caccount-more-info',
      messagesTabsHeader: '.tickets-header-with-tabs',
      urgencyCheckbox: 'input.js-mark-ticket-urgent',
      tariffScaleSelect: 'select.js-change-tafiff-scale',
      assignTo: '.js-assign-to'
    },

    events: {
      'click @ui.sendMessage' : 'sendMessage',
      'click @ui.attachBtn': 'onFileBrowse',
      'change @ui.attach' : 'selectAttach',
      'click @ui.close' : 'closeTicket',
      'click @ui.backToList': 'onBackToList',
      'change @ui.subjectInput' : 'onChangeSubject',
      'autogrow @ui.footer': 'onTextAreaGrow',
      'click .dropdown-menu li a': 'onTextSupportSelected',
      'click @ui.cAccountMoreInfo': 'onShowCAccountMoreInfoDialog',
      'change @ui.urgencyCheckbox': 'onChangeTicketUrgency',
      'change @ui.tariffScaleSelect': 'onTicketTariffScaleChange',
      'click @ui.assignTo': 'onAssignTo',
      'click .ticket-tab': 'onTabClick'
    },

    childEvents: {
      'onUploadImage' : 'onUploadImage',
      'attachedViewShown' : 'checkIfNeedHeightScroll',
      'onDeleteAttachment': 'onDeleteFileAttachment'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      },
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: false
      },
      ZoomFullScreenBehavior: {
        behaviorClass: ZoomFullScreenBehavior,
        target: '.js-image-cell',
        gallery: true
      }
    },

    onRender: function() {
      if (this.options.tab === 'messages-cs') {
        $('#content-region').addClass('active-cs-message-tab');
      } else {
        $('#content-region').removeClass('active-cs-message-tab');
      }
      if (this.options.tab) {
        this.$el.find('.ticket-tab').removeClass('active');
        this.$el
          .find('.ticket-tab[data-name="' + this.options.tab + '"]')
          .addClass('active');
      }
    },

    onShow: function () {
      this.messagesCouner = 0;
      $.material.init();
      NotificationCenter.getInstance().addListener(this, NotificationCenter.events.NEW_VECTORISATION_TICKET_MESSAGE, this.onMessage);
      this.showAttachmentsView();
      this.showChangeStatus();
      this.showChangeType();
      this.ui.textarea.autogrow();
      this.positionHeaderAndFooter();
      if (!this.collection.length) {
        this.ui.textarea.css('max-height', $('#content-region').height() / 2);
      } else {
        this.ui.textarea.css('max-height', (this.ui.content.height() + this.ui.footer.height()) / 2);
      }
      $(window).bind('resize.positionHeaderAndFooter', _.bind(this.positionHeaderAndFooter, this));
      App.on('sidebar:resize', this.positionHeaderAndFooter, this);
      App.on('sidebar:switch', this.positionHeaderAndFooter, this);
    },

    onTabClick: function(e) {
      Settings.set('lastMsgTab', $(e.currentTarget).data('name'));
    },

    onTextSupportSelected: function(e) {
      e.preventDefault();
      this.ui.textarea.val(
        _.i18n(Translation.getTextSupport($(e.currentTarget).data('key')),
          this.options.ticket.get('cAccountJsonLight' ).lang));
    },

    onShowCAccountMoreInfoDialog: function() {
      this.trigger('show:more:caccount:info', new Backbone.Model(this.options.ticket.get('cAccountJsonLight')));
    },

    onUploadImage: function(childView) {
      var formdata = new FormData(),
        file = childView.ui.uploadInput[0].files[0],
        request, params, src, link;

      formdata.append('file', file);
      request = App.request('userRequestFile:upload', formdata);
      childView.triggerMethod('showMessage', 'primary', _.i18n('common.loading'));

      $.when(request).done(function(resp) {
        params = { logoUID: resp };
        src = Settings.url('x2pdf', 'vectorteam/preparefile', params);

        link = window.document.createElement('a');
        link.href = src;
        link.download = 'download.pdf';
        link.click();

        childView.triggerMethod('hideMessage');
      });
    },

    onDeleteFileAttachment: function(childView, attId, ticketSecId, messageSecId) {
      this.trigger('tickets:delete:fileAttachment', attId, ticketSecId, messageSecId);
    },

    showAttachmentsView: function() {
      this.attachmentsView = new AttachmentCollectionView({el: this.ui.attachList});
      this.attachmentsView.render();
    },

    showChangeStatus: function() {
      if(!Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR]) {
          return;
      }
      this.changeStatusView = new ChangeStatusView({currentState: this.options.ticket.get('ticketState')});
      this.changeStatusView.render();
      this.listenTo(this.changeStatusView, 'status:change', this.changeStatus);
      this.$('#change-status-region').append(this.changeStatusView.el);
      },

    showChangeType: function() {
        var state = this.options.ticket.get('ticketState');
        if(!Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR] ||
            state === 'CLOSE' ||
            state === 'DONE' ||
            state === 'CANCELED') {
            return;
        }
      //set the current type as first
      var arrayTypes = [{ label: _.i18n('menu.vectorizationRequest'), value: 'VECTORIZATION' },
          { label: _.i18n('menu.backRemove'), value: 'REMOVE_BACKGROUND' },
          { label: _.i18n('menu.fileconversion'),  value: 'CONVERSION' },
          { label: _.i18n('colorization.title'),  value: 'COLORIZATION' }];

      var temp = arrayTypes[0];
      switch(this.options.ticket.get('type')){
          case 'conversion':
              arrayTypes[0] = arrayTypes[2];
              arrayTypes[2] = temp;
              break;
          case 'remove_background':
              arrayTypes[0] = arrayTypes[1];
              arrayTypes[1] = temp;
              break;
          case 'colorization':
              arrayTypes[0] = arrayTypes[3];
              arrayTypes[3] = temp;
              break;
      }

      this.changeTypeView = new DropdownCompositeView({
          collection: new Backbone.Collection(arrayTypes),
          onItemSelected: _.bind(function(type){
              this.trigger('type:change', type);
          }, this)
      });
      this.changeTypeView.render();
      this.changeTypeView.triggerMethod('before:show');
      this.$('#change-type-dropdown-region').append(this.changeTypeView.el);
    },

    onMessage: function (response) {
      if (this.options.ticket.get('secId') !== response.content.ticketSecId) {
        //if the message is not for me then ask notification center to display a notification.
        NotificationCenter.getInstance().processNotification(response);
      } else {
        if (!_.findWhere(this.collection.toArray(), {id:response.content.id})) {
          var messageModel = new MessageModel(response.content);
          this.addMessage(messageModel);
        }
      }
    },

    onChangeSubject: function() {
      this.ui.subjectInput.attr('title', this.ui.subjectInput.val());
      this.trigger('ticket:change-subject', this.ui.subjectInput.val());
    },

    serializeData: function() {
      var templateData = this.collection.toJSON();
      var config = this.options.ticket.get('configuration');

      this.childViewOptions.csMsg = this.csMsg;
      templateData.outputType = config ? config.outputType : '';
      templateData.typeClass = this.getTypeClass(this.options.ticket.get('type'));
      templateData.ticketState = this.options.ticket.get('ticketState');
      templateData.subject = this.options.ticket.get('name');
      templateData.vectorizator = Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR];
      templateData.ticketStates = ticketStates;
      templateData.code = this.options.ticket.get('cAccountJsonLight').code;
      templateData.logoUrl = imageUtils.getLogo(this.options.ticket.get('cAccountJsonLight').logo, templateData.code);
      templateData.textsSupport = this.options.textsSupport;
      templateData.ticketSecId = this.options.ticket.get('secId');
      templateData.ticketIsUrgent = this.options.ticket.get('urgent');
      templateData.tariffScales = tariffScales;
      templateData.tariffScale = this.options.ticket.get('tariffScale');
      templateData.isKanban = App.getCurrentRoute().split('/')[0] === 'graphical_service_kanban' ? true : false;
      templateData.balance = this.options.balance;
      templateData.editTariffScale = Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_MODI_TARIFFSCALE];
      templateData.editUrgency = Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_MODI_SET_URGENT];
      templateData.user = this._getAssignUserData();
      templateData.showMoreInfo = Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_SHOW_MORE_INFO];
      templateData.showCustomerMsg = Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_SHOW_CUSTOMER_MSG];

      return templateData;
    },

    _getAssignUserData: function() {
      var user = this.options.ticket.get('refUser');

      if (user) {
        return {
          userImg: (function() {
            if (user.logo) {
              return Settings.url('file', user.logo, {size: 'thumbnail'});
            } else if (user.logonId) {
              var userName = user.firstName + ' ' + user.lastName;
              return imageUtils.getLogo(
                user.logo, 
                userName.trim() ? userName : user.logonId, 
                'circle'
              );
            } else {
              return null;
            }
          })(),
          userName: user.logonId
        };
      } else {
        return {userImg: null,userName: null};
      }
    },

    onAssignTo: function(e) {
      e.preventDefault();
      if (Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR_ONLY_ASSIGN] || 
        !Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_MODI_ASSIGN]) {
        return;
      }
      var ticket = this.options.ticket.toJSON(),
        userLogonId = ticket.refUser && ticket.refUser.logonId ? ticket.refUser.logonId : null;
      
      this.trigger('tickets:details:get-users', ticket.secId, userLogonId);
    },

    updateAssignUser: function(userName, userImg) {
      var image;

      if (this.ui.assignTo.find('assigned-user-logo').length && userImg && userName) {
        this.ui.assignTo.find('.assigned-user-logo')
          .css('background-image:url(' + userImg + ')');
      } else if (!this.ui.assignTo.find('assigned-user-logo').length && userImg && userName) {
        this.ui.assignTo.html(
          '<div class="assigned-user-logo" style="background-image:url(' + userImg +')"></div>'
        );
      } else if (!this.ui.assignTo.find('assigned-user-logo').length && !userImg && userName) {
        image = imageUtils.getLogo(userImg, userName, 'circle');
        this.ui.assignTo.html(
          '<img class="logo-holder js-assigned-user-no-logo" src="' + image + '"/>'
        );
      } else if (!userName && !userImg) {
        image = '<i class="mdi-action-face-unlock"></i>';
        this.ui.assignTo.html(image);
      }
    },

    getTypeClass: function(type) {
          if (type === 'vectorization') {
              return 'mdi-image-grain';
          } else if (type === 'conversion') {
              return 'mdi-av-repeat';
          } else if (type === 'remove_background') {
              return 'mdi-content-content-cut';
          }
        return '';
    },


    onBackToList: function() {
      if (App.getCurrentRoute().indexOf('fromAllInOne') === -1 && App.getCurrentRoute().indexOf('graphical_service_kanban') !== -1) {
        App.navigate('graphical_service_kanban', {trigger: true});
      } else {
        App.navigate('graphical_service_all_in_one', {trigger: true});
      }
    },

    /* Scroll messages to the bottom of visible area*/
    checkIfNeedHeightScroll: function() {
      this.messagesCouner++;
      if (this.messagesCouner === this.collection.length) {
        this.heightScroll();
      }
    },

    heightScroll: function (target) {
      var content = this.ui.content,
        isVectorizator = Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR],
        vectorizationHedaer = isVectorizator ? 50 : 0; //50 height of tabsMenu

      if (target === 'attachment:add') {
        content.addClass('m-b-45');
      } else {
        content.removeClass('m-b-45');
      }

      //Get the last message position and height
      var $lastMsg = this.$('.messages-container').find('.tickets-message-container' ).last();
      if ($lastMsg.length) {
        //Set the new height on the message container
        this.ui.content.css('height', $lastMsg.position().top +
          $lastMsg.height() +
          this.ui.footer.height() + this.ui.ticketsHeader.height() + 
          vectorizationHedaer);
      }
      //Scroll at the bottom of the page
      setTimeout(function() {
        $('#content-region').animate({scrollTop: content.height()}, 'slow');
      }, 0);
    },

    sendMessage: function() {
      var cyrillicPattern = /[\u0400-\u04FF]/;
      var that = this,
        messageContent = this.ui.textarea.val(),
        textarea = this.ui.textarea,
        messageModel;
      that._disableSend();
      if ((messageContent !== '' || that.options.attachedFiles !== []) && this.csMsg || !cyrillicPattern.test(messageContent)) {
        textarea.parent().removeClass('has-error');

        messageModel = new MessageModel();
        messageModel.set('content', messageContent);
        messageModel.set('name', that.options.ticket.get('name') || ' ');
        messageModel.set('files', that.options.attachedFiles);
        messageModel.url = messageModel.postUrl({csMsg:this.csMsg===true}, that.options.ticket.get('secId'));
        textarea.prop('disabled', true);

        messageModel.save().done(function () {
          textarea.prop('disabled', false);
          textarea.val('');
          that.addMessage(messageModel);
          that.attachmentsView.collection.reset();
          that._enableSend();
        });
      } else {
        textarea.parent().addClass('has-error');
        that._enableSend();
      }
    },

    addMessage: function (messageModel) {
      this.collection.add(messageModel);
      this.options.attachedFiles = [];
      this.ui.attachBox.addClass('hidden');
      this.heightScroll();
      this._triggerDeleteTextArea();
    },

    onFileBrowse: function(e) {
      e.preventDefault();
      this.ui.attach.click();
    },

    selectAttach: function(event) {
      var files = this.ui.attach[0].files;
      this.fileToLoad = files.length;
      this.fileLoaded = 0;
      if (this.fileToLoad === 0) {
        return;
      }
      this.ui.sendMessage.addClass('disabled');
      this.startMultiUpload(event.target);
    },

    startMultiUpload: function(fileInput){
      this.fileInputTmp = fileInput;
      this.postLoadedAttach(fileInput);
    },

    postLoadedAttach: function (fileInput) {
      if (fileInput) {
        this.fileInfo = ImageUtils.getFileInfo(fileInput, this.fileLoaded);

        //Load and encode the image
        ImageUtils.encodeInputFileToBase64(fileInput,
        this.fileLoaded, _.bind(this.onAttachmentEncoded, this));
      }
    },

    onAttachmentEncoded: function(base64File) {
      var file;
      file = {
        name: this.fileInfo.fileName,
        content: base64File, //base64 file encoded
        extension: this.fileInfo.fileExt //file extension
      };
      this.fileInfo.base64Value = base64File;

      this.options.attachedFiles.push(file);
      this.fileLoaded++;
      this.showAttach(file);
    },

    showAttach: function(file) {
      var attachmentModel = new Backbone.Model();

      attachmentModel.set('name', file.name);
      attachmentModel.set('extension', file.extension);
      this.attachmentsView.collection.add(attachmentModel);
      this.ui.attachBox.removeClass('hidden');
      if (this.fileLoaded < this.fileToLoad) {
        this.startMultiUpload(this.fileInputTmp);
      }
      else{
          this.ui.attach.val('');
      }
      this.heightScroll('attachment:add');
      this.ui.sendMessage.removeClass('disabled');
    },

    changeStatus: function(state) {
      this.ui.backToList.css('pointer-events', 'none');
      this.trigger('state:change', state);
    },

    onChangeStateUI: function(state) {
      this.ui.ticketState.text(ticketStates[state].label);
      this.ui.backToList.css('pointer-events', 'auto');
    },

    _disableSend: function() {
      this.ui.sendMessage.addClass('disabled');
      this.ui.attachBtn.addClass('disabled')
        .find('a.send-new-m-b').addClass('disabled');
      this.ui.attach.attr('disabled', true);
    },

    _enableSend: function() {
      this.ui.sendMessage.removeClass('disabled');
      this.ui.attachBtn.removeClass('disabled')
        .find('a.send-new-m-b').removeClass('disabled');
      this.ui.attach.attr('disabled', false);
    },

    _triggerDeleteTextArea: function() {
      var e = $.Event('keyup', {which: 8});
      this.ui.textarea.trigger(e);
    },

    onBeforeDestroy: function() {
      App.off('sidebar:resize', this.positionHeaderAndFooter, this);
      App.off('sidebar:switch', this.positionHeaderAndFooter, this);
      $(window).off('resize.positionHeaderAndFooter');
      if (this.attachmentsView) {
        this.attachmentsView.destroy();
      }
      if(this.changeStatusView) {
        this.attachmentsView.destroy();
      }
      if ($('#content-region').hasClass('active-cs-message-tab')) {
        $('#content-region').removeClass('active-cs-message-tab');
      }      
    },

    onTextAreaGrow: function() {
      this.heightScroll();
    },

    positionHeaderAndFooter: function() {
      var $sideBarWidth = $('#sidebar-region').width();

      if($('#sidebar-region' ).hasClass('nav-off-screen')) {
        this.ui.ticketsHeader.css('top', 0);
        this.ui.ticketsHeader.css('left', 0);
        this.ui.footer.css('left',0);
      } else {
        this.ui.messagesTabsHeader.css('top', 130);
        this.ui.messagesTabsHeader.css('left', $sideBarWidth);
        this.ui.ticketsHeader.css('top', 75);
        this.ui.ticketsHeader.css('left', $sideBarWidth);
        this.ui.footer.css('left',$sideBarWidth);
      }
    },

    onChangeTicketUrgency: function(e) {
      var $input = $(e.currentTarget),
        ticketID = $input.data('ticket-cid'),
        checked = $input.is(':checked');
      
      this.trigger('ticket:update:urgency', ticketID, checked);        
    },

    onTicketTariffScaleChange: function(e) {
      var $target = $(e.currentTarget);

      this.trigger(
        'tickets:update:tariffScale', 
        $target.data('ticket-cid'), 
        $target.find(':selected').val()
      );    
    },

    setCAccountWarning: function() {
        this.ui.cAccountMoreInfo.parent().addClass('caccount-more-info-warning');
        this.$('.caccount-more-info > i' ).removeClass('fa-info-circle' ).addClass('fa-exclamation-triangle');
    }

  });

  module.exports = TicketsGraphicalRequestAllInOneView;
});
