
define('template!customerAllMessagesTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="loading-block js-loading-block">\n\t<span class="loading-message js-loading-message label"></span>\n</div>\n<div class="row tickets-message-container">\n\t';
 if (direction === 'from') { ;
__p += '\n\t\t<div class="col-xs-3 col-sm-1 avatar-section p-r-0">\n\t\t\t';
 if (fileUrl) { ;
__p += '\n\t\t\t\t<div class="avatar-in-msg" style="background-image: url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
')"></div>\n\t\t\t';
 } else { ;
__p += '\n\t\t\t\t<i class="mdi-action-account-circle avatar-messages-block"></i>\n\t\t\t';
 } ;
__p += '\n\t\t\t';
 if (user) { ;
__p += '\n\t\t\t\t<p class="first-name hidden-xs" title="' +
((__t = ( userPid.logonId )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="right">\n\t\t\t\t\t' +
((__t = ( user )) == null ? '' : __t) +
'\n\t\t\t\t</p>\n\t\t\t';
 } ;
__p += '\n\t\t</div>\n\t\t<div class="col-xs-8 col-sm-7 message-section p-l-0">\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-2 date-container">\n\t\t\t\t\t<i class="mdi-content-send" style="font-size: 24px;float:left;"title="Sent"></i>\n\t\t\t\t\t<div style="float:left;padding-top: 6px;padding-left:20px;">\n\t\t\t\t\t\t<i class="mdi-action-alarm"></i>\n\t\t\t\t\t\t<span class="m-time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t<i class="mdi-action-today"></i>\n\t\t\t\t\t\t<span class="m-day">' +
((__t = ( day )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-2 date-container">\n\t\t\t\t\t';
if (mandrillOpens) { ;
__p += '\n\t\t\t\t\t<i class="mdi-content-drafts" style="font-size: 24px;float:left;" title="# of opening, first open">' +
((__t = ( mandrillOpens )) == null ? '' : __t) +
'</i>\n\t\t\t\t\t<div style="float:left;padding-top: 6px;padding-left:20px;">\n\t\t\t\t\t\t<i class="mdi-action-alarm"></i>\n\t\t\t\t\t\t<span class="m-time">' +
((__t = ( timeMandrillOpen )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t<i class="mdi-action-today"></i>\n\t\t\t\t\t\t<span class="m-day">' +
((__t = ( dayMandrillOpen )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t</div>\n\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t</div>\n\t\t\t\t<div class="col-xs-2 date-container">\n\t\t\t\t\t';
if (mandrillClicks) { ;
__p += '\n\t\t\t\t\t<i class="mdi-navigation-check" style="font-size: 24px;float:left;" title="# of clicks, first click">' +
((__t = ( mandrillClicks )) == null ? '' : __t) +
'</i>\n\n\t\t\t\t\t<div style="float:left;padding-top: 6px;padding-left:20px;">\n\t\t\t\t\t\t<i class="mdi-action-alarm"></i>\n\t\t\t\t\t\t<span class="m-time">' +
((__t = ( timeMandrillClick )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t<i class="mdi-action-today"></i>\n\t\t\t\t\t\t<span class="m-day">' +
((__t = ( dayMandrillClick )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t</div>\n\t\t\t\t\t';
 } ;
__p += '\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t<div class="well message-container">\n\t\t\t\t\t\t<p>' +
__e( content.replace(/<[^>]br>|<br[^>]*>/g, '\n') ) +
'</p>\n\t\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t\t';
 _.each(attachments, function(item, index) { ;
__p += '\n\t\t\t\t\t\t\t<div class="attach-block">\n\t\t\t\t\t\t\t\t<div class="attachment-container">\n\t\t\t\t\t\t\t\t\t<div class="attached-image-container">\n\t\t\t\t\t\t\t\t\t\t<div class="js-image-cell js-' +
((__t = ( index )) == null ? '' : __t) +
' attachment-in-m attach-' +
((__t = ( index )) == null ? '' : __t) +
'"></div>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t  <div class="attachment-file-link js-attachment-file js-' +
((__t = ( index )) == null ? '' : __t) +
'">\n\t\t\t\t\t\t<a href="' +
((__t = ( item.downloadLink)) == null ? '' : __t) +
'">' +
((__t = ( item.fileName )) == null ? '' : __t) +
'</a>\n\t\t\t\t\t  </div>\n\t\t\t\t\t\t\t\t\t\t<a class="btn btn-primary download-btn-text btn-sm  attach-' +
((__t = ( index )) == null ? '' : __t) +
'" href="' +
((__t = ( item.downloadLink)) == null ? '' : __t) +
'">\n\t\t\t\t\t\t<i class="mdi-action-get-app"></i>\n\t\t\t\t\t\t<span>' +
((__t = ( _.i18n('common.download') )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t\t\t\t</a>\n\n\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t';
 } else { ;
__p += '\n\t\t<div class="col-xs-offset-1 col-sm-offset-4 col-xs-8 col-sm-7 message-section p-r-0">\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-12 date-container">\n\t\t\t\t\t<i class="mdi-content-send" style="font-size: 24px;float:left;"title="Sent"></i>\n\t\t\t\t\t<div style="float:left;padding-top: 6px;padding-left:20px;">\n\t\t\t\t\t\t<i class="mdi-action-alarm"></i>\n\t\t\t\t\t\t<span class="m-time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t<i class="mdi-action-today"></i>\n\t\t\t\t\t\t<span class="m-day">' +
((__t = ( day )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class="row">\n\t\t\t\t<div class="col-xs-12">\n\t\t\t\t\t<div class="well message-container">\n                        <p>' +
__e( content.replace(/<[^>]br>|<br[^>]*>/g, '\n') ) +
'</p>\n\t\t\t\t\t\t<div class="row">\n\t\t\t\t\t\t\t';
 _.each(attachments, function(item, index) { ;
__p += '\n\t\t\t\t\t\t\t<div class="attach-block">\n\t\t\t\t\t\t\t\t<div class="attachment-container">\n\t\t\t\t\t\t\t\t\t<div class="attached-image-container">\n\t\t\t\t\t\t\t\t\t\t<div class="js-image-cell js-' +
((__t = ( index )) == null ? '' : __t) +
' attachment-in-m attach-' +
((__t = ( index )) == null ? '' : __t) +
'"></div>\n\t\t\t\t\t\t\t\t\t</div>\n                  <div class="attachment-file-link js-attachment-file js-' +
((__t = ( index )) == null ? '' : __t) +
'">\n                    <a href="' +
((__t = ( item.downloadLink)) == null ? '' : __t) +
'">' +
((__t = ( item.fileName )) == null ? '' : __t) +
'</a>\n                  </div>\n\t\t\t\t\t\t\t\t\t<a class="btn btn-primary download-btn-text btn-sm attach-' +
((__t = ( index )) == null ? '' : __t) +
'" href="' +
((__t = ( item.downloadLink)) == null ? '' : __t) +
'">\n\t\t\t\t\t\t\t\t\t\t<i class="mdi-action-get-app"></i>\n                    <span>' +
((__t = ( _.i18n('common.download') )) == null ? '' : __t) +
'</span>\n\t\t\t\t\t\t\t\t\t</a>\n\n\n\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t';
 }); ;
__p += '\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="col-xs-3 avatar-section p-l-0 col-sm-1">\n\t\t\t';
 if (fileUrl) { ;
__p += '\n\t\t\t\t<div class="avatar-in-msg" style="background-image: url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
')"></div>\n\t\t\t';
 } else { ;
__p += '\n\t\t\t\t<i class="mdi-action-account-circle avatar-messages-block"></i>\n\t\t\t';
 } ;
__p += '\n\t\t\t';
 if (userPid && userPid.logonId) { ;
__p += '\n\t\t\t\t<p class="first-name hidden-xs" title="' +
((__t = ( userPid.logonId )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="right">\n\t\t\t\t\t' +
((__t = ( userPid.logonId )) == null ? '' : __t) +
'\n\t\t\t\t</p>\n\t\t\t';
 } ;
__p += '\n\t\t</div>\n\t';
 } ;
__p += '\n</div>\n\n';

}
return __p
};});

