/**
 * Created by BFR on 28/06/2018.
 */
define('mailsListView',[
  'module',
  'underscore',
  'marionette',
  'app',
  'gmailUtils',
  'settings',
  'jquery',
  'dateUtils',
  'template!mailsListTpl',
  'mailView'

], function(
  module,
  _,
  Marionette,
  App,
  GmailUtils,
  Settings,
  $,
  dateConverter,
  viewTpl

) {
  'use strict';
  var MailsListView =  Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      mailDetail: '.mail-detail',
      accountOrders: '.account-orders'
    },

    modelEvents: {'sync': 'render'},

    events: {
      'click @ui.sendMessage' : 'sendMessage',
      'click .googleConnect': 'onGoogleConnectClick',
      'click .email' : 'onEmailSelect',
      'click .js-prev': 'onPreviousPage',
      'click .js-next': 'onNextPage',
      'click .js-first': 'onFirstPage',
      'change .mail-query': 'onQueryChange'
    },

    ui: {
      textarea: '.create-new-message',
      inSubject: '.input-subject',
      mailTo: '.mail-to',
      content: '.mails-content',
      footer: '.js-footer',
      sendMessage: '.js-send-new-m-b',
      blockLoading: '.js-loading-block'
    },

    serializeData: function() {
      var templateData = {};
      if (this.model) {
        templateData = this.model;
        templateData.mails = this.model;
        templateData.connected = true;
        templateData.mailTo = this.mailTo;
      } else {
        templateData.connected = false;
      }
      templateData.query = this.query;
      return templateData;
    },

    onNextPage: function() {
      this.triggerMethod('pages:navigate','next');
    },

    onPreviousPage: function() {
      this.triggerMethod('pages:navigate','prev');
    },

    onFirstPage: function() {
      this.triggerMethod('pages:navigate','');
    },

    onEmailSelect: function (e) {

      var mailId = this.$el.find(e.target).attr('rowid');
      var mailFrom = this.$el.find(e.target).attr('mailFrom');
      this.mailTo = _.escape(mailFrom);
      this.render();
      this.ui.blockLoading.show();
      this.triggerMethod('mailslist:select',mailId);
    },

    onQueryChange: function(e) {
      var input = $(e.target);
      this.query = input.val();
      this.triggerMethod('mails:query',this.query);
    },


    onGoogleConnectClick: function() {
      var gmailUtils = GmailUtils.getInstance();
      gmailUtils.signIn();
    },

    removeLoader: function() {
      this.ui.blockLoading.hide();
    },

    sendMessage: function() {
      var gmailUtils = GmailUtils.getInstance();


      var that = this,
        messageContent = this.ui.textarea.val(),
        textarea = this.ui.textarea,
        mailTo = this.ui.mailTo.val();
      that._disableSend();
      if (messageContent !== '' && mailTo !== '') {
        textarea.parent().removeClass('has-error');

        textarea.prop('disabled', true);

        gmailUtils.sendMessage(mailTo, this.ui.inSubject.val(),
          messageContent, _.bind(function () {
            textarea.prop('disabled', false);
            textarea.val('');
            this.ui.inSubject.val('');
            that._enableSend();
          },this));

      } else {
        textarea.parent().addClass('has-error');
        that._enableSend();
      }
    },

    _disableSend: function() {
      this.ui.sendMessage.addClass('disabled');
    },

    _enableSend: function() {
      this.ui.sendMessage.removeClass('disabled');
    }

  });

  module.exports = MailsListView;
});
