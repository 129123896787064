define('graphicalRequestKanbanShowController',[
  'module',
  'logger',
  'underscore',
  'settings',
  'app',
  'backbone',
  'bootbox',
  'graphicalRequestKanbanShowView',
  'graphicalRequestKanbanFilterView',
  'graphicalRequestKanbanBoardView',
  'emptyView',
  'graphicalRequestKanbanColumnView',
  'entities/kanban_tickets'
], function(
  module,
  Logger,
  _,
  Settings,
  App,
  Backbone,
  bootbox,
  View,
  FilterView,
  BoardView,
  EmptyView,
  ColumnView
) {
  'use strict';

  var GraphicalRequestKanbanController = {
    showKanbanBoard: function() {
      var that = this;

      this.view = new View();
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);

      $.when(App.request('kanban-tickets:get-users'))
        .done(function(_users) {
          that.users = that._parseUsers(_.sortBy(_users, function(user){return user.firstName.toLowerCase();}));
          that.filterView = new FilterView({users: that.users});
          that.boardView = new BoardView(); 
          that.filterView.listenTo(that.filterView, 'filter-change:update', _.bind(that._changeFilter, that));
          that._fetchTickets(that.filterView.filters);
          if (that.view.getRegion('kanbanFilters')) {
            that.view.getRegion('kanbanFilters').show(that.filterView);
          }
          if (that.view.getRegion('kanbanBoard')) {
            that.view.getRegion('kanbanBoard').show(that.boardView);
          }
          that.boardView.getRegion('states').show(new EmptyView());
      });
    },

    _parseUsers: function(_users) {
      this.users = _.map(_users, function(user) {
        if (user.firstName && user.lastName) {
          user.fullName = user.firstName + ' ' + user.lastName;
        } else if (user.firstName || user.lastName) {
          user.fullName = user.firstName ? user.firstName : user.lastName;
        } else if (user.nickName) {
          user.fullName = user.nickName;
        } else {
          user.fullName = user.logonId;
        }          
        return user;
      });
      return this.users;
    },

    _fetchTickets: function(params) {
      var that = this;
      
      $.when(
        App.request('kanban-tickets:row-count', 'OPEN', params),
        App.request('kanban-tickets:row-count', 'PENDING', params),
        App.request('kanban-tickets:row-count', 'PROGRESS', params),
        App.request('kanban-tickets:row-count', 'REVIEW', params),
        App.request('kanban-tickets:row-count', 'DONE', params)
      ).done(function(rcOpenRC, rcPending, rcProgress, rcReview, rcDone) {            
        $.when(
          rcOpenRC ? App.request('kanban-tickets:get', 'OPEN', rcOpenRC, params) : new Backbone.Collection(),
          rcPending ? App.request('kanban-tickets:get', 'PENDING', rcPending, params) : new Backbone.Collection(),
          rcProgress ? App.request('kanban-tickets:get', 'PROGRESS', rcProgress, params) : new Backbone.Collection(),
          rcReview ? App.request('kanban-tickets:get', 'REVIEW', rcReview, params) : new Backbone.Collection(),
          rcDone ? App.request('kanban-tickets:get', 'DONE', 5, params) : new Backbone.Collection()
        ).done(function(open, pending, progress, review, done) {
          that._showTicketsOnBoard({
            open: open,
            pending: pending, 
            progress: progress, 
            review: review, 
            done: done
          });
          if (that.filterView && that.filterView.ui &&
              that.filterView.ui.blinder &&
              _.isFunction(that.filterView.ui.blinder.hide)) {
            that.filterView.ui.blinder.hide();
          }
        });
      });
    },

    _changeFilter: function(filters) {
      this.boardView.getRegion('states').show(new EmptyView());
      this._fetchTickets(filters);
    },

    _changeTicketState: function(ticketId, receivedState) {
      var that = this;
      $.when(App.request('kanban-tickets:change-state', ticketId, receivedState.toUpperCase()))
        .done(function() {
          if (receivedState === 'progress') {            
            bootbox.confirm(_.i18n('timetracking.doYouWantToStart'), function(result) {
              if (result) {
                  that._startTrackTime(ticketId);
              }
            });
          }
        });
    },

    _moveToTop: function(ticketId) {
      $.when(App.request('kanban-tickets:move-to-top', ticketId))
        .done(function() {});
    },

    _cancel: function(ticketId) {
      this._changeTicketState(ticketId, 'CANCELED');
    },

    _startTrackTime: function(ticketId) {
      $.when(App.request('kanban-tickets:start-track-time', ticketId))
        .done(function (response) {
          Logger.log(module, response);
        })
        .fail(function (textStatus, erroThrown) {
          Logger.log(module, textStatus, erroThrown);
        });
    },

    _showTicketsOnBoard: function(_tickets) {
      var region,
        columnView = new ColumnView({tickets: _tickets, users: this.users});

      columnView.listenTo(columnView, 'column:on-refresh', _.bind(this._changeTicketState, this));
      columnView.listenTo(columnView, 'column:moveToTop', _.bind(this._moveToTop, this));
      columnView.listenTo(columnView, 'column:cancel', _.bind(this._cancel, this));
      columnView.listenTo(columnView, 'column:start-track-time', _.bind(this._startTrackTime, this));
      region = this.boardView.getRegion('states');
      if (region) {
        region.show(columnView);
      }
    }
  };

  module.exports = GraphicalRequestKanbanController;
});
