/**
 * Created by BFR on 24/02/2020.
 */
define('editPrintZoneView',[
  'module',
  'marionette',
  'app',
  'bootbox',
  'settings',
  'underscore',
  'template!editPrintZoneTpl',
  'popupPrintZoneView',
  'pluginUtils'
], function (
  module,
  Marionette,
  App,
  bootbox,
  Settings,
  _,
  viewTpl,
  PopupPrintZoneView,
  PluginUtils
) {
  'use strict';

  var EditPrintZoneView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'printzone',

    modelEvents: {
      sync: 'render'
    },

    ui: {
      btnContainer: '.btn-pz-container'
    },

    regions: {
      nameRegion: '.name-region',
      itNameRegion: '.itname-region'
    },

    events: {
      'mouseover': 'onMouseOver',
      'mouseout': 'onMouseOut',
      'click .btn-delete-pz': 'onDeletePzClick',
      'click .btn-edit-pz': 'onEditPzClick',
      'click .btn-edit-pz-xml': 'onBtnEditXmlClick'
    },

    onMouseOver: function () {
      this.ui.btnContainer.show();
    },

    onMouseOut: function () {
      this.ui.btnContainer.hide();
    },

    onShow: function () {
    },

    onDeletePzClick: function () {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.model.destroy();
        }
      }, this));

    },

    onEditPzClick: function () {
      var popupPrintZoneView = new PopupPrintZoneView({
        model: this.model
      });
      popupPrintZoneView.show({
        title: _.i18n('common.edit')
      });
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      if (this.options.model.get('refImprintTechnology')) {
        templateData.name = this.options.model.get('refImprintTechnology').get('name').getTextInLocale(Settings.get('lang'));
        templateData.imppCode = this.options.model.get('refImprintTechnology').get('code');
      } else {
        templateData.name = '';
        templateData.imppCode = '';
      }
      return templateData;
    },

    onBtnEditXmlClick: function () {
      PluginUtils.callPlugin({
        action: 'editPrintZoneXml',
        printZoneSecId: this.model.get('secId')
      });
    }
  });

  module.exports = EditPrintZoneView;
});
