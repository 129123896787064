define('produceProofsheetController',[
  'module',
  'produceProofsheetView',
  'app',
  'entities/session'
], function(
  module,
  ProduceProofsheetView,
  App
) {
  'use strict';

  var ProduceProofsheetController = {
    showProduceProofsheet: function(region, orderModel) {
      App.request('session:create-session', {webServiceUrl: 'random'}).done(function(session) {
        region.show(new ProduceProofsheetView({
          model: orderModel,
          session: session
        }));
      });
    }
  };

  module.exports = ProduceProofsheetController;
});
