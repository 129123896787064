/**
 * Created by Matteo on 11/07/2015.
 */
define('customerCommentsSidebarShowView',[
  'jqueryui',
  'module',
  'marionette',
  'jquery',
  'bootbox',
  'underscore',
  'app',
  'settings',
  'dateUtils',
  'template!customerCommentSidebarViewTpl'
], function(
  test,
  module,
  Marionette,
  $,
  bootbox,
  _,
  App,
  Settings,
  dateConverter,
  viewTpl

) {
  'use strict';
  
  var CustomerCommentsSidebarView =  Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'customer-comments-main',
    triggers: {
      'click @ui.switcher' : 'sidebar:resize'
    },

    ui: {
      toHide: '.cust-com-to-hide',
      toShow: '.cust-com-to-show',
      switcher: '.cust-com-switcher',
      newComment: '.js-new-comment',
      saveButton: '.js-save',
      cancelButton: '.js-cancel',
      deleteButton: '.js-delete',
      changeButton: '.js-change'
    },

   events: {
      'change @ui.newComment':'onChangeTextArea',
      'keyup @ui.newComment':'onChangeTextArea',
      'click @ui.changeButton':'onChangeComment',
      'click @ui.deleteButton':'onDeleteComment',
      'click @ui.saveButton':'onSaveComment',
      'click @ui.cancelButton':'onCancelChanges'
   },

    serializeData: function () {
      var templateData = {};
      templateData.comments = [];
      this.collection.sort();
      _.each(this.collection.models, function(model) {
        templateData.comments.push({
          secId:model.get('secId'),
          comment:model.get('comment'),
          user: model.get('refUserpid').firstName + ' ' +model.get('refUserpid').lastName,
          date: dateConverter.toDateFormatString(model.get('modDate'))
        });
      });
      templateData.nbrOfComments = this.collection.models.length;
      this.currentComment = undefined;
      return templateData;
    },

    onRender: function () {
      if (this.collection.models.length === 0) {
        $('.cust-com-aside-md').addClass('nav-xs');
      } else {
        $('.cust-com-aside-md').removeClass('nav-xs');
      }
    },

    onResize: function () {
      if (this.ui.toHide.hasClass('hide')) {
        this.showBar();
      } else {
        this.hideBar();
      }
    },

    hideBar: function () {
      this.ui.toHide
        .hide()
        .addClass('hide');
      this.ui.toShow.css('display', 'block');
    },

    showBar: function () {
      this.ui.toHide
        .show()
        .removeClass('hide');
      this.ui.toShow.css('display', 'none');
    },

    onChangeTextArea: function () {
      if (this.ui.newComment.val().length > 0) {
        this.ui.saveButton.prop('disabled', false);
        this.ui.cancelButton.prop('disabled', false);
      } else {
        this.ui.saveButton.prop('disabled', true);
        this.ui.cancelButton.prop('disabled', true);
      }
    },

    onSaveComment: function() {
      var that = this,customerComment;
      if (this.currentComment) {
        customerComment = this.collection.where({secId:this.currentComment.attr('data-id')})[0];
        customerComment.set({comment:this.currentComment.val()});
        customerComment.save(this.endUserSecId).done( function(data) {
          customerComment.set(data);
          this.currentComment= undefined;
          that.render();
      });
      } else {
        customerComment = App.request('customercomments:get-model');
        customerComment.set('comment', this.ui.newComment.val());
        customerComment.save(this.endUserSecId).success(function (data) {
          that.collection.add(data);
          this.currentComment= undefined;
          that.render();
        });
      }
    },

    onDeleteComment: function(e) {
      var that = this,
          customerComment = this.collection.where({secId:$(e.target).attr('data-id')})[0];
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function(result) {
        if (result) {
          customerComment.destroy().done(function () {
            that.render();
          });
        }
      }, this));

    },
    onCancelChanges: function() {
      if (this.currentComment) {
        this.currentComment.val(this.currentCommentOldValue);
        this.currentComment.prop('disabled',true);
        this.currentComment.blur();

      }
      this.ui.newComment.prop('disabled',false);
      this.ui.newComment.val('');
      this.ui.cancelButton.prop('disabled',true);
      this.currentComment= undefined;
    },

    onChangeComment:function (e) {
      if (this.currentComment) {
        this.currentComment.val(this.currentCommentOldValue);
        this.currentComment.prop('disabled',true);
        this.currentComment.blur();
      }
      this.currentComment = $(e.target).parent().parent().find('textarea');
      this.currentCommentOldValue = this.currentComment.val();
      $(e.target).parent().parent().find('textarea').prop('disabled',false);
      this.ui.newComment.prop('disabled',true);
      this.ui.saveButton.prop('disabled',false);
      this.ui.cancelButton.prop('disabled',false);
      this.ui.newComment.prop('disabled',true);
    }
  });

  module.exports = CustomerCommentsSidebarView;
});

