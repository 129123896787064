
define('template!billingTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n\t<div class="row billing-tab-container">\n\t\t<div class="col-md-6 left-area col-sm-12">\n\t\t\t<div class="form-group">\n\t\t\t\t<a class="btn add-btn-text btn-sm add-new-code-b">\n\t\t\t\t\t<i class="mdi-content-add"></i>\n\t\t\t\t\t<span>' +
((__t = ( _.i18n("price.billingCode.add") )) == null ? '' : __t) +
'</span>\n\t\t\t\t</a>\n\t\t\t\t<input type="file"\n\t\t\t\t\t\t\t id="price-excel-input"\n\t\t\t\t\t\t\t class="hidden js-upload-prices" data-icon="true"\n\t\t\t\t\t\t\t multiple="multiple"\n\t\t\t\t\t\t\t accept="text/csv"/>\n\t\t\t\t<label for="price-excel-input" class="btn add-btn-text btn-sm billing-import">\n\t\t\t\t  <i class="mdi-content-add"></i>\n\t\t\t\t  <span>' +
((__t = ( _.i18n("price.import") )) == null ? '' : __t) +
'</span>\n\t\t\t\t</label>\n\t\t\t\t<!-- <a href="" class="btn add-btn-text btn-sm billing-export disabled hidden" target="_blanck">\n\t\t\t\t\t<i class="mdi-content-add"></i>\n\t\t\t\t\t<span>' +
((__t = ( _.i18n("price.export") )) == null ? '' : __t) +
'</span>\n\t\t\t\t</a> -->\n\t\t\t</div>\n\t\t\t<div class="form-group">\n\t\t\t\t<div class="billing-price-table"></div>\n\t\t\t</div>\n\t\t</div>\n\t\t<div class="col-md-6 right-area col-sm-12">\n\t\t\t<div class="form-group">\n\t\t\t\t<a class="btn add-btn-text btn-sm add-new-pricelist-b">\n\t\t\t\t\t<i class="mdi-content-add"></i>\n\t\t\t\t\t<span>' +
((__t = ( _.i18n("price.add") )) == null ? '' : __t) +
'</span>\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<div class="form-group">\n\t\t\t\t<div class="billing-detail-table"></div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

