define('entities/admin/notifications',[
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'backboneRelational',
  'entities/product_translation'
], function (
  App,
  $,
  _,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  app.ns.Notification = Backbone.RelationalModel.extend({
    defaults: {
      refUserpid: null
    },

    url: function () {
      return Settings.url('compuzz2', 'v2/usernotifications/' + this.get('secId'));
    },

    postUrl: function () {
      return Settings.url('compuzz2', 'v2/usernotifications');
    },

    fetch: function () {
      this.url = Settings.url('compuzz2', 'v2/usernotifications/' + this.get('secId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    save: function () {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    },

    idAttribute: 'secId',

    relations: []
  });

  var NotificationCollection = Backbone.Collection.extend({
    url: function () {
      return Settings.url('compuzz2', 'v2/tasks');
    },
    model: app.ns.Notification
  });

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function () {
      return 'v2/usernotifications/rowCount';
    },

    getNotificationsUrl: function () {
      return Settings.url('compuzz2', 'v2/usernotifications');
    },

    getNotificationsCount: function () {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/usernotifications/rowCount'),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getNotifications: function () {
      var notifications = new NotificationCollection({}),
        defer = $.Deferred(),
        params = {
          first: 0,
          pageSize: 10
        };

      notifications.url = Settings.url('compuzz2', 'v2/usernotifications/', params);
      notifications.fetch({
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('notifications:model', function (notificationJSON) {
    var tt;
    if (notificationJSON) {
      tt = Backbone.Relational.store.find(app.ns.Task, notificationJSON.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.Task(notificationJSON);
    }
  });

  App.reqres.setHandler('notifications:row-count-url', function () {
    return API.getRowCountUrl();
  });

  App.reqres.setHandler('notifications:get-url', function () {
    return API.getNotificationsUrl();
  });

  App.reqres.setHandler('notifications:count', function () {
    return API.getNotificationsCount();
  });

  App.reqres.setHandler('notifications:entities', function () {
    return API.getNotifications();
  });

});
