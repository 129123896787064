define('entities/phone/calls',[
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings'
], function (
  App,
  $,
  _,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * REST API
   */
  var API = {
    getCallRecords: function (secId) {
      return $.ajax({
        url: Settings.url('compuzz2', 'v2/calls/' + secId + '/downloadRecord')
      });
    },

    getCollectionUrl: function (data) {
      var params = '';
      if (data) {
        if (data.endUserSecId) {
          params = data.endUserSecId ? '?endUserSecId=' + data.endUserSecId : '';
        } else if (data.endUserContactSecId) {
          params = data.endUserContactSecId ? '?endUserContactSecId=' + data.endUserContactSecId : '';
        }
      }
      return Settings.url('compuzz2', 'v2/calls/' + params);
    },

    getRowCountUrl: function (data) {
      var params = '';
      if (data) {
        if (data.endUserSecId) {
          params = data.endUserSecId ? '?endUserSecId=' + data.endUserSecId : '';
        } else if (data.endUserContactSecId) {
          params = data.endUserContactSecId ? '?endUserContactSecId=' + data.endUserContactSecId : '';
        }
      }
      return 'v2/calls/rowCount' + params;
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('calls:records', function (secId) {
    return API.getCallRecords(secId);
  });
  App.reqres.setHandler('calls:row-count-url', function (endUserSecId) {
    return API.getRowCountUrl(endUserSecId);
  });

  App.reqres.setHandler('calls:get-collection-url', function (endUserSecId) {
    return API.getCollectionUrl(endUserSecId);
  });
});
