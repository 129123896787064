/**
 * Created by Matteo on 11/07/2015.
 */
define('headerShowView',[
  'module',
  'logger',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'imageUtils',
  'autocompleteView',
  'template!headerViewTpl',
  'template!headerCaccountAutocompleteTpl',
  'openTasksCollectionView',
  'notificationsCollectionView',
  'plivoUtils',
  'globalSearchView',
  'rolesMixin',
  'entities/global_search'
], function(
  module,
  Logger,
  Marionette,
  _,
  App,
  $,
  Settings,
  imageUtils,
  AutocompleteView,
  viewTpl,
  caccountAutocompleteItemTpl,
  OpenTasksCollectionView,
  NotificationsCollectionView,
  PlivoUtils,
  GlobalSearchView,
  RolesMixin
) {
  'use strict';

  var HeaderView =  Marionette.LayoutView.extend({
    template: viewTpl,

    triggers: {
      'click @ui.logout' : 'onLogout',
      'click @ui.sidebar' : 'onShowSidebar',
      'click @ui.editLogin' : 'onEdit'
    },

    ui: {
      logout : '.logout',
      sidebar: '.show-hide-sidebar',
      user: '.show-hide-user',
      navbar: '.nav-user',
      avatar: '.avatar-dropdown',
      logo: '.avatar-in-header',
      userName: '.js-user-name',
      caccountName: '.js-caccount-name',
      caccountLogo: '.js-caccount-logo',
      caret: '.brand-title .caret',
      dropdown: '.caccount-hover-dropdown',
      availableCAccount: '.available-caccount',
      searchCaccount: '.search-caccount',
      editLogin: '.edit-ac',
      accountSwitch: '.js-account-switch',
      accountSwitchDropdownContainer: '.caccounts-dropdown-container',
      accountSwitchDropdown: '.js-account-switch-dropdown',
      languageItem: '.language-item',
      inputPhoneNumber: '.input-phone-number',
      btnPhoneNumber: '.btn-header-phone-number',
      btnDialNumber: '.btn-header-phone-number.on-the-phone',
      btnPhoneCall: '.btn-call-phone-number',
      validationPhoneMsg: '.validate-phone-message',
      plivoUsersMenu: '.plivo-users-menu',
      openTasks: '.tasksNotifications',
      notifications: '.notifications',
      nbrOpenTasks: '.nbrOpenTasks',
      nbrNotifications: '.nbrNotifications'
    },

    events: {
      'click @ui.availableCAccount': 'onSwitchCaccount',
      'click @ui.accountSwitch': 'onShowDropdown',
      'click @ui.accountSwitchDropdownContainer': 'onAccountContainerClick',
      'click @ui.languageItem': 'changeLanguage',
      'click @ui.inputPhoneNumber': 'onEnterPhoneNumber',
      'change @ui.inputPhoneNumber': 'onChangePhoneNumber',
      'keydown @ui.inputPhoneNumber': 'onCheckPhoneInputValue',
      'focus @ui.inputPhoneNumber': 'onClearValidation',
      'click @ui.btnPhoneNumber': 'onBtnPhoneNumberClick',
      'click @ui.btnPhoneCall': 'onPhoneCall',
      'click @ui.btnDialNumber': 'onBtnDialNumberClick',
      'click @ui.openTasks': 'getOpenTasks',
      'click @ui.notifications': 'getNotifications'
    },

    regions: {
      caccountAutocomplete: '.js-caccounts-autocomplete',
      globalSearch: '.search-region',
      openTasksList: '.openTasksList',
      notificationsList: '.notificationsList'
    },

    _caccountAutocompleteView: undefined,

    serializeData: function () {
      return {
        displayPhone: (Settings.get('plivoUser') && Settings.get('plivoPassword')),
        displayTasks: (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_TASKS]),
        displayNotifications: (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_TASKS])
      };
    },

    getOpenTasks: function () {
      this.trigger('getOpenTasks');
    },

    getNotifications: function () {
      this.trigger('getNotifications');
    },

    changeLanguage: function (event) {
      var lang = this.$el.find(event.currentTarget).children().attr('lang');
      this.setCurrentLanguage(lang);
      this.trigger('changeLanguage', lang);
    },

    setCurrentLanguage: function (language) {
      var $selected = this.$el.find('.language-head');
      $selected.attr('lang', language);
      $selected.text(language);
    },

    getAccountsAutocompleteOptions: function() {
      return {
        type: 'remote',
        alwaysOpen: true,
        remote: Settings.url('compuzz2', 'sessions.json/token/byuser/caccounts', {env: Settings.compuzzEnv}),
        valueKey: 'code',
        apiKey: 'secId',
        placeholder: _.i18n('selectaccount.placeholder'),
        value: null,
        name: 'accounts',
        onPressKey: true,
        callback: _.bind(this.onSwitchCaccount, this),
        childTemplate: caccountAutocompleteItemTpl,
        childViewSerializeData: this._autocompleteViewSerializeData,
        lazyLoad: true,
        keys: {
          query: 'caccountCode',
          limit: 'pageSize'
        }
      };
    },

    checkAvailableOpenTasks: function (count) {
      if (count > 0) {
        this.ui.openTasks.show();
        this.ui.nbrOpenTasks.text(count);
      } else {
        this.ui.openTasks.hide();
      }
    },

    checkNotifications: function (count) {
      if (count > 0) {
        this.ui.notifications.show();
        this.ui.nbrNotifications.text(count);
      } else {
        this.ui.notifications.hide();
      }
    },

    onEnterPhoneNumber: function (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    },

    onChangePhoneNumber: function (event) {
      event.target.value = event.target.value.replace(/\s/g, '');
    },

    showOpenTasksView: function () {
      this.openTasksCollectionView = new OpenTasksCollectionView();
      this.openTasksCollectionView.listenTo(this.openTasksCollectionView, 'tasks:done',
        _.bind(this.onAcceptOpenTasks, this));
      this.getRegion('openTasksList').show(this.openTasksCollectionView);
    },

    onAcceptOpenTasks: function (openTask) {
      this.trigger('tasks:done', openTask);
    },

    showNotificationsView: function () {
      this.notificationsCollectionView = new NotificationsCollectionView();
      this.notificationsCollectionView.listenTo(this.notificationsCollectionView, 'notifications:delete',
        _.bind(this.onDeleteNotification, this));
      this.getRegion('notificationsList').show(this.notificationsCollectionView);
    },

    onDeleteNotification: function (notification) {
      this.trigger('notifications:delete', notification);
    },

    onCheckPhoneInputValue: function (event) {
      if (event.which === 32) return false;
    },

    onBtnPhoneNumberClick: function (event) {
      if ($(event.currentTarget).hasClass('on-the-phone')) return;
      event.preventDefault();
      event.stopImmediatePropagation();
      var prevPhoneValue = this.ui.inputPhoneNumber.val();
      var currentPhoneValue = $(event.currentTarget).data('dial-value');
      this.ui.inputPhoneNumber.val(prevPhoneValue + currentPhoneValue);
    },

    onBtnDialNumberClick: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      PlivoUtils.dtmf($(event.currentTarget).data('dial-value'));
    },

    onClearValidation: function() {
      this.ui.validationPhoneMsg.hide();
    },

    onPhoneCall: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      if (this._validatePhoneNumber()) {
        PlivoUtils.callNumber(this.ui.inputPhoneNumber.val());
      } else {
        this.ui.validationPhoneMsg.show();
      }
    },

    _validatePhoneNumber: function() {
      var regex = /^\+(?:[\d]*)$/;
      return regex.test(this.ui.inputPhoneNumber.val());
    },

    onRender: function () {
      this.displayCurrentUserPidInfo();
      this.trigger('getAvailableCaccounts');
      this.setCurrentLanguage(Settings.get('lang'));
      this.searchView = new GlobalSearchView({
        data:[]
      });
      this.getRegion('globalSearch' ).show(this.searchView);
    },

    onShow: function () {
      $.material.init();
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_TASKS]) {
        this.showOpenTasksView();
        this.countOpenTasks();
        this.showNotificationsView();
        this.countNotifications();
      }
      if (Settings.get('plivoUser') && Settings.get('plivoPassword')) {
        this.refreshPlivoUsers(true);
        PlivoUtils.eventCall.push(_.bind(function () {
          this.refreshPlivoUsers(false);
        }, this));
      }
    },

    countOpenTasks: function () {
      this.trigger('countOpenTasks');
      setTimeout(_.bind(this.countOpenTasks, this), 20000);
    },

    countNotifications: function () {
      this.trigger('countNotifications');
      setTimeout(_.bind(this.countNotifications, this), 20000);
    },

    refreshPlivoUsers: function (recallAfter) {
      PlivoUtils.findConnectedUsers().then(_.bind(function (users) {
        var menu = this.$el.find(this.ui.plivoUsersMenu);
        menu.empty();
        for (var i = 0; i < users.length; i++) {
          var user = users[i],
            fnLs = user.firstName + ' ' + user.lastName;
          var logo = imageUtils.getLogo(null, fnLs);
          menu.append('<div title="' + fnLs + '"><img src="' + logo + '" style="width: 20px; height: 16px;"></img>' +
            '<div style="height:4px; width:20px; background-color: ' + (user.busy ? 'red' : 'green') +
            '; position: relative; left:0px; bottom: 0px;"></div></div>');
        }
        if (recallAfter) {
          setTimeout(_.bind(this.refreshPlivoUsers, this), 20000, true);
        }
      }, this)).fail(_.bind(function() {
        if (recallAfter) {
          setTimeout(_.bind(this.refreshPlivoUsers, this), 20000, true);
        }
      }));
    },

    onShowMenu: function(target) {
      if (this.ui.navbar.hasClass('open') || target === 'hide') {
        this.ui.navbar.removeClass('open');
      } else {
        this.ui.navbar.addClass('open');
      }
    },

    checkAvailableCaccounts: function (count) {
      if (count > 1) {
        this.ui.caret.show();
        this.renderCaccountsDropdown();
        this.displayDropdown();
      } else {
        this.ui.caret.hide();
        this.displayCurrentCaccountInfo();
      }
    },

    renderCaccountsDropdown: function () {
      this._caccountAutocompleteView = new AutocompleteView(this.getAccountsAutocompleteOptions());

      this.getRegion('caccountAutocomplete').show(this._caccountAutocompleteView);
      this._caccountAutocompleteView.$('.ac-suggestions.dropdown-menu').removeClass('dropdown-menu ac-suggestions').
        addClass('caccount-hover-dropdown');
      this.displayCurrentCaccountInfo(Settings.get('currentCAccount'));
    },

    displayCurrentCaccountInfo: function(account) {
      var currentCAccount = account || Settings.get('currentCAccount'),
        refMainCAccountJson = currentCAccount.refMainCAccountJson,
        logo, brandLogo;
      
      if (currentCAccount) {
        this.currentCAccount = currentCAccount;
        this.ui.caccountName.text(currentCAccount.code);
        if (refMainCAccountJson ) {
          brandLogo  = imageUtils.getLogo(
            refMainCAccountJson.logo,
            refMainCAccountJson.code, {
              action: 'res',
              params: 't'
            }
          );
          this.$('.compuzz_general_logo' ).attr('src', brandLogo );
        } else {
          this.$('.compuzz_general_logo' ).attr('src', Settings.url('base', Settings.get('compuzzLogo')) );
        }
        logo = imageUtils.getLogo(
          currentCAccount.logo,
          currentCAccount.code, {
            action: 'res',
            params: 't'
          }
        );
        this.ui.caccountLogo.attr('src', logo);
        $('title').html('Compuzz ' + currentCAccount.code + ' Dashboard');
      }
    },

    displayCurrentUserPidInfo: function() {
      var currentUser = Settings.get('currentUser'),
        fileUrl;

      if (currentUser) {
        fileUrl = imageUtils.getLogo(
          currentUser.logo,
          currentUser.firstName + ' ' + currentUser.lastName, {
            action: 'res',
            params: 't'
          }, 'rect'
        );
        this.ui.userName.text(currentUser.logonId);
        this.ui.logo.attr('style', 
          'background-image: url(' + fileUrl + ')');
      } else {
        this.ui.userName.text('Please Log in!');
      }
    },

    onShowDropdown: function() {
      var that = this;

      setTimeout(function() {
        if (that._caccountAutocompleteView) {
          that._caccountAutocompleteView.ui.autocomplete.focus();
        }
      }, 10);
    },

    onAccountContainerClick: function(e) {
      e.stopImmediatePropagation();
    },

    _onCloseDropdown: function() {
      this.ui.accountSwitchDropdown.removeClass('open');
    },

    displayDropdown: function() {
      this.$('.navbar-brand').removeClass('disabled');
    },

    onSwitchCaccount: function(fieldName, cAccount) {
      App.trigger('switchCaccount', cAccount.toJSON());
    },

    _autocompleteViewSerializeData: function() {
      var templateData = this.model.toJSON();

      templateData.url = imageUtils.getLogo(this.model.get('logo'), this.model.get('code'), {
        action: 'res',
        params: 't'
      });

      return templateData;
    }
  });

  module.exports = HeaderView;
});
