
define('template!customerAllMessagesTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="with-header">\n  <div class="command-tickets-content col-xs-12">\n    <div class="messages-container">\n    </div>\n  </div>\n</div>\n';

}
return __p
};});

