define( 'entities/admin/glossaries',[
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'backboneRelational',
  'entities/product_translation'
], function(
  App,
  $,
  _,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = { ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = { ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  app.ns.Glossaries = Backbone.RelationalModel.extend({
    defaults: {
      code: '',
      name: '',
      type: 'color',
      refDynamicText: new app.ns.DynamicText()
    },

    url: function() {
      return Settings.url('compuzz2', 'v2/glossaries/' + this.get('secId'));
    },

    postUrl: function () {
      return Settings.url('compuzz2', 'v2/glossaries');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    },

    idAttribute: 'secId',

    relations: [{
      type: Backbone.HasOne,
      key: 'refDynamicText',
      relatedModel: 'DynamicText'
    }]
  } );

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function() {
      return 'v2/glossaries/rowCount';
    },

    getCustomersUrl: function() {
      return Settings.url('compuzz2', 'v2/glossaries');
    }
  };

  App.reqres.setHandler('glossaries:model', function(glossariesJSON) {
    var tt;
    if (glossariesJSON) {
      tt = Backbone.Relational.store.find(app.ns.Glossaries, glossariesJSON.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.Glossaries(glossariesJSON);
    }
  });

  App.reqres.setHandler('glossaries:delete', function (secId) {
    var url = Settings.url('compuzz2', 'v2/glossaries/' + secId),
      defer = $.Deferred();

    $.ajax({
      type: 'DELETE',
      url: url,
      success: function (data) {
        defer.resolve(data);
      }
    });

    return defer.promise();
  });

  App.reqres.setHandler('glossaries:entities', function () {
    return API.getCAccountEntities();
  });

  App.reqres.setHandler('glossaries:row-count-url', function () {
    return API.getRowCountUrl();
  });

  App.reqres.setHandler('glossaries:get-url', function () {
    return API.getCustomersUrl();
  });

});
