/**
 * Created by OLD on 31/08/2015.
 */
define('customerAllMessagesTabController',[
  'module',
  'logger',
  'underscore',
  'backbone',
  'jquery',
  'app',
  'customerAllMessagesTabView',
  'entities/command_tickets',
  'settings',
  'rolesMixin',
  'controllerMixin',
  'entities/offers'
], function(
  module,
  Logger,
  _,
  Backbone,
  $,
  App,
  View,
  CollectionView,
  Settings,
  RolesMixin,
  ControllerMixin
) {
  'use strict';

  var CommandTicketsController = _.extend({
    showTab: function(data, region, csMsg, customer) {
      this.purchaseOrderId = data.id;
      this.regionView = region;
      this.customer = customer;
      this.getTicketsByCustomer(customer.secId);
    },

    showTabFromCustomer: function( regionView, customer, ViewInstance) {
      this.customer = customer.model;
      this.getTicketsByCustomer(this.customer.get('secId'),ViewInstance);
      this.regionView = regionView;

    },

    getTicketsByCustomer: function(secId,ViewInstance) {
      var
        ticketRequest = App.request('commandtickets:get-by-customer', secId),
        view,
        that = this;
      $.when(ticketRequest)
        .done(function(response) {
          var tickets = response.responseJSON,
            ticketDetails = [];
          _.each(tickets, function(ticket) {
            _.each(ticket.messages, function(ticketMessage) {
              var ticketDetail = { poi : ticket.purchaseOrderId,
                content : ticketMessage.content,
                date : ticketMessage.date,
                direction: ticketMessage.direction,
                attachments: ticketMessage.attachements,
                userPid: ticketMessage.userPid,
                mandrillOpens: ticketMessage.mandrillOpens,
                mandrillClicks: ticketMessage.mandrillClicks,
                firstMandrillClick: ticketMessage.firstMandrillClick,
                firstMandrillOpen: ticketMessage.firstMandrillOpen
              };
              ticketDetails.push(ticketDetail);
            });
          });
          var collection = new Backbone.Collection(ticketDetails);
          if (ViewInstance) {
            view = ViewInstance;
          } else {
            view = new View({
              collection: collection,
              csMsg: that.csMsg,
              customer: that.customer
            });
          }
          that.view = view;
          that.regionView.show(view);
        });
    }
  }, ControllerMixin);

  module.exports = CommandTicketsController;
});

