define('offersKanbanCommentPopupView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'template!offersKanbanCommentPopupTpl',
  'dialogFormView'
], function (
  module,
  Marionette,
  $,
  _,
  Backbone,
  Settings,
  viewTpl,
  DialogFormView
) {
    'use strict';

    var AssignPopupView = DialogFormView.extend({
      template: viewTpl,

      events: {
        'change @ui.input': 'onInputChange'
      },

      ui: {
        input: '.js-info-input'
      },

      onShow: function () {
        $.material.init();
      }
    });

    module.exports = AssignPopupView;
  });
