
define('template!caccountTransportTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<form>\n  <div class="row">\n    <label class="col-xs-8">Small Order Amount</label>\n    <input class="col-xs-4 txt-input txt-small-order-amount" data-property="smallOrderAmount"\n           value="' +
((__t = ( smallOrderAmount )) == null ? '' : __t) +
'"/>\n  </div>\n  <div class="row p-t-5">\n    <label class="col-xs-8">Small Order Fee</label>\n    <input class="col-xs-4 txt-input txt-small-order-fee" data-property="smallOrderFee" value="' +
((__t = ( smallOrderFee )) == null ? '' : __t) +
'"/>\n  </div>\n  <div class="row p-t-5">\n    <label class="col-xs-8">Type</label>\n    <select class="txt-input" data-property="transport" data-subproperty="mode">\n      <option value="B" ' +
((__t = ( transport.mode === 'B' ? 'selected' : '')) == null ? '' : __t) +
'>Number of boxes</option>\n      <option value="Q" ' +
((__t = ( transport.mode === 'Q' ? 'selected' : '')) == null ? '' : __t) +
'>Quantity of pieces</option>\n      <option value="T" ' +
((__t = ( transport.mode === 'T' ? 'selected' : '')) == null ? '' : __t) +
'>Total price of order</option>\n      <option value="W" ' +
((__t = ( transport.mode === 'W' ? 'selected' : '')) == null ? '' : __t) +
'>Weight of order</option>\n    </select>\n  </div>\n  <h2>Prices</h2>\n  <div class="transport-price-region sa-list">\n\n  </div>\n  <div class="col-xs-12">\n    <div class="btn new-cAccount-button btn-add-transport-price">\n      <i class="mdi-content-add"></i>\n      <span>' +
((__t = ( _.i18n('factors.add') )) == null ? '' : __t) +
'</span>\n    </div>\n    <div class="btn new-cAccount-button btn-delete-transport-price">\n      <i class="mdi-content-delete"></i>\n      <span>' +
((__t = ( _.i18n('factors.delete') )) == null ? '' : __t) +
'</span>\n    </div>\n  </div>\n</form>';

}
return __p
};});

