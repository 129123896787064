define('detailMailView',[
    'app',
    'module',
    'marionette',
    'underscore',
    'template!detailMailTpl'
], function(
  App,
  module,
    Marionette,
_,
    detailMailTpl
) {
    'use strict';

    var DetailMailView = Marionette.LayoutView.extend({
        template: detailMailTpl,

        ui: {
            'messageIframe': '.message-iframe',
            'messageForward': '.message-forward',
            'messageReply': '.message-reply',
            'messageContainer': '.message-container',
            'forwardToAddress' : '.forward-to-address',
            'replyToAddress' : '.reply-to-address',
            'toForwardMessage' : '.to-forward-message',
            'toReplyMessage' : '.to-reply-message',
            'replyToMailButton' : '.js-forward',
            'loadingBlock' : '.js-loading-block',
            'loadingMessage' : '.js-loading-message',
            'messageSubject' : '.message-subject'
        },

        events: {
            'click .offer-detail' : 'onOfferClick',
            'click .order-detail' : 'onOrderClick',
            'click .mail-forward' : 'onForwardReply',
            'click .mail-reply' : 'onForwardReply',
            'click .cancel-email' : 'cancelEmail',
            'click .send-email' : 'sendEmail',
            'keyup .forward-to-address' : 'onForwardToAddressEvent',
            'change .to-reply-message' : 'onDivChange'
        },

        regions: {

        },

        serializeData: function() {
            var templateData = this.model;
            templateData.mail = this.model;
            templateData.offers = this.model.offers;
            templateData.orders = this.model.orders;
            return templateData;
        },

        onShow: function() {
            $.material.init();
            if (this.ui.messageIframe) {
                $('body', $(this.ui.messageIframe)[0].contentWindow.document).html(this.model.contentDoc);
                setTimeout(_.bind(this.fitContent, this), 500);
            }
            this.triggerMethod('mail:loaded');
        },

        fitContent: function() {
            this.ui.messageIframe[0].height = this.ui.messageIframe[0].contentWindow.document.body.scrollHeight;
        },

        cancelEmail: function() {
            this.cleanFields();
            this.ui.messageForward.hide();
            this.ui.messageReply.hide();
            this.ui.messageIframe.show();
            this.ui.messageSubject.show();
        },

        onOfferClick: function(e) {
            var rowId = e.target.textContent;
            var url = $(location).attr('protocol')+ '//' + $(location).attr('hostname') + ':' + $(location).attr('port') +'/#orders/';
            window.open(url +rowId,'_blank');
        },

        onOrderClick: function(e) {
            var rowId = e.target.textContent;
          var url = $(location).attr('protocol') + '//' + $(location).attr('hostname') + ':' + $(location).attr('port') +'/#orders/';
          window.open(url + rowId, '_blank');
        },

        onForwardReply: function(e) {
         if ($(e.target).hasClass('mail-forward')) {
             this.showForward(true);
         } else {
             this.showReply(true);
         }
        },

        onForwardToAddressEvent: function (e) {
            var address = e.target;
            this.ui.replyToMailButton.prop('disabled', true);
            if (address.value) {
                var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                 if (pattern.test(address.value)){
                    this.ui.replyToMailButton.prop('disabled', false);
                }
            }
        },

        sendEmail: function(e) {
            this.ui.loadingMessage.html(_.i18n('common.sending'));
            this.ui.loadingBlock.show();

            var toAddr = '',
                messageToForwardReply = this.model;

            if ($(e.target).hasClass('js-forward')) {
                toAddr = this.ui.forwardToAddress.val();
            } else {
                toAddr = _.findWhere(messageToForwardReply.payload.headers, { 'name': 'From'}).value;
            }

            //var destination = _.findWhere(messageToForward.payload.headers, { 'name': 'To'});
            _.findWhere(messageToForwardReply.payload.headers,{'name':'To'}).value = toAddr;
            messageToForwardReply = _.omit(messageToForwardReply,'from', 'date', 'to', 'subject', 'direction',
              'content', 'contentDoc', 'time', 'day', 'offers', 'orders', 'mail', 'unread');

            if ($(e.target).hasClass('js-forward')) {
                messageToForwardReply.payload.body = this.ui.toForwardMessage.html();
                this.triggerMethod('mail:refwd',messageToForwardReply,'f');
            } else {
                messageToForwardReply.payload.body = this.ui.toReplyMessage.html();
                this.triggerMethod('mail:refwd',messageToForwardReply,'r');
            }
        },

        mailSent: function(error) {
            var that = this;
            if (error) {
                this.ui.loadingMessage.html(error);
                setTimeout(function(){
                    that.ui.loadingMessage.hide();
                }, 3000);
            } else {
                this.ui.loadingMessage.html(_.i18n('common.sent'));
                setTimeout(function () {
                    that.ui.loadingMessage.hide();
                }, 2000);
            }
            this.ui.messageForward.hide();
            this.ui.messageReply.hide();
            this.ui.messageIframe.show();
            this.ui.messageSubject.show();

        },

        showForward: function() {
            this.cleanFields();
            this.ui.messageForward.show();
            this.ui.messageIframe.hide();
            this.ui.messageSubject.hide();
        },

        showReply: function() {
            this.cleanFields();
            this.ui.messageReply.show();
            this.ui.messageIframe.hide();
            this.ui.messageSubject.hide();
        },

        cleanFields: function() {
            this.ui.forwardToAddress.val('');
            this.ui.replyToAddress.val(this.model.mail.from);
            var replyMessage = '<br>'+
                'On ' + this.model.mail.date + ' at ' + this.model.mail.time +
                ', ' + this.model.mail.from + ' wrote :<br>'+
                this.model.mail.contentDoc;
            this.ui.toReplyMessage.html(replyMessage);
            var forwardMessage = '<br>'+
                '---------- Forwarded message ---------<br>' +
                'From: '+ this.model.mail.from +'<br>' +
                'Date: '+ this.model.mail.date +'<br>' +
                'Subject: '+ this.model.mail.subject +'<br>' +
                'To: '+ this.model.mail.to +'<br>' +
            '<br>' + this.model.mail.contentDoc;
            this.ui.toForwardMessage.html(forwardMessage);
        }

    });

    module.exports = DetailMailView;
});
