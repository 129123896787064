
define('template!ordersViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content m-l-0 m-r-0">\n  <div class="well jq-grid-table-page">\n    <h2>' +
((__t = ( _.i18n('menu.orderManagement') )) == null ? '' : __t) +
'</h2>\n    <div class="sub-caccounts-table">\n      ';
 if(newOrderLogotoolUrl){ ;
__p += '\n      <div class="form-group add-new-block">\n        <a href="' +
((__t = ( newOrderLogotoolUrl )) == null ? '' : __t) +
'" target="_self" class="btn new-cAccount-button add-btn-text">\n          <i class="mdi-content-add"></i>\n          <span>' +
((__t = ( _.i18n('orders.new') )) == null ? '' : __t) +
'</span>\n        </a>\n        <a class="download orders-export btn-link" href="">\n            <i class="mdi-file-file-download alligned-middle"></i>\n            <span class="action-label alligned-middle">' +
((__t = ( _.i18n("common.download") )) == null ? '' : __t) +
'</span>\n          </a>\n          <a class="download orders-pdf-download" href="" style="visibility:hidden;" download></a>\n          <div class="js-action-buttons-region orders-action-button f-r"></div>\n        </div>\n      ';
 } ;
__p += '\n      <table id="jq-grid-list" class="cacountTable" role="grid"></table>\n      <div id="jq-grid-pager-list"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

