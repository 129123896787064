/**
 * Created by GSP on 4/08/2015.
 */
define('contentShowView',[
  'module',
  'marionette',
  'jquery',
  'app',
  'underscore',
  'settings',
  'template!contentLayoutTpl',
  'tawlkToBehavior',
  'plivoUtils',
  'rolesMixin',
  'bootbox'
], function (
  module,
  Marionette,
  $,
  App,
  _,
  Settings,
  layoutTpl,
  TawlkToBehavior,
  PlivoUtils,
  RolesMixin,
  bootbox
) {
  'use strict';

  var ContentLayoutView =  Marionette.LayoutView.extend({
    template: layoutTpl,

    regions: {
      header: '#header-region',
      sidebar: '#sidebar-region',
      customerCommentsSidebar: '#customer-comments-sidebar-region',
      content: '#content-region',
      zoom: '#gallery-zoom'
    },

    ui: {
      aside: '.aside-md',
      customerCommentsSidebar: '.cust-com-aside-md',
      btnAnswer: '.btn-answer',
      btnReject: '.btn-reject',
      btnHangup: '.btn-hangup',
      btnMute: '.btn-mute',
      btnUnMute: '.btn-unmute',
      btnPhoneNumber: '.btn-phone-number',
      btnForward: '.btn-forward',
      btnIgnore: '.btn-ignore',
      showCustomerOffers: '.js-show-customer-offers'
    },  

    behaviors: {
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: true
      }
    },

    events: {
      'click @ui.btnAnswer': 'onBtnAnswerClick',
      'click @ui.btnReject': 'onBtnRejectClick',
      'click @ui.btnIgnore': 'onBtnIgnoreClick',
      'click @ui.btnHangup': 'onBtnHangupClick',
      'click @ui.btnMute': 'onBtnMuteClick',
      'click @ui.btnUnMute': 'onBtnUnMuteClick',
      'click @ui.btnPhoneNumber': 'onBtnPhoneNumberClick',
      'click @ui.btnForward': 'onBtnForwardClick',
      'click @ui.showCustomerOffers' : 'onShowCustomerOffers'
    },

    serializeData: function() {
      var templateData = {
        displayPhone: (Settings.get('plivoUser') && Settings.get('plivoPassword')),
        design2: (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) ? true : false
      };
      return templateData;
    },

    onShow: function() {
      PlivoUtils.refreshInterface();
    },

    onBtnAnswerClick: function() {
      PlivoUtils.answer();
    },

    onBtnRejectClick: function() {
      PlivoUtils.reject();
    },

    onBtnIgnoreClick: function() {
      PlivoUtils.ignore();
    },

    onBtnHangupClick: function() {
      PlivoUtils.hangup();
    },

    onBtnMuteClick: function() {
      PlivoUtils.mute();
    },

    onBtnUnMuteClick: function() {
      PlivoUtils.unmute();
    },

    onBtnPhoneNumberClick: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      PlivoUtils.dtmf(event.currentTarget.innerText);
    },

    onSidebarShow: function() {
      if (this.ui.aside.hasClass('nav-off-screen')) {
        this.ui.aside
          .removeClass('nav-off-screen')
          .hide();
      } else {
        this.ui.aside
          .addClass('nav-off-screen')
          .show();
      }
    },

    onCustomerCommentsSidebarShow: function() {
      if (!Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        if (this.ui.customerCommentsSidebar.hasClass('nav-off-screen')) {
          this.ui.customerCommentsSidebar
            .removeClass('nav-off-screen')
            .hide();
        } else {
          this.ui.customerCommentsSidebar
            .addClass('nav-off-screen')
            .show();
        }
      }
    },

    onResizeSidebar: function() {
      if (this.ui.aside.hasClass('nav-xs')) {
        this.ui.aside.removeClass('nav-xs');
      } else {
        this.ui.aside.addClass('nav-xs');
      }
    },

    onResizeCustomerCommentsSidebar: function() {
      if (!Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        var that = this;
        if ( $('#plivo-sub-header').css('display')=== 'block') {
          $('#plivo-sub-header').css('display', 'none');
          setTimeout(function () {
            if (that.ui.customerCommentsSidebar.hasClass('nav-xs')) {
              that.ui.customerCommentsSidebar.removeClass('nav-xs');
            } else {
              that.ui.customerCommentsSidebar.addClass('nav-xs');
            }

            $('#plivo-sub-header').css('display', 'block');
          }, 100);
        } else {
          if (this.ui.customerCommentsSidebar.hasClass('nav-xs')) {
            this.ui.customerCommentsSidebar.removeClass('nav-xs');
          } else {
            this.ui.customerCommentsSidebar.addClass('nav-xs');
          }
        }
      }
    },

    onBtnForwardClick: function () {
      PlivoUtils.findConnectedUsers().then(function(users) {
        var select = '<select class="forward-ddl">';
        for (var i = 0 ; i < users.length ; i++) {
          var user = users[i];
          select += '<option value="'+user.plivoUser+'">' + user.firstName + ' ' + user.lastName + '</option>';
        }
        select += '</select>';

        bootbox.dialog({
          title: _.i18n('call.forward'),
          message: select,
          buttons: {
            success: {
              label: _.i18n('call.forward') ,
              className: 'btn-success',
              callback: function() {
                PlivoUtils.forwardCall(this.find('select').val());
              }
            }
          }
        });
      });
    },

    onResizeWindow: function () {
      var width = $(document.body).width();

      if (width > 768) {
        this.ui.aside
          .removeClass('nav-off-screen')
          .show();
      }
      if (width < 767) {
        this.ui.aside.removeClass('nav-xs');
      }
    },
     onShowCustomerOffers: function (e) {
      App.navigate($(e.target).attr('data-route'), {trigger: true});
     }
  });

  module.exports = ContentLayoutView;
});
