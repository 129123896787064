define('optionsConfigDialogView',[
    'module',
    'underscore',
    'backbone',
    'app',
    'jquery',
    'settings',
    'template!optionsConfigDialogTpl',
    'dialogView',
    'optionMixEditCollectionView'
], function(
    module,
    _,
    Backbone,
    App,
    $,
    Settings,
    viewTpl,
    DialogView,
    OptionMixEditCollectionView
) {
    'use strict';

    var ConfigOptionsView = DialogView.extend({
        template: viewTpl,

        events: {
            'click .dropdown-menu-printzone a': 'onChangePrinZone',
            'click .dropdown-menu-option a': 'onChangeOption',
            'click .dropdownMenu-changeoption a': 'onChangeOption',
            'click .js-confirm': 'onConfigurationSelected',
            'change .input-quantity': 'onQuantityChange',
            'click .add-mix-line': 'onAddMixLineClick'
        },

        regions: {
            mixOptionRegion: '.mix-table-body'
        },

        serializeData: function() {
            var templateData = {};
            this.mixable = templateData.mixable = false;
            templateData.options = this.options.options;
            templateData.optionsMix = this.options.mixedOptions;
            templateData.availableOptions = this.options.availableOptions;
            if (this.options.product) {
                templateData.product = this.options.product.toJSON();
            }
            if (templateData.optionsMix && templateData.optionsMix.length > 0) {
                this.mixable = templateData.mixable = true;
            }
            if (this.options.selectedOptions && this.options.selectedOptions.length > 0) {
                var filteredSelectedOptions = [], that = this;
                templateData.availableOptions = this.options.availableOptions;
                _.each(templateData.availableOptions, function(availableOption) {
                   var selectedOne = _.findWhere(that.options.selectedOptions, {catId : availableOption.id});
                   if (selectedOne) {
                       _.each(availableOption.productOptions, function(productOption) {
                           if (productOption.id === selectedOne.id) {
                               availableOption.selected = productOption;
                           }
                       });
                   }
                });

                _.each(this.options.selectedOptions, function(selectedOption) {
                    var filteredSelectedOption = _.findWhere(templateData.availableOptions, {id : selectedOption.catId});
                    if (filteredSelectedOption) {
                        filteredSelectedOptions.push(filteredSelectedOption);
                    }
                });
                templateData.options = filteredSelectedOptions;
            }
            return templateData;
        },

        onShow: function() {
            $.material.init();
            this.currentConfiguration = {
                quantity: 50,
                printZones: {},
                options: {}
            };

            if (this.mixable) {
                var collection = new Backbone.Collection(),
                that = this, pocs;
                this.productJson = this.options.product.toJSON();
                pocs = that.productJson.productOptionCategories;
                _.each(this.options.mixedOptions, function(mixedOption) {
                    var sortedPocs = that.moveToTop(pocs, mixedOption.options);
                    var defaultModel = new Backbone.Model({
                        productOptionCategories: sortedPocs,
                        quantity: mixedOption.quantity
                    });
                    collection.push(defaultModel);
                });

                this.optionMixEditCollectionView = new OptionMixEditCollectionView({
                    collection: collection,
                    product: this.productJson
                });
                this.getRegion('mixOptionRegion').show(this.optionMixEditCollectionView);
            }
        },

        onAddMixLineClick: function() {
            var defaultModel = new Backbone.Model({
                productOptionCategories: this.productJson.productOptionCategories,
                quantity: 100
            });
            this.optionMixEditCollectionView.collection.push(defaultModel);
        },

        onChangeOption: function(e){
            e.preventDefault();
            var target = $(e.target),
                index = target.data('index'),
                optionId = target.data('optionId');
            this.$el.find('.option-'+ index +'-label').text(target.text());
            this.currentConfiguration.options[index] = optionId;
        },

        onConfigurationSelected: function() {
            var optionsArray = [];
            if (this.currentConfiguration.options) {
                optionsArray = $.map(this.currentConfiguration.options, function(value) {
                    return [value];
                });
            }
            if (optionsArray && optionsArray.length > 0) {
                    this.trigger('options-changed', this.currentConfiguration);
            } else if (this.mixable) {
                this.currentConfiguration.optionsMix = this.optionMixEditCollectionView.getMixesConfiguration();
                this.currentConfiguration.quantity = 0;
                _.each(this.currentConfiguration.optionsMix, _.bind(function(mix) {
                    this.currentConfiguration.quantity += mix.quantity;
                }, this));
                this.trigger('options-changed', this.currentConfiguration);
            }

        },

        moveToTop: function(pocs, options) {
            var newPocs = [];
            _.each(pocs, function(poc) {
                var optionIndex = _.findWhere(options, {catId:poc.id}).id;
                var poIndex = poc.productOptions.findIndex( function(po){
                    return po.id === optionIndex;
                });
                poc.productOptions.splice(0, 0, poc.productOptions.splice(poIndex, 1)[0] );
                var newPoc = JSON.parse(JSON.stringify(poc));
                newPocs.push(newPoc);
            });
            return newPocs;
        }
    });
    module.exports = ConfigOptionsView;
});

