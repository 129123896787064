/**
 * Created by OLD on 20/11/2015.
 */
define('changeTypePopupView',[
    'module',
    'jquery',
    'underscore',
    'settings',
    'template!changeTypePopupTpl',
    'dialogFormView',
    'dropdownCompositeView'
], function(
    module,
    $,
    _,
    Settings,
    viewTpl,
    DialogFormView,
    DropdownCompositeView
) {
    'use strict';

    var OrderStatusPopupView = DialogFormView.extend({
        template: viewTpl,

        regions: {
            dropDownStatus: '.order-type-dropdown-region'
        },

        onRender: function() {
            this.dropDown = new DropdownCompositeView({
                modelProperty: 'name',
                modelDisplayProperty: 'name',
                collection: this.options.types,
                modelSelectValue: this.options.currentType
            });
            this.getRegion('dropDownStatus').show(this.dropDown);
        }
    });

    module.exports = OrderStatusPopupView;
});
