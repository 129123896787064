/**
 * Created by RKL on 07/09/2015.
 */
define('customersShowView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'jquery',
  'settings',
  'imageUtils',
  'template!customersViewTpl',
  'customersTabs',
  'rolesMixin',
  'backToListBehavior',
  'customersCopyPasteMix',
  'tasksCreatorBehavior',
  'plivoUtils'
], function (
  module,
  Marionette,
  _,
  Backbone,
  App,
  $,
  Settings,
  imageUtils,
  viewTpl,
  customersTabs,
  RolesMixin,
  BackToListBehavior,
  CopyPasteMix,
  TasksCreatorBehavior,
  PlivoUtils
) {
  'use strict';

  var CustomersDetailsView =  Marionette.LayoutView.extend({

    template: viewTpl,

    ui: {
      tab: '.customer-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder',
      name: '.p-name',
      code: '.p-code',
      phone: '.p-phone',
      email: '.p-email',
      collectionToggle: '.js-collection-toggle',
      compuLogoToggle: '.js-compuLogo-toggle',
      vatValid: '.p-vat-valid',
      linkPhoneButton: '.js-link-phone-to-customer',
      icoNotifyMe: '.ico-notify-me'
    },

    events: {
      'click @ui.tab': 'onTabClick',
      'click @ui.linkPhoneButton': 'onLinkPhoneButton',
      'click @ui.phone': 'onPhoneNumberClick',
      'click @ui.icoNotifyMe': 'onNotifyMeClick'
    },

    regions: {
      tabContent: '#customer-tab-content',
      customerActions: '.js-action-buttons-region'
    },

    behaviors: function () {
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: 'customers'
        },
        CreateTask: {
          behaviorClass: TasksCreatorBehavior,
          refEndUser: this.options.model
        }
      };
    },

    initialize: function () {
      _.extend(this, CopyPasteMix);
    },

    onPhoneNumberClick: function (e) {
      var target = $(e.currentTarget);
      PlivoUtils.callNumber(target.text());
    },

    serializeData: function () {
      var templateData = this.model.toJSON(),
        cAccountRoles = Settings.get('cAccountRoles');
      templateData.currentPlivoCall = Settings.getFromMemory('plivoCurrentCallNumber');
      templateData.isNew = this.options.data.id === 'new';
      if (this.options.data.id === 'new') {
        templateData.customersTabs = [];
        var profileTab = _.findWhere(customersTabs, {role: RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE});
        templateData.customersTabs.push(profileTab);
      } else if (cAccountRoles) {
        templateData.customersTabs = [];
        _.each(customersTabs, function (tab) {
          if (cAccountRoles[tab.role] === true) {
            templateData.customersTabs.push(tab);
          }
        });
      } else {
        templateData.customersTabs = customersTabs;
      }
      templateData.isCollection = this.model.get('userType') === 'collection';
      templateData.compulogoCollection = this.model.get('compulogoCollection');
      templateData.webshopUrl = null;
      if (Settings.get('webshopUrl')) {
        var locale;
        if (templateData.locales && (templateData.locales).length >= 2) {
          locale = (templateData.locales).substring(0,2).toLowerCase();
        } else {
          locale = 'fr';
        }
        templateData.webshopUrl = Settings.get('webshopUrl') + '/' + locale +
          '/' + 'account' + '/' + templateData.secId;
      }
      templateData.design2 = Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2];
      templateData.customerNumber = this.model.getCustomerNumber();
      return templateData;
    },

    onRender: function() {
      this._setLogo(this.model.get('logoUid'), this.model.get('code'));

      this.$el.find('.area-compuLogo').toggleClass('sr-only', this.model.get('userType') !== 'collection');
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.customer-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function () {
      this.prepareCopyPasteActionView('fromCategory');
      this.refreshNoficationFlag();
      $.material.init();
    },

    onNotifyMeClick: function () {
      $.when(App.request('customer:notify-me', this.model.get('secId'), !this.userNofified).done(_.bind(function () {
        this.refreshNoficationFlag();
      }, this)));
    },

    refreshNoficationFlag: function () {
      $.when(App.request('customer:is-user-notified', this.model.get('secId')).done(_.bind(function (res) {
        this.userNofified = res > 0;
        if (this.userNofified) {
          this.ui.icoNotifyMe.removeClass('mdi-social-notifications-off');
          this.ui.icoNotifyMe.addClass('mdi-social-notifications');
        } else {
          this.ui.icoNotifyMe.removeClass('mdi-social-notifications');
          this.ui.icoNotifyMe.addClass('mdi-social-notifications-off');
        }
      }, this)));
    },

    onTabClick: function (e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };

      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
      this.checkIfCopyPasteAvailabe();
    },

    onCollectionToggle: function(e) {
      var $target = $(e.currentTarget);
      var compuLogo = this.$el.find('.area-compuLogo');
      compuLogo.toggleClass('sr-only', !$target.prop('checked'));
      this.model.set('compulogoCollection',compuLogo.prop('checked'));
      this.model.setAsCollection($target.prop('checked'), compuLogo.prop('checked'));

    },

    onCompuLogoToggle: function(e) {
      var $target = $(e.currentTarget);
      this.model.set('compulogoCollection',($target.prop('checked')));
      this.model.setAsCollection(true, $target.prop('checked'));
    },

    onHeaderChange: function(customer) {
      this.ui.name.text(customer.name);
      this.ui.phone.text(customer.tel ? customer.tel : '--');
      this.ui.email.text(customer.email ? customer.email : '--');
      this._setLogo(customer.logoUid, customer.code);
      if (customer.subjectToVat && customer.vatNumberChecked) {
        this.ui.vatValid.hide();
      } else {
        this.ui.vatValid.show();
      }
    },

    _setLogo: function(logoUid, code) {
      var logo = imageUtils.getLogo(logoUid, code);
      if(logo) {
        this.ui.logo.css('background-image', 'url(' + logo + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }
    },

    onLinkPhoneButton: function(e) {
      // link phone number to current customer
      console.log($(e.target).attr('data-phoneNumber'));
      var that = this,tel = $(e.target).attr('data-phoneNumber'), endUserSecId = this.model.get('secId');
      $.when(App.request('customer:update-phone', endUserSecId,tel)).done(function (resp) {
        if (resp.status === 200) {
          App.trigger('customers:show', that.model.get('secId'));
          $('.js-link-phone-to-customer').hide();
          Settings.setToMemory('plivoCurrentCallNumber', null);
        } else if (resp.status === 201) {
          var contact = JSON.parse(resp.responseText);
          if (contact.refAddress.tel === tel) {
            $('.js-link-phone-to-customer').hide();
            Settings.setToMemory('plivoCurrentCallNumber', null);
            App.navigate('customers/'+endUserSecId+'/contacts/'+contact.secId+'/details/info', {trigger:true});
          }
        }
      });
    }

  });

  module.exports = CustomersDetailsView;
});
