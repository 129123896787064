/**
 * Created by RKL on 13/08/2015.
 */
define('caccountShareTabController',[
  'module',
  'underscore',
  'app',
  'jquery',
  'bootbox',
  'caccountShareLayoutView',
  'caccountShareView',
  'caccountAddShareView',
  'entities/caccounts/share',
  'settings'
], function (
  module,
  _,
  App,
  $,
  bootbox,
  LayoutView,
  ShareView,
  PopupView
) {
  'use strict';

  var CAccountShareTabController = {
    showTab: function(data) {
      var region, layoutView, shareFromView, shareToView;

      region = 'tabContent';

        layoutView = new LayoutView();
        shareFromView = new ShareView({
          share: 'from',
          data: data
        });
        shareToView = new ShareView({
          share: 'to',
          data: data
        });

        this.layoutView = layoutView;
        this.views = {
          from: shareFromView,
          to: shareToView
        };
        this.data = data;
        this.sharesLists = {};

        this.subscribeToEvents();

        App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region).show(layoutView);
        this.layoutView.getRegion('accountShareFrom').show(shareFromView);
        this.layoutView.getRegion('accountShareTo').show(shareToView);
    },

    onGridLoaded: function() {
      this.fetchShare('from');
      this.fetchShare('to');
    },

    fetchShare: function (share) {
      var that = this,
        params = {
          cAccountId: this.data.cAccountId,
          share: share
        },
        request, data;

      request = App.request('caccounts:get-share', params);

      $.when(request).done(function (list) {
        that.sharesLists[share] = list;
        data = {
            url: null,
            rowCountUrl: null
          };

        that.views[share].displayGrid(list, data);
      });
    },

    onAddShare: function (share) {
      this.popupView = new PopupView({
        share: share,
        callback: _.bind(this.onAddNew, this)
      });

      this.popupView.show({title: _.i18n('popup.share.add')});
      $.material.init();
    },

    onAddNew: function (model, share) {
      var that = this,
        data = {},
        request;

      if (model.secId === this.data.cAccountId) {
        this.onError(_.i18n('warning.share.exist'), share);
        return;
      }

      if (share === 'from') {
        data.caccountFrom = model;
        data.caccountTo = this.data.model.toJSON();
      } else {
        data.caccountFrom = this.data.model.toJSON();
        data.caccountTo = model;
      }
      data.creditPayedBy = null;

      request = App.request('caccounts:post-share', data);
      $.when(request).done(function () {
        that.sharesLists[share].push(data);
        that.views[share].fetchGridData(that.sharesLists[share]);

      }).fail(function (error) {
        if (error.status === 404) {
          that.onError(_.i18n('warning.share.exist'), share);
        } else {
          that.onError(_.i18n('warning.systemError'), share);
        }
      });
    },

    onError: function (message, share) {
      this.views[share].triggerMethod('errorMessage', message);
    },

    onDeleteShare: function(shareId, share) {
      var that = this,
        type, otherType, index,
        deleteRequest, data;

      bootbox.confirm(_.i18n('common.confirmDelete'), function (result) {
        if (result) {
          if (share === 'from') {
            type = 'caccountFrom';
            otherType = 'caccountTo';
          } else {
            type = 'caccountTo';
            otherType = 'caccountFrom';
          }

          data = _.filter(that.sharesLists[share], function (obj, i) {
            if (obj[type].secId === shareId) {
              index = i;
              return true;
            }
          });
          data[0][otherType] = that.data.model.toJSON();

          deleteRequest = App.request('caccounts:delete-share', data[0]);
          $.when(deleteRequest).done(function() {
            that.sharesLists[share].splice(index, 1);
            that.views[share].fetchGridData(that.sharesLists[share]);
          }).fail(function () {
            that.onError(_.i18n('warning.systemError'), share);
          });
        }
      });
    },

    subscribeToEvents: function () {
      var that = this;

      this.views.from.listenTo(
        this.views.from,
        'onGridLoaded',
        _.bind(this.onGridLoaded, this)
      );

      _.each(this.views, function (view) {
        view.listenTo(view, 'onAddShare', _.bind(that.onAddShare, that));
        view.listenTo(view, 'onDeleteShare', _.bind(that.onDeleteShare, that));
      });
    }
  };

  module.exports = CAccountShareTabController;
});
