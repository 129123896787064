
define('template!internalMessageTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n  <div class="command-tickets-content col-xs-12">\n    <div class="messages-container">\n    </div>\n  </div>\n  <div class="loading-block js-loading-block loader">\n  </div>\n  <div class="js-footer footer-vbox">\n    <!-- Footer content -->\n    <div class="message-form">\n      <div class="row">\n        <div class="attachments-addon hidden">\n          <ul class="attachments-addon-list list-inline"></ul>\n        </div>\n      </div>\n      <div class="clearfix">\n        <div class="col-xs-12 col-lg-8">\n          <div class=" row">\n            <div class="col-xs-2 col-lg-1">\n              <div class="row">\n                <label for="browseAttachFile" class="control-label attach-btn">\n                  <a class="btn btn-fab btn-fab-mini btn-raised btn-sm btn-primary send-new-m-b"><i class="mdi-editor-attach-file"></i></a>\n                </label>\n                <input id="browseAttachFile" multiple type="file" name="filename" accept="*" class="hidden attach-input"/>\n              </div>\n            </div>\n            <div class="col-xs-8 col-lg-8">\n              <textarea class="create-new-message" rows="2" id="textArea" placeholder="' +
((__t = ( _.i18n("common.type.message") )) == null ? '' : __t) +
'">' +
((__t = ( tempMessage )) == null ? '' : __t) +
'</textarea>\n            </div>\n            <div class="col-xs-2 col-lg-1">\n              <div class="row">\n                <a class="btn btn-fab btn-fab-mini btn-raised btn-sm action-btn send-new-m-b js-send-new-m-b">\n                  <i class="mdi-content-send"></i>\n                </a>\n              </div>\n            </div>\n            ';
 if (!csMsg) { ;
__p += '\n            <div class="col-xs-2 col-lg-2" style="margin-top: 10px;margin-left: -25px;">\n              <div class="row">\n                <input type="checkbox" id="actionRequired" class="js-action-required" ' +
((__t = ( actionRequired ? 'checked' : '' )) == null ? '' : __t) +
'>\n                <label for="actionRequired"> ' +
((__t = ( _.i18n('orders.actionRequired') )) == null ? '' : __t) +
'</label>\n                </div>\n              </div>\n            </div>\n          ';
 } else { ;
__p += '\n          <div class="col-xs-2 col-lg-2" style="margin-top: 10px;margin-left: -25px;">\n            <div class="row">\n              <input type="checkbox" id="actionRequiredInternal" class="js-action-required-internal" ' +
((__t = ( actionRequiredInternal ? 'checked' : '' )) == null ? '' : __t) +
'>\n              <label for="actionRequiredInternal"> ' +
((__t = ( _.i18n('orders.actionRequired') )) == null ? '' : __t) +
'</label>\n            </div>\n          </div>\n          <div class="col-xs-2 col-lg-2" style="margin-top: 10px;margin-left: -25px;">\n            <div class="row">\n              <input type="checkbox" id="actionRequiredUkr" class="js-action-required-ukr" ' +
((__t = ( actionRequiredUkr ? 'checked' : '' )) == null ? '' : __t) +
'>\n              <label for="actionRequiredUkr"> ' +
((__t = ( _.i18n('orders.actionRequiredUkr') )) == null ? '' : __t) +
'</label>\n            </div>\n          </div>\n        </div>\n          ';
 } ;
__p += '\n            </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};});

