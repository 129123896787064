/**
 * Created by RKL on 13/08/2015.
 */
define('customerOrdersTabController',[
  'module',
  'underscore',
  'marionette',
  'app',
  'ordersShowController',
  'entities/orders'
], function(
  module,
  _,
  Marionnette,
  App,
  OrderViewController
) {
  'use strict';

  var CustomersOrdersTabController =  _.extend(OrderViewController.getInstance(), {
    showTab: function (data, ViewInstance) {
      OrderViewController.showOrders.call(this, data, ViewInstance,true);
    },

    showRegion: function() {
      this.view.clearFilter();
      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(this.view);
    },

    initializeGrid: function(gridStatuses) {
        var data = {
          url: App.request(
            'order:get-collection-url',
            this.data.model.get('endUserId')
          ),
          rowCountUrl: App.request(
            'order:get-count-url',
            this.data.model.get('endUserId')
          ),
          statuses: gridStatuses
        };

        this.view.displayGrid(data,true);
    }

  });

  module.exports = CustomersOrdersTabController ;
});
