
define('template!editPrintAreaTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="printarea" style="position: relative;">\n  <div class="row">\n    <div class="col-xs-3">\n      <div class="pa">\n        <div class="pa-code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n        <div class="row">\n          <div class="col-xs-12">\n            <div class="pa-info">\n              <div class="pa-name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n              <div class="pa-size">' +
((__t = ( width + 'x' + height + 'mm' )) == null ? '' : __t) +
'</div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class="col-xs-9">\n      <div class="pz">\n        <div class="pz-title">\n          Print zones\n          <i class="mdi mdi-av-my-library-add btn-add-printzone clickable p-l-10"\n             style="font-size: 18px;"></i>\n        </div>\n        <div class="row pz-header">\n          <div class="col-xs-1 imppCode">Imprint.</div>\n          <div class="col-xs-4 name">Name</div>\n          <div class="col-xs-2 size">Size</div>\n          <div class="col-xs-2 setupCode">Setup</div>\n          <div class="col-xs-1 unitCode">Code</div>\n        </div>\n        <div class="pz-region"></div>\n      </div>\n    </div>\n  </div>\n  ';
 if (!readOnly) { ;
__p += '\n  <div class="btn-container btn-pa-container" style="display: none;">\n    <i class="mdi mdi-image-color-lens btn-edit-xml btn-frame"></i>\n    <i class="mdi mdi-action-delete btn-delete-pa btn-frame"></i>\n    <i class="mdi mdi-editor-mode-edit btn-edit-pa btn-frame"></i>\n  </div>\n  ';
 } ;
__p += '\n</div>';

}
return __p
};});

