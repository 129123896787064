/**
 * Created by BFR on 24/02/2020.
 */
define('editPimOptionsView',['module',
        'marionette',
        'editPimOptionView'],
  function(module,
           Marionette,
           EditPimOptionView){
  'use strict';
  

  var EditPimOptionsView = Marionette.CollectionView.extend({
    childView: EditPimOptionView,
    childViewOptions: function() {     
      return {
        pimProduct: this.options.pimProduct
      };
    }
  });

  module.exports = EditPimOptionsView;
});
