define( 'entities/customers/customers_comments',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

var CustomerComment = Backbone.Model.extend({
  defaults: {
    secId : null,
    comment : null,
    refEndUserSecId: null,
    modDate:null,
    refUserpid: {}
  },
  idAttribute: 'secId',
  save: function(endUserSecId) {
    this.url = Settings.url('compuzz2', 'endusercomments.json/'+endUserSecId);
    return Backbone.Model.prototype.save.call(this);
  },
  destroy: function() {
    this.url =  Settings.url('compuzz2', 'endusercomments.json/' + this.get('secId'));
    return Backbone.Model.prototype.destroy.call(this);
  }
});

var CustomerComments = Backbone.Collection.extend({
    model: CustomerComment,
  comparator: function(model) {
    return -Date.parse(model.get('modDate'));
  },
    fetch: function(endUserSecId) {
     this.url = Settings.url('compuzz2', 'endusercomments.json/' + endUserSecId );
     return Backbone.Collection.prototype.fetch.call(this);
   }
    });


  /**
   * REST API
   */
  var API = {
    getCustomerComments: function(endUserSecId) {

      var customerComments = new CustomerComments({}),
        defer = $.Deferred();

      customerComments.url = Settings.url( 'compuzz2', 'endusercomments.json', {endUserSecId: endUserSecId});
      customerComments.fetch( {
        success: function(data) {
          defer.resolve(data);
        }
      } );

      return defer.promise();
    },
    getCollection: function() {
      return new CustomerComments();
    },
    getModel: function() {
      return new CustomerComment();
    }
  };

  App.reqres.setHandler('customercomments:get', function (endUserSecId) {
    return API.getCustomerComments(endUserSecId);
  });

  App.reqres.setHandler('customercomments:get-collection', function () {
    return API.getCollection();
  });
  App.reqres.setHandler('customercomments:get-model', function () {
    return API.getModel();
  });
});

