
define('template!caccountAdminsAddTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content">\n  <div class="my-vector-popup-container">\n    <form class="form-horizontal">\n      <fieldset>\n       <!-- First name  -->\n        <div class="form-group" data-field-name="firstName">\n          <div class="col-xs-12">\n            <input type="text" class="form-control floating-label js-popup-input" data-field-name="firstName" placeholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( firstName )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <!-- Last name  -->\n        <div class="form-group" data-field-name="lastName">\n          <div class="col-xs-12">\n            <input type="text" class="form-control floating-label js-popup-input" data-field-name="lastName" placeholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( lastName )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <!-- Email  -->\n        <div class="form-group" data-field-name="email">\n          <div class="col-xs-12">\n            <input type="email" class="form-control floating-label js-popup-input" data-field-name="email" placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'" value="' +
((__t = ( email )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        <!-- ExternalId  -->\n        <div class="form-group" data-field-name="externalId">\n          <div class="col-xs-12">\n              <input type="text" class="form-control floating-label js-popup-input" data-field-name="externalId" placeholder="' +
((__t = ( _.i18n('common.externalId') )) == null ? '' : __t) +
'" value="' +
((__t = ( externalId )) == null ? '' : __t) +
'" maxlength="10">\n          </div>\n        </div>\n        ';
 if (design2) { ;
__p += '\n            <div class="form-group" data-field-name="dtpLevel">\n              <div class="col-xs-12">\n                <span style="font-size: 11px;">' +
((__t = ( _.i18n('common.dtpLevel') )) == null ? '' : __t) +
'</span>\n              </div>\n              <div class="col-xs-12">\n                  <select class="form-control js-info-input js-popup-input" data-field-name="dtpLevel" value="' +
((__t = ( dtpLevel )) == null ? '' : __t) +
'"\n                          placeholder="' +
((__t = ( _.i18n('common.dtpLevel') )) == null ? '' : __t) +
'">\n                    <option value="">None</option>\n                      <option value="1" ';
 if (1 === dtpLevel) { ;
__p += ' selected ';
 } ;
__p += ' >1 Low</option>\n                      <option value="2" ';
 if (2 === dtpLevel) { ;
__p += ' selected ';
 } ;
__p += ' >2 Medium</option>\n                      <option value="3" ';
 if (3 === dtpLevel) { ;
__p += ' selected ';
 } ;
__p += ' >3 Hard</option>\n                  </select>\n              </div>\n            </div>\n        ';
 } ;
__p += '\n        ';
 if (userTypes && userTypes.length > 0) { ;
__p += '\n        <!-- Type  -->\n        <div class="form-group" data-field-name="type">\n          <div class="col-xs-12">\n            <span style="font-size: 11px;">' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'</span>\n          </div>\n          <div class="col-xs-12">\n            <select class="form-control js-info-input js-popup-input" data-field-name="type" value="' +
((__t = ( type )) == null ? '' : __t) +
'"\n                    placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'">\n              ';
 _.each(userTypes, function(userType) { ;
__p += '\n              <option value="' +
((__t = ( userType.typeCode )) == null ? '' : __t) +
'"\n              ';
 if (type === userType.typeCode) { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( userType.typeCode )) == null ? '' : __t) +
'</option>\n              ';
 }); ;
__p += '\n            </select>\n          </div>\n        </div>\n        ';
 } ;
__p += '\n        ';
 if (tasks) { ;
__p += '\n        <div class="form-group" data-field-name="type">\n          <div class="col-xs-12">\n            <input type="text" class="form-control floating-label js-popup-input" data-field-name="type"\n                   placeholder="' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'" value="' +
((__t = ( type )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        ';
 } ;
__p += '\n      </fieldset>\n    </form>\n  </div>\n</div>';

}
return __p
};});

