
define('template!leadProfileTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="well customer-profile">\n  <h1>' +
((__t = ( _.i18n('customerDetail.profile.title') )) == null ? '' : __t) +
'</h1>\n  <hr class="well-separator"/>\n  <div class="detailed-info-form-container">\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.endUserId') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( endUserId ? endUserId : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( email ? email : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('endUsersDetail.language') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( locales ? locales : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.webSite') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( webSite ? webSite : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.vatNumber') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( vatNumber ? vatNumber : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( tel ? tel : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.siret') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( siret ? siret : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('endUsersDetail.endUserComments') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( comment ? comment : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('endUsersDetail.login') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( login ? login : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('endUsersDetail.mainActivity') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( mainActivity ? mainActivity : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('endUsersDetail.companyNumber') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( companyNumber ? companyNumber : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('endUsersDetail.subActivity') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( subActivity ? subActivity : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('endUsersDetail.nbrEmployees') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( nbrEmployees ? nbrEmployees : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('endUser.firstContactPhone') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( firstContactPhone ? firstContactPhone : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="col-md-6">\n        <div class="col-xs-4"></div>\n        <div class="col-xs-8"></div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('endUser.firstContactMail') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( firstContactMail ? firstContactMail : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="col-md-6">\n        <div class="col-xs-4"></div>\n        <div class="col-xs-8"></div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('endUser.firstContactVoiceMail') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( firstContactVoiceMail ? firstContactVoiceMail : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n      <div class="col-md-6">\n        <div class="col-xs-4"></div>\n        <div class="col-xs-8"></div>\n      </div>\n    </div>\n    <div class="row m-t-20 m-b-10">\n      <div class="col-md-6">\n        <div class="col-xs-12 text-center">' +
((__t = ( _.i18n('endUsersDetail.invoiceAddress') )) == null ? '' : __t) +
'<span>\n            <i class="mdi-content-content-copy large-regular-compuzz clickable js-copy-invoice-address"\n               style="font-size:20px;"\n               title="' +
((__t = ( _.i18n('common.copy'))) == null ? '' : __t) +
'"></i>\n        </span></div>\n      </div>\n      <div class="col-md-6">\n        <div class="col-xs-12 text-center"><span\n                class="m-b-10">' +
((__t = ( _.i18n('endUsersDetail.refDeliveryAddress') )) == null ? '' : __t) +
'</span><span>\n            <i class="mdi-content-content-copy large-regular-compuzz clickable js-copy-delivery-address"\n               style="font-size:20px;"\n               title="' +
((__t = ( _.i18n('common.copy'))) == null ? '' : __t) +
'"></i>\n        </span></div>\n      </div>\n    </div>\n    <div class="row">\n        <div class="col-md-6">\n            <div class="col-xs-4">' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-8">' +
((__t = ( name ? name : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.companyName') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( refDeliveryAddress.name ? refDeliveryAddress.name : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n        <div class="col-md-6">\n            <div class="col-xs-4">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-8">' +
((__t = ( firstName ? firstName : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( refDeliveryAddress.firstName ? refDeliveryAddress.firstName : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n        <div class="col-md-6">\n            <div class="col-xs-4">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-8">' +
((__t = ( lastName ? lastName : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( refDeliveryAddress.lastName ? refDeliveryAddress.lastName : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n\n    <div class="row">\n        <div class="col-md-6">\n            <div class="col-xs-4">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-8">' +
((__t = ( addressL1 ? addressL1 : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( refDeliveryAddress.addressL1 ? refDeliveryAddress.addressL1 : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n        <div class="col-md-6">\n            <div class="col-xs-4">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-8">' +
((__t = ( addressL2 ? addressL2 : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( refDeliveryAddress.addressL2 ? refDeliveryAddress.addressL2 : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n        <div class="col-md-6">\n\n        </div>\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.houseNumber') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( refDeliveryAddress.houseNumber ? refDeliveryAddress.houseNumber : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n        <div class="col-md-6">\n            <div class="col-xs-4">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-8">' +
((__t = ( zip ? zip : '-' )) == null ? '' : __t) +
' / ' +
((__t = ( city ? city : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( refDeliveryAddress.zip ? refDeliveryAddress.zip : '-' )) == null ? '' : __t) +
' / ' +
((__t = ( refDeliveryAddress.city ? refDeliveryAddress.city : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n        <div class="col-md-6">\n            <div class="col-xs-4">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-8">' +
((__t = ( country ? country : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( countryDelivery ? countryDelivery : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="pull-right p-r-15">\n        <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="well customer-profile siren-info">\n  <h1>' +
((__t = ( _.i18n('customerDetail.profile.sirenInfo') )) == null ? '' : __t) +
'</h1>\n  <hr class="well-separator"/>\n  <pre class="detailed-info-form-container region-siren-info">\n  </pre>\n</div>';

}
return __p
};});

