/**
 * Created by OLD on 28/05/2019.
 */
define('offersKanbanFiltersView',[
  'module',
  'underscore',
  'backbone',
  'marionette',
  'app',
  'settings',
  'datePickerPopoverMixin',
  'template!offersKanbanFiltersTpl'
], function (
  module,
  _,
  Backbone,
  Marionette,
  App,
  Settings,
  datePickerPopoverMixin,
  viewTpl
) {
    'use strict';

    var OffersKanbanFiltersView = Marionette.LayoutView.extend({
      template: viewTpl,
      filtersName: 'offersRequestKanbanFilters',
      filters: {},

      ui: {
        select: 'select',
        inputText: 'input[type=text]',
        inputDate: '.js-date-field',
        blinder: '.filters-blinder',
        clear: '.ui-search-input a'
      },

      events: {
        'change @ui.select': 'onChangeFilterSelect',
        'change @ui.inputText': 'onChangeFilterInput',
        'click @ui.clear': 'onClearFilter'
      },

      initialize: function () {
        this.filters = _.extend(this.filters, Settings.getFromMemory(this.filtersName));
      },

      serializeData: function () {
        return {
          filters: this.filters
        };
      },

      onRender: function () {
        datePickerPopoverMixin.kanbanBoardDataInit(
          this.ui.inputDate,
          _.bind(this.onChangeFilterDate, this)
        );
      },

      onShow: function () {
        $.material.init();
      },

      onChangeFilterDate: function ($element) {
        var value = $element.val();

        if ($element.attr('name') === 'date' && value) {
          this.filters.date = value;
          this.filters.dateFrom = datePickerPopoverMixin.parseDateFrom(value);
          this.filters.dateTo = datePickerPopoverMixin.parseDateTo(value);
        }
        this._updateBoard();
      },

      onChangeFilterSelect: function (e) {
        var key = $(e.currentTarget).attr('name'),
          value = $(e.target).val() === 'all' || $(e.target).val() === '0' ? '' : $(e.target).val();
        if (value.toLowerCase() === 'no') {
          value = 'false';
        } else if (value.toLowerCase() === 'yes') {
          value = 'true';
        }
        this.filters[key] = value;
        this._updateBoard();
      },

      onChangeFilterInput: function (e) {
        var key = $(e.currentTarget).attr('name'),
          value = $(e.currentTarget).val();

        this.filters[key] = value;
        this._updateBoard();
      },

      _updateBoard: function () {
        Settings.setToMemory(this.filtersName, this.filters);
        this.filters = _.extend(this.filters, Settings.getFromMemory(this.filtersName));
        this.trigger('offers_kanban:filter:update', this.filters);
        this.ui.blinder.show();
      },

      onClearFilter: function (e) {
        e.preventDefault();
        var key = $(e.currentTarget).attr('name');

        if (!$(e.currentTarget).parent().find('[name=' + key + ']').val()) {
          return;
        }

        $(e.currentTarget).parent().find('[name=' + key + ']').val('');
        if (key === 'date') {
          this.filters.dateFrom = '';
          this.filters.dateTo = '';
          this.ui.inputDate.trigger('clear');
        }
        this.filters[key] = '';
        this._updateBoard();
      }
    });

    module.exports = OffersKanbanFiltersView;
  });
