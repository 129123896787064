/**
 * Created by Matteo on 11/07/2015.
 */
define('customerCommentsSidebarShowController',[
  'module',
  'underscore',
  'jquery',
  'app',
  'customerCommentsSidebarShowView',
  'settings',
  'rolesMixin',
  'entities/customers/customers_comments'
], function (
  module,
  _,
  $,
  App,
  View,
  Settings,
  RolesMixin
) {
  'use strict';
  var CustomerCommentsSidebarController = {

    showCustomerCommentsSidebar: function() {
      if (!Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        var view = new View();
        App.off('customer:comments');
        App.on('customer:comments',_.bind(this.onGetCustomer, this));
        view.listenTo(view, 'sidebar:resize', this.onResizeSidebar);
        view.listenTo(view, 'customer:comments', this.onResizeSidebar);
        $(window).on('resize', _.bind(this.updateViewTextAreas,this));
        this.view = view;
        this.view.collection = App.request('customercomments:get-collection');
        App.regions.getRegion('main').currentView.getRegion('customerCommentsSidebar').show(view);
      }
    },

    onResizeSidebar: function(obj) {
      obj.view.onResize();
      App.trigger('customerCommentsSidebar:resize');
    },

    onGetCustomer: function (secId) {
      var that = this;
      $.when(this.view.collection.fetch(secId)).done(function () {
        that.view.endUserSecId = secId;
        that.view.render();
      });
    },
    updateViewTextAreas: function () {
      this.view.onRender();
    }
  };

  module.exports = CustomerCommentsSidebarController;
});

