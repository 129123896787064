
define('template!caccountPriceFactorsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row col-lg-12">\n  <div class="col-xs-12 sa-list">\n    <div class="col-xs-12 col-md-7 col-lg-5 sa-item">\n      <div class="sa">\n        <div class="sa-code" style="margin-left: -15px;">FactorV2</div>\n      </div>\n      <div class="row">\n        <label class="col-xs-9">30% margin buying price value</label>\n        <input class="col-xs-2 txt-param txt-param1 p-l-0 p-r-0" value="' +
((__t = ( factors.param1 )) == null ? '' : __t) +
'"/>\n      </div>\n      <div class="row">\n        <label class="col-xs-9">Minimum buying price order value</label>\n        <input class="col-xs-2 txt-param txt-param2 p-l-0 p-r-0" value="' +
((__t = ( factors.param2 )) == null ? '' : __t) +
'"/>\n      </div>\n      <div class="row">\n        <label class="col-xs-9">Maximum buying price staggers</label>\n        <input class="col-xs-2 txt-param txt-param3 p-l-0 p-r-0" value="' +
((__t = ( factors.param3 )) == null ? '' : __t) +
'"/>\n      </div>\n      </br>\n      <div class="col-xs-12">FactorV2 per supplier</div>\n      <div class="col-xs-12">\n        <select class="ddl-suppliers-factorv2">\n          ';
 _.each(suppliers, function(sup) { ;
__p += '\n          <option value="' +
((__t = ( sup.secId )) == null ? '' : __t) +
'">' +
((__t = ( sup.code )) == null ? '' : __t) +
'</option>\n          ';
 }); ;
__p += '\n        </select>\n      </div>\n      <div class="btn btn-add-factorsV2">\n        <i class="mdi-content-add"></i>\n        <span>' +
((__t = ( _.i18n('factors.add') )) == null ? '' : __t) +
'</span>\n      </div>\n      <div class="col-xs-12 supplier-factorsv2-region">\n      </div>\n    </div>\n    <div class="col-xs-12 col-md-5 col-lg-3 sa-item">\n      <div class="sa">\n        <div class="sa-code" style="margin-left: -15px;">Factor</div>\n      </div>\n      <div class="col-xs-12">\n        <select class="ddl-suppliers">\n          ';
 _.each(suppliers, function(sup) { ;
__p += '\n          <option value="' +
((__t = ( sup.secId )) == null ? '' : __t) +
'">' +
((__t = ( sup.code )) == null ? '' : __t) +
'</option>\n          ';
 }); ;
__p += '\n        </select>\n      </div>\n      <div class="col-xs-12 supplier-factors-region"></div>\n      <div class="col-xs-12">\n        <div class="btn new-cAccount-button btn-add-factor">\n          <i class="mdi-content-add"></i>\n          <span>' +
((__t = ( _.i18n('factors.add') )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="btn new-cAccount-button btn-delete-factor">\n          <i class="mdi-content-delete"></i>\n          <span>' +
((__t = ( _.i18n('factors.delete') )) == null ? '' : __t) +
'</span>\n        </div>\n      </div>\n    </div>\n    <div class="col-xs-12 col-md-6 col-lg-4 sa-item">\n      <div class="sa">\n        <div class="sa-code" style="margin-left: -15px;">Factor Imprint</div>\n      </div>\n      <div class="col-xs-12 p-l-0 p-r-0">\n        <select class="ddl-suppliers-imprint">\n          ';
 _.each(suppliers, function(sup) { ;
__p += '\n          <option value="' +
((__t = ( sup.secId )) == null ? '' : __t) +
'">' +
((__t = ( sup.code )) == null ? '' : __t) +
'</option>\n          ';
 }); ;
__p += '\n        </select>\n      </div>\n      <div class="col-xs-12  p-l-0 p-r-0 p-t-5">\n        <div class="print-codes-region" style="display: contents;"></div>\n      </div>\n      <div class="col-xs-12  p-l-0 p-r-0 supplier-print-factors-region"></div>\n    </div>\n    <div class="col-xs-12 col-md-6 col-lg-4 sa-item">\n      <div class="sa">\n        <div class="sa-code" style="margin-left: -15px;">Transport Cost</div>\n      </div>\n      <div class="col-xs-12 p-l-0 p-r-0">\n        <select class="ddl-suppliers-transport">\n          ';
 _.each(suppliers, function(sup) { ;
__p += '\n          <option value="' +
((__t = ( sup.secId )) == null ? '' : __t) +
'">' +
((__t = ( sup.code )) == null ? '' : __t) +
'</option>\n          ';
 }); ;
__p += 'a\n        </select>\n      </div>\n      <div class="col-xs-12  p-l-0 p-r-0 p-t-5">\n        <div class="transport-region" style="display: contents;"></div>\n      </div>\n      <div class="col-xs-12  p-l-0 p-r-0 supplier-print-factors-region"></div>\n    </div>\n  </div>\n</div>\n';

}
return __p
};});

