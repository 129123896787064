
define('template!ordersKanbanFiltersTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-jqgrid orders-kanban-filters">\n  <div class="filters-blinder hide"></div>\n  <div class="ui-jqgrid-hbox p-r-0">\n    <table class="ui-jqgrid-htable ui-common-table">\n      <thead>\n        <tr class="ui-jqgrid-labels" role="row">\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span></span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('orderList.personalized') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('orderList.payedByCustomer') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('orderList.payedToManufacturer') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('orderList.orderNumber') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('orderList.endUser') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('orderList.orderCreatDate') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('orderList.productOwner') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('orderList.productCode') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('orderList.productName') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span>' +
((__t = ( _.i18n('orderList.invoiceNumber') )) == null ? '' : __t) +
'</span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span><i class="mdi-action-markunread-mailbox" title="' +
((__t = ( _.i18n('orderList.actionRequired') )) == null ? '' : __t) +
'"/></span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span><i class="mdi-editor-mode-comment" title="' +
((__t = ( _.i18n('orderList.actionRequiredInternal') )) == null ? '' : __t) +
'"/></span>\n          </th>\n          <th role="columnheader" class="ui-th-column ui-th-ltr ui-state-default">\n            <span><i class="mdi-image-color-lens" title=" ' +
((__t = ( _.i18n('orderList.actionRequiredUkr') )) == null ? '' : __t) +
' "/></span>\n          </th>\n        </tr>\n        <tr class="ui-search-toolbar" role="row">\n          <th role="columnheader" class="ui-state-default ui-th-ltr">\n            <div class="p-l-10 m-t-5 quick-filters-mark">QUICK FILTERS:</div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_personalized">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>\n                    <td class="ui-search-input">\n                      <select role="select" name="personalized" id="gs_personalized" class="ui-widget-content ui-corner-all">\n                        <option ';
 if(filters && filters.personalized === ''){ ;
__p += 'selected';
};
__p += '\n                          role="option" value="">all</option>\n                        <option ';
 if(filters && filters.personalized === 'true'){;
__p += 'selected';
};
__p += '\n                          role="option" value="yes">Yes</option>\n                        <option ';
 if(filters && filters.personalized === 'false'){;
__p += 'selected';
};
__p += '\n                          role="option" value="no">No</option>\n                      </select>\n                    </td>\n                    <td class="ui-search-clear">\n                      <a name="personalized" title="Reset Search Value" class="clearsearchclass">x</a>\n                    </td>\n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_payedByCustomer">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>\n                    <td class="ui-search-input">\n                      <select role="select" name="payedByCustomer" id="gs_payedByCustomer"  class="ui-widget-content ui-corner-all">\n                        <option ';
 if(filters && filters.payedByCustomer === ''){ ;
__p += 'selected';
};
__p += '\n                          role="option" value="">all</option>\n                        <option ';
 if(filters && filters.payedByCustomer === 'true'){;
__p += 'selected';
};
__p += '\n                          role="option" value="yes">Yes</option>\n                        <option ';
 if(filters && filters.payedByCustomer === 'false'){;
__p += 'selected';
};
__p += '\n                          role="option" value="no">No</option>\n                      </select>\n                    </td>\n                    <td class="ui-search-clear">\n                      <a name="payedByCustomer" title="Reset Search Value" class="clearsearchclass">x</a>\n                    </td>\n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_payedToManufacturer">\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>\n                    <td class="ui-search-input">\n                      <select role="select" name="payedToManufacturer" id="gs_payedToManufacturer"  class="ui-widget-content ui-corner-all">\n                        <option ';
 if(filters && filters.payedToManufacturer === ''){ ;
__p += 'selected';
};
__p += '\n                          role="option" value="">all</option>\n                        <option ';
 if(filters && filters.payedToManufacturer === 'true'){;
__p += 'selected';
};
__p += '\n                          role="option" value="yes">Yes</option>\n                        <option ';
 if(filters && filters.payedToManufacturer === 'false'){;
__p += 'selected';
};
__p += '\n                          role="option" value="no">No</option>\n                      </select>\n                    </td>\n                    <td class="ui-search-clear">\n                      <a name="payedToManufacturer" title="Reset Search Value" class="clearsearchclass">x</a>\n                    </td>\n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_purchaseOrderId">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>\n                      <td class="ui-search-input">\n                        <input type="text" name="purchaseOrderId" id="gs_purchaseOrderId" role="textbox" class="ui-widget-content ui-corner-all"\n                        ';
 if (filters && filters.purchaseOrderId){;
__p += ' value="' +
((__t = ( filters.purchaseOrderId )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="purchaseOrderId" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_quantity">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>\n                      <td class="ui-search-input">\n                        <input type="text" name="quantity" id="gs_quantity" role="textbox" class="ui-widget-content ui-corner-all"\n                        ';
 if (filters && filters.quantity){;
__p += ' value="' +
((__t = ( filters.quantity )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="quantity" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_endUserId">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>\n                      <td class="ui-search-input">\n                        <input type="text" name="endUserId" id="gs_endUserId" role="textbox" class="ui-widget-content ui-corner-all"\n                        ';
 if (filters && filters.endUserId){;
__p += ' value="' +
((__t = ( filters.endUserId )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="endUserId" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_createDate">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>\n                      <td class="ui-search-input">\n                        <input type="period-picker-popover" name="createDate" id="gs_createDate" role="textbox" class="ui-widget-content ui-corner-all js-date-field"\n                        ';
 if (filters && filters.createDate){;
__p += ' value="' +
((__t = ( filters.createDate )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="createDate" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_productOwner">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>\n                        <td class="ui-search-input" style="display:flex">\n                            <div style="display:block;">\n                                <input type="text" name="productOwner" id="gs_productOwner" role="textbox" class="ui-widget-content ui-corner-all"\n                                       style="background-color: white; width:80%;border-bottom:1px solid lightgrey;"\n                                ';
 if (filters && filters.productOwner){;
__p += ' value="' +
((__t = ( filters.productOwner )) == null ? '' : __t) +
'" ';
};
__p += '>\n                                <a name="productOwner" title="Reset Search Value" class="clearsearchclass" style="padding-top: 20px;">\n                                    x\n                                </a>\n                                <input type="text" name="supplierNumber" id="gs_supplierNumber" role="textbox" class="ui-widget-content ui-corner-all"\n                                       placeholder="' +
((__t = (_.i18n('orders.supplierNumber'))) == null ? '' : __t) +
'" style="background-color: white;width:80%;"\n                                ';
 if (filters && filters.supplierNumber){;
__p += ' value="' +
((__t = ( filters.supplierNumber )) == null ? '' : __t) +
'" ';
};
__p += '>\n                                <a name="supplierNumber" title="Reset Search Value" class="clearsearchclass" style="padding-top: 20px;">\n                                    x\n                                </a>\n                            </div>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_productCode">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>\n                      <td class="ui-search-input">\n                        <input type="text" name="productCode" id="gs_productCode" role="textbox" class="ui-widget-content ui-corner-all"\n                        ';
 if (filters && filters.productCode){;
__p += ' value="' +
((__t = ( filters.productCode )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="productCode" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_productName">\n              <div>\n                <table class="ui-search-table" cellspacing="0">\n                  <tbody>\n                    <tr>\n                      <td class="ui-search-input">\n                        <input type="text" name="productName" id="gs_productName" role="textbox" class="ui-widget-content ui-corner-all"\n                        ';
 if (filters && filters.productName){;
__p += ' value="' +
((__t = ( filters.productName )) == null ? '' : __t) +
'" ';
};
__p += '>\n                        <a name="productName" title="Reset Search Value" class="clearsearchclass">\n                          x\n                        </a>\n                      </td>\n                    </tr>\n                  </tbody>\n                </table>\n              </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_invoiceNumber">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                <tr>\n                  <td class="ui-search-input">\n                    <input type="text" name="invoiceNumber" id="gs_invoiceNumber" role="textbox" class="ui-widget-content ui-corner-all" size = "8"\n                    ';
 if (filters && filters.invoiceNumber){;
__p += ' value="' +
((__t = ( filters.invoiceNumber )) == null ? '' : __t) +
'" ';
};
__p += '>\n                    <a name="invoiceNumber" title="Reset Search Value" class="clearsearchclass">\n                      x\n                    </a>\n                  </td>\n                </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_actionRequired">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                  <tr>\n                    <td class="ui-search-input">\n                      <div style="display:block;">\n                          <select role="select" name="actionRequired" id="gs_actionRequired"  class="ui-widget-content ui-corner-all"\n                                style="width:60px;">\n                            <option ';
 if(filters && filters.actionRequired === ''){ ;
__p += 'selected';
};
__p += '\n                            role="option" value="">all</option>\n                            <option ';
 if(filters && filters.actionRequired === 'true'){;
__p += 'selected';
};
__p += '\n                            role="option" value="yes">Yes</option>\n                            <option ';
 if(filters && filters.actionRequired === 'false'){;
__p += 'selected';
};
__p += '\n                            role="option" value="no">No</option>\n                          </select>\n                          <a name="actionRequired" title="Reset Search Value" class="clearsearchclass">x</a>\n                      </div>\n                      <div style="display:block;">\n                          <select role="select" name="isLastMessageByCustomer" id="gs_isLastMessageByCustomer"  class="ui-widget-content ui-corner-all"\n                                  style="width:60px;">\n                              <option ';
 if(filters && filters.isLastMessageByCustomer === ''){ ;
__p += 'selected';
};
__p += '\n                              role="option" value="">all</option>\n                              <option ';
 if(filters && filters.isLastMessageByCustomer === 'true'){;
__p += 'selected';
};
__p += '\n                              role="option" value="yes">' +
((__t = ( _.i18n('offerList.lastMessageIsFromCustomer') )) == null ? '' : __t) +
'></option>\n                          </select>\n                          <a name="isLastMessageByCustomer" title="Reset Search Value" class="clearsearchclass">x</a>\n                      </div>\n                    </td>\n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_actionRequiredInternal">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                <tr>\n                  <td class="ui-search-input">\n                    <select role="select" name="actionRequiredInternal" id="gs_actionRequiredInternal"  class="ui-widget-content ui-corner-all">\n                      <option ';
 if(filters && filters.actionRequiredInternal === ''){ ;
__p += 'selected';
};
__p += '\n                      role="option" value="">all</option>\n                      <option ';
 if(filters && filters.actionRequiredInternal === 'true'){;
__p += 'selected';
};
__p += '\n                      role="option" value="yes">Yes</option>\n                      <option ';
 if(filters && filters.actionRequiredInternal === 'false'){;
__p += 'selected';
};
__p += '\n                      role="option" value="no">No</option>\n                    </select>\n                  </td>\n                  <td class="ui-search-clear">\n                    <a name="actionRequiredInternal" title="Reset Search Value" class="clearsearchclass">x</a>\n                  </td>\n                </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n          <th role="columnheader" class="ui-state-default ui-th-ltr" id="gsh_jq-grid-list_actionRequiredUkr">\n            <div>\n              <table class="ui-search-table" cellspacing="0">\n                <tbody>\n                <tr>\n                  <td class="ui-search-input">\n                    <select role="select" name="actionRequiredUkr" id="gs_actionRequiredUkr"  class="ui-widget-content ui-corner-all">\n                      <option ';
 if(filters && filters.actionRequiredUkr === ''){ ;
__p += 'selected';
};
__p += '\n                      role="option" value="">all</option>\n                      <option ';
 if(filters && filters.actionRequiredUkr === 'true'){;
__p += 'selected';
};
__p += '\n                      role="option" value="yes">Yes</option>\n                      <option ';
 if(filters && filters.actionRequiredUkr === 'false'){;
__p += 'selected';
};
__p += '\n                      role="option" value="no">No</option>\n                    </select>\n                  </td>\n                  <td class="ui-search-clear">\n                    <a name="actionRequiredUkr" title="Reset Search Value" class="clearsearchclass">x</a>\n                  </td>\n                </tr>\n                </tbody>\n              </table>\n            </div>\n          </th>\n        </tr>\n      </thead>\n    </table>\n  </div>\n</div>\n';

}
return __p
};});

