define('orderCompositionTabController',[
  'module',
  'app',
  'underscore',
  'backbone',
  'orderCompositionTabView',
  'compuzzProUtils',
  'entities/session'
], function (
  module,
  App,
  _,
  Backbone,
  CompositionTabView,
  CompuzzProUtils
) {
  'use strict';

  var CompositionTabController = {
    showCompositionTab: function (region, orderModel, customer, product) {
      var that = this;
      this.region = region;
      orderModel.set('compositions', _.sortBy(orderModel.get('compositions'), function (composition) {
        return composition.paCode;
      }));
      customer = customer;
      this.product = product;
      this.orderModel = orderModel;
      var coll = that.getCompositionCollection(orderModel);
      coll.orderModel = orderModel;
      coll.productModel = product;
      this.view = new CompositionTabView({
        collection: coll,
        productModel: product
      });
      this.view.listenTo(this.view, 'editor:open', _.bind(this.onEditorOpen, this));
      this.view.orderModel = orderModel;
      this.view.printAreas = product.get('printAreas');

      CompuzzProUtils.getInstance().addListener(this.view, CompuzzProUtils.events.COMPUZZ_PRO_REFRESH_COMPOSITION,
        _.bind(function () {
          this.orderModel.fetch().done(_.bind(function () {
            this.showCompositionTab(this.region, this.orderModel, this.customer, this.product);
          }, this));
        }, this));

      region.show(this.view);
    },

    getCompositionCollection: function (orderModel) {
      var compositionCollection = new Backbone.Collection(orderModel.get('compositions'));

      return compositionCollection;
    },

    onEditorOpen: function (poi, printZone, editorUrl) {
      var productCode = this.view.orderModel.attributes.productCode;
      App.request('session:create-session-from-poi', {webServiceUrl: 'compuzz', returnUrl: location.href},
        poi).done(_.bind(function (session) {
        var sessionTokenRequest = App.request('session:createToken', session.sessionId);

        $.when(sessionTokenRequest).done(function (resp) {
          var tokenId = resp.tokenId;
          var url = editorUrl +
            '/purchaseorder/' + poi +
            '/product/' + encodeURIComponent(productCode) +
            '/printzone/' + printZone +
            '?tokenId=' + tokenId;
          window.open(url, '_blank');

        });
      }, this));
    }
  };

  module.exports = CompositionTabController;
});

