/**
 * Created by GSP on 19/10/2015.
 */
define('controllerMixin',['module',
        'underscore'], function(module, _){

  'use strict';

  var ControllerMixin = {

    getInstance: function() {
      return _.extend({}, this);
    }
  };

  module.exports = ControllerMixin;
});
