/**
 * Created by OLD on 04/09/2017.
 */
define('ordersKanbanMain',[
  'module',
  'logger',
  'ordersKanbanRouter'
], function(
  module,
  Logger
) {
  'use strict';
  
  var OrdersKanban = {
    start: function() {
      Logger.log(module, 'starting OrdersKanbanModule');
    },

    stop: function() {
      Logger.log(module, 'stopping OrdersKanbanModule');
    }
  };

  module.exports = OrdersKanban;
});
