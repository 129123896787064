/**
 * Created by BFR on 13/04/2022.
 */
define('selectCustomerPopupView',[
  'module',
  'app',
  'jquery',
  'underscore',
  'settings',
  'template!selectCustomerPopupTpl',
  'dialogView',
  'autocompleteView',
  'entities/customers/customers'
], function (
  module,
  App,
  $,
  _,
  Settings,
  viewTpl,
  DialogView,
  AutocompleteView
) {
  'use strict';

  module.exports = DialogView.extend({
    template: viewTpl,

    regions: {
      customerAutoComplete: '.customer-auto-complete'
    },

    ui: {
      confirmButton: '.js-confirm'
    },

    events: {
      'click .js-cancel-popup': 'onCancelClick',
      'click @ui.confirmButton': 'onConfirmClick'
    },

    onCancelClick: function () {
      this.hide();
    },

    onConfirmClick: function () {
      var autoCompleteVal = this.getRegion('customerAutoComplete').currentView.ui.autocomplete.val();
      if (!this.selectedCustomer || this.selectedCustomer.get('code') !== autoCompleteVal) {
        if (autoCompleteVal) {
          this.trigger('customer-selected', App.request('customer:model', {
            code: autoCompleteVal,
            endUserId: autoCompleteVal
          }));
          this.trigger('onSave');
          this.hide();
        }
      } else {
        this.trigger('customer-selected', this.selectedCustomer);
        this.trigger('onSave');
        this.hide();
      }
    },

    onShow: function () {
      $.material.init();
      var glossaryAutoComplete = new AutocompleteView(
        this.getAutocompleteOptions()
      );
      this.getRegion('customerAutoComplete').show(glossaryAutoComplete);
    },

    getAutocompleteOptions: function () {
      return {
        type: 'remote',
        alwaysOpen: true,
        remote: Settings.url('compuzz2', 'endusers.json/endusers'),
        valueKey: 'code',
        apiKey: 'code',
        placeholder: _.i18n('selectecustomer.placeholder'),
        value: null,
        name: 'code',
        onPressKey: true,
        lazyLoad: true,
        keys: {
          query: 'code',
          limit: 'pageSize'
        },
        callback: _.bind(this.onSelectCustomer, this)
      };
    },

    onSelectCustomer: function (field, model) {
      if (model) {
        this.ui.confirmButton.prop('disabled', false);
        this.selectedCustomer = model;
      }
    }
  });
});
