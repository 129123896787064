/**
 * Created by BFR on 02/11/2022.
 */
define('orderProofsheetHistoryTabController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'orderProofsheetHistoryTabView',
  'entities/orders',
  'settings'
], function (
  module,
  _,
  App,
  bootbox,
  View
) {
  'use strict';

  module.exports = {

    showTab: function (region, orderModel) {
      this.model = orderModel;
      var view = new View({model: this.model});

      this.view = view;
      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      region.show(view);
    },

    onGridLoaded: function () {
      var data = {
        url: App.request('order-proofsheet-history:get-url', this.model.get('purchaseOrderId')),
        rowCountUrl: App.request('order-proofsheet-history:row-count-url', this.model.get('purchaseOrderId'))
      };

      this.view.displayGrid(data, true);
    }
  };
});
