/**
 * Created by OLD on 31/08/2015.
 */
define('internalMessageTabController',[
  'module',
  'logger',
  'underscore',
  'backbone',
  'jquery',
  'app',
  'internalMessageTabView',
  'entities/command_tickets',
  'settings',
  'rolesMixin',
  'entities/offers'
], function (
  module,
  Logger,
  _,
  Backbone,
  $,
  App,
  View,
  CollectionView,
  Settings,
  RolesMixin
) {
  'use strict';

  var CommandTicketsController = {
    showTab: function (data, region, csMsg, customer) {
      this.model = data;
      this.csMsg = csMsg;
      this.getTicketsByPurchaseOrder(data.id, csMsg);
      this.purchaseOrderId = data.id;
      this.regionView = region;
      this.customer = customer;
      this.actionRequired = data.get('actionRequired');
      this.actionRequiredInternal = data.get('actionRequiredInternal');
      this.actionRequiredUkr = data.get('actionRequiredUkr');
    },

    getTicketsByPurchaseOrder: function (poi, csMsg) {
      var ticketRequest = App.request('commandtickets:purchaseorder', poi, csMsg),
        view,
        that = this;

      $.when(ticketRequest)
        .done(function (response) {
          var collection = new Backbone.Collection(response.responseJSON[0].messages);
          if ((Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_CUSTOMER_MESSAGES] && !csMsg) ||
            (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_INTERNAL_MESSAGES] && csMsg)) {
            view = new View({
              collection: collection,
              ticket: response.responseJSON[0],
              attachedFiles: [],
              textsSupport: [],
              csMsg: that.csMsg,
              customer: that.customer,
              actionRequired: that.actionRequired,
              actionRequiredInternal: that.actionRequiredInternal,
              actionRequiredUkr: that.actionRequiredUkr
            });
            that.view = view;
            view.listenTo(view, 'command:ticket:save-message', _.bind(that.saveTicketMessage, that));
            view.listenTo(view, 'command:action-required:change', _.bind(that.updateActionRequired, that));
            view.listenTo(view, 'command:action-required-internal:change', _.bind(that.updateActionRequiredInternal, that));
            view.listenTo(view, 'command:action-required-ukr:change', _.bind(that.updateActionRequiredUkr, that));
            view.listenTo(view, 'message:delete', _.bind(that.deleteMessage, that));
            that.regionView.show(view);
          }
        });
    },

    getTickets: function (secId) {
      var that = this,
        ticketRequest = App.request('commandtickets:get-data', secId),
        cAccountDuplicateId,
        collection, view;

      $.when(ticketRequest)
        .done(function (response) {
          collection = new Backbone.Collection(response.get('messages'));
          view = new CollectionView({
            collection: collection,
            ticket: response, attachedFiles: []
          });
          that.view = view;
          $.when(App.request('commandtickets:mark-read', response.get('id')))
            .done(function (response) {
              Logger.log(response);
            });
          cAccountDuplicateId = App.request('caccount:duplicate:ip', response.get('cAccountJsonLight').secId);
          $.when(cAccountDuplicateId).done(function (cAccounts) {
            that.view.cAccountDuplicateIP = cAccounts;
            if (cAccounts && cAccounts.length) {
              that.view.setCAccountWarning();
            }
          });
          App.regions.getRegion('main').currentView.getRegion('content').show(view);

        });
    },

    saveTicketMessage: function (secId, messageModel) {
      var saveMessageRequest = App.request('commandtickets:save-message', secId, messageModel, this.csMsg);
      $.when(saveMessageRequest).done(_.bind(function (message) {
        this.view.addMessage(message);
      }, this));
    },

    updateActionRequired: function (checked) {
      var that = this;
      $.when(App.request('offer:update', {
        purchaseOrderId: this.purchaseOrderId,
        actionRequired: checked
      })).done(_.bind(function () {
        this.model.set('actionRequired', checked);
        that.view.hideLoadingBlock();
      }, this));
    },

    updateActionRequiredInternal: function (checked) {
      $.when(App.request('offer:update', {
        purchaseOrderId: this.purchaseOrderId,
        actionRequiredInternal: checked
      })).done(_.bind(function () {
        this.model.set('actionRequiredInternal', checked);
        this.view.hideLoadingBlock();
      }, this));
    },

    updateActionRequiredUkr: function (checked) {
      $.when(App.request('offer:update', {
        purchaseOrderId: this.purchaseOrderId,
        actionRequiredUkr: checked
      })).done(_.bind(function () {
        this.model.set('actionRequiredUkr', checked);
        this.view.hideLoadingBlock();
      }, this));
    },

    deleteMessage: function (message) {
      var deleteMessageRequest = App.request('commandtickets:delete-message', message.options.model.get('secId'));
      $.when(deleteMessageRequest).done(_.bind(function () {
        this.view.collection.remove(message);
        $(message)[0].remove();
      }, this));
    }
  };

  module.exports = CommandTicketsController;
});

