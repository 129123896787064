
define('template!phoneNumberSelectDialogPhoneNumberTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page">\n    <div class="products-table">\n      <table id="jq-grid-list-dialog" class="product-table" role="grid"></table>\n      <div id="jq-grid-pager-list-dialog" class="jq-grid-pager-list-dialog"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

