/**
 * Created by RKL on 26/08/2015.
 */
define('commandLineAttachsCollectionView',[
  'module',
  'marionette',
  'app',
  'elementsCollectionView',
  'stateMessages',
  'commandLineAttachsItemView',
  'commandLineAttachsNewItemView',
  'template!commandLineAttachsCollectTpl'
], function (
  module,
  Marionette,
  App,
  ElementsCollectionView,
  StateMessages,
  ItemView,
  NewItemView,
  viewTpl
) {
  'use strict';

  var CommandLinesAttachmentsTabCompositeView = ElementsCollectionView.extend({
    template: viewTpl,
    childViewContainer: '.attachments-container',


    getChildView: function(item) {
      if (item.get('id') !== 0) {
        return ItemView;
      } else {
        return NewItemView;
      }
    }
  });

  module.exports = CommandLinesAttachmentsTabCompositeView;
});
