/**
 * Created by RKL on 21/08/2015.
 */
define('ordersMainRouter',[
  'require',
  'exports',
  'module',
  'jquery',
  'logger',
  'underscore',
  'marionette',
  'app',
  'contentShowController',
  'itemPagesNameMixin',
  'entities/customers/customers'
], function (
  require,
  exports,
  module,
  $,
  Logger,
  _,
  Marionette,
  App,
  ContentShowController,
  ItemPagesNameMixin
) {
  'use strict';

  var OrdersRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'orders': 'showView',
      'orders/(customers/:customerSecId/orders/):orderSecId': 'showOrderDefaultTab',
      'orders/(customers/:customerSecId/orders/):orderSecId(/:tabName)': 'showOrderTab',

      'orders/(customers/:customerSecId/producttemplates/):orderSecId': 'showOrderDefaultTab',
      'orders/(customers/:customerSecId/producttemplates/):orderSecId(/:tabName)': 'showOrderTab',

      'orders/customers/:customerSecId/orders/:orderSecId/:tab/produceProofsheet': 'showProduceProofsheet',
      'orders/customers/:customerSecId/orders/:orderSecId/produceProofsheet': 'showProduceProofsheet'
    }
  });

  var API = {
    showView: function () {
      require([
        'ordersShowController'
      ], function (ShowController) {
        ContentShowController.showContent().done(function () {
          App.startSubModule('ordersMain');
          ShowController.showOrders();
          App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS_LIST);
        });
      });
    },

    showOrderDefaultTab: function (customerSecId, orderSecId) {
      API.showOrderTab(customerSecId, orderSecId, 'details');
    },

    showOrderTab: function (customerSecId, orderSecId, tabName) {
      ContentShowController.showContent().done(function () {
        require([
          'orderTabsLayoutController'
        ], function (OrderTabsLayoutController) {
          tabName = OrderTabsLayoutController.getFirstAllowedTab(tabName);
          var region = App.regions.getRegion('main').currentView.getRegion('content');
          OrderTabsLayoutController.showTabsLayout(region, tabName, orderSecId).done(function (region, orderModel, customer, product) {
            switch (tabName) {
              case 'details':
                require([
                  'orderDetailsTabController'
                ], function (OrderDetailsTabController) {
                  OrderDetailsTabController.showDetailsTab(region, orderModel, customer, product);
                });
                break;
              case 'price':
                require([
                  'orderPriceTabController'
                ], function (OrderPriceTabController) {
                  OrderPriceTabController.showPrice(region, orderModel);
                });
                break;
              case 'composition':
                require([
                  'orderCompositionTabController'
                ], function (OrderCompositionTabController) {
                  OrderCompositionTabController.showCompositionTab(region, orderModel, customer, product);
                });
                break;
              case 'attachments':
                require([
                  'commandLineAttachsTabController'
                ], function (CommandLineAttachsTabController) {
                  CommandLineAttachsTabController.showTab(orderModel, region);
                });
                break;
              case 'tracking':
                require([
                  'trackingTabController'
                ], function (TrackingTabController) {
                  TrackingTabController.showTab(orderModel, region);
                });
                break;
              case 'customer_messages':
              case 'internal_messages':
                require([
                  'internalMessageTabController'
                ], function (InternalMessageTabController) {
                  var csMsg = tabName === 'internal_messages';
                  var customer = App.request('customers:entity:end-user-id', orderModel.get('endUserId'));
                  customer.fetch().done(_.bind(function (customer) {
                    InternalMessageTabController.showTab(orderModel, region, csMsg, customer);
                  }, this));
                });
                break;
              case 'all_customer_messages':
                require([
                  'customerAllMessagesTabController'
                ], function (CustomerAllMessagesTabController) {
                  var csMsg = App.getCurrentRoute().includes('internal_messages');
                  var customer = App.request('customers:entity:end-user-id', orderModel.get('endUserId'));
                  customer.fetch().done(_.bind(function (customer) {
                    CustomerAllMessagesTabController.showTab(orderModel, region, csMsg, customer);
                  }, this));
                });
                break;
              case 'order_invoices':
                require([
                  'orderInvoiceTabController'
                ], function (OrderInvoiceTabController) {
                  OrderInvoiceTabController.showTab(region, orderModel);
                });
                break;
              case 'order_proofsheet_history':
                require([
                  'orderProofsheetHistoryTabController'
                ], function (OrderProofsheetHistoryTabController) {
                  OrderProofsheetHistoryTabController.showTab(region, orderModel);
                });
                break;
            }
            if (customerSecId) {
              App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
            } else {
              if (orderModel.get('commandType') === 'offer') {
                App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS);
              } else {
                App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS);
              }
            }
            $('#customer-comments-sidebar-region').show();
          });
        });
      });
    },

    showProduceProofsheet: function (customerSecId, orderSecId, tab) {
      ContentShowController.showContent().done(function () {
        require([
          'orderTabsLayoutController',
          'produceProofsheetController'
        ], function (OrderTabsLayoutController, ProduceProofsheetController) {
          var region = App.regions.getRegion('main').currentView.getRegion('content'),
            tabName = tab ? tab : 'details';
          OrderTabsLayoutController.showTabsLayout(region, tabName, orderSecId).done(function (region, orderModel) {
            ProduceProofsheetController.showProduceProofsheet(region, orderModel);
          });
        });
      });
    }
  };

  App.on('orders:show', function () {
    App.navigate('orders');
    API.showView();
  });

  App.on('before:start', function () {
    new OrdersRouter({
      controller: API
    });
  });

  module.exports = OrdersRouter;
});

