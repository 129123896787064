
define('template!leadContactsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n\t<div class="well customer-contacts jq-grid-table-page">\n\t    <div class="customer-contacts-table">\n\t\t\t<div class="form-group add-new-block">\n\t\t\t\t<a class="btn new-contacts-button add-btn-text js-add-new">\n\t\t\t\t\t<i class="mdi-content-add"></i>\n\t\t\t\t\t<span>' +
((__t = ( _.i18n('common.new') )) == null ? '' : __t) +
'</span>\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t\t<table id="jq-grid-list" class="contactsTable" role="grid"></table>\n\t\t\t<div id="jq-grid-pager-list"></div>\n\t\t</div>\n\t</div>\n</div>';

}
return __p
};});

