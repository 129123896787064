/**
 * Created by RKL on 07/09/2015.
 */
define('leadsShowController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'leadsShowView',
  'emptyView',
  'leadInfoController',
  'leadProfileTabController',
  'leadContactsTabController',
  'leadCallsTabController',
  'mailsController',
  'rolesMixin',
  'settings',
  'leadsTabs',
  'entities/caccounts'
], function (
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  InfoShowController,
  profileTabController,
  contactsTabController,
  callsTabController,
  mailsController,
  RolesMixin,
  Settings,
  LeadsTabs
) {
  'use strict';

  var LeadsDetailsController = {
    fetchLead: function (data) {
      App.trigger('customer:comments', data.id);
      var that = this,
        emptyView = new EmptyView(),
        contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.id) {
        this.showLead(data);
      } else {
        this.model = App.request('customers:entity', data.id);
        data.model = this.model;
        if (data.id === 'new') {
          that.showLead(data);
        } else {
          this.model.fetch().done(function () {
            if (contentRegion) {
              that.showLead(data);
            }
          });
        }
      }
    },

    showLead: function (data) {
      var view = new View({model: this.model, data: data});

      this.view = view;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      this.displayTabs(data);

      view.listenTo(view, 'onViewMore', _.bind(this.onViewMore, this));
      view.listenTo(view, 'onDelete', _.bind(this.onDelete, this));
      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
      view.listenTo(App, 'lead:header:rerender', _.bind(this.onHeaderChange, this));
    },

    displayTabs: function (data) {
      var roles = Settings.get('cAccountRoles'),
        controllers = {};

      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE] = profileTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS] = contactsTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CALLS] = callsTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_MAILS] = mailsController;

      data.model = this.model;

      if (data.id === 'new') {
        profileTabController.showTab(data);
        return;
      }
      if (data.tab) {
        controllers[data.tab.role].showTab(data);
      } else if (roles) {
        _.some(LeadsTabs, function (c, index) {
          data.tab = LeadsTabs[index];
          if (roles[data.tab.role] === true) {
            controllers[data.tab.role].showTab(data);
            return true;
          }
        });
      }

      this.tab = data.tab;
    },

    onViewMore: function () {
      InfoShowController.showLeadInfo(this.data);
    },

    onHeaderChange: function (lead) {
      this.model.set({
        companyName: lead.companyName,
        tel: lead.tel,
        email: lead.email,
        logoUid: lead.logoUid,
        code: lead.code,
        refDeliveryAddress: lead.refDeliveryAddress,
        vatNumberValid: lead.vatNumberValid,
        vatNumberChecked: lead.vatNumberChecked
      });
      this.view.onHeaderChange(lead);
    },

    onDelete: function () {
      var that = this;

      bootbox.confirm('Delete Lead?', function (result) {
        if (result) {
          that.model.url = that.model.deleteUrl();
          that.model.destroy().done(function () {
            App.trigger('leads:show');
          });
        }
      });
    },

    onTabClick: function (tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;

        this.displayTabs(this.data);
        App.navigate('leads/' + this.model.get('secId') + '/' + tab.route);
      }
    }
  };

  module.exports = LeadsDetailsController;
});
