define('openTasksCollectionView',[
    'module',
    'backbone',
    'marionette',
    'openTasksView'
], function(
    module,
    Backbone,
    Marionette,
    OpenTasksView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: OpenTasksView,
        collection: new Backbone.Collection(),

        childEvents: {
            'tasks:done': function (view) {
                this.trigger('tasks:done', view.model);
            }
        }
    });
});
