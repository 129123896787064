/**
 * Created by GSP on 29/08/2017.
 */
define('leadCallsTabController',[
    'module',
    'underscore',
    'marionette',
    'app',
    'callsListController',
    'entities/phone/calls'
  ], function(
    module,
    _,
    Marionnette,
    App,
    CallsListController
  ) {
    'use strict';
  
    var LeadsCallsTabController =  _.extend(CallsListController.getInstance(), {
      showTab: function (data, ViewInstance) {
        CallsListController.show.call(this, data, ViewInstance);
      },
  
      showRegion: function() {
          App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(this.view);
      },
  
      initializeGrid: function() {
          var data = {
            url: App.request(
              'calls:get-collection-url',
              {endUserSecId: this.data.model.get('secId')}
            ),
            rowCountUrl: App.request(
              'calls:row-count-url',
              {endUserSecId: this.data.model.get('secId')}
            )
          };
          this.view.displayGrid(data);
      }
  
    });
  
    module.exports = LeadsCallsTabController;
  });
