define('orderTabsData',[
  'module',
  'underscore',
  'rolesMixin'
], function (
  module,
  _,
  RolesMixin
) {
  'use strict';

  module.exports = [
    {
      name: 'details',
      title: _.i18n('orders.details.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_DETAIL
    }, {
      name: 'price',
      title: _.i18n('orders.price.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_PRICE
    }, {
      name: 'composition',
      title: _.i18n('orders.composition.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_PERSONALIZATION
    }, {
      name: 'attachments',
      title: _.i18n('orders.attachments.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_ATTACHMENT
    }, {
      name: 'tracking',
      title: _.i18n('orders.tracking.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_TRACKING
    }, {
      name: 'customer_messages',
      title: _.i18n('tab.messages'),
      role: RolesMixin.DASHBOARD_ORDERS_CUSTOMER_MESSAGES
    }, {
      name: 'all_customer_messages',
      title: _.i18n('tab.messagesAll'),
      role: RolesMixin.DASHBOARD_ORDERS_CUSTOMER_MESSAGES
    }, {
      name: 'internal_messages',
      title: _.i18n('tab.messagesCs'),
      role: RolesMixin.DASHBOARD_ORDERS_INTERNAL_MESSAGES
    }, {
      name: 'order_invoices',
      title: _.i18n('cAccountDetail.invoices.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_INVOICES
    }, {
      name: 'order_proofsheet_history',
      title: _.i18n('cAccountDetail.proofsheetHistory.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_PROOFSHEET_HISTORY
    }
  ];
});

