/**
 * Created by OLD on 28/05/2019.
 */
define('offersKanbanController',[
  'module',
  'logger',
  'underscore',
  'app',
  'bootbox',
  'backbone',
  'offersKanbanView',
  'emptyView',
  'offersKanbanFiltersView',
  'offersKanbanFiltersDesign2View',
  'offersKanbanBoardView',
  'offersKanbanCardsView',
  'offersKanbanCommentPopupView',
  'jquery',
  'settings',
  'rolesMixin',
  'productSelectDialogView',
  'productConfigDialogView',
  'selectCustomerPopupView',
  'entities/offers_kanban',
  'entities/offers',
  'entities/kanban_tickets'
], function (
  module,
  Logger,
  _,
  App,
  bootbox,
  Backbone,
  View,
  EmptyView,
  FilterView,
  FilterDesign2View,
  BoardView,
  CardsView,
  CommentPopupView,
  $,
  Settings,
  RolesMixin,
  ProductSelectDialog,
  ProductConfigDialog,
  CustomerSelectDialog
) {
  'use strict';

  var OffersKanbanController = {
    showOffersKanban: function (parentStatus, endUserId) {
      $('#customer-comments-sidebar-region').hide();
      var fetchStatuses = App.request('offer_kanban:statuses');


      this.cardCollections = [];
      this.view = new View({cardCollections: this.cardCollections});
      this.view.listenTo(this.view, 'addNewOffer', _.bind(this.onAddNewOffer, this));

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        this.filterView = new FilterDesign2View();
      } else {
        this.filterView = new FilterView();
      }

      if (endUserId) {
        this.filterView.filters.endUserId = endUserId;
        Settings.setToMemory(this.filterView.filtersName, this.filterView.filters);
      }

      this.filterView.listenTo(this.filterView,
        'offers_kanban:filter:update',
        _.bind(this._changeFilter, this));

      $.when(App.request('kanban-tickets:get-users'))
        .done(_.bind(function (_users) {
          this.users = this._parseUsers(_.sortBy(_users, function (user) {
            return user.firstName.toLowerCase();
          }));

          $.when(fetchStatuses).done(_.bind(function (statuses) {
            this.statuses = statuses.toJSON();
            if (parentStatus) {
              var parent = _.findWhere(this.statuses, {statusConst: parentStatus});
              parent.main = true;
              this.statuses = parent.childStatus;
              this.statuses.unshift(parent);
            }
            _.each(this.statuses, function (status) {
              status.statusConstId = status.statusConst.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
            });
            var fetchStatuses = App.request('offer:statuses', true),
              that = this;
            $.when(fetchStatuses).done(function (contextMenuStatuses) {
              that.contextMenuStatuses = contextMenuStatuses.models;
              that.boardView = new BoardView({
                users: this.users,
                statuses: that.statuses,
                contextMenuStatuses: that.contextMenuStatuses
              });

              that.view.getRegion('kanbanFilters').show(that.filterView);
              that.view.getRegion('kanbanBoard').show(that.boardView);

              that._drawBoardRegions();
              that._showEmptyViewOnBoards();
              that._getOffersCardByStatus(that.filterView.filters);
            });
          }, this));
        }, this));

      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    },

    onAddNewOffer: function () {
      var currentRoute = App.getCurrentRoute();
      if (currentRoute !== 'offers_kanban' && this.data && this.data.model.get('secId')) {
        this.openProductDialog();
      } else {
        this.openEndUserDialog();
      }
    },

    openEndUserDialog: function () {
      this.customerSelectDialog = new CustomerSelectDialog();
      this.customerSelectDialog.listenTo(this.customerSelectDialog, 'customer-selected', _.bind(this.onCustomerSelected, this));
      this.customerSelectDialog.show({
        title: _.i18n('dialog.offer.selectCustomer')
      });
    },

    onCustomerSelected: function (selectedCustomer) {
      this.data = {
        model: selectedCustomer
      };
      this.openProductDialog();
    },

    openProductDialog: function () {
      this.productSelectDialog = new ProductSelectDialog({
        multiSelect: false,
        displayLeaveButton: false
      });
      this.productSelectDialog.listenTo(this.productSelectDialog, 'products-selected', _.bind(this.onProductsSelected, this));
      this.productSelectDialog.show({
        title: _.i18n('dialog.offer.selectProduct'),
        className: 'product-selection-dialog'
      });
    },

    onProductsSelected: function (products) {
      this.currentproducts = _.pluck(products, 'rowid');
      this.displayProductConfigPopup();
    },

    displayProductConfigPopup: function () {
      var selectedProduct = App.request('products:model', {secId: this.currentproducts[0]});
      selectedProduct.fetch().done(_.bind(function () {
        selectedProduct.fullFetch(selectedProduct.get('cCode')).done(_.bind(function () {
          this.productConfigDialog = new ProductConfigDialog({product: selectedProduct});
          this.productSelectDialog.hide();
          this.productConfigDialog.show({
            title: _.i18n('dialog.offer.configureOffer'),
            className: 'product-selection-dialog'
          });
          this.productConfigDialog.listenTo(this.productConfigDialog, 'product-configured', _.bind(this.onProductConfigured, this));
          this.productConfigDialog.listenTo(this.productConfigDialog, 'change-product', _.bind(this.onChangeProduct, this));
        }, this));
      }, this));
    },

    onChangeProduct: function () {
      this.productConfigDialog.hide();
      this.openProductDialog();
    },

    onProductConfigured: function (configuration) {
      //endUserId, customerId
      App.request('offer-kanban:create-from-configuration',
        this.productConfigDialog.model.get('secId'),
        this.data ? this.data.model.get('endUserId') : '',
        this.data ? this.data.model.get('code') : '',
        configuration
      ).then(_.bind(function () {
        bootbox.dialog({
          title: _.i18n('offer.continueTitle'),
          message: _.i18n('offer.continueText'),
          buttons: {
            success: {
              label: _.i18n('offer.continueSameCustomer'),
              className: 'btn-success',
              callback: _.bind(function () {
                this.displayProductConfigPopup();
              }, this)
            },
            main: {
              label: _.i18n('offer.continueOtherCustomer'),
              className: 'btn-primary',
              callback: _.bind(function () {
                this.openEndUserDialog();
              }, this)
            }
          }
        });
      }, this));
      this.productConfigDialog.hide();
    },

    _parseUsers: function (_users) {
      this.users = _.map(_users, function (user) {
        if (user.firstName && user.lastName) {
          user.fullName = user.firstName + ' ' + user.lastName;
        } else if (user.firstName || user.lastName) {
          user.fullName = user.firstName ? user.firstName : user.lastName;
        } else if (user.nickName) {
          user.fullName = user.nickName;
        } else {
          user.fullName = user.logonId;
        }
        return user;
      });
      return this.users;
    },

    _drawBoardRegions: function () {
      this.boardRegion = _.map(this.statuses, function (status) {
        var region = {},
          regionKey = status.statusConstId,
          regionName = '#' + status.statusConstId + '-container';

        region[regionKey] = regionName;
        return region;
      });
      _.each(this.boardRegion, _.bind(function (region) {
        this.boardView.addRegions(region);
      }, this));
    },

    _showEmptyViewOnBoards: function () {
      _.each(this.boardRegion, _.bind(function (region) {
        this.boardView.getRegion(_.keys(region)[0]).show(new EmptyView());
      }, this));
    },

    _changeFilter: function (params) {
      this._showEmptyViewOnBoards();
      this._getOffersCardByStatus(params);
    },

    _getOffersCardByStatus: function (params) {
      _.each(this.statuses, _.bind(function (status) {
        var filters = _.extend({
          status: status.statusConst
        }, params);

        if (status.main) {
          filters.excludeSubLevel = true;
        }

        if (!filters.showOnlyMine) {
          filters.showOnlyMine = {};
        }
        if (Settings.get('showOnlyMine' + filters.status, true) === 'true') {
          filters.showOnlyMine[filters.status] = true;
        }
        this._fetchOfferCards(filters);
      }, this));
    },

    _fetchOfferCards: function (filters) {
      $.when(App.request('offer_kanban:offers', filters))
        .done(_.bind(function (offers, count) {
          var showOnlyMine = false;
          if (filters.showOnlyMine && filters.showOnlyMine[filters.status]) {
            showOnlyMine = true;
          }
          var regionKey = filters.status.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase(),
            cardsView = new CardsView({
              status: filters.status,
              collection: offers,
              count: count,
              users: this.users,
              showOnlyMine: showOnlyMine,
              contextMenuStatuses: this.contextMenuStatuses
            });

          this.cardCollections.push({
            collection: offers,
            status: filters.status,
            count: count
          });
          cardsView.listenTo(cardsView, 'offer-kanban:update:card', _.bind(this._updateCardStatus, this));
          cardsView.listenTo(cardsView, 'offer-kanban:refresh:cards', _.bind(this._refreshCards, this));
          if (this.boardView.getRegion(regionKey)) {
            this.boardView.getRegion(regionKey).show(cardsView);
          }
          this.boardView.filters = filters;
        }, this));
    },

    _refreshCards: function (status, showOnlyMine) {
      if (!this.filterView.filters.showOnlyMine) {
        this.filterView.filters.showOnlyMine = {};
      }
      this.filterView.filters.showOnlyMine[status] = showOnlyMine;
      var filters = _.extend({
        status: status
      }, this.filterView.filters);
      var st = _.findWhere(this.statuses, {statusConst: status});
      if (st.main) {
        filters.excludeSubLevel = true;
      }
      this._fetchOfferCards(filters);
    },

    _updateCardStatus: function (poi, status) {
      /*var statusObj = _.findWhere(this.statuses, {statusConst: status});
      if (statusObj && statusObj.commentStatus) {
          App.request('offer-kanban:get:comment', poi).then(_.bind(function(comments) {
              var edit = false;

              if (comments && comments.length() > 0 && comments[comments.length - 1].notSend === true) {
                  edit = true;
              }
              var commentPopupView = new CommentPopupView({
                  model: new Backbone.Model({comment: edit?comments[comments.length - 1].content:''})
              });
              commentPopupView.show({
                  title: _.i18n('common.comment'),
                  buttons: {
                      ok: {
                          label: _.i18n('common.ok'),
                          className: 'btn-default',
                          callback: _.bind(function() {
                              var currentUserPid = Settings.get('currentUser' );
                              var comment = {
                                  content: commentPopupView.model.get('comment'),
                                  externalId: '',
                                  externalUserId: currentUserPid.externalId,
                                  msgDate: new Date().getTime(),
                                  title: commentPopupView.model.get('title'),
                                  user: currentUserPid.secId
                              };

                              if (edit) {
                                  comments[comments.length - 1] = comment;
                              } else {
                                  comments.push(comment);
                              }

                              App.request('offer-kanban:update:comment', poi, comments).then(function() {
                                  $.when(App.request('offer-kanban:update:table', poi, status)).done(function() {});
                              });
                              return true;
                          }, this)
                      }
                  }
              });
          }, this));
      } else {*/
      $.when(App.request('offer-kanban:update:table', poi, status)).done(function () {
      });
      //}
    }
  };

  module.exports = OffersKanbanController;
});
