/**
 * Created by BFR on 05/08/2022.
 */
define('caccountTransportView',[
  'module',
  'jquery',
  'marionette',
  'app',
  'underscore',
  'template!caccountTransportTpl',
  'caccountTransportItemPriceView'
], function (
  module,
  $,
  Marionette,
  App,
  _,
  viewTpl,
  caccountTransportItemPriceView
) {
  'use strict';

  module.exports = Marionette.CompositeView.extend({
    template: viewTpl,
    childView: caccountTransportItemPriceView,
    childViewContainer: '.transport-price-region',

    modelEvents: {
      'toSave': 'saveModel'
    },

    regions: {
      transportPriceRegion: '.transport-price-region'
    },

    events: {
      'click .btn-add-transport-price': 'onAddTransportPriceClick',
      'click .btn-delete-transport-price': 'onRemoveTransportPriceClick',
      'change .txt-input': 'onTxtChange'
    },

    onTxtChange: function (e) {
      var txt = $(e.currentTarget);
      var subProperty = txt.data('subproperty');
      var m = this.model;
      if (subProperty) {
        m = this.model.get(txt.data('property'));
        m.set(txt.data('subproperty'), txt.val());
      } else {
        m.set(txt.data('property'), Number(txt.val()));
      }

      this.saveModel();
    },

    saveModel: function () {
      this.model.save(this.options.supplierUid);
    },

    onAddTransportPriceClick: function () {
      this.collection.addNew();
    },

    onRemoveTransportPriceClick: function () {
      this.collection.remove(this.collection.at(this.collection.length - 1));
    }
  });
});
