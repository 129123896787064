/**
 * Created by BFR on 16/06/2020.
 */
define('pimProductPricesTabController',[
  'module',
  'underscore',
  'app',
  'jquery',
  'pimProductPricesTabView',
  'emptyView',
  'entities/pim_product',
  'entities/caccounts',
  'entities/pim_product_competitor',
  'entities/pim_product_similar'
], function (
  module,
  _,
  App,
  $,
  View,
  EmptyView
) {

  'use strict';
  var PimProductPricesController = {

    showDetails: function (region, model) {
      if (region) {
        this.region = region;
      } else {
        region = this.region;
      }
      this.model = model;

      var emptyView = new EmptyView();
      region.show(emptyView);

      var config = {
        quantity: 500,
        options: [],
        printZonesColors: {}
      };

      _.each(this.model.get('data').get('productOptionCategories').toJSON(), function (poc) {
        config.options.push(poc.productOptions[0].pimId);
      });

      var sup = App.request('pim:products:get-print-factors', this.model.get('id'));
      sup.done(_.bind(function (pimPrintFactors) {

        _.each(this.model.get('data').get('printAreas').models, function (pa) {
          _.each(pa.get('printZones').models, function (pz) {
            if (pz) {
              config.printZonesColors[pz.get('pimId')] = 1;
            }
          });
        });

        var pzPrices = App.request('pim:products:printzone-prices:model');
        var pricesCol = App.request('pim:products:prices:collection');
        var compsCol = App.request('pim:product-competitor:collection');
        var simsCol = App.request('pim:product-similar:collection');
        var _this = this;

        $.when(App.request('caccount:get-factors')).done(_.bind(function (factorsV2) {
          $.when(App.request('caccount:get-factors-by-supplier-uid', this.model.get('supplierUid')))
            .done(_.bind(function (factorsV2Supplier) {
              if (factorsV2Supplier) {
                factorsV2 = factorsV2Supplier;
              }
              if (this.model.getCurrentResProduct()) {
                $.when(pzPrices.fetch(this.model.get('id')),
                  compsCol.fetch(this.model.getCurrentResProduct().get('id')),
                  simsCol.fetch(this.model.getCurrentResProduct().get('id')),
                  pricesCol.fetch(this.model.getCurrentResProduct().get('id'))).done(_.bind(function () {

                  var request = App.request('pim:products:get-price-for-configuration',
                    this.model.getCurrentResProduct().get('id'), config, true);

                  $.when(request).done(_.bind(function (price) {
                    App.request('pim:products:prices-factor-sup', _this.model.get('id')).done(_.bind(function (pricesFactorSup) {

                      _.each(price.scale, function (scale, index) {
                        scale.factorModel = pricesCol.findByQuantity(scale.quantity);
                        if (scale.factorModel === null) {
                          scale.factorModel = {
                            quantity: Number(scale.quantity),
                            factor: 1.3,
                            defaultFactor: pricesFactorSup.length > index ? pricesFactorSup[index].factor : 1.3
                          };
                          scale.previousFactor = scale.factorModel.factor;
                        } else {
                          scale.factorModel = scale.factorModel.toJSON();
                          scale.factorModel.defaultFactor = pricesFactorSup.length > index ? pricesFactorSup[index].factor : 1.3;
                          scale.previousFactor = scale.factorModel.factor;
                        }
                      });

                      this.view = new View({
                        model: this.model,
                        price: price,
                        productPrices: pricesCol,
                        pricesFactorSup: pricesFactorSup,
                        pimPrintFactors: pimPrintFactors,
                        printZonePrices: pzPrices,
                        competitorsCollection: compsCol,
                        similarsCollection: simsCol,
                        config: config,
                        useFactorV2: ((!pricesCol || pricesCol.length === 0) && factorsV2),
                        factorsV2: factorsV2
                      });

                      emptyView.destroy();
                      this.view.listenTo(this.view, 'product-prices:update-prices', _.bind(this.onPricesUpdate, this));
                      this.view.listenTo(this.view, 'pim-product-prices:refresh', _.bind(this.showDetails, this));
                      region.show(this.view);
                    }, this));
                  }, this));
                }, this));
              }
            }, this));
        }, this));
      }, this));
    },

    onPricesUpdate: function (factors) {
      this.view.ui.approveLoading.show();
      var pricesCol = App.request('pim:products:prices:collection', factors);
      pricesCol.saveAll(this.model.getCurrentResProduct().get('id')).done(_.bind(function (models) {
        this.view.generateOldestLabel(models);
        this.view.ui.approveLoading.hide();
      }, this));
    }
  };

  module.exports = PimProductPricesController;
});
