/**
 * Created by RKL on 15/09/2015.
 */
define('customerLogosImagesGroupsController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'groupsController',
  'customerLogosImagesItemsController',
  'entities/customers/design_elements'
], function(
  module,
  Backbone,
  _,
  App,
  GroupsController,
  ElementsController
) {
  'use strict';

  /**
   * This controller is extended from GroupsController
   */
  var CustomersLogosImagesController = _.defaults({
    showTab: function(data) {
      if (!data.tab) {
        data.tab = 'logosimages';
      }

      this.data = data;
      this.region = 'tabContent';
      this.objectType = 'image';
      this.elControllers = {};

      this.subscribeToEvents();
      this.showLayoutView();
    },

    displayElements: function(groupId) {
      if (!this.elControllers[groupId]) {
        this.elControllers[groupId] = _.extend({id: groupId}, ElementsController);
      }

      if (!this.elControllers[groupId].active) {
        _.each(this.elControllers, function (controller) {
          controller.active = false;
        });
        this.elControllers[groupId].active = true;
        this.elControllers[groupId].displayElements(this.data, groupId);
      }
    },
    getGroupCount: function(secId, callback) {
      var fetchCount;

      fetchCount = App.request(
        'customers:elements-count',
        {
          secId: secId,
          endUserId: this.data.model.get('endUserId'),
          type: this.objectType
        }
      );

      $.when(fetchCount).done(function(count) {
        callback(count);
      });
    }
  }, GroupsController);

  module.exports = CustomersLogosImagesController;
});
