define('leadsCopyPasteMix',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'app',
  'settings',
  'bootbox',
  'jqgridActionsButtonFormatterActions',
  'jqgridActionsButtonFormatterView',
  'entities/caccounts/templates'
], function (
  module,
  Backbone,
  Marionette,
  _,
  App,
  Settings,
  bootbox,
  availableActions,
  ActionButtonsView
) {
  'use strict';

  var TemplatesCopyPasteMix = {

    checkIfCopyPasteAvailabe: function () {
      var $active = this.$('.caccount-tabs-container .customer-tab.active'),
        name = $active.data('name');

      if (name === 'templates' || name === 'designelements' ||
        this.pasteTarget === 'fromVariations') {
        this.getRegion('leadActions').$el.show();
      } else {
        this.getRegion('leadActions').$el.hide();
      }
    },

    prepareCopyPasteActionView: function (target) {
      var preState = Settings.get('actionButtonState') || {};
      this.actionsButtonView = this._getActionButtonsView();
      this.listenTo(this.actionsButtonView, 'action', this._onAction);
      this.getRegion('leadActions').show(this.actionsButtonView);

      // need to off events 
      this.boundOnSelectionChanged = _.bind(this.onTemplateSelectionChanged, this);
      this.boundSetStateToActionView = _.bind(this.setStateToActionButtonView, this);

      App.on(
        'template:selectionChanged',
        this.boundOnSelectionChanged
      );

      App.on(
        'copy-paste-state:set',
        this.boundSetStateToActionView
      );

      //clear buttons state
      this.setButtonsState(_.extend({
        unselect: false,
        copy: false,
        cut: false,
        paste: false
      }, preState), false);

      this.prepareCollection();

      this.params = {};
      this.pasteTarget = target;

      this.checkIfCopyPasteAvailabe();
    },

    prepareCollection: function () {
      this.selectedTemplatesCollection = Settings.get('selectedTemplatesCollection');
      if (!this.selectedTemplatesCollection) {
        this.selectedTemplatesCollection = new Backbone.Collection();
        Settings.set('selectedTemplatesCollection', this.selectedTemplatesCollection);
      }
    },

    onModelRemoved: function (model) {
      var params = Settings.get('copiedTemplatesParams'),
        removedSecId = model.get('secId'),
        secIds = params ? params.secIds : null,
        state = {},
        collection = this.selectedTemplatesCollection,
        copied;

      collection.remove(model);
      if (collection.length === 0) {
        _.extend(state, {
          unselect: false,
          copy: false,
          cut: false
        });
      }
      if (secIds) {
        copied = secIds.find(function (item) {
          return item.secId === removedSecId;
        });
        if (copied) {
          var index = secIds.indexOf(copied);
          secIds.splice(index, 1);
          if (secIds.length === 0) {
            _.extend(state, {
              paste: false
            });
          }
        }
      }
      if (Object.keys(state).length > 0) {
        this.setButtonsState(state, true);
      }
    },

    setButtonsState: function (state, forEach) {
      if (forEach) {
        App.trigger('copy-paste-state:set', state);
      } else {
        this.setStateToActionButtonView(state);
      }
      Settings.set('actionButtonState', state);
    },

    setStateToActionButtonView: function (state) {
      this.actionsButtonView.setButtonsState(state);
    },

    _getActionButtonsView: function () {
      var actions = ['copy', 'cut', 'paste', 'unselect'],
        actionsCollection = new Backbone.Collection();
      _.each(actions, function (action) {
        actionsCollection.push(availableActions[action]);
      });

      return new ActionButtonsView({
        collection: actionsCollection,
        dontShowIcons: true
      });
    },

    _onAction: function (action) {
      switch (action) {
        case 'copy':
          this.copyItems();
          break;
        case 'cut':
          this.cutItems();
          break;
        case 'paste':
          this.onBeforePaste();
          break;
        case 'unselect':
          this.unselectAll();
          break;
        default :
          throw 'Action is not supported';
      }
    },

    onTemplateSelectionChanged: function (selected, model, variations) {
      var clonedModel = model.clone();

      clonedModel.listenTo(model, 'remove', _.bind(this.onModelRemoved, this));
      //for proper group count and refresh
      clonedModel.set('isVariation', variations);
      clonedModel.set('modelToRefresh', Settings.get('currentGroupModel'));

      if (variations && this.pasteTarget === 'fromCategoty' ||
        !variations && this.pasteTarget === 'fromVariant') return;
      if (!selected) {
        this.selectedTemplatesCollection.add(clonedModel);
      } else {
        this.selectedTemplatesCollection.remove(clonedModel);
      }
      this.checkCopyCutPossibility();
    },

    checkCopyCutPossibility: function () {
      if (this.selectedTemplatesCollection.length > 0) {
        this.setButtonsState({
          unselect: true,
          copy: true,
          cut: true
        }, false);
      } else {
        this.setButtonsState({
          unselect: false,
          copy: false,
          cut: false
        }, false);
      }
    },

    getSelected: function () {
      var selectedTemplatesIds = [],
        collection = this.selectedTemplatesCollection.toJSON();

      _.each(collection, function (item) {
        selectedTemplatesIds.push({
          secId: item.secId,
          isVariation: item.isVariation,
          modelToRefresh: item.modelToRefresh
        });
      });
      return selectedTemplatesIds;
    },

    prepareCopyPastParams: function () {
      var groupModel = Settings.get('currentGroupModel'),
        params = Settings.get('copiedTemplatesParams'),
        targetSecId = this.pasteTarget === 'fromCategory' ?
          groupModel.get('secId') : Settings.get('lastVariationsParent'),
        copyPasteParams = {
          secIds: params.secIds,
          move: params.move,
          targetSecId: targetSecId,
          targetType: this.pasteTarget === 'fromCategory' ? 'TEMPLATE_GROUP' : 'TEMPLATE',
          targetCAccountSecId: Settings.get('currentCAccount').secId,
          targetEndUserId: groupModel.get('endUserId')
        };

      return copyPasteParams;
    },

    unselectAll: function () {
      this.selectedTemplatesCollection.reset();
      App.trigger('copy-paste:unselectAll:fromVariations');
      App.trigger('copy-paste:unselectAll:fromCategory');
      this.setButtonsState({
        unselect: false,
        copy: false,
        cut: false
      }, true);
    },

    copyItems: function () {
      this.params.secIds = this.getSelected();
      this.params.move = false;
      Settings.set('copiedTemplatesParams', this.params);
      this.setButtonsState({
        copy: true,
        cut: true,
        paste: true,
        unselect: true
      }, true);
    },

    cutItems: function () {
      this.params.secIds = this.getSelected();
      this.params.move = true;
      Settings.set('copiedTemplatesParams', this.params);
      this.setButtonsState({
        copy: true,
        cut: true,
        paste: true,
        unselect: true
      }, true);
    },

    onBeforePaste: function () {
      if (this.pasteTarget === 'fromVariations') {
        var confirmMsg = this.countTemplatesWithVariationsNumber() +
          ' ' + _.i18n('common.confirmCreateVariations');
        bootbox.dialog({
          title: _.i18n('common.confirm'),
          message: confirmMsg,
          buttons: {
            cancel: {
              label: _.i18n('common.cancel')
            },
            confirm: {
              label: _.i18n('common.confirm'),
              callback: _.bind(function () {
                this.pasteItems();
              }, this)
            }
          }
        });
      } else {
        this.pasteItems();
      }
    },

    countTemplatesWithVariationsNumber: function () {
      var number = 0;
      this.selectedTemplatesCollection.each(function (model) {
        number += 1 + model.get('variationsNumber');
      });
      return number;
    },

    pasteItems: function () {
      var params = this.prepareCopyPastParams(Settings.get('copiedTemplatesParams'));

      App.request('caccount:template-paste-to', params).done(_.bind(this.itemsWerePasted, this));
      this.checkIfMoved(params);
    },

    checkIfMoved: function (params) {
      if (params.move) {
        this.setButtonsState({
          unselect: false,
          copy: false,
          cut: false,
          paste: false
        }, true);
        this.selectedTemplatesCollection.reset();
      }
      this.params = params;
    },

    itemsWerePasted: function (templates) {
      var listToRefresh;
      if (_.isEmpty(templates)) return;

      if (this.params.move) {
        listToRefresh = this.getListToRefresh();
        if (listToRefresh && listToRefresh.length > 0 ||
          this.pasteTarget === 'fromVariations') {
          this.refreshGroupCount(listToRefresh);
          App.trigger('templates:itemsWerePasted', templates, listToRefresh, this.params.move);
        } else {
          App.trigger('templates:itemsWerePasted', templates, null, this.params.move);
        }
      } else {
        App.trigger('templates:itemsWerePasted', templates);
      }
    },

    getListToRefresh: function () {
      //list without current group
      var list = this.params.secIds,
        refreshList = _.uniq(_.pluck(list, 'modelToRefresh')),
        index = refreshList.indexOf(Settings.get('currentGroupModel'));

      if (index !== -1) {
        refreshList.splice(index, 1);
      }
      return refreshList;
    },

    refreshGroupCount: function (listToRefresh) {
      //if we paste from curent group to variation
      listToRefresh = listToRefresh.slice();
      listToRefresh.push(Settings.get('currentGroupModel'));
      _.each(listToRefresh, _.bind(function (groupModel) {
        var secIds = _.where(this.params.secIds, {modelToRefresh: groupModel}),
          noVariations = _.where(secIds, {isVariation: false}),
          count = noVariations ? noVariations.length : 0;
        //recount only with ordinary templates count
        groupModel.set('count', groupModel.get('count') - count);
      }, this));
    },

    offCopyPasteEvents: function () {
      App.off(
        'template:selectionChanged',
        this.boundOnSelectionChanged
      );

      App.off(
        'copy-paste-state:set',
        this.boundSetStateToActionView
      );
    }

  };

  module.exports = TemplatesCopyPasteMix;
});
