/**
 * Created by BFR on 01/08/2022.
 */
define('caccountSupplierFactorsV2View',['module',
    'app',
    'jquery',
    'marionette',
    'underscore',
    'caccountSupplierFactorV2View',
    'template!caccountSupplierFactorsV2Tpl',
    'entities/caccounts'
  ],
  function (module,
            App,
            $,
            Marionette,
            _,
            SupplierFactorV2View,
            viewTpl) {
    'use strict';

    module.exports = Marionette.CompositeView.extend({
      childView: SupplierFactorV2View,
      className: 'col-md-12 p-t-10',
      template: viewTpl,
      childViewContainer: '.supplier-factorsv2-view',

      ui: {
        txtSupplierParam1: '.txt-supplier-param1',
        txtSupplierParam2: '.txt-supplier-param2',
        txtSupplierParam3: '.txt-supplier-param3'
      },

      events: {
        'change @ui.txtSupplierParam1': 'onTxtChange',
        'change @ui.txtSupplierParam2': 'onTxtChange',
        'change @ui.txtSupplierParam3': 'onTxtChange'
      },

      onTxtChange: function () {
        this.model.set('param1', Number(this.ui.txtSupplierParam1.val()));
        this.model.set('param2', Number(this.ui.txtSupplierParam2.val()));
        this.model.set('param3', Number(this.ui.txtSupplierParam3.val()));
        this.saveChanges();
      },

      childEvents: {
        'item:change': function () {
          this.saveChanges();
        }
      },

      serializeData: function () {
        var templateData = this.model.toJSON();
        if (!templateData.param3) {
          templateData.param3 = null;
        }
        return templateData;
      },

      saveChanges: function () {
        $.when(App.request('caccount:update-factors', {
          param1: this.model.get('param1'),
          param2: this.model.get('param2'),
          param3: this.model.get('param3'),
          scales: this.collection.toJSON()
        }, this.options.supplierUid)).done(_.bind(function () {

        }, this));
      }
    });
  });
