/**
 * Created by OLD on 09/09/2017.
 */
define('orderKanbanItemCardView',[
  'module',
  'underscore',
  'marionette',
  'app',
  'settings',
  'moment',
  'imageUtils',
  'template!ordersKanbanItemCardTpl',
  'jquery',
  'graphicalRequestKanbanAssignPopupView',
  'rolesMixin',
  'orderUtils'
], function (
  module,
  _,
  Marionette,
  App,
  Settings,
  moment,
  imageUtils,
  viewTpl,
  $,
  PopupView,
  RolesMixin,
  OrderUtils
) {
  'use strict';

  var ItemCardView = Marionette.ItemView.extend({
    template: viewTpl,

    events: {
      'dblclick @ui.card': '_redirectToCardDetails',
      'click @ui.assignTo': '_onAssignTo',
      'click .enduserid-btn': '_onEndUserClick',
      'click @ui.selector': 'onSelectClick',
      'mouseover .js-card-item': 'onMouseOver',
      'click .icon-tracking': 'onTrackingClick',
      'click .action-required-customer-message': 'onActionRequiredClick',
      'click .action-required-internal-message': 'onActionRequiredClick',
      'click .action-required-ukr-message': 'onActionRequiredClick',
      'dblclick .nbr-order-same-customer': 'onNbrOrderSameCustomerClick'
    },

    ui: {
      card: '.js-card-item',
      assignTo: '.js-assign-to',
      selector: '.item-selector-circle',
      selectableItem: '.card-item'
    },

    onTrackingClick: function (e) {
      e.preventDefault();
      e.stopPropagation();
      App.navigate('orders/' + this.model.get('poi') + '/tracking', {trigger: true});
    },

    onNbrOrderSameCustomerClick: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var endUserFilterInput = $('#gs_endUserId');
      endUserFilterInput.val(this.model.get('endUserId'));
      endUserFilterInput.change();
    },

    onSelectClick: function (e) {
      e.preventDefault();
      e.stopPropagation();

      var $template = this.ui.selectableItem,
        selected = $template.hasClass('selected') ? true : false;

      if (selected) {
        $template.removeClass('selected');
      } else {
        $template.addClass('selected');
      }
    },

    onActionRequiredClick: function (e) {
      e.preventDefault();
      e.stopPropagation();
      var messagePath = $(e.currentTarget).hasClass('action-required-customer-message') ? '/customer_messages' : '/internal_messages';
      var offerId = $(e.currentTarget).attr('data-id');
      App.navigate('#orders/' + offerId + messagePath, {trigger: true});
    },

    initialize: function () {
      this.users = this.options.users;
      this.orders = this.options.orders;
      this.contextMenuStatuses = this.options.contextMenuStatuses;
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      if (templateData.date) {
        if (typeof (templateData.date) === 'object') {
          templateData.date = moment.unix(templateData.date.time / 1000);
        } else {
          templateData.date = moment.utc(templateData.date).local().format('DD/MM HH:mm');
        }
      }
      templateData.fileUrl = this._getImageLink(this.model.get('snapshotUid'));
      templateData.userImg = this._getUserImg();
      templateData.productName = this.model.get('productName') ? this.model.get('productName') : '';
      templateData.cardColor = this.model.get('cardColor') ? this.model.get('cardColor') :
        (templateData.hasComment ? 'ffffa0' : '');

      var now = new Date();
      var mDate;
      if (templateData.expShippingDate) {
        if (typeof (templateData.expShippingDate) === 'object') {
          mDate = moment.unix(templateData.expShippingDate.time / 1000);
        } else {
          mDate = moment(this.model.get('expShippingDate'));
        }
        templateData.expShippingDate = mDate.format('DD/MM/YYYY');

        if (mDate.diff(now, 'days') <= 1) {
          templateData.dateColor = '#ff0000';
        } else if (mDate.diff(now, 'days') <= 3) {
          templateData.dateColor = '#ff8300';
        } else {
          templateData.dateColor = '#333333';
        }
      }

      if (templateData.lastEmailResponse) {
        if (typeof (templateData.lastEmailResponse) === 'object') {
          mDate = moment.unix(templateData.lastEmailResponse.time / 1000);
        } else {
          mDate = moment(this.model.get('lastEmailResponse'));
        }
        var diff = mDate.diff(now, 'days');
        if (-diff === 0) {
          templateData.lastEmailResponse = 'today';
        } else if (-diff === 1) {
          templateData.lastEmailResponse = 'yesterday';
        } else {
          templateData.lastEmailResponse = -diff + ' days ago';
        }

        if (diff < -10) {
          templateData.dateLastEmailResponseColor = '#ff0000';
        } else if (diff < -4) {
          templateData.dateLastEmailResponseColor = '#ff8300';
        } else {
          templateData.dateLastEmailResponseColor = '#333333';
        }
      }

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        templateData.design2 = true;
      } else {
        templateData.design2 = false;
      }

      templateData.invoiceFileUidLink = '#orders/' + this.model.get('poi') + '/order_invoices';
      //templateData.invoiceFileUidLink = this._getDownloadLink(this.model.get('invoiceFileUid'));

      templateData.state = this.options.state;

      var price = this.model.get('price');
      var qty = this.model.get('quantity');
      templateData.quantityPrice = (qty ? (qty + 'pcs - ') : '') + (price ? price.toFixed(2) + '€' : '');

      if (templateData.validationDate) {
        if (typeof (templateData.validationDate) === 'object') {
          templateData.validationDate = moment.unix(templateData.validationDate.time / 1000);
        } else {
          templateData.validationDate = moment(templateData.validationDate).format('DD/MM');
        }
      }
      if (templateData.nbrPackSent > 0 && templateData.nbrPackSentToPrinter > 0) {
        templateData.deliveryIconResize = 'font-size:20px;';
      } else {
        templateData.deliveryIconResize = null;
      }
      if (!templateData.nbrPackSentToPrinter) {
        templateData.nbrPackSentToPrinter = 0;
      }
      return templateData;
    },

    onShow: function () {
      //$.material.init();
    },

    onMouseOver: function () {
      if (!Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        if (!this.menuLoaded) {
          this.menuLoaded = true;
          OrderUtils._initContextMenu(this);
        }
      }
    },

    _onEndUserClick: function () {
      App.navigate('customers/code/' + this.model.get('endUserId'), {trigger: true});
    },

    _getUserImg: function () {
      var user = this.model.get('refUser');
      if (user && user.logo) {
        return Settings.url('file', user.logo, {size: 'thumbnail'});
      } else if (user && user.logonId) {
        return imageUtils.getLogo(null, user.logonId, null, 'circle');
      } else {
        return null;
      }
    },

    _getDownloadLink: function (uid) {
      if (uid) {
        return Settings.url('file', uid, {cmds: 'on'});
      } else {
        return '';
      }
    },

    _getImageLink: function (snapshot) {
      if (!snapshot) {
        return '';
      } else {
        return Settings.url('file', snapshot, {size: 'thumbnail'});
      }
    },

    _redirectToCardDetails: function () {
      if (App.getCurrentRoute().indexOf('orders_kanban') !== -1) {
        App.navigate('orders/' + this.model.get('poi'), {trigger: true});
      }
    },

    _onAssignTo: function (e) {
      e.preventDefault();
      var orderId = $(e.currentTarget).attr('data-id'),
        assignedOrder = _.findWhere(this.orders, {poi: orderId}),
        userLogonId = assignedOrder.refUser ? assignedOrder.refUser.logonId : null;

      this._showAssignPopup(orderId, userLogonId);
    },

    _showAssignPopup: function (orderId, userLogonId) {
      var that = this,
        popupView = new PopupView(
          {users: this.users, assignedUser: userLogonId}
        );

      popupView.show({
        title: 'Assign To',
        className: 'vectorizationAssignTo',
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default',
            callback: function () {
              return true;
            }
          },
          success: {
            label: 'Assign',
            className: 'btn-primary',
            callback: function () {
              if (popupView.selectedUser) {
                if (popupView.selectedUser.get('secId')) {
                  return that._updateOrder(orderId,
                    popupView.selectedUser.get('secId')
                  );
                } else {
                  return that._unassignOrder(orderId);
                }
              } else {
                return true;
              }
            }
          }
        }
      });
    },

    _updateAssignUser: function (userName, userImg, orderId) {
      var ticket = _.findWhere(this.orders, {poi: orderId}),
        image;

      _.find($('.card-item'), function (card) {
        if ($(card).attr('data-id') === orderId) {
          if ($(card).find('assigned-user-logo').length && userImg && userName) {
            $(card).find('.assigned-user-logo')
              .css('background-image:url(' + userImg + ')');
          } else if (!$(card).find('assigned-user-logo').length && userImg && userName) {
            $(card).find('.assigned-face-block').html(
              '<div class="assigned-user-logo" style="background-image:url(' + userImg + ')"></div>'
            );
          } else if (!$(card).find('assigned-user-logo').length && !userImg && userName) {
            image = imageUtils.getLogo(userImg, userName, 'circle');
            $(card).find('.assigned-face-block').html(
              '<img class="logo-holder js-assigned-user-no-logo" src="' + image + '"/>'
            );
          } else if (!userName && !userImg) {
            image = '<i class="mdi-action-face-unlock"></i>';
            $(card).find('.assigned-face-block').html(image);
          }
        }
      });
      ticket.userImg = userImg;
      ticket.userName = userName;
    },

    _updateOrder: function (orderId, userSecId) {
      var that = this;

      $.when(App.request('order-kanban:assign-users', orderId, userSecId))
        .done(function (data) {
          var userName = data.refUser.logonId,
            userImg = data.refUser.logo ? Settings.url('file', data.refUser.logo, {size: 'thumbnail'}) : null;

          that._updateAssignUser(userName, userImg, orderId);
        });
    },

    _unassignOrder: function (orderId) {
      var that = this;

      $.when(App.request('order-kanban:unassigned-users', orderId))
        .done(function () {
          that._updateAssignUser(null, null, orderId);
        });
    },

    refresh: function (newStatus) {
      this.model.set('status', newStatus);
      this.triggerMethod('refreshBoard');
    }
  });

  module.exports = ItemCardView;
});

