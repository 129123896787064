define('popupPrintZoneView',[
  'module',
  'marionette',
  'app',
  'backbone',
  'underscore',
  'jquery',
  'colorpicker',
  'settings',
  'dialogFormView',
  'template!popupCreatePrintZoneTpl',
  'autocompleteView',
  'entities/product_rel'
], function (
  module,
  Marionette,
  App,
  Backbone,
  _,
  $,
  colorpicker,
  Settings,
  DialogFormView,
  popupCreatePrintZoneTpl,
  AutocompleteView
) {
  'use strict';

  module.exports = DialogFormView.extend({
    template: popupCreatePrintZoneTpl,

    ui: {
      colorBtn: '.colorpick',
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      inputMaxColors: '.input-max-colors',
      fixedFillingGroup: '.fixed-filling-items',
      bgOptionColor: '.bg-option-color'
    },

    regions: {
      printersRegion: '.printers-auto-complete',
      imprintTechnologyRegion: '.imprint-technology-region'
    },

    events: {
      'click @ui.colorBtn': 'onShowColorpicker',
      'click .js-cancel-popup': 'hide',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange',
      'click @ui.confirm': 'onConfirm',
      'click @ui.cancel': 'onCancel',
      'change input[type=radio]': 'onRadioFixedFillingChange'
    },

    modelEvents: {
      sync: 'onSave'
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;

      templateData.color = null;
      if (this.model.get('fixedColor')) {
        templateData.color = this.model.get('fixedColor').toHex();
      } else {
        templateData.color = '#000000';
      }

      templateData.optionCategories = [];

      _.each(this.model.get('refPrintArea').get('refProduct').get('productOptionCategories').models, function (poc) {
        templateData.optionCategories.push({
          secId: poc.get('secId'),
          name: poc.get('name').getTextInLocale(Settings.get('lang'))
        });
      });

      return templateData;
    },

    onShow: function () {
      $.material.init();
      this.showImprintList(Settings.get('currentCAccount').secId);
    },

    onRadioFixedFillingChange: function (e) {
      var $target = $(e.currentTarget);
      if ($target.is(':checked')) {
        switch ($target.val()) {
          case 'color':
            this.model.set('fixedFillingType', 'color');
            this.model.set('fixedProductOptionCategoryColor', null);
            this.model.set('fixedColor', null);
            break;
          case 'option-color':
            this.model.set('fixedFillingType', 'productOptionCategoryColor');
            this.model.set('fixedColor', null);
            this.selectOptionCategory();
            break;
        }
        this.render();
      }
    },

    onShowColorpicker: function (event) {
      var target = $(event.currentTarget),
        newColor;

      target.colorpicker();
      target.colorpicker('show');
      target.on('changeColor.colorpicker', _.bind(function (event) {
        newColor = event.color.toHex();
        this.model.set('fixedColor', App.request('products-rel:color:model', event.color.toRGB()));
      }, this));
    },

    selectOptionCategory: function () {
      var current = _.find(this.model.get('refPrintArea').get('refProduct').get('productOptionCategories').models, _.bind(function (m) {
        return m.get('secId') === this.ui.bgOptionColor.find('option:selected').val();
      }, this));
      this.model.set('fixedProductOptionCategoryColor', current);
    },

    showImprintList: function (printerSecId) {
      if (this.imprintTechnologyView) {
        this.imprintTechnologyView.destroy();
      }
      var autoCompleteOptions = this.getAutocompleteOptions('compuzz2', 'v2/imprinttechnologies/fromprinter/' + printerSecId,
        'codeName', 'codeName', 'codeName', _.bind(function (propertyIt, modelIt) {
          this.model.set('refImprintTechnology', modelIt.toJSON());
          this.activateRightElements(modelIt, true);
          this.ui.inputMaxColors.val(this.model.get('maxColors'));
        }, this));
      if (this.model.get('refImprintTechnology')) {
        autoCompleteOptions.value = this.model.get('refImprintTechnology').get('codeName');
        this.activateRightElements(this.model.get('refImprintTechnology'), false);
      }
      this.imprintTechnologyView = new AutocompleteView(autoCompleteOptions);
      this.getRegion('imprintTechnologyRegion').show(this.imprintTechnologyView);
    },

    activateRightElements: function (modelIt, initColors) {
      switch (modelIt.get('colorModeType')) {
        case 'fixedFilling':
          this.ui.inputMaxColors.prop('disabled', true);
          this.ui.fixedFillingGroup.prop('disabled', false);
          if (initColors) {
            this.model.set('maxColors', 1);
          }
          break;
        case 'quadri':
          this.ui.inputMaxColors.prop('disabled', true);
          this.ui.fixedFillingGroup.prop('disabled', true);
          if (initColors) {
            this.model.set('maxColors', 4);
          }
          break;
        case 'spot':
          this.ui.inputMaxColors.prop('disabled', false);
          this.ui.fixedFillingGroup.prop('disabled', true);
          if (initColors) {
            this.model.set('maxColors', modelIt.get('maxNumberOfColors'));
          }
          break;
      }
    },

    validate: function () {
      var errors = [];
      if (!this.model.get('refImprintTechnology')) {
        errors.push({
          name: 'refImprintTechnology'
        });
      }
      if (!_.isEmpty(errors)) {
        this.onError(errors);
        return false;
      }
      return true;
    },

    onConfirm: function () {
      if (!this.validate()) {
        return;
      }
      this.model.save();
    },

    onEscape: function () {
      if (!this.newItem) {
        this.model = null;
      }
    },

    onCancel: function () {
      if (!this.newItem) {
        this.model = null;
      }
      this.hide();
    },

    onSave: function () {
      this.triggerMethod('saved');
      this.model = null;
      this.hide();
    }
  });
});
