/**
 * Created by Matteo on 11/07/2015.
 */
define('sidebarShowView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'menuItemsUtils',
  'template!sidebarViewTpl',
  'jquery'
], function(
  module,
  Marionette,
  _,
  App,
  Settings,
  MenuItemUtils,
  viewTpl
) {
  'use strict';
  
  var SidebarView =  Marionette.ItemView.extend({
    template: viewTpl,

    triggers: {
      'click @ui.switcher' : 'sidebar:resize'
    },

    ui: {
      items: 'li[item-page-name]',
      toHide: '.to-hide',
      toShow: '.to-show',
      switcher: '.switcher',
      openItems: '.open-items'
    },

    events: {
      'click @ui.openItems' : 'onOpenSubMenuItems'
    },

    serializeData: function () {
      var templateData = {};
      templateData.menuItems = MenuItemUtils.getMenuItems();
      return templateData;
    },

    setActiveItem: function(name) {
      this.ui.items.removeClass('active');
      var $menuItem = this.$el.find('li[item-page-name=' + name  + ']');
      var $a = $menuItem.find('a').first();

      if($a.hasClass('open-items') === false){
          $menuItem.addClass('active' );
      }
      var firstMenuItemParent = $menuItem.parents('li[item-page-name]' ).first();

      $menuItem.find('a').first().focus();

      //check if the current menu is a sub menu and  if the parent menu is not already opened
      if($menuItem.data('type') === 'sub-menu' && ! firstMenuItemParent.find('.hide-sub' ).is( ':visible' )) {
        firstMenuItemParent.find('a.open-items' ).trigger('click',[true]);
      }
    },

    onResize: function() {
      if (this.ui.toHide.hasClass('hide')) {
        this.ui.toHide
          .show()
          .removeClass('hide');
        this.ui.toShow.css('display', 'none');
      } else {
        this.ui.toHide
          .hide()
          .addClass('hide');
        this.ui.toShow.css('display', 'block');
      }
    },

    onOpenSubMenuItems: function(e, isSubMenu) {
      var $target = this.$(e.currentTarget),
        li = $target.parent().attr('item-page-name' );

      //unselect the other opened menu
      var $openItems = this.$('a.open-items' ).not($target );
      $openItems.next().slideUp('slow', function(){
          $openItems.find( '.open-sub' ).show();
          $openItems.find( '.hide-sub' ).hide();
      });

      if(!isSubMenu) {
        this.setActiveItem(li);
      }
      //slide effect
      $target.next().slideDown('slow');
      this.showSubMenuItems($target);
    },

    showSubMenuItems: function($target) {
      if ( $target.next().is( ':visible' ) === true ) {
        $target.find( '.open-sub' ).hide();
        $target.find( '.hide-sub' ).show();
      } else {
        $target.find( '.open-sub' ).show();
        $target.find( '.hide-sub' ).hide();
      }
    }
  });

  module.exports = SidebarView;
});
