define('entities/admin/tasks',[
  'app',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'backboneRelational',
  'entities/product_translation'
], function (
  App,
  $,
  _,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  app.ns.Task = Backbone.RelationalModel.extend({
    defaults: {
      refCAccount: null,
      done: false,
      refCreator: null,
      taskType: 'COM_TEAM',
      comment: null,
      refUserpid: null,
      userGroup: 'COM_TEAM',
      dateDue: null,
      refCommandLine: null,
      refPhoneCall: null,
      refEndUser: null,
      refEndUserContact: null,
      refProduct: null,
      notifyOnDone: true,
      refTask: null
    },

    url: function () {
      return Settings.url('compuzz2', 'v2/tasks/' + this.get('secId'));
    },

    postUrl: function () {
      return Settings.url('compuzz2', 'v2/tasks');
    },

    fetch: function () {
      this.url = Settings.url('compuzz2', 'v2/tasks/' + this.get('secId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    save: function () {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    },

    getRefCommandLine: function () {
      if (this.get('refTask')) {
        return this.get('refTask').refCommandLine;
      } else {
        return this.get('refCommandLine');
      }
    },

    getRefEndUser: function () {
      if (this.get('refTask')) {
        return this.get('refTask').refEndUser;
      } else {
        return this.get('refEndUser');
      }
    },

    getRefProduct: function () {
      if (this.get('refTask')) {
        return this.get('refTask').refProduct;
      } else {
        return this.get('refProduct');
      }
    },

    idAttribute: 'secId',

    relations: []
  });

  var TaskCollection = Backbone.Collection.extend({
    url: function () {
      return Settings.url('compuzz2', 'v2/tasks');
    },
    model: app.ns.Task
  });

  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function (onlyMine) {
      if (onlyMine) {
        return 'v2/tasks/rowCount?onlyMine=true';
      } else {
        return 'v2/tasks/rowCount';
      }
    },

    getTasksUrl: function (onlyMine) {
      if (onlyMine) {
        return Settings.url('compuzz2', 'v2/tasks', {onlyMine: onlyMine});
      } else {
        return Settings.url('compuzz2', 'v2/tasks');
      }
    },

    getOpenTaskCount: function () {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'v2/tasks/rowCount', {
          done: 'false',
          forMe: true
        }),
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getOpenTasks: function () {
      var openTasks = new TaskCollection({}),
        defer = $.Deferred(),
        params = {
          first: 0,
          pageSize: 10,
          done: 'false',
          forMe: true
        };

      openTasks.url = Settings.url('compuzz2', 'v2/tasks/', params);
      openTasks.fetch({
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('tasks:model', function (taskJSON) {
    var tt;
    if (taskJSON) {
      tt = Backbone.Relational.store.find(app.ns.Task, taskJSON.secId);
    }
    if (tt) {
      return tt;
    } else {
      return new app.ns.Task(taskJSON);
    }
  });

  App.reqres.setHandler('tasks:row-count-url', function (onlyMine) {
    return API.getRowCountUrl(onlyMine);
  });

  App.reqres.setHandler('tasks:get-url', function (onlyMine) {
    return API.getTasksUrl(onlyMine);
  });

  App.reqres.setHandler('tasks:open:count', function () {
    return API.getOpenTaskCount();
  });

  App.reqres.setHandler('tasks:open:entities', function () {
    return API.getOpenTasks();
  });

});
