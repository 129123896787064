
define('template!detailMailTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-8" style="height: 800px;">\n\n    <div class="row" style="height:700px;">\n        <div class="loading-block js-loading-block">\n            <span class="loading-message js-loading-message label" style="font-min-size: 1em;"></span>\n        </div>\n        <div class="row message-subject">\n            <h2 style="float:left;margin-left:40px;">' +
((__t = ( subject )) == null ? '' : __t) +
'</h2>\n        </div>\n        <div class="col-xs-12">\n            <!-- forward email section-->\n            <div class="message-forward col-xs-12"\n                    style="width: 100%; border: 0; display:none;">\n                <div>\n                    <input class="forward-to-address" type="text" size="30" placeholder="to" style="margin: 20px 0 20px 0;"/>\n                    <button class="cancel-email  btn" style="float:right;margin-left:25px;">' +
((__t = ( _.i18n('common.cancel'))) == null ? '' : __t) +
'</button>\n                    <button class="send-email action-btn btn js-forward" style="float:right;margin-left:25px;" disabled>' +
((__t = ( _.i18n('common.send') )) == null ? '' : __t) +
'</button>\n                </div>\n                <div style="border: 1px solid black;margin-top: 20px;">Fwd: ' +
((__t = ( subject )) == null ? '' : __t) +
'</div>\n                <div class="to-forward-message" contenteditable="true" style="margin-top:20px;background-color:white;overflow:auto;height:600px;">\n                    <br>\n                ---------- Forwarded message ---------<br>\n                From: ' +
((__t = ( from )) == null ? '' : __t) +
'<br>\n                Date: ' +
((__t = ( date )) == null ? '' : __t) +
'<br>\n                Subject: ' +
((__t = ( subject )) == null ? '' : __t) +
'<br>\n                To: ' +
((__t = ( to )) == null ? '' : __t) +
'<br>\n                <br>\n                ' +
((__t = ( contentDoc )) == null ? '' : __t) +
'\n                </div>\n            </div>\n\n            <!-- reply email section-->\n            <div class="message-reply col-xs-12"\n                 style="width: 100%; border: 0; display:none;">\n                <div>\n                    <input class="reply-to-address" type="text" value="' +
((__t = (from )) == null ? '' : __t) +
'" size="35"  style="margin: 20px 0 20px 0;" readonly/>\n                    <button class="cancel-email  btn" style="float:right;margin-left:25px;">' +
((__t = ( _.i18n('common.cancel'))) == null ? '' : __t) +
'</button>\n                    <button class="send-email action-btn btn js-reply" style="float:right;margin-left:25px;">' +
((__t = ( _.i18n('common.send') )) == null ? '' : __t) +
'</button>\n                </div>\n                <div style="border: 1px solid black;margin-top: 20px;">Re: ' +
((__t = ( subject )) == null ? '' : __t) +
'</div>\n                <div class="to-reply-message" contenteditable="true" style="margin-top:20px;background-color:white;overflow:auto;">\n                    <br>\n                    On ' +
((__t = ( date )) == null ? '' : __t) +
' at ' +
((__t = ( time )) == null ? '' : __t) +
', ' +
((__t = ( from )) == null ? '' : __t) +
' wrote :<br>\n                    ' +
((__t = ( contentDoc )) == null ? '' : __t) +
'\n                </div>\n            </div>\n\n            <!-- email display section -->\n            <div style="overflow-y:auto;height:600px;">\n            <iframe class="message-iframe col-xs-12"\n                    style="width: 100%; border: 0; display: block;overflow-y:auto;"  contenteditable="true">\n                ' +
((__t = ( contentDoc )) == null ? '' : __t) +
'\n            </iframe>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="row message-subject">\n            <button class="mail-forward btn btn-warning" style="float:right;margin-right:50px;">' +
((__t = ( _.i18n('call.forward'))) == null ? '' : __t) +
'</button>\n            <button class="mail-reply btn btn-warning" style="float:right;">' +
((__t = ( _.i18n('common.reply'))) == null ? '' : __t) +
'</button>\n        </div>\n    </div>\n</div>\n\n\n\n\n<div class="col-xs-4 account-orders" style="height: 680px;overflow-y: auto;overflow-x:hidden;float:none;">\n    <div>\n        <h2 style="text-align:center;">' +
((__t = ( _.i18n('offers.last10') )) == null ? '' : __t) +
'</h2>\n        ';
 if (offers && offers.length > 0) { ;
__p += '\n        ';
 _.each(offers, function (offer) { ;
__p += '\n        <div style="margin-bottom:20px;">\n            <span class = "offer-detail" rowid="' +
((__t = ( offer.purchaseOrderId)) == null ? '' : __t) +
'" style="cursor: pointer;"><b>' +
((__t = ( offer.purchaseOrderId )) == null ? '' : __t) +
'</b></span><br>\n            <div>' +
((__t = ( offer.productCode )) == null ? '' : __t) +
' - ' +
((__t = ( offer.productName )) == null ? '' : __t) +
'</div>\n            <div>' +
((__t = ( offer.status )) == null ? '' : __t) +
'</div>\n            <div>' +
((__t = ( offer.createDate )) == null ? '' : __t) +
'</div>\n        </div>\n        ';
 }); ;
__p += '\n        ';
 } else { ;
__p += '\n        <h4>' +
((__t = ( _.i18n('offers.noOffers') )) == null ? '' : __t) +
'</h4>\n        ';
 } ;
__p += '\n    </div>\n    <div>\n        <h2 style="text-align:center;">' +
((__t = ( _.i18n('orders.last10') )) == null ? '' : __t) +
'</h2>\n        ';
 if (orders && orders.length > 0) { ;
__p += '\n        ';
 _.each(orders, function (order) { ;
__p += '\n        <div style="margin-bottom:20px;">\n            <span class = "order-detail" rowid="' +
((__t = ( order.purchaseOrderId)) == null ? '' : __t) +
'" style="cursor: pointer;"><b>' +
((__t = ( order.purchaseOrderId )) == null ? '' : __t) +
'</b></span><br>\n            <div>' +
((__t = ( order.productCode )) == null ? '' : __t) +
' - ' +
((__t = ( order.productName )) == null ? '' : __t) +
'</div>\n            <div>' +
((__t = ( order.status )) == null ? '' : __t) +
'</div>\n            <div>' +
((__t = ( order.createDate )) == null ? '' : __t) +
'</div>\n        </div>\n        ';
 }); ;
__p += '\n        ';
 } else { ;
__p += '\n        <h4>' +
((__t = ( _.i18n('orders.noOrders') )) == null ? '' : __t) +
'</h4>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';

}
return __p
};});

