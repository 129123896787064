
define('template!customerCommentSidebarViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="right-side-bar" style="overflow-y:auto;height:100%">\n\n  <div class="vbox">\n    <div class="closed-customer-comments" style="display:none">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
' ' +
((__t = (nbrOfComments)) == null ? '' : __t) +
'</div>\n    <div class="scrollable" style="margin-top: 90px">\n      <h2 style="text-align:center">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</h2>\n      <textarea class="new-comment js-new-comment" style="width:95%;resize:none; height: 105px;"></textarea>\n      <button disabled class="btn btn-primary disabled-grey js-cancel"\n              style="padding: 5px 10px 5px 10px;">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'\n      </button>\n      <button disabled class="btn btn-primary disabled-grey js-save"\n              style="padding: 5px 10px 5px 10px;">' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'\n      </button>\n      <div class="sidebar-scroll-container resize-on-grow"\n           style="margin-top: 290px;margin-bottom: 165px;overflow-y:auto;" ;>\n        ';
 _.each(comments, function(comment) {;
__p += '\n        <div><textarea disabled style="width:85%;float:left;min-height:55px;overflow:hidden;resize:none"\n                       data-id="' +
((__t = ( comment.secId )) == null ? '' : __t) +
'">' +
((__t = ( comment.comment )) == null ? '' : __t) +
'</textarea>\n          <div style="float:right;width:15%;">\n            <span class="mdi-action-delete cell-delete clickable js-delete" data-id="' +
((__t = ( comment.secId )) == null ? '' : __t) +
'"></span>\n            <span class="mdi-content-create clickable js-change" data-id="' +
((__t = ( comment.secId )) == null ? '' : __t) +
'"></span>\n          </div>\n        </div>\n        <span class="small-light-compuzz f-l">' +
((__t = (comment.user)) == null ? '' : __t) +
'</span><span class="small-light-compuzz m-l-15">' +
((__t = (comment.date)) == null ? '' : __t) +
'</span>\n        ';
 }); ;
__p += '\n      </div>\n    </div>\n  </div>\n  <div class="cust-com-footer-switcher">\n    <i class="mdi-navigation-chevron-left icon cust-com-switcher  cust-com-to-show"></i>\n    <i class="mdi-navigation-chevron-right icon  cust-com-switcher  cust-com-to-hide"></i>\n  </div>\n</div>\n';

}
return __p
};});

