
define('template!editPimPrintZoneTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n  <div class="col-xs-1 imppCode">' +
((__t = ( imppCode )) == null ? '' : __t) +
'</div>\n  <div class="col-xs-4 name">' +
((__t = ( name )) == null ? '' : __t);
 if (maxColors) {
    ;
__p += ' (' +
((__t = ( maxColors )) == null ? '' : __t) +
' C) ';
 } else if (digital) { ;
__p += ' (DIG) ';
 } else if (eng) { ;
__p += ' (ENG) ';
 } ;
__p += '\n  </div>\n  <div class="col-xs-2 size">' +
((__t = ( width + 'x' + height + 'mm' )) == null ? '' : __t) +
'</div>\n  <div class="col-xs-2 setupCode">' +
((__t = ( setupCode )) == null ? '' : __t) +
'</div>\n  <div class="col-xs-1 unitCode">' +
((__t = ( unitCode )) == null ? '' : __t) +
'</div>\n  ';

  var css = '';
  if(allowUpload) {
  css = 'context-menu-pz';
  }
  ;
__p += '\n  <div class="col-xs-2 image">\n    ';
 if(imageUrl != '') { ;
__p += '\n    <img data-link="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'" class="jq-picture js-image-cell ' +
((__t = ( css )) == null ? '' : __t) +
'" src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'"/>\n    ';
 } ;
__p += '\n  </div>\n</div>\n';

}
return __p
};});

