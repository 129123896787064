
define('template!caccountAttachsItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="main text-center">\n  <div class="image-holder-container _texture-bg js-image-holder-container">\n    <div class="attached-image-container">\n      <div data-name="' +
((__t = ( fileLink )) == null ? '' : __t) +
'" class="js-image-cell attachment-in-m"></div>\n    </div>\n  </div>\n</div>\n<div class="template-item-editor js-template-item-editor h-60 b-25">\n  <i class="mdi-image-color-lens icon js-modify-icon"></i>\n  <i class="mdi-action-delete icon js-remove-icon"></i>\n  <a id="download-attachment" mimetype="application/octet-stream" href="' +
((__t = ( downloadLink )) == null ? '' : __t) +
'" download>\n    <label for="download-attachment">\n      <i class="mdi-file-file-download icon js-download-icon"></i>\n    </label>\n  </a>\n  <i class="fa fa-search-plus icon js-zoom-icon" aria-hidden="true"></i>\n</div>';

}
return __p
};});

