/**
 * Created by RKL on 18/08/2015.
 */
define('elementsCollectionView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'stateMessages',
  'customerLogosImagesItemView',
  'customerLogosImagesNewItemView',
  'template!customerLogosImagesCollectionTpl'
], function (
  module,
  Marionette,
  _,
  App,
  StateMessages,
  ItemView,
  NewItemView,
  templatesTpl
) {
  'use strict';

  var CollectionView = Marionette.CompositeView.extend({
    template: templatesTpl,
    childViewContainer: '.elements-container',

    triggers: {
      'click @ui.returnButton' : 'onPressReturn'
    },

    ui: {
      returnButton: '.js-return-button',
      scrollContainer: '.scroll-container',
      scrollBlock: '.lazy-load-block'
    },

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },
    
    onShow: function() {
      this.ui.scrollContainer.on('scroll', _.bind(this.onScroll, this));
      this.trigger('showView');
    },

    onScroll: function() {
      this.trigger('onScroll');
    },

    getChildView: function(item) {
      if (item.get('secId') !== null) {
        return ItemView;
      } else {
        return NewItemView;
      }
    },

    childViewOptions: function() {
      return {
        data: this.options.data
      };
    }
  });

  module.exports = CollectionView;
});
