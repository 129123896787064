define('productTemplatesTabView',[
  'app',
  'module',
  'underscore',
  'attachmentsView'
], function (
  App,
  Module,
  _,
  AttachmentsView
) {
  'use strict';

  Module.exports = AttachmentsView.extend({
    className: 'customers-detail-attachments-view attachments-view',
    setConfig() {
      return {
        canCopy: false
      };
    },

    fetch: function (first, pageSize) {
      return this.model.getTemplates(first, pageSize, this.options.loadFromAllLevel);
    },

    uploadItem: function (file) {
      return this.model.uploadTemplate(file);
    },

    deleteItem(template) {
      return this.model.deleteTemplate(template);
    },

    editItem: function (attachment) {
      App.navigate('attachments/attachments/' + this.model.get('secId') + '/' + attachment.get('secId') + '/caccount', {trigger: true});
    }
  });
});

