define('orderTabsLayoutView',[
  'module',
  'tabsBaseView',
  'template!orderTabsHeaderTpl',
  'autocompleteView',
  'jquery',
  'app',
  'envHandler',
  'backToListBehavior',
  'tasksCreatorBehavior',
  'marionette',
  'underscore',
  'backbone',
  'jqgridActionsButtonFormatterActions',
  'jqgridActionsButtonFormatterView',
  'logotoolUtils',
  'settings',
  'imageUtils',
  'compuzzProUtils',
  'rolesMixin',
  'cAccountInfoController',
  'userAssignView',
  'entities/orders',
  'entities/offers_kanban'
], function (
  module,
  TabsBaseView,
  headerTpl,
  AutocompleteView,
  $,
  App,
  EnvironmentHandler,
  BackToListBehavior,
  TasksCreatorBehavior,
  Marionette,
  _,
  Backbone,
  availableActions,
  ActionButtonsView,
  LogoToolUtils,
  Settings,
  imageUtils,
  CompuzzProUtils,
  RolesMixin,
  SupplierInfoController,
  UserAssign
) {
  'use strict';

  var HeaderView = Marionette.LayoutView.extend({
    template: headerTpl,

    inputSelector: '.js-statuses-region input',

    events: {
      'change .js-upload-proofsheet': 'onUploadCustomProofsheet',
      'change .js-upload-export': 'onUploadCustomExport',
      'click @ui.linkPhoneButton': 'onLinkPhoneButton'
    },

    regions: {
      statuses: '.js-statuses-region',
      actions: '.js-action-buttons-region',
      supplierInfo: '.supplier-info-region',
      regionUserAssign: '.region-user-assign'
    },

    ui: {
      warningPrice: '.warning-price',
      linkPhoneButton: '.js-link-phone-to-customer'
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.status = this.getStatusLabel();
      templateData.currentPlivoCall = Settings.getFromMemory('plivoCurrentCallNumber');
      templateData.webshopUrl = null;
      templateData.webshopProofSheet = null;
      templateData.productHasDifferentConf = this.options.productHasDifferentConf;

      templateData.stock = null;
      templateData.outOfStock = false;
      this.options.product.computeStock(templateData, templateData.productCode);

      templateData.supplierProductCode = this.options.product.get('baseCode');

      templateData.design2 = (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) ? true : false;

      if (!templateData.design2) {
        templateData.productCode = this.options.product.get('cCode');
      }

      templateData.minimumOrderQuantity = this.options.product.get('minimumPacksPerOrder') ?
        this.options.product.get('minimumPacksPerOrder') : 1;
      if (Settings.get('webshopUrl')) {
        var locale;
        if (this.options.customer.locales && (this.options.customer.locales).length >= 2) {
          locale = (this.options.customer.locales).substring(0, 2).toLowerCase();
        } else {
          locale = 'fr';
        }
        templateData.webshopUrl = Settings.get('webshopUrl') + '/' + locale +
          '/account/' + this.options.customer.secId;
        templateData.webshopProofSheet = Settings.get('webshopUrl') + '/' + locale +
          '/proofsheet/draft/' + templateData.id;
      }
      templateData.emailLink = 'https://mail.google.com/mail/u/commercial@zaprinta.com/#search/' + this.options.customer.email;
      if (this.model.get('invoiceFileUid')) {
        if (this.model.get('invoiceFileUid').indexOf(',') !== -1) {
          templateData.invoiceFileUidLink = '#orders/' + this.model.get('purchaseOrderId') + '/order_invoices';
        } else {
          templateData.invoiceFileUidLink = this._getDownloadLink(this.model.get('invoiceFileUid'));
        }
      } else {
        templateData.invoiceFileUidLink = '';
      }

      return templateData;
    },

    getStatusLabel: function () {
      var status = this.options.statuses.find(_.bind(function (item) {
        return item.get('statusConst') === this.model.get('status');
      }, this));

      if (!status && this.model.get('status')) {
        status = _.i18n('commandstatus.' + this.model.get('status').toLowerCase());
      } else if (status) {
        status = status.get('status');
      }
      return status;
    },

    _getDownloadLink: function (uid) {
      if (uid) {
        return Settings.url('file', uid, {cmds: 'on'});
      } else {
        return '';
      }
    },

    onUploadCustomProofsheet: function (e) {
      $('.loading-block').show();
      var target = e.target,
        fileInfo;

      if (e && e.target && e.target.files && e.target.files[0]) {
        fileInfo = imageUtils.getFileInfo(target, 0);

        if (fileInfo) {
          App.request('order:upload-custom-proofsheet', this.model.get('purchaseOrderId'), fileInfo).then(function () {
            CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
            $('.loading-block').hide();
          });
        }
      }
    },

    onUploadCustomExport: function (e) {
      $('.loading-block').show();
      var target = e.target,
        fileInfo;

      if (e && e.target && e.target.files && e.target.files[0]) {
        fileInfo = imageUtils.getFileInfo(target, 0);

        if (fileInfo) {
          App.request('order:upload-custom-export', this.model.get('purchaseOrderId'), fileInfo).then(function () {
            CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
            $('.loading-block').hide();
          });
        }
      }
    },

    behaviors: function () {
      if (this.options.model.get('commandType') === 'offer') {
        this.backToListUrl = 'customers/' + this.options.customer.secId + '/offers';
      } else {
        this.backToListUrl = 'customers/' + this.options.customer.secId + '/orders';
      }
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: this.backToListUrl
        },
        CreateTask: {
          behaviorClass: TasksCreatorBehavior,
          refCommandLine: this.options.model
        }
      };
    },

    initialize: function () {
      App.on('showHidePriceWarning', this.showHidePriceWarning, this);
    },

    showHidePriceWarning: function (price1, price2) {
      if (price1 !== null && price2 !== null) {
        if (price1 !== price2) {
          this.ui.warningPrice.show();
        }
        if (price1 > price2) {
          this.ui.warningPrice.css('color', 'red');
        } else {
          this.ui.warningPrice.css('color', 'black');
        }
      } else {
        this.ui.warningPrice.hide();
      }
    },

    destroy: function () {
      App.off('showHidePriceWarning');
    },

    onShow: function () {
      var actionsButtonView = this._getActionButtonsView();

      this.listenTo(actionsButtonView, 'action', this._onAction);

      this.getRegion('actions').show(actionsButtonView);
      SupplierInfoController.show(this.getRegion('supplierInfo'), this.options.product.get('manufacturerCode'));

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_PRICE]) {
        var savedPrice = this.model.get('savedPrice');
        if (savedPrice && savedPrice.total) {
          var requestRs = this.model.getPrice(true, false);
          $.when(requestRs).done(_.bind(function (rsPrice) {
            this.showHidePriceWarning(Number(rsPrice.total), Number(savedPrice.total));
          }, this));
        }
      }

      this.regionUserAssign = new UserAssign({
        user: this.model.get('refUser')
      });
      this.listenTo(this.regionUserAssign, 'selected-user-modified', this._onUserAssignChange);
      this.listenTo(this.regionUserAssign, 'unassign-user', this._onUserUnAssign);
      this.getRegion('regionUserAssign').show(this.regionUserAssign);
    },

    _onUserAssignChange: function (userSecId) {
      $.when(App.request('offer-kanban:assign-users', this.model.get('purchaseOrderId'), userSecId))
        .done(_.bind(function (data) {
          if (data) {
            this.regionUserAssign.refresh(data.refUser);
          } else {
            this.regionUserAssign.refresh();
          }
        }, this));
    },

    _onUserUnAssign: function () {
      $.when(App.request('offer-kanban:unassigned-users', this.model.get('purchaseOrderId')))
        .done(_.bind(function (data) {
          if (data) {
            this.regionUserAssign.refresh(data.refUser);
          } else {
            this.regionUserAssign.refresh();
          }
        }, this));
    },

    onOrderStatusChange: function () {
      this.$el.find('#order-header-status').text(this.getStatusLabel());
    },

    _getActionButtonsView: function () {
      var actions = ['edit', 'export', 'productSheet', 'duplicate', 'uploadCustomProofsheet', 'uploadCustomExport'],
        actionsCollection = new Backbone.Collection();

      if (!Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        actions.push('toInvoice');
        if (this.model.get('pim')) {
          actions.push('convertPimToCompuzz');
        }
      }

      _.each(actions, function (action) {
        actionsCollection.push(availableActions[action]);
      });

      return new ActionButtonsView({
        collection: actionsCollection,
        dontShowIcons: true
      });
    },

    _onAction: function (action) {
      switch (action) {
        case 'edit':
          App.request('session:create-session-from-poi', {webServiceUrl: 'compuzz', returnUrl: location.href},
            this.model.get('purchaseOrderId')).done(_.bind(function (session) {
            window.location.href = EnvironmentHandler.getValue(Settings.get('environment'), 'editOrderUrl') +
              '#customization/oloSessionId/' +
              session.sessionId + '/productCode/' +
              this.model.get('productCode') +
              '/purchaseOrderId/' + this.model.get('purchaseOrderId');
          }, this));
          break;
        case 'export':
          var pdfUrl = Settings.url('pdf', '', {
            poi: this.model.get('purchaseOrderId'),
            docType: 'pzi'
          }, true);
          window.open(pdfUrl, '_self');
          break;
        case 'productSheet':
          var pdfRUrl = Settings.url('pdf', '', {
            poi: this.model.get('purchaseOrderId'),
            docType: 'prs'
          }, true);
          window.open(pdfRUrl, '_self');
          break;
        case 'duplicate':
          this.trigger('duplicateOrder');
          break;
        case 'uploadCustomProofsheet':
          this.$('#order-proofsheet-input').trigger('click');
          break;
        case 'uploadCustomExport':
          this.$('#order-export-input').trigger('click');
          break;
        case 'toInvoice':
          this.trigger('toInvoice');
          break;
        case 'convertPimToCompuzz':
          this.trigger('convertPimToCompuzz');
          break;
        default :
          throw 'Action is not supported';
      }
    },
    onLinkPhoneButton: function (e) {
      console.log($(e.target).attr('data-phoneNumber'));
      var tel = $(e.target).attr('data-phoneNumber'), endUserSecId = this.options.customer.secId;
      $.when(App.request('customer:update-phone', endUserSecId, tel)).done(function () {
        $('.js-link-phone-to-customer').hide();
        Settings.setToMemory('plivoCurrentCallNumber', null);
      });
    }
  });

  var OrderTabsLayoutView = TabsBaseView.extend({

    events: function () {
      return _.extend({
        'click .p-code': 'onUserCodeClick'
      }, this.baseEvents);
    },

    onUserCodeClick: function () {
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_CUSTOMERS]) {
        App.navigate('customers/' + this.options.customer.secId, {trigger: true});
      }
    },

    onShowTabsBase: function () {
      this.getRegion('header').show(new HeaderView({
        model: this.model,
        customer: this.options.customer,
        statuses: this.options.statuses,
        product: this.options.product,
        productHasDifferentConf: this.options.productHasDifferentConf
      }));
    },

    onNavigate: function (tabName) {
      var currentRoute = App.getCurrentRoute(),
        routeSplitted = currentRoute.split('/');

      if (_.indexOf(routeSplitted, 'details') !== -1 ||
        _.indexOf(routeSplitted, 'composition') !== -1 ||
        _.indexOf(routeSplitted, 'price') !== -1 ||
        _.indexOf(routeSplitted, 'attachments') !== -1 ||
        _.indexOf(routeSplitted, 'tracking') !== -1 ||
        _.indexOf(routeSplitted, 'internal_messages') !== -1 ||
        _.indexOf(routeSplitted, 'all_customer_messages') !== -1 ||
        _.indexOf(routeSplitted, 'customer_messages') !== -1 ||
        _.indexOf(routeSplitted, 'order_proofsheet_history') !== -1 ||
        _.indexOf(routeSplitted, 'order_invoices') !== -1) {
        routeSplitted.pop();
        routeSplitted.push(tabName);
      } else {
        routeSplitted.push(tabName);
      }
      App.navigate(routeSplitted.join('/'), {trigger: true});
    }
  });

  module.exports = OrderTabsLayoutView;
});

