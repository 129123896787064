/**
 * Created by RKL on 13/08/2015.
 */
define('orderInvoiceTabView',[
  'module',
  'jquery',
  'marionette',
  'underscore',
  'app',
  'settings',
  'dateUtils',
  'jqgridView',
  'moment',
  'template!orderInvoiceTabTpl',
  'entities/orders'
], function (
  module,
  $,
  Marionette,
  _,
  App,
  Settings,
  dateConverter,
  JqGridView,
  moment,
  viewTpl
) {
  'use strict';

  var CAccountInvoicesTabView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'orderInvoicesFilters',

    triggers: {},

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-invoices',
        jqGridPager: '#jq-grid-pager-invoices'
      }, this.gridUi);
    },

    events: function () {
      return _.extend({}, this.gridEvents);
    },

    gridOptions: function (data) {
      var that = this,
        defaultsOptions, options;

      this.sidx = 'creatDate';
      this.sord = 'desc';
      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: _.i18n('common.date'),
            name: 'invoiceDate',
            classes: 'invoices-link',
            formatter: this.dateFormatter,
            search: false,
            index: 'invoiceDate',
            width: 60
          }, {
            label: _.i18n('invoice.number'),
            name: 'invoiceNumber',
            classes: 'invoices-link',
            formatter: this.invoiceNumberFormatter,
            search: false,
            index: 'invoiceNumber',
            width: 60
          }, {
            label: _.i18n('common.type'),
            name: 'invoiceType',
            classes: 'invoices-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'invoiceType',
            width: 60
          }, {
            label: _.i18n('common.amount'),
            name: 'amount',
            classes: 'invoices-link',
            formatter: this.invoiceAmountFormatter,
            search: false,
            index: 'amount',
            width: 60
          }, {
            label: _.i18n('invoices.download'),
            name: 'fileUid',
            align: 'left',
            classes: 'invoices-link',
            formatter: this.downloadFormater,
            search: false,
            index: 'fileUid',
            width: 60
          }, {
            label: _.i18n('invoices.creditNote'),
            name: 'refCreditNote',
            align: 'left',
            classes: 'credit-note-link',
            formatter: this.creditNoteFormater,
            search: false,
            index: 'refCreditNote',
            width: 60
          }
        ],

        onSelectRow: _.bind(function (rowid, bool, e) {
          if ($(e.target).hasClass('credit-note-link') && e.type === 'click') {
            $('.loading-block').show();
            $.when(App.request('order-invoices:credit-note', rowid)).done(_.bind(function () {
              this.updatePagination('reloadGrid');
              $('.loading-block').hide();
            }, this));
          }
        }, this),

        serializeGridData: function (postData) {
          var date;

          if (postData.creatDate) {
            date = new Date(dateConverter.toMDYFormat(postData.creatDate));
            postData.dateFrom = date.getTime();
            postData.dateTo = Date.now();
          } else if (!that.sorting) {
            delete postData.dateFrom;
            delete postData.dateTo;
          }
          that.sorting = false;

          if (postData.type === that.allString) {
            delete postData.type;
          }
          postData.sortField = postData.sidx;
          defaultsOptions.serializeGridData.apply(this, arguments);

          return postData;
        }
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    creditNoteFormater: function (cellValue, options, model) {
      if (!cellValue && model.invoiceType === 'invoice') {
        var link = '<button class="btn btn-primary download-btn-text btn-sm credit-note-link" target="_blank" >' +
          '<i class="mdi-action-get-app credit-note-link"></i>' +
          '<span class="credit-note-link">' + _.i18n('common.creditNote') + '</span>' +
          '</button>';
        return link;
      } else {
        return '';
      }
    },

    downloadFormater: function (cellValue, options, model) {
      var link = '<a class="btn btn-primary download-btn-text btn-sm" target="_blank" href="' + Settings.url('dl', 'proforma', {
          invoiceSecId: model.secId
        }) + '">' +
        '<i class="mdi-action-get-app"></i>' +
        '<span>' + _.i18n('common.download') + '</span>' +
        '</a>';
      return link;
    },

    invoiceNumberFormatter: function (cellValue, options, model) {
      var year = moment(model.invoiceDate).year() - 2000;
      while (cellValue.toString().length < ((year < 22) ? 4 : 5)) {
        cellValue = '0' + cellValue;
      }
      cellValue = year.toString() + cellValue;

      return '<span class="cell-default" data-row-id="' +
        options.rowId + '">' + cellValue + '</span>';
    },

    invoiceAmountFormatter: function (cellValue, options, model) {
      var amount = 0;
      _.each(model.endUserInvoiceItems, function (i) {
        amount += Number(i.price);
        if (i.addCost) {
          amount += Number(i.addCost);
        }
      });

      return '<span class="cell-default" data-row-id="' +
        options.rowId + '">' + amount.toFixed(2) + '€</span>';
    },

    onGridFilterChange: function () {
      var that = this,
        filters = {},
        value, date;

      _.each(this.colModel, function (column) {
        if (column.search) {
          if (column.stype === 'select') {
            value = that.$('select[name="' + column.index + '"]').val();
            filters[column.index] = value === that.allString ? '' : value;
          } else if (column.sorttype === 'date') {
            value = that.$('input[name="' + column.index + '"]').val();
            date = new Date(dateConverter.toMDYFormat(value));
            filters.dateFrom = date.getTime();
            filters.dateTo = Date.now();
          } else {
            value = that.$('input[name="' + column.index + '"]').val();
            filters[column.index] = value;
          }
        }
      });

      this.filters = filters;
      this.filterChange = true;
      this.updatePagination(true);
    }

  });

  module.exports = CAccountInvoicesTabView;
});
