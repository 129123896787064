/**
 * Created by RKL on 13/08/2015.
 */
define('leadProfileTabController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'leadProfileView',
  'emptyView',
  'settings',
  'entities/price/price_billing_table'
], function(
  module,
  _,
  Backbone,
  App,
  View,
  EmptyView,
  Settings
) {
  'use strict';

  var LeadsProfileTabController = {
    showTab: function(data) {
      if (!data.tab) {
        data.tab = 'profile';
      }
      this.showLeadInfo(data);
    },

    showLeadInfo: function(data) {
      var that = this,
        emptyView = new EmptyView(),
        fetchCountries, fetchLangs, fetchPriceLists;

      this.model = App.request('customers:entity', data.id);
      this.tab = data.tab;
      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(emptyView);

      this.data = data;
      if (!Settings.get('countries') || !Settings.get('languages') || !Settings.get('priceLists')) {
        fetchCountries = App.request('caccount:countries');
        fetchLangs = App.request('caccount:languages');
        fetchPriceLists = App.request('pricelist:get-collection-url');

        $.when(fetchCountries, fetchLangs, fetchPriceLists).done(function(countries, langs, priceLists) {
          Settings.set('countries', countries.toJSON());
          Settings.set('languages', langs.toJSON());
          priceLists.unshift({
            code: null,
            name: _.i18n('common.none')
          });
          Settings.set('priceLists', priceLists);
          if(data.id === 'new') {
            that.createView();
          } else {
            that.model.fetch({
              success: function() {
                that.createView();
              }
            });
          }
        });
      } else {
        if(data.id === 'new') {
          that.createView();
        } else {
          that.model.fetch({
            success: function() {
              that.createView();
            }
          });
        }
      }

    },

    createView: function() {
      var countries = Settings.get('countries'),
          priceLists = Settings.get('priceLists'),
        view;

      view = new View({
        model: this.model,
        countries: countries,
        isNew: this.data.id === 'new',
        priceLists: priceLists
      });
      view.tempModel = new Backbone.Model(this.model.toJSON());
      this.view = view;

      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(view);
    }
  };

  module.exports = LeadsProfileTabController;
});
