/**
 * Created by RKL on 15/09/2015.
 */
define('groupsController',[
  'module',
  'backbone',
  'underscore',
  'app',
  'bootbox',
  'groupsLayoutView',
  'groupsCollectionView',
  'caccountObjectNamesAddView',
  'emptyView',
  'settings',
  'entities/customers/design_elements'
], function(
  module,
  Backbone,
  _,
  App,
  bootbox,
  LayoutView,
  GroupsView,
  PopupView
) {
  'use strict';

  var Controller = {

    subscribeToEvents: function() {
      App.off('folder-groups:show-group');
      App.on('folder-groups:show-group', _.bind(this.onShowGroup, this));

      App.off('folder-groups:get-count');
      App.on('folder-groups:get-count', _.bind(this.getGroupCount, this));

      App.off('folder-groups:add-group');
      App.on('folder-groups:add-group', _.bind(this.onAddGroup, this));

      App.off('folder-groups:remove-group');
      App.on('folder-groups:remove-group', _.bind(this.onRemoveGroup, this));

      App.off('folder-groups:edit-group');
      App.on('folder-groups:edit-group', _.bind(this.onEditGroup, this));

      $(window).on('resize', _.bind(this.calcColumnHeight, this));

      this.data.onChangeCount = _.bind(this.onChangeCount, this);
    },

    onChangeCount: function() {
      this.data.groupModel.count = undefined;
      this.showGroups(true);
    },

    onShowGroup: function(model) {
      var data = this.data,
        secId = model.get('secId'),
        link = data.type === 'caccount' ? 'caccount/' : 'customers/';

      this.data.secId = secId;
      App.navigate(link + data.id + '/' + data.tab.name + '/' + secId);
      this.showGroups();
    },

    showLayoutView: function() {
      var mainRegion = App.regions.getRegion('main');
      
      this.layoutView = new LayoutView({data: this.data});
      
      if (mainRegion) {
        mainRegion.currentView.getRegion('content').currentView.getRegion(this.region).show(this.layoutView);
      }
      this.data.elRegion = this.layoutView.getRegion('elementsColumn');

      this.fetchGroups();
    },

    fetchGroups: function() {
      var that = this,
        request;

      if (that.data.reload || (!this.groupsCollection || this.groupsCollection.length === 0) ||
          this.endUserId !== this.data.id) {
        this.endUserId = this.data.id;
        request = App.request(
          'customers:objectnames',
          {
            type: this.objectType,
            endUserId: this.data.model.get('endUserId')
          }
        );

        $.when(request).done(function(collection) {
          that.groupsCollection = collection;
          that.data.groupsCollection = collection;
          that.showGroups();
        });
      } else {
        this.showGroups();
      }
    },

    showGroups: function(changeCount) {
      var view = new GroupsView({
          collection: this.groupsCollection,
          data: this.data
        }),
        secId;

      if (this.data.secId) {
        secId = this.data.secId;
        this.data.groupModel = this.groupsCollection.get(secId);
      } else {
        secId = this.groupsCollection.models[0].get('secId');
        this.data.secId = secId;
        this.data.groupModel = this.groupsCollection.models[0];
      }

      this.layoutView.getRegion('groupsColumn').show(view);
      if (!changeCount) {
        this.data.groupsCollection = this.groupsCollection;
        this.displayElements(secId);
      }
      
      this.calcColumnHeight(true);
    },

    calcColumnHeight: function(calc) {
      var windowHeight, headerHeight, detailsHeaderHeight, height, a;

      if (calc === true || (this.layoutView && typeof this.layoutView.ui.groupsColumn !== 'string' &&
          this.layoutView.ui.groupsColumn.is(':visible'))) {
        windowHeight = $(window).height();
        headerHeight = $('header').height() || 75;
        detailsHeaderHeight = $('.caccount-details-header').height();
        a = this.data.tab === 'templates' ? 65 : 105;
        height = windowHeight - headerHeight - detailsHeaderHeight - a;

        this.layoutView.ui.groupsColumn.height(height);
      }
    },

    getGroupCount: function(secId, callback) {
      var fetchCount;

      fetchCount = App.request(
        'customers:objectnames-count',
        {
          secId: secId,
          endUserId: this.data.model.get('endUserId'),
          type: this.objectType
        }
      );

      fetchCount.fetch().done(function(count) {
        callback(count);
      });
    },

    onAddGroup: function() {
      var that = this,
        model = App.request('customers:objectnames-group-model');

      model.set('type', this.objectType);
      model.set('endUserId', this.data.model.get('endUserId'));
      this.popupView = new PopupView({
        model: model,
        objectType: this.objectType
      });
      this.popupView.show({title: _.i18n('popup.objectName.details'),
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default',
            callback: function() { return true; }
          },
          success: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary',
            callback: function() {
              return that.onConfirmPopup(that.popupView.model);
            }
          }
        }
      });
    },

    onConfirmPopup: function(model) {
      var that = this;

      if (!this.validate()) { return false; }

      model.save().done(function() {
        that.groupsCollection.add(model);
      });
    },

    validate: function() {
      var fieldsToValidate = [
        {name: 'name', type: 'required'},
        {name: 'code', type: 'required'}
      ];

      return this.popupView.validate(fieldsToValidate);
    },

    onRemoveGroup: function(model) {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function(result) {
        if (result) {
          model.url = model.deleteUrl();
          model.destroy();
        }
      }, this));
    },

    onEditGroup: function(model, view) {
      bootbox.prompt({
        title: _.i18n('popup.objectName.details'),
        value: model.get('name'),
        callback: _.bind(function(result) {
          if (result !== null) {
            model.set('name', result);
            model.url = model.postUrl();
            model.save().done(function() {
              view.render();
            });
          }
        }, this)
      });
    }
  };

  module.exports = Controller;
});
