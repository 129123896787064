define('compuzzProUtils',[
    'module',
    'settings',
    'underscore',
    'jquery',
    'logger',
    'marionette',
    'notify'
], function (module, Settings, _, $, Logger, Marionette) {
    'use strict';

    var events = {
        COMPUZZ_PRO_CONNECTED: 'notificationCenter:compuzzProConnected',
        COMPUZZ_PRO_EDIT_COMPOSITION: 'compuzzProUtils:compuzzProEditComposition',
        COMPUZZ_PRO_REFRESH_COMPOSITION: 'notificationCenter:compuzzProRefreshComposition'
    };

    var instance = null;

    var CompuzzProUtils = Marionette.Object.extend({

        connected: false,
        tokenId: null,
        request: null,

        setupWebSocket: function () {
            var webSocketUrl = Settings.compuzzAwsWs + '?cAccountUid=' + this.tokenId + ':dashboard' + '&user=' +
                Settings.get('currentUser').logonId;
            this.connection = new WebSocket(webSocketUrl);

            this.connection.onopen = function() {
                console.log('Connected to WebSocket server.');
            };

            this.connection.onmessage = _.bind(this.onMessage, this);

            this.connection.onerror = function(error) {
                console.error('WebSocket Error: ' + error);
            };

            this.connection.onclose = _.bind(function() {
                console.log('WebSocket connection closed. Attempting to reconnect...');
                setTimeout(_.bind(function() {
                    this.setupWebSocket();
                }, this), 3000); // Reconnect after 3 seconds
            }, this);
        },

        subscribe:function(tokenId) {
            if (this.tokenId !== tokenId) {
                this.tokenId = tokenId;
                this.setupWebSocket();

                if (Settings.compuzzAwsWsSend) {
                    setTimeout(_.bind(this.sendData, this), 1000, {}, true);
                    this.keepAlive();
                }
            }
        },

        keepAlive: function () {
            setTimeout(_.bind(this.keepAlive, this), 5000);
            this.sendData({type: 'keep_alive'});
        },

        sendData: function (data, sendToMe) {
            var url = Settings.url('compuzz', 'atmo/compuzzpro/' + this.tokenId + ':dashboard', null, true);

            if (Settings.compuzzAwsWsSend) {
                var tkId = this.tokenId;
                if (sendToMe) {
                    tkId += ':dashboard';
                } else {
                    tkId += ':app';
                }
                data = {
                    action: 'onMessage',
                    cAccountUid: tkId,
                    message: data
                };
                if (this.connection.readyState === WebSocket.OPEN) {
                    this.connection.send(JSON.stringify(data));
                }
            } else {
                $.ajax({
                    type: 'POST',
                    url: url,
                    data: JSON.stringify(data),
                    processData: false,
                    contentType: 'application/json',
                    success: function () {
                        console.log('data sent');
                    }
                });
            }
        },

        unSubscribe: function () {
            if (this.connection) {
                this.connection.close();
            }
        },

        onMessage: function (response) {
            try {
                var message;
                if (Settings.compuzzAwsWsSend) {
                    message = JSON.parse(response.data);
                } else {
                    message = JSON.parse(response.data.split('|')[1]);
                }

                if (message && message.value) {
                    switch (message.value) {
                        case 'COMPUZZ_PRO_CONNECTED':
                            this.connected = true;
                            break;
                        case 'COMPUZZ_PRO_DISCONNECTED':
                            this.connected = false;
                            break;
                    }
                }

                if (this._events && this._events['notificationCenter:' + message.type]) {
                    this.triggerMethod('notificationCenter:' + message.type, message);
                } else {
                    console.log(message);
                }
            } catch (e) {
            }
        },

        processNotification: function () {

        },

        removeListener: function (view, event, callback) {
            this.off(event, callback, view);
        },

        addListener: function (view, event, callback) {
            this.on(event, callback, view);
            view.listenTo(view, 'before:destroy', _.bind(function () {
                this.off(event, callback);
            }, this));
        },

        displayNotification: function (title, message, urlFromFragment, hideDelay, type, forceJsNotification) {
            var fullUrl = document.location.origin + document.location.pathname + urlFromFragment;
            if (_.isUndefined(hideDelay) || !_.isNumber(hideDelay)) {
                hideDelay = 5000;
            }
            if (window.Notification && window.Notification.permission === 'granted' && !forceJsNotification) {
                var options = {
                    body: message ? message.replace(new RegExp('<br>', 'g'), ' ') : '',
                    icon: Settings.url('base', Settings.get('defaultLogo'))
                };

                var notification = new window.Notification(title, options);
                notification.onclick = function () {
                    window.location = fullUrl;
                    window.focus();
                };
                notification.onshow = function () {
                    window.setTimeout(function () {
                        notification.close();
                    }, hideDelay);
                };
            } else {
                $.notify({
                    title: title,
                    message: message,
                    url: fullUrl,
                    target: '_self',
                    autoHide: true,
                    autoHideDelay: hideDelay
                }, {
                    type: type || 'info',
                    placement: {
                        from: 'top',
                        align: 'center'
                    }
                });
            }
        }
    });

    var getInstance = function () {
        if (!instance) {
            instance = new CompuzzProUtils();
        }
        return instance;
    };

    module.exports = CompuzzProUtils;
    module.exports.getInstance = getInstance;
    module.exports.events = events;
});

