define('notificationsCollectionView',[
  'module',
  'backbone',
  'marionette',
  'notificationsView'
], function (
  module,
  Backbone,
  Marionette,
  NotificationsView
) {
  'use strict';

  module.exports = Marionette.CollectionView.extend({
    childView: NotificationsView,
    collection: new Backbone.Collection(),

    childEvents: {
      'notifications:delete': function (view) {
        this.trigger('notifications:delete', view.model);
      }
    }
  });
});
