/**
 * Created by RKL on 26/08/2015.
 */
define('globalSearchView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'app',
  'settings',
  'template!globalSearchViewTpl'
], function (
  module,
  Backbone,
  Marionette,
  _,
  App,
  Settings,
  viewTpl
) {
  'use strict';

  var emptyView =  Marionette.LayoutView.extend({
    template: viewTpl,
    initialize: function() {
      this.searchResults = [];
    },
    ui: {
      offers: '.js-show-more',
      searchButton: '.js-global-search',
      searchInput: '.js-search-input',
      navigate: '.js-navigate'
    },
    events: {
      'click @ui.offers' : 'onCLickShowMore',
      'click @ui.searchButton' : 'onSearchButtonClick',
      'click @ui.navigate' : 'onNavigateClick',
      'click @ui.searchInput' : 'onSearchInputClick',
      'keydown @ui.searchInput' : 'onSearchInputChange'
    },
    serializeData: function() {
      $('.js-progress-bar').hide();
      var templateData = {};
      templateData.searchResults = this.searchResults;
      templateData.currentSearch = this.currentSearch?this.currentSearch:'';
      return templateData;
    },
    onSearchButtonClick: function(e) {
      if (e) {
        e.stopPropagation();
      }
      var that= this;
      if (this.ui.searchInput.val() && this.ui.searchInput.val().length > 2) {
        $('.js-progress-bar').show();
        App.request('globalsearch', encodeURI(this.ui.searchInput.val())).done(function (searchResults) {
          if (searchResults.status === 200) {
            _.each(searchResults.responseJSON, function (searchResult) {
              if (searchResult.type === 'product') {
                searchResult.route = 'product/' + searchResult.id;
                searchResult.type = _.i18n('menu.products');
              } else if (searchResult.type === 'command') {
                searchResult.route = 'orders/' + searchResult.id;
                searchResult.type = _.i18n('menu.ordersManagement');
              } else if (searchResult.type === 'offer') {
                searchResult.route = 'orders/' + searchResult.id;
                searchResult.type = _.i18n('menu.offerManagement');
              } else if (searchResult.type === 'customer') {
                searchResult.route = 'customers/' + searchResult.id;
								searchResult.type = _.i18n('menu.clients');
              }
            });
            that.searchResults = searchResults.responseJSON;
            that.currentSearch = that.ui.searchInput.val();
            $('.js-progress-bar').hide();
            that.render();
            $('.js-search-dropdown').addClass('open');
          } else {
            $('.js-progress-bar').hide();
            console.log(_.i18n('common.request.error')+' '+searchResults.responseJSON);
          }
        });
      }
    },
    onSearchInputClick: function() {
      if (this.searchResults.length > 0) {
        setTimeout(function () {
          $('.js-search-dropdown').addClass('open');
        }, 10);
      }
    },
    onSearchInputChange: function(e) {
      var charCode = (e.which) ? e.which : e.keyCode;

      if (charCode === 13) {
        e.preventDefault();
        this.onSearchButtonClick();
      } else {
        $('.js-search-dropdown').removeClass('open');
        this.searchResults = [];
        if (this.ui.searchInput.val().length + ((charCode === 8 || charCode ===46)?-1:1) < 3) {
          $('.js-global-search').removeClass('clickable');
          $('.js-global-search').css('color','lightgrey');
        } else {
          $('.js-global-search').addClass('clickable');
          $('.js-global-search').css('color','black');
        }
      }
    },

    onCLickShowMore: function(e) {
      e.stopPropagation();
      var button = $('.js-hide-five-'+$(e.target).attr('data-type'));
      if (button.css('display') === 'none') {
        $(e.target).html('-');
        button.css('display', 'block');
      } else {
        button.css('display', 'none');
        $(e.target).html('...');
      }
    },

    onNavigateClick: function(e) {
      e.stopPropagation();
      $('.js-search-dropdown').removeClass('open');
        var newRoute = $(e.target).attr('data-route');
      App.trigger('view:destroy',newRoute);
      App.navigate(newRoute, {trigger: true});

    }
  });

  module.exports = emptyView;
});
