define('popupOptionView',[
  'module',
  'marionette',
  'app',
  'backbone',
  'underscore',
  'jquery',
  'settings',
  'imageUploadBehavior',
  'dialogFormView',
  'dynamicTextView',
  'template!popupCreateOptionTpl'
], function (
  module,
  Marionette,
  App,
  Backbone,
  _,
  $,
  Settings,
  ImageUploadBehavior,
  DialogFormView,
  DynamicTextView,
  popupCreateOptionTpl
) {
  'use strict';

  module.exports = DialogFormView.extend({
    template: popupCreateOptionTpl,

    ui: {
      colorBtn: '.colorpick',
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm'
    },

    regions: {
      nameRegion: '.name-region'
    },

    events: {
      'click @ui.colorBtn': 'onShowColorpicker',
      'click .btn-color-delete': 'onDeleteColor',
      'click .btn-image-delete': 'onDeleteImage',
      'click .js-cancel-popup': 'hide',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange',
      'click @ui.confirm': 'onConfirm',
      'click @ui.cancel': 'onCancel'
    },

    modelEvents: {
      sync: 'onSave'
    },

    loadImageInMemory: true,

    behaviors: {
      ImageUpload: {
        behaviorClass: ImageUploadBehavior
      }
    },

    initialize: function () {
    },

    onShowColorpicker: function (event) {
      var target = $(event.currentTarget),
        newColor;
      var input = target.find('input');
      if (!input.val()) {
        input.val('#ff0000');
        input.attr('disabled', false);
      }
      target.colorpicker();
      target.colorpicker('show');
      target.on('changeColor.colorpicker', _.bind(function (event) {
        newColor = event.color.toHex();
        this.model.set(target.data('key'), App.request('products-rel:color:model', event.color.toRGB()));
      }, this));
    },

    onDeleteColor: function (event) {
      var target = $(event.currentTarget);
      var input = target.parent().find('input');
      input.val('');
      input.attr('disabled', true);
      var square = target.parent().find('.color-block');
      square.css('background-color', '#FFFFFF');
      this.model.set(target.data('key'), null);
    },

    onDeleteImage: function (event) {
      var target = $(event.currentTarget);
      //target.parent().find('.download-title').remove();
      this.model.set(target.data('key'), null);
      this.render();
    },

    onImageUploadConfirm: function (base64Value, fileExt, property, fileName) {
      var image = _.findWhere(this.model.get('optionImageToSaveList'), {optionIndex: Number(property)});
      if (image) {
        image.fileName = fileName;
        image.base64 = base64Value;
      } else {
        this.model.get('optionImageToSaveList').push({
          fileName: fileName,
          base64: base64Value,
          optionIndex: Number(property)
        });
      }
      this.$el.find('.image-content-' + property).attr('style',
        'background-image:url(data:image/' + fileExt + ';base64,' + base64Value + ');');
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;

      for (var i = 1; i <= 4; i++) {
        var color = this.model.get('color' + i);
        if (color) {
          templateData['color' + i] = color.toHex();
        } else {
          templateData['color' + i] = null;
        }
      }
      templateData.images = {};
      for (var j = 1; j <= 4; j++) {
        if (this.model.get('picture' + j + 'Uid')) {
          templateData.images['' + j] = {
            uid: this.model.get('picture' + j + 'Uid'),
            url: Settings.url('file', this.model.get('picture' + j + 'Uid'))
          };
        }
      }
      return templateData;
    },

    onRender: function () {
      $.material.init();
      this.nameDtView = new DynamicTextView({model: this.model.get('name'), noSaving: true});
      this.getRegion('nameRegion').show(this.nameDtView);
    },

    onConfirm: function () {
      this.model.save();
    },

    onEscape: function () {
      if (!this.newItem) {
        this.model = null;
      }
    },

    onCancel: function () {
      if (!this.newItem) {
        this.model = null;
      }
      this.hide();
    },

    onSave: function () {
      this.triggerMethod('saved');
      this.model = null;
      this.hide();
    }
  });
});
