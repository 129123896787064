
define('template!productPricesTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(useFactorV2) { ;
__p += '\n<h2 style="font-weight: bold; color: green;">This product is using automated pricing</h2>\n<button class="btn-approve pull-right">Switch custom pricing</button>\n';
 } ;
__p += '\n<div class="detailed-info-form-container" style="margin-right: 30px; margin-left: 20px;">\n    <div class="row">\n        <div class="col-xs-6">\n            <div class="col-xs-12">\n                <div class="row">\n                    <div class="col-xs-12">\n                        <div class="m-t-5 col-lg-2">\n                            <div>' +
((__t = ( _.i18n("common.onOff") )) == null ? '' : __t) +
'</div>\n                        </div>\n                        <div class="m-t-5 col-lg-2">\n                            ' +
((__t = ( _.i18n("product.supplier") )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="m-t-5 col-lg-2">\n                            ' +
((__t = ( _.i18n("common.status") )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="m-t-5 col-lg-4">\n                            ' +
((__t = ( _.i18n("common.comment") )) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n                </div>\n                <div class="row">\n                    <div class="col-xs-12">\n                        <div class="m-t-5 col-lg-2">\n                            <div><input type="checkbox" class="cbx-show-in-webshop" ' +
((__t = ( product.showInWebshop?'checked':'' )) == null ? '' : __t) +
' /></div>\n                        </div>\n                        <div class="m-t-5 col-lg-2">\n                            ' +
((__t = ( product.manufacturerCode )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="m-t-5 col-lg-2">\n                            <select class="input-product-status2">\n                                ';
 if (product.status2 !== 'New' && product.status2 !== 'Best seller' && product.status2 !== 'Sale' && product.status2 !== 'Best price') { ;
__p += '\n                                    <option value="' +
((__t = ( product.status2 )) == null ? '' : __t) +
'">' +
((__t = ( product.status2 )) == null ? '' : __t) +
'</option>\n                                ';
 } ;
__p += '\n                                <option value="New" ' +
((__t = ( (product.status2 === 'New')?'selected':'' )) == null ? '' : __t) +
'>New</option>\n                                <option value="Best seller" ' +
((__t = ( (product.status2 === 'Best seller')?'selected':'' )) == null ? '' : __t) +
'>Best seller</option>\n                                <option value="Sale" ' +
((__t = ( (product.status2 === 'Sale')?'selected':'' )) == null ? '' : __t) +
'>Sale</option>\n                                <option value="Best price" ' +
((__t = ( (product.status2 === 'Best price')?'selected':'' )) == null ? '' : __t) +
'>Best price</option>\n                                <option value="Ending" ' +
((__t = ( (product.status === 'Ending')?'selected':'' )) == null ? '' : __t) +
'>Ending</option>\n                                <option value="Soldout" ' +
((__t = ( (product.status === 'Soldout')?'selected':'' )) == null ? '' : __t) +
'>Soldout</option>\n                            </select>\n                        </div>\n                        <div class="m-t-5 col-lg-4">\n                            <textarea style="width: 350px" class="input-product-comment">' +
((__t = ( product.comment )) == null ? '' : __t) +
'</textarea>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="col-xs-6 competitors-region">\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-6 h-20">\n\n        </div>\n        <div class="col-xs-6 similars-region">\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-12">\n            <div class="col-xs-5">\n                <button class="btn-save-product m-l-15">' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-12 p-r-0">\n            <div class="col-xs-12 p-l-0 p-r-0">\n                <div class="col-xs-5">\n                    <div class="col-xs-12">\n                        ' +
((__t = ( _.i18n("print.imprint") )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="col-xs-12 prices-header-table">\n                        <div class="col-xs-1 p-l-0 p-r-0" style="width: 30px;">\n                            ' +
((__t = ( _.i18n("common.nR") )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-1 p-l-0 p-r-0" style="width: 30px;">\n                            ' +
((__t = ( _.i18n("common.onOff") )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-2 p-l-0 p-r-0">\n                            ' +
((__t = ( _.i18n("print.location") )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-2 p-l-0 p-r-0">\n                            ' +
((__t = ( _.i18n("print.technic") )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-2 p-l-0 p-r-0">\n                            ' +
((__t = ( _.i18n("print.printer") )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-1 p-l-0 p-r-0" style="width: 30px;">\n                            ' +
((__t = ( _.i18n("common.colors") )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-2 p-l-0 p-r-0">\n                            ' +
((__t = ( _.i18n("print.setup") )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-2 p-l-0 p-r-0">\n                            ' +
((__t = ( _.i18n("print.handling") )) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n                </div>\n                <div class="col-xs-7">\n                    <div class="col-xs-12 p-l-0">\n                        <div class="col-xs-12">\n                            ' +
((__t = ( _.i18n("print.imprint") )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-12 prices-header-table">\n                            ';
 var sizePercent = 100 / (price.scale.length + 1); ;
__p += '\n                            ';
 _.each(price.scale, function(p, index) { ;
__p += '\n                                <div class="col-xs-1 p-l-0 p-r-0" style="width: ' +
((__t = ( sizePercent )) == null ? '' : __t) +
'%;">\n                                    ';
 if ((price.scale.length -1) === index) { ;
__p += '\n                                        ' +
((__t = ( p.quantity )) == null ? '' : __t) +
'+\n                                    ';
 } else { ;
__p += '\n                                        ' +
((__t = ( p.quantity )) == null ? '' : __t) +
'-' +
((__t = ( Number(price.scale[index+1].quantity) -1 )) == null ? '' : __t) +
'\n                                    ';
 } ;
__p += ' \n                                </div>\n                            ';
 }); ;
__p += '\n                            <div class="col-xs-1 p-l-0 p-r-0" style="width: ' +
((__t = ( sizePercent )) == null ? '' : __t) +
'%;">\n                                ' +
((__t = ( _.i18n("price.factor") )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="row imprints-region">\n    </div>\n    <div class="row">\n        <div class="col-xs-12">\n            <span class="pull-right lblPzModDate"></span>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-5">\n            <button class="btn-save-pz m-l-15">' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'</button>\n        </div>\n        <div class="col-xs-7">\n            <button class="btn-approve-pz-price pull-right">' +
((__t = ( _.i18n("common.approve") )) == null ? '' : __t) +
'</button>\n        </div>\n    </div>\n    <div class="row p-t-20">\n        <div class="col-xs-3">\n            <div class="col-xs-5">\n                <div class="row" style="height: 40px;">\n                </div>\n                <div class="row">\n                    <div class="col-xs-6">\n                        <div class="row">\n                            <div class="col-xs-12 p-r-0" style="height: 40px;">' +
((__t = ( _.i18n("price.purchase") )) == null ? '' : __t) +
'</div>\n                        </div>\n                        <!--<div class="row">\n                            <div class="col-xs-12 p-r-0">Margin</div>\n                        </div>\n                        <div class="row">\n                            <div class="col-xs-12 p-r-0 prices-editable">30%</div>\n                        </div>\n                        <div class="row">\n                            <div class="col-xs-12 p-r-0">LP</div>\n                        </div>\n                        <div class="row">\n                            <div class="col-xs-12 p-r-0">€0,23</div>\n                        </div>-->\n                    </div>\n                    <div class="col-xs-3">\n                        ';
 _.each(price.scale, function(scale) { ;
__p += '\n                            <div class="row">\n                                <div class="col-xs-6 p-l-0" style="text-align: right;">' +
((__t = ( scale.quantity )) == null ? '' : __t) +
'</div>\n                            </div>\n                        ';
 }); ;
__p += '\n                    </div>\n                </div>\n            </div>\n            <div class="col-xs-7">\n                <div class="row">\n                    ' +
((__t = ( _.i18n("price.calculator") )) == null ? '' : __t) +
'\n                </div>\n                <div class="row">\n                    <div class="col-xs-4"></div>\n                    <div class="col-xs-4">\n                        ' +
((__t = ( _.i18n("price.price") )) == null ? '' : __t) +
'\n                    </div>\n                    <div class="col-xs-4 prices-editable">\n                        ' +
((__t = ( _.i18n("price.factor") )) == null ? '' : __t) +
'\n                    </div>\n                </div>\n                <div class="price-region-calculator">\n\n                </div>\n                <div class="row">\n                    <button class="btn-copy-to-scale">-></button>\n                </div>\n            </div>\n        </div>\n        <div class="col-xs-9">\n            <div class="row">\n                ' +
((__t = ( _.i18n("price.salesPrice") )) == null ? '' : __t) +
'\n                ';
 if (nbrTechnic > 0) { ;
__p += '\n                    <select class="select-technic">\n                        ';
 for (var i = 1 ; i <= nbrTechnic ; i++) { ;
__p += '\n                            <option value="' +
((__t = ( i )) == null ? '' : __t) +
'">' +
((__t = ( i )) == null ? '' : __t) +
'</option>\n                        ';
 } ;
__p += '\n                    </select>\n                ';
 } ;
__p += '\n            </div>\n            <div class="row prices-header-table">\n                <div class="col-xs-1 p-l-0 prices-editable">\n                    ' +
((__t = ( _.i18n("price.amount") )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-1 p-l-0">\n                    ' +
((__t = ( _.i18n("price.price") )) == null ? '' : __t) +
'\n                </div>\n                ';
 if(!useFactorV2) { ;
__p += '\n                <div class="col-xs-1 p-l-0 prices-editable">\n                    ' +
((__t = ( _.i18n("price.price") )) == null ? '' : __t) +
'\n                </div>\n                ';
 } ;
__p += '\n                <div class="col-xs-1 p-l-0">\n                    ' +
((__t = ( _.i18n("price.factor") )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-1 p-l-0">\n                    ' +
((__t = ( _.i18n("print.imprint") )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-1 p-l-0">\n                    ' +
((__t = ( _.i18n("price.handling") )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-1 p-l-0">\n                    ' +
((__t = ( _.i18n("print.setup") )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-1 p-l-0">\n                    ' +
((__t = ( _.i18n("price.smOrdFee") )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-1 p-l-0">\n                    ' +
((__t = ( _.i18n("price.shipping") )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-1 p-l-0">\n                    ' +
((__t = ( _.i18n("price.total") )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-1 p-l-0">\n                    ' +
((__t = ( _.i18n("price.marginPercent") )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-xs-1 p-l-0">\n                    ' +
((__t = ( _.i18n("price.marginEuro") )) == null ? '' : __t) +
' €\n                </div>\n            </div>\n            <div class="price-region-scale">\n\n            </div>\n            <div class="row">\n                <div class="btn btn-add-scale p-l-0 p-r-0 p-t-0 p-b-0">\n                    <i class="mdi-content-add"></i>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class="row">\n        <div class="col-xs-12">\n            <span class="pull-right lblModDate"></span>\n        </div>\n    </div>\n    <div class="row" style="padding-bottom: 40px;">\n        <div class="col-xs-12">\n            ';
 if (!useFactorV2) { ;
__p += '\n            <button class="btn-approve pull-right">' +
((__t = ( _.i18n("common.approve") )) == null ? '' : __t) +
'</button>\n            ';
 } ;
__p += '\n            <div class="small-loading-block js-app-loading loader pull-right"></div>\n        </div>\n    </div>\n    <div class="loading-block js-loading-block loader"></div>\n</div>';

}
return __p
};});

