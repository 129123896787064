/**
 * Created by BFR on 28/06/2018.
 */
define('mailsView',[
  'module',
  'underscore',
  'marionette',
  'app',
  'settings',
  'jquery',
  'dateUtils',
  'template!mailsTpl',
  'mailView',
  'gmailUtils',
  'entities/mails'
], function(
  module,
  _,
  Marionette,
  App,
  Settings,
  $,
  dateConverter,
  viewTpl,
  itemView,
  GmailUtils
) {
  'use strict';

  var MailsView =  Marionette.CompositeView.extend({
    template: viewTpl,
    childViewContainer: '.messages-container',
    childView: itemView,
    childViewOptions: {},

    events: {
      'click @ui.sendMessage' : 'sendMessage',
      'click .googleConnect': 'onGoogleConnectClick'
    },

    ui: {
      textarea: '.create-new-message',
      inSubject: '.input-subject',
      content: '.mails-content',
      footer: '.js-footer',
      attach: '.attach-input',
      attachBtn: '.attach-btn',
      sendMessage: '.js-send-new-m-b'
    },

    serializeData: function() {
      var templateData = {};
      if (this.options.collection) {
          templateData.connected = true;
      } else {
          templateData.connected = false;
      }
      return templateData;
    },

    onShow: function() {
      $.material.init();
      this.positionFooter();
      this.ui.textarea.css('max-height', (this.ui.content.height() + this.ui.footer.height()) / 2);
      $(window).on('resize.positionHeaderAndFooter', _.bind(this.positionFooter, this));
      App.on('sidebar:resize', this.positionFooter, this);
      App.on('sidebar:switch', this.positionFooter, this);
      this.ui.textarea.css('height', this.ui.footer.height() / 1.5);
      setTimeout(_.bind(this.heightScroll, this), 2000);
    },

    onGoogleConnectClick: function() {
        var gmailUtils = GmailUtils.getInstance();
        gmailUtils.signIn();
    },

    positionFooter: function() {
      var self = this;
      var $sideBarWidth = $('#sidebar-region').width();
      if($('#sidebar-region' ).hasClass('nav-off-screen')) {
          self.ui.footer.css('left',0);
      } else if(self.ui.footer){
          self.ui.footer.css('left',$sideBarWidth);
      }
    },

    sendMessage: function() {
      var gmailUtils = GmailUtils.getInstance();


      var that = this,
          messageContent = this.ui.textarea.val(),
          textarea = this.ui.textarea,
          email;
      that._disableSend();
      if (messageContent !== '') {
          textarea.parent().removeClass('has-error');

          textarea.prop('disabled', true);

          email = this.options.customer.get('email');

          gmailUtils.sendMessage(email?email:this.options.customer.get('endUserId'), this.ui.inSubject.val(),
                                messageContent, _.bind(function () {
              textarea.prop('disabled', false);
              textarea.val('');
              this.ui.inSubject.val('');
              that._enableSend();
          },this));

      } else {
          textarea.parent().addClass('has-error');
          that._enableSend();
      }
    },

    _disableSend: function() {
      this.ui.sendMessage.addClass('disabled');
      this.ui.attachBtn.addClass('disabled')
          .find('a.send-new-m-b').addClass('disabled');
      this.ui.attach.attr('disabled', true);
    },

    _enableSend: function() {
      this.ui.sendMessage.removeClass('disabled');
      this.ui.attachBtn.removeClass('disabled')
          .find('a.send-new-m-b').removeClass('disabled');
      this.ui.attach.attr('disabled', false);
    },

    heightScroll: function () {
      var content = this.ui.content;

      content.removeClass('m-b-45');

      //Get the last message position and height
      var $lastMsg = this.$('.messages-container').find('.tickets-message-container' ).last();
      if ($lastMsg.length) {
          //Set the new height on the message container
          this.ui.content.css('height', $lastMsg.position().top +
              $lastMsg.height() +
              this.ui.footer.height());
      }
      //Scroll at the bottom of the page
      setTimeout(_.bind(function() {
          $('#content-region').animate({scrollTop: this.ui.content.height()}, 'slow');
      }, this), 0);
    }
  });

  module.exports = MailsView;
});
