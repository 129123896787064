/**
 * Created by OBL on 16/04/2019.
 */
define('customersMessagesTabController',[
  'module',
  'underscore',
  'marionette',
  'customerAllMessagesTabController',
  'app',
  'entities/offers'
], function(
  module,
  _,
  Marionnette,
  MessageViewController,
  App

) {
  'use strict';

  var CustomersMessagesTabController =  _.extend(MessageViewController.getInstance(), {
    showTab: function (data, ViewInstance) {
      MessageViewController.showTabFromCustomer.call(this,
        App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent'),
        data,ViewInstance);
    }

  });

  module.exports = CustomersMessagesTabController ;
});

