
define('template!editPimOptionTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-2 code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n    <div class="col-xs-4 name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n    <div class="col-xs-2 color">\n        ';
 if(color !== '') { ;
__p += '\n            <div class="color-round" style="background-color:' +
((__t = ( color )) == null ? '' : __t) +
'"></div>\n        ';
 } ;
__p += '\n    </div>\n    ';
 
        var css = '';
        if(allowUpload) {
            css = 'context-menu-option';
        }
    ;
__p += '\n    <div class="col-xs-4 images">\n        ';
 _.each(images, function (url, index) { ;
__p += '\n            <div class=\'image\'>\n                ';
 if (defaultIndexPicture === (index + 1)) { ;
__p += '\n                <i class="mdi-action-bookmark" style="position:absolute; font-size: 13px; color: green;"></i>\n                ';
 } ;
__p += '\n                <img data-index="' +
((__t = ( index+1 )) == null ? '' : __t) +
'" data-link="' +
((__t = ( url )) == null ? '' : __t) +
'" class="jq-picture js-image-cell ' +
((__t = ( css )) == null ? '' : __t) +
'" src="' +
((__t = ( url )) == null ? '' : __t) +
'"/>\n            </div>\n        ';
 });;
__p += '\n    </div>\n</div>';

}
return __p
};});

