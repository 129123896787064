/**
 * Created by RKL on 26/08/2015.
 */
define('caccountAttachsTabController',[
  'module',
  'underscore',
  'app',
  'elementsController',
  'caccountAttachsCollectionView',  
  'galleryView',
  'settings',
  'entities/caccounts/attachments'
], function(
  module,
  _,
  App,
  ElementsController,
  View,
  GalleryView,
  Settings
) {
  'use strict';

  var CAccountsAttachmentsTabController = _.defaults({

    requests: {
      newModelRequest: 'caccount:attachments-model',
      collectionRequest: 'caccount:attachments-lazy',
      countRequest: 'caccount:attachments-count'

    },

    showTab: function(data) {
      var params, countRequest;

      this.setValues(data);
      params = this.getParams('0');
      countRequest = App.request(this.requests.countRequest, params);
      this.getGroupCount(countRequest);

      this.subscribeToEvents();
    },

    getGroupCount: function(request) {

      $.when(request).done(_.bind(function(count) {
        this.count = count;
        this.showAttachments(this.data);
      }, this));
    },

    subscribeToEvents: function() {
      App.off('caccount:upload-attachment');
      App.off('caccount:delete-attachment');
      App.off('caccount:attachments-zoom');
      App.off('caccount:attachments-modify');

      App.on('caccount:upload-attachment', _.bind(this.onUpload, this));
      App.on('caccount:delete-attachment', _.bind(this.onDelete, this));
      App.on('caccount:attachments-zoom', _.bind(this.onZoom, this));
      App.on('caccount:attachments-modify', _.bind(this.onModify, this));

      $(window).on('resize', _.bind(this.checkResize, this));
    },

    setValues: function(data) {
      this.data = data;
      this.secId = data.type === 'customer' ? data.id : data.cAccountId;
      this.first = 0;
    },

    getParams: function(first) {
      var params = {
          caccountSecId: this.data.cAccountId,
          first: first || this.collection.length - 1,
          pageSize: Settings.get('lazyLoadingSize')
        };

      if (this.data.type === 'customer') {
        params.endUserSecId = this.data.id;
      }

      return params;
    },

    showAttachments: function() {
      var that = this,
        regionView = App.regions.getRegion('main').currentView.getRegion('content').currentView,
        newModel = App.request(this.requests.newModelRequest),
        view;

      if ((!this.collection || this.collection.length === 0 || this.tempId !== this.secId) || this.data.reload) {
        this.tempId = this.secId;
        this.collection = App.request(this.requests.collectionRequest, this.getParams('0'));

        this.collection.fetch().done(function() {
          that.collection.add(newModel, {at: 0});
          view = new View({collection: that.collection});
          that.view = view;
          view.listenTo(view, 'showView', _.bind(that.onShowView, that));
          view.listenTo(view, 'onScroll', _.bind(that.checkLoadMore, that));
          that.elementsView = view;
          if(that.data.type === 'customer'){
            regionView.getRegion('tabContent').show(view);
          }
          else{
            regionView.getRegion('tabContent').show(view);
          }
          
        });
      } else {
        view = new View({collection: this.collection});
        this.view = view;
        view.listenTo(view, 'showView', _.bind(this.onShowView, this));
        view.listenTo(view, 'onScroll', _.bind(this.checkLoadMore, this));
        this.elementsView = view;
        if(that.data.type === 'customer'){
          regionView.getRegion('tabContent').show(view);
        }
        else{
          regionView.getRegion('tabContent').show(view);
        }
        
      }
    },

    checkLoadMore: function() {
      var scrollBlock = this.elementsView.ui.scrollBlock,
        count = this.count;

      if (scrollBlock.is(':visible') &&
          this.first !== this.collection.length - 1 &&
          this.collection.length <= count) {
        this.checkLoaderIsVisible();
      } else if (this.collection.length > count ||
          this.collection.length === 1) {
        scrollBlock.hide();
      }
    },

    onUpload: function(formdata) {
      var that = this,
        data = {
          formdata: formdata,
          id: this.secId,
          type: this.data.type
        },
        uploadRequest = App.request('caccount:upload-attachment', data);

      this.view.triggerMethod(
        'showMessage',
        'primary',
        _.i18n('common.loading')
      );
      $.when(uploadRequest).done(function(attachments) {
        that.view.triggerMethod('hideMessage');
        that.collection.add(attachments, {at: 1});
      }).fail(function() {
        that.view.triggerMethod(
          'errorMessage',
          _.i18n('warning.systemError')
        );
      });
    },

    onDelete: function(model) {
      model.url = model.deleteUrl(this.data.type, this.secId);
      model.destroy();
    },

    onModify: function(secId) {
      App.navigate('attachments/attachments/' + this.data.id + '/' + secId + '/' + this.data.type, {trigger: true});
    },

    onZoom: function(id) {
      var that = this,
        data = (_.filter(
          this.collection.models,
          function(item) {
            if (item.get('downloadLink') !== null) {
              return item;
            }
          }
        )).sort(function(item){
          return item.get('id')!==id;
        }),
        galleryView = new GalleryView({
          id: id,
          data: data,
          onLoadEnd: function() {
            that.galleryLoading = false;
          }
        });

      if (!this.galleryLoading) {
        this.galleryLoading = true;
        App.regions.getRegion('main').currentView.getRegion('zoom').show(galleryView);
      }
    }
  }, ElementsController);

  module.exports = CAccountsAttachmentsTabController;
});
