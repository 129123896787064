
define('template!pimProductImprintTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-1 index"><div class="col">' +
((__t = ( index )) == null ? '' : __t) +
'</div></div>\n<div class="col-xs-1 onOff">\n    <div class="col">\n        <input type="checkbox" class="cbx-enable-pz" ' +
((__t = ( activated?'checked':'' )) == null ? '' : __t) +
' />\n    </div>\n</div>\n<div class="col-xs-2 name"><div class="col" title="' +
((__t = ( paName )) == null ? '' : __t) +
'">' +
((__t = ( newPz?'NEW : ':'' )) == null ? '' : __t) +
'' +
((__t = ( paName )) == null ? '' : __t) +
'</div></div>\n<div class="col-xs-3 technic"><div class="col" title="' +
((__t = ( pzName )) == null ? '' : __t) +
'">' +
((__t = ( newPz?'NEW : ':'' )) == null ? '' : __t) +
'' +
((__t = ( pzName )) == null ? '' : __t) +
'&nbsp;(' +
((__t = ( pz.width )) == null ? '' : __t) +
'X' +
((__t = ( pz.height )) == null ? '' : __t) +
'&nbsp;mm)</div></div>\n<div class="col-xs-1 colors"><div class="col">' +
((__t = ( pz.maxColors )) == null ? '' : __t) +
'</div></div>\n<div class="col-xs-3 setup">\n    <div class="edit">\n        <div class="col">\n            ';
 if (currentPzPrice.length > 0) { ;
__p += '\n\n                ';
 if (currentPzPrice[0].imptSetupPrice) { ;
__p += '\n                    <span>' +
((__t = ( currentPzPrice[0].imptSetupPrice )) == null ? '' : __t) +
'</span>\n                ';
 } else if (customPrice) { ;
__p += '\n                \n                    <input type="text" class="txt-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-1" data-price="' +
((__t = ( currentPzPrice[0].setUpPrice )) == null ? '' : __t) +
'"  data-index="0" style="display: none;" value="' +
((__t = ( currentPzPrice[0].setUpPrice )) == null ? '' : __t) +
'" />   \n                    <span class="lbl-price>" data-index="0">' +
((__t = ( currentPzPrice[0].setUpPrice )) == null ? '' : __t) +
'</span>\n                    \n                    <input type="text" class="txt-factorized-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-1" data-price="' +
((__t = ( currentPzPrice[0].setUpPrice )) == null ? '' : __t) +
'"  data-index="0" style="display: none;" value="' +
((__t = ( currentPzPrice[0].setUpPrice * setupFactor)) == null ? '' : __t) +
'" />\n                    <span class="lbl-factorized-price" data-index="0">' +
((__t = ( currentPzPrice[0].setUpPrice * setupFactor )) == null ? '' : __t) +
'</span>\n                ';
 } ;
__p += '\n            ';
 } ;
__p += '\n        </div>\n\n        ';
 if (!useFactorV2) { ;
__p += '\n        <div class="col">\n            ';
 if (currentPzPrice.length > 0 && !customPrice) { ;
__p += '\n                <input type="text" class="txt-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-1" data-price="' +
((__t = ( currentPzPrice[0].imptSetupPrice )) == null ? '' : __t) +
'" data-factor="' +
((__t = ( currentPzPrice[0].setupFactor )) == null ? '' : __t) +
'" data-index="0" style="display: none;" value="' +
((__t = ( Number(currentPzPrice[0].imptSetupPriceSale).toFixed(2) )) == null ? '' : __t) +
'" />\n                <span class="lbl-price" data-index="0">' +
((__t = ( Number(currentPzPrice[0].imptSetupPriceSale).toFixed(2) )) == null ? '' : __t) +
'</span>\n            ';
 } ;
__p += '\n        </div>\n        ';
 } ;
__p += '\n    </div>\n\n    <div class="col">\n        ';
 if (currentPzPrice.length > 0 && currentPzPrice[0].imptHandlingUnitPrice && !customPrice) { ;
__p += '\n            <span>' +
((__t = ( Number(currentPzPrice[0].imptHandlingUnitPrice).toFixed(2) )) == null ? '' : __t) +
'</span>\n        ';
 } ;
__p += '\n    </div>\n</div>\n';
 if (!useFactorV2) { ;
__p += '\n<div class="col-xs-1 handling">\n    <div class="col">\n        ';
 if (currentPzPrice.length > 0) { ;
__p += '\n            ';
 if (!customPrice) { ;
__p += '\n                <input type="text" class="txt-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-2" data-price="' +
((__t = ( currentPzPrice[0].imptHandlingUnitPrice )) == null ? '' : __t) +
'" data-factor="' +
((__t = ( currentPzPrice[0].handlingFactor )) == null ? '' : __t) +
'" data-index="1" style="display: none;" value="' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'" />\n                <span class="lbl-price" data-index="1">' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'</span>\n            ';
 } else { ;
__p += '\n                <input type="text" class="txt-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-2" data-price="' +
((__t = ( currentPzPrice[0].imptHandlingUnitPrice )) == null ? '' : __t) +
'" data-factor="' +
((__t = ( currentPzPrice[0].handlingFactor )) == null ? '' : __t) +
'" data-index="1" style="display: none;" value="' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'" />\n                <span class="lbl-price" data-index="1" style="display:none">' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'</span>\n\n                <input type="text" class="txt-factorized-price" data-pz-id="' +
((__t = ( currentPzPrice[0].secId )) == null ? '' : __t) +
'" data-quantity="-2" data-price="' +
((__t = ( currentPzPrice[0].imptHandlingUnitPrice )) == null ? '' : __t) +
'" data-factor="' +
((__t = ( currentPzPrice[0].handlingFactor )) == null ? '' : __t) +
'" data-index="1" style="display: none;" value="' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'" />\n                <span class="lbl-factorized-price" data-index="1" style="display:none">' +
((__t = ( currentPzPrice[0].imptHandlingPriceSale )) == null ? '' : __t) +
'</span>\n            ';
 } ;
__p += '\n        </div>\n    ';
 } ;
__p += '\n</div>\n';
 } ;


}
return __p
};});

