/**
 * Created by BFR on 06/12/2019.
 */
define('trackingMainRouter',[
  'underscore',
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin',
  'trackingTabs',
  'trackingDetailsController'
], function(
    _,
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin,
  TrackingTabs,
  TrackingDetailsController
) {
  'use strict';

  var TrackingRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'tracking(/:secId)(/:tab)' : 'showTrackingDetails'
    }
  });

  var API = {
    checkContent: function() {
      var defer = $.Deferred();

      require([
        'contentShowController'
      ], function(ContentShowController) {
        ContentShowController.showContent().done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    },

      showTrackingDetails: function(secId, tab) {
      this.checkContent().done(function() {
        require([
          'trackingTabController'
        ], function(TrackingController) {
          if (TrackingController.createEditView) {
              TrackingController.createEditView.hide(true);
              TrackingController.createEditView = null;
          }

          if (secId) {
              TrackingDetailsController.fetchTracking({
              secId: secId,
              tab: _.findWhere(TrackingTabs, {name: tab})
            });
          }

          App.execute('set:active:nav:item', ItemPagesNameMixin.TRACKING);
        });
      });
    }
  };

  App.on('tracking:show', function() {
    App.navigate('tracking');
  });

  App.on('before:start', function() {
    new TrackingRouter({
      controller: API
    });
  });

  module.exports = TrackingRouter;
});
