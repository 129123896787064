define('customersTabs',[
	'underscore',
  'rolesMixin'
], function (_,
             RolesMixin) {
  'use strict';

  var customersTabs = [
      {
        route: 'profile',
        name: 'profile',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE,
        title: _.i18n('endUsersDetail.profile.tabTitle')
      }, {
        route: 'logosimages',
        name: 'logosimages',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_LOGOSIMAGES,
        title: _.i18n('endUsersDetail.logosImages.tabTitle')
      }, {
        route: 'datasets',
        name: 'datasets',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_DATASETS,
        title: _.i18n('endUsersDetail.dataset.tabTitle')
      }, {
        route: 'producttemplates',
        name: 'producttemplates',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PRODUCTTEMPLATES,
        title: _.i18n('endUsersDetail.corporateTemplates.tabTitle')
      }, {
        route: 'templates',
        name: 'templates',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_TEMPLATES,
        title: _.i18n('endUsersDetail.templates.tabTitle')
      }, {
        route: 'designelements',
        name: 'designelements',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_DESIGNELEMENTS,
        title: _.i18n('endUsersDetail.design-elements.tabTitle')
      }, {
          route: 'orders',
          name: 'orders',
          role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ORDERS,
          title: _.i18n('endUsersDetail.orders.tabTitle')
      }, {
          route: 'offers',
          name: 'offers',
          role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_OFFERS,
          title: _.i18n('endUsersDetail.offers.tabTitle')
      }, {
        route: 'attachments',
        name: 'attachments',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ATTACHMENTS,
        title: 'Attachments'
      }, {
        route: 'proofsheets',
        name: 'proofsheets',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ATTACHMENTS,
        title: 'Proofsheets'
      }, {
        route: 'fonts',
        name: 'fonts',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_FONTS,
        title: _.i18n('font.custom')
      }, {
        route: 'contacts',
        name: 'contacts',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS,
        title: _.i18n('endUsersDetail.contacts.tabTitle')
      },
      {
        route: 'calls',
        name: 'calls',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CALLS,
        title: _.i18n('endUsersDetail.calls.tabTitle')
      },
      {
        route: 'mails',
        name: 'mails',
        role : RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_MAILS,
        title: _.i18n('endUsersDetail.mails.tabTitle')
      },
    {
      route: 'commandmessages',
      name: 'commandmessages',
      role : RolesMixin.DASHBOARD_ORDERS_CUSTOMER_MESSAGES,
      title: _.i18n('endUsersDetail.command.ticket.messages.tabTitle')
    }
    ];

  return customersTabs;
});
