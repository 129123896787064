/**
 * Created by GSP on 27/08/2017.
 */
define('callsRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'underscore',
    'marionette',
    'app',
    'itemPagesNameMixin'
], function (
    require,
    exports,
    module,
    Logger,
    _,
    Marionette,
    App,
    ItemPagesNameMixin
) {
    'use strict';

    var CallsRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'calls': 'showListView'
        },
        onRoute: function() {
            $('#customer-comments-sidebar-region').hide();
        }
    });

    var API = {

        showListView: function () {
            require([
                'callsListController',
                'contentShowController'
            ], function (
                ListController,
                ContentShowController
            ) {
                ContentShowController.showContent().done(function () {
                    ListController.show();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.PHONE_CALLS);
                });
            });
        }
    };

    App.on('before:start', function () {
        new CallsRouter({
            controller: API
        });
    });

    module.exports = CallsRouter;
});
