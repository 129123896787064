
define('template!groupsLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="hbox folders-container">\r\n\t<aside class="groups-column-container">\r\n\t\t';
 if (showAddButton) { ;
__p += '\r\n\t\t\t<div class="add-new-block">\r\n\t\t\t  <div class="btn js-add-new add-btn-text">\r\n\t\t\t    <i class="mdi-content-add icon"></i>\r\n          <span>' +
((__t = ( _.i18n('common.new') )) == null ? '' : __t) +
'</span>\r\n\t\t\t  </div>\r\n\t\t\t</div>\r\n\t\t';
 } ;
__p += '\r\n\t\t\r\n\t\t<div id="groups-column" class="groups-column"></div>\r\n\t</aside>\r\n\t<section class="elements-column-container">\r\n\t\t<div id="elements-column" class="elements-column vbox"></div>\r\n\t</section>\r\n</div>';

}
return __p
};});

