
define('template!globalSearchViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class= "js-global-search-field" style="position: absolute;top: 20px;right: 25%;\n                display:flex;font-size: 14px;color:grey">\n  <div>\n    <input class="js-search-input" type="text" size="30" value="' +
((__t = ( currentSearch )) == null ? '' : __t) +
'" spellcheck="false">\n    <div class="progress progress-striped active js-progress-bar" style="display: none">\n      <div class="progress-bar" style="width: 100%"></div>\n    </div>\n  </div>\n  <div style="margin-left:10px;">\n    <i class="mdi-action-search js-global-search" style="float: right;color:lightgrey"/>\n  </div>\n  <div class="dropdown js-search-dropdown" style="position:static">\n    <button class="btn btn-primary dropdown-toggle" id="menu1" type="button" data-toggle="dropdown" style="display:none">\n      <span class="caret"></span></button>\n    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1" style="width:500px;">\n      ';
 var currentType = '';
__p += '\n      ';
 var typeIndex = 0;
__p += '\n      ';
 var currentClass = '';
__p += '\n      ';
 if (searchResults && searchResults.length > 0) {;
__p += '\n        ';
 _.each(searchResults, function(searchResult) { ;
__p += '\n          ';
 if (currentType === '') { ;
__p += '\n            <li style="font-size: 15px;font-weight: bold"> ' +
((__t = ( searchResult.type )) == null ? '' : __t) +
'</li>\n            ';
 currentType = searchResult.type ;
__p += '\n            ';
 typeIndex = 0 ;
__p += '\n          ';
 } else { ;
__p += '\n            ';
  if (currentType !== searchResult.type) {;
__p += '\n              ';
 if (typeIndex > 5) {;
__p += '\n                <li>\n                  <button class="js-show-more" data-type="' +
((__t = (currentType.toLowerCase())) == null ? '' : __t) +
'"\n                  style="border: none;color: black;width: 100%;\n                  margin-top: -1px;">. . .</button>\n                </li>\n              ';
 } ;
__p += '\n              <li style="font-size: 15px;font-weight: bold"> ' +
((__t = ( searchResult.type )) == null ? '' : __t) +
'</li>\n              ';
 currentType = searchResult.type ;
__p += '\n              ';
 typeIndex = 0 ;
__p += '\n            ';
  } ;
__p += '\n          ';
 } ;
__p += '\n          ';
 typeIndex += 1 ;
__p += '\n          ';
 currentClass = (typeIndex > 5)?'js-hide-five-'+currentType.toLowerCase():'' ;
__p += '\n          <li class="js-navigate ' +
((__t = ( currentClass)) == null ? '' : __t) +
'" data-route="' +
((__t = ( searchResult.route)) == null ? '' : __t) +
'"\n              style="color:black">' +
((__t = ( searchResult.description )) == null ? '' : __t) +
'</li>\n        ';
 }); ;
__p += '\n        ';
 if (typeIndex > 5) {;
__p += '\n          <li class="js-show-more">\n            <button class="js-show-more" data-type="' +
((__t = (currentType.toLowerCase())) == null ? '' : __t) +
'"\n                    style="border: none;color: black;width: 100%;\n                    margin-top: -1px;">. . .</button></li>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n    </ul>\n  </div>\n</div>';

}
return __p
};});

