
define('template!imageSliderTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="js-slider">\n  ';
 _.each(imageUrls, function(imageUrl) { ;
__p += '\n    <div class="image-centered-block cpz-slider-top-image">\n      <img src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'"/>\n    </div>\n  ';
 }) ;
__p += '\n</div>\n<div class="js-slider-nav">\n  ';
 _.each(imageUrls, function(imageUrl) { ;
__p += '\n    <div class="image-centered-block cpz-slider-bottom-image">\n      <img src="' +
((__t = ( imageUrl )) == null ? '' : __t) +
'"/>\n    </div>\n  ';
 }) ;
__p += '\n</div>';

}
return __p
};});

