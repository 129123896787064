define('ordersKanbanCommentPopupView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'backbone',
  'settings',
  'template!ordersKanbanCommentPopupTpl',
  'dialogFormView'
], function(
  module,
  Marionette,
  $,
  _,
  Backbone,
  Settings,
  viewTpl,
  DialogFormView
) {
  'use strict';

  var AssignPopupView = DialogFormView.extend({
    template: viewTpl,

    events: {
      'change @ui.input': 'onInputChange'
    },

    ui: {
      input: '.js-info-input'
    },

    onShow: function() {
      $.material.init();
    }
  });

  module.exports = AssignPopupView;
});
