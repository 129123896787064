/**
 * Created by RKL on 13/08/2015.
 */
define('customerProfileView',[
  'module',
  'customerCreateEditLayoutView',
  'underscore',
  'jquery',
  'settings',
  'marionette',
  'dateUtils',
  'template!customerProfileTabTpl'
], function (
  module,
  CreateEditLayoutView,
  _,
  $,
  Settings,
  Marionette,
  dateFormatter,
  viewTpl
) {
  'use strict';

  var CustomersInfoView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    ui: {
      regionSirenInfo: '.region-siren-info',
      sirenInfo: '.siren-info'
    },

    events: {
      'click .js-edit': '_onEdit',
      'click .js-copy-invoice-address': '_onCopyToClipboard',
      'click .js-copy-delivery-address': '_onCopyToClipboard'
    },

    serializeData: function () {
      var templateData = this.model.toJSON(),
        secId = this.model.get('secId'),
        countryCode, countryCodeDelivery;

      templateData.viewLess = secId;
      templateData.newItem = this.options.isNew;
      countryCode = this.model.get('countryCode');
      templateData.country = this.getCountryName(countryCode) ? this.getCountryName(countryCode).name : null;

      if (templateData.firstContactPhone) {
        templateData.firstContactPhone = dateFormatter.toDateTimeFormatString(templateData.firstContactPhone);
      }
      if (templateData.firstContactMail) {
        templateData.firstContactMail = dateFormatter.toDateTimeFormatString(templateData.firstContactMail);
      }
      if (templateData.firstContactVoiceMail) {
        templateData.firstContactVoiceMail = dateFormatter.toDateTimeFormatString(templateData.firstContactVoiceMail);
      }

      countryCodeDelivery = this.model.get('refDeliveryAddress').countryCode;
      templateData.countryDelivery = this.getCountryName(countryCodeDelivery) ? this.getCountryName(countryCodeDelivery).name : null;

      if (templateData.email) {
        var mail = templateData.email;
        templateData.email = '<a href="https://mail.google.com/mail/u/0/?view=cm&to=' + mail + '" target="_blank">' +
          mail + '</a></br> ' + _.i18n('orderDetail.history.tabTitle') + ': ' +
          '<a href="https://mail.google.com/mail/#search/from%3A(' + mail + ')" target="_blank">' +
          _.i18n('common.received') + '</a>, ' +
          '<a href="https://mail.google.com/mail/#search/to%3A(' + mail + ')" target="_blank">' +
          _.i18n('common.sent') + '</a>';
      }

      return templateData;
    },

    onRender: function () {
      if (this.model.get('vatNumber') && this.model.get('vatNumber').indexOf('FR') === 0) {
        $.ajax({
          method: 'get',
          url: Settings.url('https://bxbp3ch1m7.execute-api.eu-west-1.amazonaws.com/prod/api-insee', '', {
            vatNumber: this.model.get('vatNumber')
          }),
          contentType: 'application/json',
          success: _.bind(function (data) {
            delete data.siret.header;
            delete data.siren.header;
            this.deleteNullProperties(data);
            if (this.ui.regionSirenInfo && this.ui.regionSirenInfo.text) {
              this.ui.regionSirenInfo.text(JSON.stringify(data, null, 4));
            }
            this.ui.sirenInfo.show();
          }, this)
        });
      } else {
        this.ui.sirenInfo.hide();
      }
    },

    deleteNullProperties: function (obj) {
      for (var prop in obj) {
        if (obj[prop] === null) {
          delete obj[prop];
        } else if (typeof obj[prop] === 'object') {
          this.deleteNullProperties(obj[prop]);
        }
      }
    },

    _onEdit: function () {
      var createEditView = new CreateEditLayoutView({
        customerModel: this.model,
        newItem: false,
        countries: this.options.countries,
        priceLists: this.options.priceLists
      });
      createEditView.show({
        title: _.i18n('customers.edit')
      });
    },
    _onCopyToClipboard: function (e) {
      var clipboard = '',
        country;
      if ($(e.target).hasClass('js-copy-delivery-address')) {
        var tmpModel = this.model.get('refDeliveryAddress');
        country = this.getCountryName(tmpModel.countryCode);
        clipboard = (tmpModel.name ? tmpModel.name : '') + '\n' +
          (tmpModel.lastName ? tmpModel.lastName : '') + ' ' +
          (tmpModel.firstName ? tmpModel.firstName : '') + '\n\n' +
          (tmpModel.addressL1 ? tmpModel.addressL1 : '') + '\n' +
          (tmpModel.addressL2 ? tmpModel.addressL2 : '') + '\n' +
          (tmpModel.houseNumber ? tmpModel.houseNumber : '') + '\n' +
          (tmpModel.zip ? tmpModel.zip : '') + ' ' +
          (tmpModel.city ? tmpModel.city : '') + ' ' + '\n' +
          (country ? country.name : '') + '\n';
      } else {
        country = this.getCountryName(this.model.get('countryCode'));
        clipboard = (this.model.get('name') ? this.model.get('name') : '') + '\n' +
          (this.model.get('lastName') ? this.model.get('lastName') : '') + ' ' +
          (this.model.get('firstName') ? this.model.get('firstName') : '') + '\n\n' +
          (this.model.get('addressL1') ? this.model.get('addressL1') : '') + '\n' +
          (this.model.get('addressL2') ? this.model.get('addressL2') : '') + '\n' +
          (this.model.get('zip') ? this.model.get('zip') : '') + ' ' +
          (this.model.get('city') ? this.model.get('city') : '') + ' ' + '\n' +
          (country ? country.name : '') + '\n';
      }

      navigator.clipboard.writeText(clipboard);
    },

    getCountryName: function (countryCode) {
      return _.findWhere(this.options.countries, {code: countryCode});
    }

  });

  module.exports = CustomersInfoView;
});
