define('leadCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'jquery',
  'dialogFormView',
  'template!leadCreateEditLayoutTpl',
  'imageUtils',
  'imageUploadBehavior',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'settings',
  'activitiesUtils',
  'dateUtils'
], function (
  module,
  Marionette,
  Backbone,
  $,
  DialogFormView,
  createEditLayoutTpl,
  imageUtils,
  ImageUploadBehavior,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView,
  Settings,
  ActivitiesUtils,
  dateConverter
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      logo: '.js-logo-holder',
      logoDiv: 'div.js-logo-holder',
      imgBlock: 'div.profile-img-block',
      changeLogo: '.js-change-logo',
      removeLogo: '.js-remove-logo',
      input: '.js-info-input',
      inputDelivery: '.js-info-input-delivery',
      endUserIdInput: '.js-end-user-id',
      vatArea: '.vat-area',
      subjectToVat: '#subjectToVat',
      vatInput: '#vat-input',
      siretInput: '.siret-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      langlabel: '.lang-label',
      subscribed: '#subscribed',
      invalidVat: '.invalid-vat-number',
      validVat: '.valid-vat-number',
      vatServerNotReachable: '.vat-server-not-reachable',
      checkingVatNumber: '.checking-vat-number',
      mainActivityInput: '.js-activity-input',
      subActivityInput: '.js-sub-activity-input'
    },

    events: {
      'click @ui.removeLogo': 'onRemoveLogo',
      'click .js-cancel-popup': 'hide',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange',
      'change @ui.inputDelivery': 'onInputDeliveryChange',
      'click .js-confirm': 'onConfirm',
      'change @ui.subjectToVat': 'onChangeSubjectToVat',
      'change @ui.subscribed': 'onChangeSubscribed',
      'click .dropdown-menu-lang a': 'onChangeLanguage',
      'click .js-copy-address': 'onCopyInvoiceAddress',
      'change @ui.vatInput': 'onChangeVAT',
      'click .btn-vat-from-siret': 'onVatFromSiretClick',
      'change @ui.mainActivityInput': 'onMainActivityChange',
      'change .js-info-input-date': 'onDateInputChange'
    },

    regions: {
      selectCountries: '.js-select-countries-region',
      selectCountriesDelivery: '.js-select-countries-delivery-region'
    },

    className: 'profile-create-edit',

    behaviors: {
      ImageUpload: {
        behaviorClass: ImageUploadBehavior
      },
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'email', type: 'multipleEmail'}
    ],

    onDateInputChange: function (e) {
      var $target = $(e.currentTarget),
        value = $target.val(),
        fieldName = $target.data('field-name');
      this.onChange(fieldName, dateConverter.formatTUtc(value));
      this.triggerMethod('enable:cancel:confirm');
    },

    onCopyInvoiceAddress: function () {
      var delAddress = this.model.get('refDeliveryAddress');
      delAddress.name = this.model.get('name');
      delAddress.firstName = this.model.get('firstName');
      delAddress.lastName = this.model.get('lastName');
      delAddress.addressL1 = this.model.get('addressL1');
      delAddress.addressL2 = this.model.get('addressL2');
      delAddress.zip = this.model.get('zip');
      delAddress.city = this.model.get('city');
      delAddress.countryCode = this.model.get('countryCode');
      this.render();
      this.onShow();
      this.triggerMethod('enable:cancel:confirm');
    },

    onMainActivityChange: function () {
      var selectedMainActivity = this.ui.mainActivityInput.find('option:selected');
      var activityClass = selectedMainActivity.data('class');

      var subActivities = [];
      for (var prop in ActivitiesUtils.subActivity) {
        if (prop.indexOf(activityClass) === 0) {
          subActivities.push(ActivitiesUtils.subActivity[prop]);
        }
      }
      subActivities = _.sortBy(subActivities);
      this.ui.subActivityInput.empty();
      this.ui.subActivityInput.append('<option></option>');
      _.each(subActivities, _.bind(function (subActivity) {
        this.ui.subActivityInput.append('<option ' + (subActivity === this.model.get('subActivity') ?
          'selected' : '') + '>' + subActivity + '</option>');
      }, this));
      this.triggerMethod('enable:cancel:confirm');
    },

    /*jshint -W061 */
    onVatFromSiretClick: function () {
      var sSiren = this.ui.siretInput.val().substr(0, 9);
      var lChaine = '' + sSiren;
      lChaine = '' + this.EpureNbr(lChaine);

      // Formule :
      // Clef TVA = ( ( (Siren Modulo 97) * 3 ) + 12 ) Modulo 97

      var sClef = '';
      sClef = (((eval(sSiren) % 97) * 3) + 12) % 97;
      // Modif Janvier 2012
      if (eval(sClef) < 10) {
        sClef = '0' + sClef;
      }

      this.ui.vatInput.val('FR' + sClef + sSiren);
      this.ui.vatInput.change();
    },

    /*jshint -W061 */
    EpureNbr: function (sTexte) {
      var i = 0;
      var lChaine = '' + sTexte;
      while (i < eval(lChaine.length)) {
        if (lChaine.charCodeAt(i) < 48 || lChaine.charCodeAt(i) > 57) {
          lChaine = lChaine.substring(0, i) + lChaine.substring(i + 1, lChaine.length);
          i--;
        }
        i++;
      }
      return lChaine;
    },

    onInputDeliveryChange: function (e) {
      var $target = $(e.currentTarget),
        value = $target.val(),
        fieldName = $target.data('field-name');

      this.onChangeDelivery(fieldName, value);
    },

    onChangeDelivery: function (fieldName, value) {
      this.model.get('refDeliveryAddress')[fieldName] = value;
      this.triggerMethod('enable:cancel:confirm');
    },
    onChangeVAT: function (e) {
      var $target = $(e.currentTarget),
        value = $target.val();
      this.checkVat(value);
    },

    checkVat: function (value) {
      var that = this;
      if (!value.match('^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$')) {
        this.ui.invalidVat.attr('style', 'display:inline;color:orange');
      } else {
        var url = Settings.url('compuzz2', 'v2/vatservice/checkVatNumber?countryCode=' +
          value.substr(0, 2) +
          '&VATNumber=' + value.substr(2, value.length));
        this.ui.checkingVatNumber.attr('style', 'display:inline;color:color:#85ac85;');

        $('.checkingVatNumber').fadeOut(500).fadeIn(500);
        $.ajax({
          type: 'GET',
          url: url,
          success: function (resp) {
            if (resp === false) {
              that.ui.invalidVat.attr('style', 'display:inline;color:orange');
              that.ui.validVat.attr('style', 'display:none;color:green');
              that.model.set('vatNumberChecked', true);
              that.model.set('vatNumberValid', false);
              that.ui.vatServerNotReachable.attr('style', 'display:none');
            } else {
              that.model.set('vatNumberChecked', true);
              that.model.set('vatNumberValid', true);
              that.ui.vatServerNotReachable.attr('style', 'display:none');
              that.ui.validVat.attr('style', 'display:inline;color:green');
              that.ui.invalidVat.attr('style', 'display:none');
              that.triggerMethod('enable:cancel:confirm');

            }
            that.ui.checkingVatNumber.attr('style', 'display:none');
          },
          error: function () {
            that.ui.vatServerNotReachable.attr('style', 'display:inline;color:orange');
            that.ui.validVat.attr('style', 'display:none;color:green');
            that.ui.checkingVatNumber.attr('style', 'display:none');
          }
        });
      }
    },

    initialize: function (options) {
      this.model = options.leadModel.clone();
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;

      if (templateData.firstContactPhone) {
        templateData.firstContactPhone = dateConverter.formatT(templateData.firstContactPhone);
      }
      if (templateData.firstContactMail) {
        templateData.firstContactMail = dateConverter.formatT(templateData.firstContactMail);
      }
      if (templateData.firstContactVoiceMail) {
        templateData.firstContactVoiceMail = dateConverter.formatT(templateData.firstContactVoiceMail);
      }

      templateData.mainActivities = [];
      for (var prop in ActivitiesUtils.mainActivity) {
        templateData.mainActivities.push({
          code: prop,
          value: ActivitiesUtils.mainActivity[prop]
        });
      }
      templateData.mainActivities = _.sortBy(templateData.mainActivities, 'value');

      return templateData;
    },

    onShow: function () {
      var countriesView = new AutocompleteView(
        this._getCountriesAutocompleteOptions()
      );
      this.getRegion('selectCountries').show(countriesView);


      var countriesViewDelivery = new AutocompleteView(
        this._getCountriesDeliveryAutocompleteOptions()
      );
      this.getRegion('selectCountriesDelivery').show(countriesViewDelivery);

      this.displayLogo(this.model.get('logoUid'), this.model.get('code'));

      this.ui.vatArea.toggleClass('disabled', this.model.get('subjectToVat'));

      var subjectToVat = this.model.get('subjectToVat') || false;
      this.ui.vatArea.toggleClass('hidden', !subjectToVat);
      this.ui.subjectToVat.prop('checked', subjectToVat);

      var newLang = this.model.get('locales') || 'None';
      this.ui.langlabel.text(newLang);

      var subscribed = this.model.get('subscribed') || true;
      this.ui.subscribed.prop('checked', subscribed);

      $.material.init();

      this.initTokenFields();
      if (this.model.get('subjectToVat') &&
        (!this.model.get('vatNumberChecked') || !this.model.get('vatNumberValid'))) {
        this.checkVat(this.model.get('vatNumber'));
      }

      this.onMainActivityChange();
    },

    initTokenFields: function () {
      // these variables are used to fix email input field appearance
      var value = this.$('#emailInput').val(),
        leadValue = this.$('#CustomerEmailInput').val(),
        emailTokenCount = value ? value.split(';').length : 0,
        leadEmailTokenCount = leadValue ? leadValue.split(';').length : 0;

      if (emailTokenCount > 0) {
        this.$el.find('[data-field-name="email"] .floating-label').addClass('floating-label-focused');
      }

      this.$('#emailInput').tokenfield({
        createTokensOnBlur: true,
        inputType: 'email',
        delimiter: [';', ',', ' ']
      }).on('tokenfield:createdtoken', _.bind(function (e) {
        this.onTokenFieldInputChange(e);
        emailTokenCount++;
      }, this))
        .on('tokenfield:tokenEdited', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
          emailTokenCount--;
        }, this));

      // fix conflicts between materializecss and tokenfield plugin
      this.$('[data-field-name="email"]').off('focusin').focusin(function () {
        $(this).find('.floating-label').addClass('floating-label-focused selected');
      }).off('focusout').focusout(function () {
        if ($(this).find('#emailInput-tokenfield').val() === '' && emailTokenCount === 0) {
          $(this).find('.floating-label').removeClass('floating-label-focused');
        }
        $(this).find('.floating-label').removeClass('selected');
      });

      this.$('#CustomerEmailInput').tokenfield({
        createTokensOnBlur: true,
        inputType: 'email',
        delimiter: [';', ',', ' ']
      }).on('tokenfield:createdtoken', _.bind(function (e) {
        this.onTokenFieldInputChange(e);
        leadEmailTokenCount++;
      }, this))
        .on('tokenfield:tokenEdited', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function (e) {
          this.onTokenFieldInputChange(e);
          leadEmailTokenCount--;
        }, this));
    },

    onChangeLanguage: function (e) {
      e.preventDefault();
      var newLang = $(e.target).text();
      this.model.set('locales', newLang);
      this.ui.langlabel.text(newLang);
      this.triggerMethod('enable:cancel:confirm');
    },

    onTokenFieldInputChange: function (e) {
      this.onInputChange(e);
      this.triggerMethod('enable:cancel:confirm');
    },

    displayLogo: function (logo, code, remove) {
      var image = imageUtils.getLogo(logo, code);

      if (image) {
        this.ui.logo.removeClass('hidden');
        this.ui.imgBlock.removeClass('hidden');
        this.ui.logo.attr('src', image);
        if (!this.ui.logoDiv.hasClass('hidden')) {
          this.ui.logoDiv.addClass('hidden');
        }
      } else {
        if (remove) {
          this.ui.logo.attr('src', '');
        }
        this.ui.logo.attr('src', '').addClass('hidden');
        this.ui.imgBlock.addClass('hidden');
        this.ui.logoDiv.removeClass('hidden');
      }

      if (!this.model.get('logoUid')) {
        this.ui.removeLogo.hide();
      } else {
        this.ui.removeLogo.show();
      }
    },

    onImageUploadConfirm: function (base64Value, fileExt) {
      this.model.set('encodedValue', base64Value);
      this.model.set('extension', fileExt);
      this.ui.logo.removeClass('hidden');
      this.ui.imgBlock.removeClass('hidden');

      if (!base64Value) {
        this.ui.logo.attr('src', imageUtils.getLogo(base64Value, this.model.get('code')));
        this.ui.removeLogo.hide();
        this.ui.logo.attr('src', '').addClass('hidden');
        this.ui.imgBlock.addClass('hidden');
        this.ui.logoDiv.removeClass('hidden');
      } else {
        this.ui.logo.attr('src', 'data:image/png;base64,' + base64Value);
        this.ui.removeLogo.show();
      }

      if (!this.ui.logoDiv.hasClass('hidden') && base64Value) {
        this.ui.logoDiv.addClass('hidden');
      }

      this.triggerMethod('enable:cancel:confirm');
    },

    onRemoveLogo: function () {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.ui.changeLogo.val('');
          this.model.set('logoUid', null);
          this.onImageUploadConfirm(null, null);
        }
      }, this));
    },

    onConfirm: function () {
      if (this.options.newItem === true) {
        this.fieldsToValidate.push(
          {name: 'endUserId', type: 'required'}
        );
      }

      if (this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    onChangeSubscribed: function () {
      this.model.set('subscribed', this.ui.subscribed.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeSubjectToVat: function () {
      this.model.set('subjectToVat', this.ui.subjectToVat.is(':checked'));
      this.ui.vatArea.toggleClass('hidden', !(this.ui.subjectToVat.is(':checked')));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangePriceList: function (model) {
      this.model.set('priceListCode', model.get('code'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onSave: function (model) {
      var modelJSON = model.toJSON();
      this.options.leadModel.set(modelJSON);
      this.options.leadModel.trigger('change', this.options.leadModel);
      this.triggerMethod('saved');
      App.trigger('lead:header:rerender', modelJSON);
      App.navigate('leads/' + model.get('secId'), {trigger: true});
      this.hide();
    },

    onSaveError: function (model, response) {
      if (response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function () {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=endUserId').addClass('has-error');
    },

    _getCountriesAutocompleteOptions: function () {
      var countryCode, countryValue, country;

      countryCode = this.model.get('countryCode') || '';
      country = _.findWhere(this.options.countries, {code: countryCode});
      countryValue = country ? country.name : null;

      return {
        data: this.options.countries,
        valueKey: 'name',
        apiKey: 'id',
        limit: this.options.countries.length,
        placeholder: _.i18n('common.countryCode'),
        value: countryValue,
        name: 'countryCode',
        callback: _.bind(this.onChangeCountry, this)
      };
    },

    _getCountriesDeliveryAutocompleteOptions: function () {
      var countryCode, countryValue, country;

      countryCode = this.model.get('refDeliveryAddress').countryCode || '';
      country = _.findWhere(this.options.countries, {code: countryCode});
      countryValue = country ? country.name : null;

      return {
        data: this.options.countries,
        valueKey: 'name',
        apiKey: 'id',
        limit: this.options.countries.length,
        placeholder: _.i18n('common.countryCode'),
        value: countryValue,
        name: 'countryCode',
        callback: _.bind(this.onChangeCountryDelivery, this)
      };
    },

    _getPriceListAutoCompleteOptions: function () {
      return {
        modelProperty: 'code',
        modelDisplayProperty: 'name',
        modelSelectValue: this.model.get('priceListCode'),
        collection: new Backbone.Collection(this.options.priceLists),
        onItemSelected: _.bind(this.onChangePriceList, this)
      };
    },

    onChangeCountryDelivery: function (fieldName, model) {
      var code = model ? model.get('code') : '';
      this.onChangeDelivery(fieldName, code);
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeCountry: function (fieldName, model) {
      var code = model ? model.get('code') : '';

      this.onChange(fieldName, code);
      this.triggerMethod('enable:cancel:confirm');
    },

    onBeforeDestroy: function () {
      this.$('#emailInput').tokenfield('destroy');
      this.$('#CustomerEmailInput').tokenfield('destroy');
    }
  });

  module.exports = CreateEditLayoutView;
});
