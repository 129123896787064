/**
 * Created by OLD on 04/09/2017.
 */
define('ordersKanbanView',[
  'module',
  'underscore',
  'jquery',
  'backbone',
  'marionette',
  'app',
  'settings',
  'notificationCenter',
  'logotoolUtils',
  'template!ordersKanbanViewTpl',
  'rolesMixin',
  'entities/orders_kanban'
], function (
  module,
  _,
  $,
  Backbone,
  Marionette,
  App,
  Settings,
  NotificationCenter,
  logotoolUtils,
  viewTpl,
  RolesMixin
) {
  'use strict';

  var OrdersKanbanView =  Marionette.LayoutView.extend({
    template: viewTpl,    

    ui: {
      downloadPdf: '.orders-pdf-download'
    },

    regions: {
      kanbanFilters: '#kanbanorder-filters-region',
      kanbanBoard: '#kanbanorder-board-region'
    },

    serializeData: function () {
      var templateData = {};

      templateData.newOrderLogotoolUrl = logotoolUtils.getLogotoolCataloguesUrl({
        returnUrl: window.location.href,
        endUserSecId: 'new'
      });

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        templateData.design2 = true;
      } else {
        templateData.design2 = false;
      }

      return templateData;
    },

    onShow: function() {
      $.material.init();
      NotificationCenter.getInstance().addListener(
        this, 
        NotificationCenter.events.CHANGE_INFO_ORDER, 
        this.onMessage
      );   
    },

    onMessage: function(response) {
      $.when(App.request('order-kanban:new:model', response.content))
        .done(_.bind(function(model) {
          var index = -1;
          _.each(this.options.cardCollections, _.bind(function(item) {
            index = _.indexOf(item.collection.models,_.findWhere(item.collection.models,{id: model.get('poi')}));
            model.set('cardColor',response.content.cardColor);
            if (item.collection.get(model.get('poi'))) {
              item.collection.remove(model.get('poi'));
            }
            if (item.status === response.content.status && response.content.commandType === 'command') {
              if (index !== -1) {
                item.collection.add(model, {at: index});
              } else {
              item.collection.add(model);
               }
              index = -1;
            }
          }, this));
        }, this));
    }
  });

  module.exports = OrdersKanbanView;
});

