/**
 * Created by RKL on 21/08/2015.
 */
define('leadsMain',[
  'module',
  'logger',
  'leadsMainRouter'
], function (
  module,
  Logger
) {
  'use strict';

  var Leads = {
    start: function () {
      Logger.log(module, 'starting LeadsModule');
    },

    stop: function () {
      Logger.log(module, 'stopping LeadsModule');
      $('#customer-comments-sidebar-region').hide();
    }
  };

  module.exports = Leads;
});
