define('entities/caccounts/share',[
  'app',
  'jquery',
  'backbone',
  'underscore',
  'settings'
], function (
  App,
  $,
  Backbone,
  _,
  Settings
) {
  'use strict';

  /**
   * REST API
   */

  var CAccountShare = Backbone.Model.extend({
    defaults: {
      comment: ''
    },

    url: function () {
      return Settings.url('compuzz2', 'v2/caccountshare');
    },

    idAttribute: 'secId'
  });

  var CAccountInfo = Backbone.Model.extend({
    defaults: {
      comment: ''
    }
  });

  var API = {
    getShare: function (data) {
      var defer = $.Deferred(),
        url = Settings.url(
          'compuzz2',
          'v2/caccountshare/' + data.cAccountId + '/' + data.share
        );

      $.ajax({
        url: url,
        success: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    postShare: function (data) {
      var url = Settings.url('compuzz2', 'v2/caccountshare');

      return $.ajax({
        url: url,
        type: 'POST',
        data: JSON.stringify(data),
        contentType: 'application/json'
      });
    },

    deleteShare: function (data) {
      var url = Settings.url('compuzz2', 'v2/caccountshare');

      return $.ajax({
        url: url,
        type: 'DELETE',
        data: JSON.stringify(data),
        contentType: 'application/json'
      });
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('caccounts:share-model', function (data) {
    return new CAccountShare(data);
  });

  App.reqres.setHandler('caccounts:get-share', function (data) {
    return API.getShare(data);
  });

  App.reqres.setHandler('caccounts:get-share-from-sorted', function () {
    var params = {
      cAccountId: Settings.get('currentCAccount').secId,
      share: 'from'
    };
    var defer = $.Deferred();
    $.when(API.getShare(params)).done(_.bind(function (list) {
      list.sort(function (a, b) {
        if (a.caccountFrom.code.toUpperCase() > b.caccountFrom.code.toUpperCase()) {
          return 1;
        }
        if (b.caccountFrom.code.toUpperCase() > a.caccountFrom.code.toUpperCase()) {
          return -1;
        }
        return 0;
      });
      defer.resolve(list);
    }, this));
    return defer;
  });

  App.reqres.setHandler('caccounts:post-share', function (data) {
    return API.postShare(data);
  });

  App.reqres.setHandler('caccounts:delete-share', function (data) {
    return API.deleteShare(data);
  });

  App.reqres.setHandler('caccounts:get-supplier-info', function (supplierCode) {
    var defer = $.Deferred(),
      url = Settings.url('compuzz2', 'v2/caccountshare/supplierInfo', {supplierCode: supplierCode});

    $.ajax({
      type: 'GET',
      url: url,
      success: function (result) {
        defer.resolve(new CAccountInfo(result));
      }
    });

    return defer.promise();
  });
});
