
define('template!orderTabsHeaderTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (!design2 && pim) { ;
__p += '\n<div class="vertical-divider col-lg-1"></div>\n<div class="col-left name-holder pim-icon">\n    <label>PIM</label>\n</div>\n';
 } ;
__p += '\n<div class="vertical-divider col-lg-1"></div>\n<div class="col-left name-holder">\n    <p>' +
((__t = ( _.i18n("orderList.orderNumber") )) == null ? '' : __t) +
': ' +
((__t = ( externalNumber )) == null ? '' : __t) +
'</p>\n    <p id="order-header-status">' +
((__t = ( status )) == null ? '' : __t) +
'</p>\n    <p>' +
((__t = ( _.i18n("orderList.agentId") )) == null ? '' : __t) +
': ' +
((__t = ( agentId )) == null ? '' : __t) +
'</p>\n</div>\n<div class="warning-price" style="float:left; display: none;">\n    <i class="mdi-alert-warning clickable" style="font-size:38px;" title="' +
((__t = ( _.i18n(" orderList.priceDiff") )) == null ? '' : __t) +
'"></i>\n</div>\n';
 if (productHasDifferentConf) { ;
__p += '\n<i class="mdi-action-highlight-remove" title="' +
((__t = ( _.i18n('common.checkSupplierPrintData') )) == null ? '' : __t) +
'"\n   style="font-size: 33px; color: red;"></i>\n';
 } ;
__p += '\n<div class="supplier-info-region" style="float: left;"></div>\n<div class="vertical-divider col-lg-1"></div>\n<div class="col-left">\n    ';
 if (webshopUrl) { ;
__p += '\n    <a class="btn btn-primary btn-sm" href="' +
((__t = ( webshopUrl)) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( _.i18n('menu.account') )) == null ? '' : __t) +
'</a>\n    <a class="btn btn-primary btn-sm" href="' +
((__t = ( webshopProofSheet)) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( _.i18n('common.proofsheet')
        )) == null ? '' : __t) +
'</a>\n    <a class="btn btn-primary btn-sm" href="' +
((__t = ( emailLink)) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( _.i18n('menu.mails') )) == null ? '' : __t) +
'</i></a>\n    ';
 } ;
__p += '\n    <div class="region-user-assign"></div>\n</div>\n<span class="cards-checkbox-container" style="font-size: 30px;">\n    ';
 if (actionRequired === true) { ;
__p += '\n            <i class="mdi-action-markunread-mailbox"></i>\n    ';
 } ;
__p += '\n    ';
 if (actionRequiredInternal === true) { ;
__p += '\n            <i class="mdi-editor-mode-comment"></i>\n    ';
 } ;
__p += '\n    ';
 if (actionRequiredUkr === true) { ;
__p += '\n            <i class="mdi-image-color-lens"></i>\n    ';
 } ;
__p += '\n    ';
 if (payedByCustomer) { ;
__p += '\n        <i class="mdi-editor-attach-money" title="' +
((__t = ( _.i18n('orderList.payedByCustomer') )) == null ? '' : __t) +
'" aria-hidden="true"></i>\n    ';
 } ;
__p += '\n    ';
 if (payedToManufacturer) { ;
__p += '\n        <i class="fa fa-check-circle" aria-hidden="true"></i>\n    ';
 } ;
__p += '\n    ';
 if (toInvoice) { ;
__p += '\n        ';
 if (invoiceFileUidLink) { ;
__p += '\n        <a href="' +
((__t = ( invoiceFileUidLink )) == null ? '' : __t) +
'">\n            <i class="mdi-file-folder" title="' +
((__t = ( _.i18n('orderList.invoice') )) == null ? '' : __t) +
'" aria-hidden="true"></i>\n        </a>\n        ';
 } else { ;
__p += '\n        <i class="mdi-file-folder-open" title="' +
((__t = ( _.i18n('orderList.invoice') )) == null ? '' : __t) +
'" aria-hidden="true"></i>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n    ';
 if (nbrPackSent > 0) { ;
__p += '\n        ';
 if (nbrPackSent === nbrPackDelivered) { ;
__p += '\n            <i class="mdi-maps-local-shipping icon-tracking" title="' +
((__t = ( _.i18n('orderList.delivered') )) == null ? '' : __t) +
'"\n               style="color: green;" aria-hidden="true"></i>\n        ';
 } else { ;
__p += '\n            <i class="mdi-maps-local-shipping icon-tracking" title="' +
((__t = ( _.i18n('orderList.onTheWay') )) == null ? '' : __t) +
'"\n               style="color: orange;" aria-hidden="true"></i>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n    ';
 if (nbrPackSentToPrinter > 0) { ;
__p += '\n        ';
 if (nbrPackSentToPrinter === nbrPackDeliveredToPrinter) { ;
__p += '\n            <i class="mdi-maps-local-print-shop icon-tracking" title="' +
((__t = ( _.i18n('orderList.delivered') )) == null ? '' : __t) +
'"\n               style="color: green;" aria-hidden="true"></i>\n        ';
 } else { ;
__p += '\n            <i class="mdi-maps-local-print-shop icon-tracking" title="' +
((__t = ( _.i18n('orderList.onTheWay') )) == null ? '' : __t) +
'"\n               style="color: orange;" aria-hidden="true"></i>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n</span>\n<button class="btn btn-primary js-link-phone-to-customer" data-phoneNumber="' +
((__t = ( currentPlivoCall?currentPlivoCall:'' )) == null ? '' : __t) +
'"\n' +
((__t = ( currentPlivoCall?'style=""':'style="display:none;"' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('customer.linkPhone') )) == null ? '' : __t) +
'\n</button>\n<div class="vertical-divider col-lg-1"></div>\n<div id="order-header-extra-1" class="col-left name-holder">\n    <p>' +
((__t = ( _.i18n('product.code') )) == null ? '' : __t) +
': ' +
((__t = ( productCode )) == null ? '' : __t) +
'</p>\n    ';
 if (!design2) { ;
__p += '\n    <p>' +
((__t = ( _.i18n('productList.productCodeMan') )) == null ? '' : __t) +
': ' +
((__t = ( supplierProductCode )) == null ? '' : __t) +
'</p>\n    ';
 } ;
__p += '\n    <p>' +
((__t = ( _.i18n('offerList.productOwner') )) == null ? '' : __t) +
': ' +
((__t = ( productOwner )) == null ? '' : __t) +
'</p>\n</div>\n<div class="vertical-divider col-lg-1"></div>\n<div id="order-header-extra-2" class="col-left name-holder">\n    <p>' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
': ' +
((__t = ( commandType )) == null ? '' : __t) +
'</p>\n    ';
 if (!design2 && quantity < minimumOrderQuantity) { ;
__p += '\n    <p style="color: red;">' +
((__t = ( _.i18n('orders.totalQuantity') )) == null ? '' : __t) +
': ' +
((__t = ( quantity )) == null ? '' : __t) +
' (min : ' +
((__t = ( minimumOrderQuantity
        )) == null ? '' : __t) +
')</p>\n    ';
 } else { ;
__p += '\n    <p style="' +
((__t = (outOfStock?'color:red;':'')) == null ? '' : __t) +
'">' +
((__t = ( _.i18n('orders.totalQuantity') )) == null ? '' : __t) +
': ' +
((__t = ( quantity )) == null ? '' : __t) +
'</p>\n    ';
 } ;
__p += '\n    <p>' +
((__t = ( _.i18n('orders.weight') )) == null ? '' : __t) +
': ' +
((__t = ( quantity * weight )) == null ? '' : __t) +
' ' +
((__t = ( weightUnit )) == null ? '' : __t) +
'</p>\n</div>\n<div class="vertical-divider col-lg-1"></div>\n<div class="js-action-buttons-region orders-action-button f-r"></div>\n<input type="file"\n       id="order-proofsheet-input"\n       class="hidden js-upload-proofsheet " data-icon="true"\n       multiple="single"\n       accept="application/pdf"/>\n<input type="file"\n       id="order-export-input"\n       class="hidden js-upload-export " data-icon="true"\n       multiple="single"\n       accept="application/ai"/>\n<span class="orders-back-to-list back-to-list js-back-to-list"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n';
 if (!design2) { ;
__p += '\n<i class="mdi-action-assignment-turned-in create-task f-r p-t-10"></i>\n';
 } ;
__p += '\n';

}
return __p
};});

