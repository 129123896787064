/**
 * Created by BFR on 07/10/2021.
 */
define('adminTasksView',[
  'module',
  'jquery',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'imageUtils',
  'settings',
  'jqgridView',
  'template!adminTasksTpl',
  'adminTasksCreateEditView',
  'tasksCreatorBehavior',
  'entities/admin/tasks'
], function (
  module,
  $,
  Marionette,
  _,
  Backbone,
  App,
  imageUtils,
  Settings,
  JqGridView,
  viewTpl,
  CreateEditLayoutView,
  TasksCreatorBehavior
) {
  'use strict';

  module.exports = JqGridView.extend({
    template: viewTpl,
    filtersName: 'tasksFilters',

    behaviors: function () {
      return {
        CreateTask: {
          behaviorClass: TasksCreatorBehavior
        }
      };
    },

    onDeleteEndUser: function (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.trigger('tasks:remove', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    pagination: function () {
      return {
        first: 0,
        rowCount: 0,
        pageSize: Settings.get('perPage15'),
        currentPage: 1
      };
    },

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list'
      }, this.gridUi);
    },

    events: function () {
      return _.extend({
      }, this.gridEvents);
    },

    gridOptions: function (data) {
      var defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.comment'),
              name: 'comment',
              formatter: this.defaultFormatter,
              classes: 'code-link',
              search: false,
              index: 'comment',
              sortable: false,
              width: 150
            }, {
              label: _.i18n('task.refCreator'),
              name: 'refCreator',
              formatter: this.userFormatter,
              classes: 'cell-link',
              search: true,
              index: 'onlyMine',
              sortable: false,
              width: 55,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: 'false:' + _.i18n('common.all') + ';true:' + _.i18n('task.onlyMine')
              }
            }, {
              label: _.i18n('task.refUserpid'),
              name: 'refUserpid',
              formatter: this.userFormatter,
              classes: 'cell-link',
              search: false,
              index: 'refUserpid',
              sortable: false,
              width: 55
            }, {
              label: _.i18n('task.dateDue'),
              name: 'dateDue',
              formatter: this.dateTimeFormatter,
              classes: 'cell-link',
              search: false,
              index: 'dateDue',
              sortable: false,
              width: 55
            }, {
              label: _.i18n('task.done'),
              name: 'done',
              formatter: this.checkBoxBaseFormatter,
              classes: 'cell-link',
              search: false,
              index: 'done',
              sortable: false,
              width: 55
            }, {
              label: _.i18n('task.taskType'),
              name: 'taskType',
              formatter: this.defaultFormatter,
              classes: 'cell-link',
              search: false,
              index: 'taskType',
              sortable: false,
              width: 55
            }
          ],

          onSelectRow: _.bind(function (rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              this.onEditItem(rowid);
            }
          }, this)
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onGridFilterChange: function (e) {
      this.onGridFilterChangeV2(e);
    },

    onEditItem: function (secId) {
      var m = App.request('tasks:model', {secId: secId});
      m.fetch().done(function () {
        var tasksCreatorBehavior = new TasksCreatorBehavior();
        tasksCreatorBehavior.editTask(m);
      });
    }
  });
});
