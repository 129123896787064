define('graphicalRequestKanbanColumnCardView',[
  'module',
  'jquery',
  'underscore',
  'marionette',
  'app',
  'settings',
  'moment',
  'bootbox',
  'template!graphicalRequestKanbanColumnTicketViewTpl',
  'graphicalRequestKanbanAssignPopupView',
  'graphicalRequestKanbanTimePopupView',
  'graphicalRequestKanbanHistoryPopupView',
  'imageUtils',
  'template!graphicalRequestKanbanColumnTicketItemViewTpl',
  'rolesMixin',
  'contextMenu',
  'entities/caccounts/credits'
], function(
  module,
  $,
  _,
  Marionette,
  App,
  Settings,
  moment,
  bootbox,
  itemTpl,
  PopupView,
  TimePopupView,
  HistoryPopupView,
  imageUtils,
  ticketItemTpl,
  RolesMixin
) {
  'use strict';

  var KanbanBoardCardView = Marionette.ItemView.extend({
    template: itemTpl,
    tagName: 'ul',
    className: 'draggable kanban-column sortable-item',

    events: {
      'click @ui.flag': '_flagTicket',
      'click @ui.cardItemPopover': '_showPopoverDialog',
      'click @ui.assignTo': '_onAssignTo',
      'dblclick @ui.card': '_showCardDetails'
    },

    ui: {
      hideUi: '.hide',
      flag: '.flagg-btn',
      cardItemPopover: '.card-vectorization-type',
      assignTo: '.js-assign-to',
      card: '.js-card-item'
    },

    initialize: function() {
      this.users = this.options.users;
      this.tickets = this.options.tickets;
      Settings.set('ticket-' + this.options.state, this.tickets);
      this.$el.attr('data-state', this.options.state);
    },

    serializeData: function() {
      return {
        tickets: this.tickets,
        state: this.options.state,
        renderTemplate: Marionette.Renderer.render,
        templateItem: ticketItemTpl,
        onlyAssign: Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR_ONLY_ASSIGN]
      };
    },

    onShow: function() {
      this._initSortableBoard();
      this._initContextMenu();
    },

    _initSortableBoard: function() {
      var that = this,
        startState, receivedState, ticketId;

      $('.sortable-item, .cards').sortable({
        connectWith: '.sortable-item, .cards',
        placeholder: 'highlight',
        opacity: 0.8,
        cursor: 'move',
        start: function(event, ui) {
          $(ui.item).addClass('selected');
        },
        over: function() {
          $(this).parent().find('.cards').addClass('columnHover');
        },
        out: function() {
          $(this).parent().find('.cards').removeClass('columnHover');
        },
        receive: function(event, ui) {
          startState = $(ui.sender).attr('data-state');
          receivedState = $(event.target).attr('data-state');
          ticketId = $(ui.item).attr('data-id');
          that.trigger('ticketView:onrefreshBoard', startState, receivedState, ticketId);
        },
        stop: function(event, ui) {
          $(ui.item).removeClass('selected', {duration:700});
        }
      }).disableSelection();
    },

    _initContextMenu: function() {
      var that = this;

      $.contextMenu({
        selector: '.context-menu-' + this.options.state,
        items: {
          preview: {
            name: 'Preview',
            callback: function() {
              var secId = $(this).attr('data-id');

              that._getTicketDetails(secId);
            }
          },
          sep1: '---------',
          open: {
            name: 'Details',
            callback: function() {
              var secId = $(this).attr('data-id');
              App.trigger('graphical_service_kanban:show:details', secId);
            }
          },
          sep2: '---------',
          movetop: {
            visible: function() {
              if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_MODI_MOVE_TO_TOP]) {
                return true;
              } else {
                return false;
              }
            },
            name: 'Move to top',
            callback: function() {
              var secId = $(this).attr('data-id');
              that.trigger('ticketView:moveToTop', secId);
            }
          },
          sep3: '---------',
          assign: {
            visible: function() {
              if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_MODI_ASSIGN]) {
                return true;
              } else {
                return false;
              }
            },
            name: 'Assign',
            callback: function() {
              var ticketId = $(this).attr('data-id'),
                assignedTicket = _.findWhere(Settings.get('ticket-' + that.options.state), {id: ticketId}),
                userLogonId = assignedTicket.userName ? assignedTicket.userName : null;
              that._showAssignPopup(ticketId, userLogonId);
            }
          },
          sep4: '---------',
          estimate: {
            name: 'Estimate',
            callback: function() {
              var secId = $(this).attr('data-id');
              that._getTime(secId);
            }
          },
          sep5: '---------',
          history: {
              visible: function() {
                  if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_SHOW_HISTORY]) {
                      return true;
                  } else {
                      return false;
                  }
              },
              name: 'History',
              callback: function() {
                  var secId = $(this).attr('data-id');
                  that._showHistoryPopup(secId);
              }
          },
          sep6: '---------',
          copyUrl: {
            name: 'Copy',
            callback: function() {
              var secId = $(this).attr('data-id'),
                text = window.location.origin + '/#graphical_service_kanban/' + secId + '/messages-cs',
                aux = document.createElement('input');

              aux.setAttribute('value', text);
              document.body.appendChild(aux);
              aux.select();
              document.execCommand('copy');
              document.body.removeChild(aux);
            }
          }
        },
        trigger: 'right'
      });
    },

    _showCardDetails: function(e) {
      var secId = $(e.currentTarget).attr('data-id');

      App.trigger('graphical_service_kanban:show:details', secId);
    },

    _onAssignTo: function(e) {
      e.preventDefault();
      if (Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR_ONLY_ASSIGN] || 
        !Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_MODI_ASSIGN]) {
        return;
      }
      var ticketId = $(e.currentTarget).attr('data-id'),
        assignedTicket = _.findWhere(Settings.get('ticket-' + this.options.state), {id: ticketId}),
        userLogonId = assignedTicket.userName ? assignedTicket.userName : null;

      this._showAssignPopup(ticketId, userLogonId);
    },

    _showPopoverDialog: _.throttle(function(event) {
      if (this.kanbanPopoverIsShown) {return;}
      var ticketSecId = $(event.currentTarget).data('id');

        this._getTicketDetails(ticketSecId);
    }, 500),

    _getTicketDetails: function(ticketSecId) {
      var that = this,
        role = RolesMixin.DASHBOARD_GRAPHICALREQUESTS_SHOW_CUSTOMER_INFO,
        isAdmin = Settings.get('cAccountRoles')[role] ? true : false,
        tData = {};

      $.when(App.request('kanban-tickets:get-ticket-details', ticketSecId))
        .done(function(ticket) {
          tData = {
            cAccountSecId: ticket.cAccountJsonLight.secId,
            url: that._getAttachmentUrl(ticket),
            downloadUrl: that._getAttachmentDownloadUrl(ticket),
            msg: that._getLastConversations(ticket.messages),
            customer: ticket.cAccountJsonLight.code,
            isAdmin: isAdmin
          };
          if (!isAdmin) {
            that._buildPopoverDialog(tData);
          } else {
            tData.requester = ticket.requester;
            $.when(
              App.request('caccount-credits:get-balance', tData.cAccountSecId),
              App.request('kanban-tickets:row-count', '', {requester: tData.requester}),
              App.request('kanban-tickets:get', '', 2, {requester: tData.requester}, 'modDate')
            ).done(function(balance, rowCount, last2tickets) {
              tData.preLastModDate = that._getRequesterModDate(last2tickets);
              tData.rowCount = rowCount;
              tData.balance = balance;
              that._buildPopoverDialog(tData);
            });
          }
        });
    },

    _getRequesterModDate: function(data) {
      if (data.length > 1) {
        return moment(data.toJSON()[1].modDate).format('DD/MM/YYYY');
      } else {
        return moment(data.toJSON()[0].modDate).format('DD/MM/YYYY');
      }
    },

    _getAttachmentUrl: function(ticket) {
      if (ticket.messages.length && ticket.messages[0].attachements.length) {
        return ticket.messages[0].attachements[0].fileLink;
      }
      return '';
    },

    _getAttachmentDownloadUrl: function(ticket) {
      if (ticket.messages.length && ticket.messages[0].attachements.length) {
        return ticket.messages[0].attachements[0].downloadLink;
      }
      return '';
    },

    _getLastConversations: function(messages) {
      var reverseMsgs = _.first(messages.reverse(), 2),
        last2Msgs = [], elementClass, elementLabel;

      last2Msgs = _.map(reverseMsgs, function(msg) {
        if (msg.content) {
          elementClass = msg.direction === 'from' ? 'text-left' : 'text-right';
          elementLabel = msg.direction === 'from' ? '<b>Customer: </b>' : '<b>Customer services: </b>';
          return '<p class="'+ elementClass +'">' + elementLabel + msg.content + '</p>';
        }
      });
      last2Msgs = _.compact(last2Msgs);
      return last2Msgs.join('<hr>');
    },

    _buildPopoverDialog: function(tData) {
      var that = this,
        link = tData.downloadUrl ? '<a class="clickable" href="' + tData.downloadUrl + '">' +
          '<i class="mdi-action-get-app"></i></a>' : '',
        content = '<div class="text-center"><div class="img-responsive">' +
          '<img class="img-popover" src="' + tData.url + '"/>' + link +
          '</div></div><div class="kanban-popover-msgs-container">' + tData.msg + '</div>',
        dialogTitle = '<span>' + tData.customer + '</span>',
        btnObj = {},
        labelColor;
      
      if (tData.isAdmin) {
        labelColor = tData.balance <= 0 ? 'danger' : 'success';
        content = content + '<hr/><div><b>Total tickets:</b> ' + tData.rowCount + '</div>' + 
          '<div><b>Previous ticket modification date:</b> ' + tData.preLastModDate + '</div>';
        dialogTitle = dialogTitle + '<span class="rounded p-5 label-' + 
          labelColor + ' m-l-15">' + tData.balance + '</span>';

        btnObj = {
          cancel: {
            label: 'Show all related tickets',
            className: 'btn-primary',
            callback: function() {
              window.open(
                window.location.origin + '/#graphical_service_all_in_one?requester=' + tData.requester, 
                '_blank'
              );
              return false;
            }
          }
        };
      }
      this.kanbanPopoverIsShown = true;
      bootbox.dialog({
        title: dialogTitle,
        className: 'kanban-popover-dialog-alert',
        message: content,
        backdrop: true,
        buttons: btnObj
      }).on('hidden.bs.modal', function() {
        that.kanbanPopoverIsShown = false;
      });
    },

    _getTime: function(ticketSecId) {
      var that = this,
        timeSpent = 0;

      $.when(App.request('kanban-tickets:get-time', ticketSecId))
        .done(function(time) {
          _.each(time, function(item) {
            timeSpent += (item.timeSpent - item.removedTime);
          });
          that._showTimePopup(timeSpent, ticketSecId);
        });
    },

    _showHistoryPopup: function(ticketId) {
      var popupView = new HistoryPopupView({
        ticketSecId: ticketId
      });

      popupView.show({
          title: 'History'
      });
    },

    _showTimePopup: function(loggedTime, ticketId) {
      var tariffScale = _.findWhere(this.tickets, {id: ticketId}).tariffScale,
      estimatedTime = moment.duration(tariffScale, 'hours').as('seconds'),
      remainingTime = estimatedTime - loggedTime,
      popupView = new TimePopupView({
        times: {
          estimated: estimatedTime,
          remaining: remainingTime,
          logged: loggedTime
        },
        ticketSecId: ticketId
      });

      popupView.show({
        title: 'Time',
        className: 'vectorizationTimeSpent',
        buttons: {
          success: {
            label: 'Close',
            className: 'btn-primary',
            callback: function() { return true; }
          }
        }
      });
    },

    _showAssignPopup: function(ticketId, userLogonId) {
      var that = this,
        popupView = new PopupView(
          {users: this.users, assignedUser: userLogonId}
        );

      popupView.show({
        title: 'Assign To',
        className: 'vectorizationAssignTo',
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default',
            callback: function() { return true; }
          },
          success: {
            label: 'Assign',
            className: 'btn-primary',
            callback: function() {
              if (popupView.selectedUser) {
                if (popupView.selectedUser.get('secId')) {
                  return that._updateTicket(ticketId,
                    popupView.selectedUser.get('secId')
                  );
                } else {
                  return that._unassignTicket(ticketId);
                }
              } else {
                return true;
              }
            }
          }
        }
      });
    },

    _updateAssignUser: function(userName, userImg, ticketId) {
      var ticket = _.findWhere(this.tickets, {id: ticketId}),
        image;

      _.find($('.card-item'), function(card) {
        if ($(card).attr('data-id') === ticketId) {
          if ($(card).find('assigned-user-logo').length && userImg && userName) {
            $(card).find('.assigned-user-logo')
              .css('background-image:url(' + userImg + ')');
          } else if (!$(card).find('assigned-user-logo').length && userImg && userName) {
            $(card).find('.assigned-face-block').html(
              '<div class="assigned-user-logo" style="background-image:url(' + userImg +')"></div>'
            );
          } else if (!$(card).find('assigned-user-logo').length && !userImg && userName) {
            image = imageUtils.getLogo(userImg, userName, 'circle');
            $(card).find('.assigned-face-block').html(
              '<img class="logo-holder js-assigned-user-no-logo" src="' + image + '"/>'
            );
          } else if (!userName && !userImg) {
            image = '<i class="mdi-action-face-unlock"></i>';
            $(card).find('.assigned-face-block').html(image);
          }
        }
      });
      ticket.userImg = userImg;
      ticket.userName = userName;
    },

    _updateTicket: function(ticketId, userSecId) {
      var that = this;

      $.when(App.request('kanban-tickets:assign-users', ticketId, userSecId))
        .done(function(data) {
          var userName = data.refUser.logonId,
            userImg = data.refUser.logo ? Settings.url('file', data.refUser.logo, {size: 'thumbnail'}) : null;

          that._updateAssignUser(userName, userImg, ticketId);
        });
    },

    _unassignTicket: function(ticketId) {
      var that = this;

      $.when(App.request('kanban-tickets:unassigned-users', ticketId))
        .done(function() {
          that._updateAssignUser(null, null, ticketId);
        });
    },

    _flagTicket: function(e) {
      e.preventDefault();
      if (!Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_MODI_FLAG]) { return; }
      var ticketId = $(e.currentTarget).attr('data-id'),
        card = this.$('.card-item[data-id="' + ticketId + '"]'),
        flag;

      if ($(e.currentTarget).hasClass('flagged')) {
        $(e.currentTarget).removeClass('flagged');
        card.removeClass('marked');
        flag = false;
      } else {
        $(e.currentTarget).addClass('flagged');
        card.addClass('marked');
        flag = true;
      }
      $.when(App.request('kanban-tickets:flagged-ticket', ticketId, flag))
        .done(function() {});
    },

    onShowMore: function(e) {
      e.preventDefault();
      this.ui.hideUi.removeClass('hide');
      $(e.currentTarget).hide();
    }
  });

  module.exports = KanbanBoardCardView;
});
