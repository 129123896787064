/**
 * Created by BFR on 06/12/2019.
 */
define('trackingDetailsView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'template!trackingDetailsTpl',
  'trackingTabs',
  'rolesMixin',
  'backToListBehavior'
], function (
  module,
  Marionette,
  _,
  Backbone,
  App,
  Settings,
  imageUtils,
  viewTpl,
  trackingTabs,
  RolesMixin,
  BackToListBehavior
) {
  'use strict';

  var TrackingDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      tab: '.tracking-tab',
      tabPane: '.tab-pane',
      code: '.p-code',
      name: '.p-name'
    },

    events: {
      'click @ui.tab': 'onTabClick'
    },

    regions: {
      trackingDetailTab: '#detail-tab'
    },

    behaviors: function() {
        return {
            BackToList: {
                behaviorClass: BackToListBehavior,
                redirectUrl: this.backToListUrl()
            }
        };
    },

    backToListUrl: function() {
      return 'orders/' + this.options.model.get('refCommandLine').poi + '/tracking';
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.trackingTabs = trackingTabs;
      return templateData;
    },

    onRender: function() {
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el
          .find('.tracking-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function() {
      $.material.init();
    },

    onTabClick: function(e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };

      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
    },

    onHeaderChange: function(tracking) {
      this.ui.code.text(tracking.code);
      this.ui.name.text(tracking.name);
    }
  });

  module.exports = TrackingDetailsView;
});
