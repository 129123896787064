
define('template!cAccountInfoTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (comment) { ;
__p += '\n<span class="mdi-action-help cell-tooltip-info" title="' +
((__t = ( comment )) == null ? '' : __t) +
'"></span>\n';
 } ;


}
return __p
};});

