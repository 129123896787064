/**
 * Created by OBL on 16/04/2019.
 */
define('customerOffersTabController',[
  'module',
  'underscore',
  'marionette',
  'app',
  'offersShowController',
  'entities/offers'
], function(
  module,
  _,
  Marionnette,
  App,
  OfferViewController
) {
  'use strict';

  var CustomersOffersTabController =  _.extend(OfferViewController.getInstance(), {
    showTab: function (data, ViewInstance) {
      OfferViewController.showOffers.call(this, data, ViewInstance,true);
    },

    showRegion: function() {
      this.view.clearFilter();
      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(this.view);
    },

    initializeGrid: function(gridStatuses) {
        var data = {
          url: App.request(
            'offer:get-collection-url',
            this.data.model.get('endUserId')
          ),
          rowCountUrl: App.request(
            'offer:get-count-url',
            this.data.model.get('endUserId')
          ),
          statuses: gridStatuses
        };

        this.view.displayGrid(data,true);
    }

  });

  module.exports = CustomersOffersTabController ;
});
