define('productPicturesTabPictureItemView',[
  'module',
  'marionette',
  'template!productPicturesTabPictureItemTpl',
  'bootbox',
  'jquery',
  'settings',
  'app',
  'underscore',
  'pluginUtils'
], function (
  module,
  Marionette,
  pictureItemTpl,
  bootbox,
  $,
  Settings,
  App,
  _,
  PluginUtils
) {
  'use strict';

  var PictureItemView = Marionette.ItemView.extend({
    template: pictureItemTpl,
    className: 'picture-elements',

    ui: {
      edit: '.js-edit',
      print: '.js-print',
      'delete': '.js-delete'
    },

    events: {
      'click @ui.edit': '_edit',
      'click @ui.print': '_print',
      'click @ui.delete': '_delete'
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.pictureImageUrl = this.model.getImageUrl(160, 120, this.options.productCode, this.options.templateFileUid);
      return templateData;
    },

    _edit: function () {
      PluginUtils.callPlugin({
        action: 'editProductPicture',
        productPictureSecId: this.model.get('secId')
      });
    },

    _print: function () {
      PluginUtils.callPlugin({
        action: 'placePrintArea',
        productPictureSecId: this.model.get('secId')
      });
    },

    _delete: function () {
      var model = this.model;
      bootbox.confirm(_.i18n('common.confirmDelete'), function (result) {
        if (result) {
          model.destroy();
        }
      });
    }
  });

  module.exports = PictureItemView;
});
