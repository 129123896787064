define('popupProductView',[
  'module',
  'marionette',
  'app',
  'backbone',
  'underscore',
  'jquery',
  'settings',
  'dialogFormView',
  'dynamicTextView',
  'rolesMixin',
  'template!popupCreateProductTpl'
], function (
  module,
  Marionette,
  App,
  Backbone,
  _,
  $,
  Settings,
  DialogFormView,
  DynamicTextView,
  RolesMixin,
  popupCreateProductTpl
) {
  'use strict';

  module.exports = DialogFormView.extend({
    template: popupCreateProductTpl,

    ui: {
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm'
    },

    regions: {
      nameRegion: '.name-region',
      descriptionRegion: '.description-region'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange',
      'click @ui.confirm': 'onConfirm',
      'click @ui.cancel': 'onCancel'
    },

    modelEvents: {
      sync: 'onSave'
    },

    initialize: function () {
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      templateData.allowEditComment = true;
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2] &&
          !Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_PRODUCTS_TEMPLATES]) {
        templateData.allowEditComment = false;
      }
      return templateData;
    },

    onRender: function () {
      $.material.init();
      if (!this.model.get('readOnly')) {
        this.nameDtView = new DynamicTextView({model: this.model.get('name')});
        this.getRegion('nameRegion').show(this.nameDtView);
        this.descriptionDtView = new DynamicTextView({model: this.model.get('description')});
        this.getRegion('descriptionRegion').show(this.descriptionDtView);
      }
    },

    onConfirm: function () {
      this.model.save();
    },

    onEscape: function () {
      if (!this.newItem) {
        this.model = null;
      }
    },

    onCancel: function () {
      if (!this.newItem) {
        this.model = null;
      }
      this.hide();
    },

    onSave: function () {
      this.triggerMethod('saved');
      this.model = null;
      this.hide();
    }
  });
});
