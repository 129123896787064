
define('template!phoneNumberSelectDialogTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div id="dialog-blinder" class="dialog-blinder"></div>\n<div>\n    <div class="select-phonenumber-region"></div>\n    <div class="user-action-buttons-block">\n    \t<a class="btn leave-button pull-right">\n\t\t\t<span>' +
((__t = ( _.i18n('common.quit') )) == null ? '' : __t) +
'</span>\n        \t<div class="ripple-wrapper"></div>\n        </a>\n    </div>\n</div>';

}
return __p
};});

