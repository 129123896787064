/**
 * Created by BFR on 23/09/2021.
 */
define('cAccountInfoController',[
  'module',
  'logger',
  'underscore',
  'app',
  'jquery',
  'cAccountInfoView',
  'entities/caccounts/share'
], function(
  module,
  Logger,
  _,
  App,
  $,
  CAccountInfoView
) {
  'use strict';

  module.exports = {
    show: function(region, supplierCode) {
      $.when(App.request('caccounts:get-supplier-info', supplierCode)).done(_.bind(function(supplierInfo) {
        this.view = new CAccountInfoView({
          model: supplierInfo
        });
        region.show(this.view);
      }, this));
    }
  };
});
