
define('template!leadsViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n  <div class="with-header">\n    <div class="caccount-details-header">\n      <div class="caccount-info-container row">\n        <div class="logo-container col-left col-lg-2">\n          <!-- Logo -->\n          <div class="original-img-wrapper zoom-img">\n            <div class="main text-center">\n              <div class="logo-holder js-logo-holder"></div>\n            </div>\n          </div>\n        </div>\n        <div class="col-left name-holder js-name-holder m-t-5 col-lg-2">\n          <p class="p-name">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n          <p class="p-code">' +
((__t = ( code )) == null ? '' : __t) +
'</p>\n          ';
 if (!design2) { ;
__p += '\n          <p class="p-code">' +
((__t = ( customerNumber )) == null ? '' : __t) +
'</p>\n          ';
 } ;
__p += '\n        </div>\n\n        ';
 if (tel) { ;
__p += '\n        <div class="vertical-divider col-lg-1"></div>\n        <div class="col-left js-phone-holder m-t-10 col-lg-2">\n          <i class="mdi-notification-phone-in-talk icon holder-i"></i>\n          <span class="p-phone">' +
((__t = ( tel )) == null ? '' : __t) +
'</span>\n        </div>\n        ';
 } ;
__p += '\n\n        <button class="btn btn-primary js-link-phone-to-customer"\n                data-phoneNumber="' +
((__t = ( currentPlivoCall?currentPlivoCall:'' )) == null ? '' : __t) +
'"\n        ' +
((__t = ( currentPlivoCall?'style=""':'style="display:none;"' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('customer.linkPhone') )) == null ? '' : __t) +
'</button>\n\n        ';
 if (email) { ;
__p += '\n        <div class="vertical-divider col-lg-1"></div>\n        <div class="col-left js-email-holder m-t-10 col-lg-2">\n          <i class="mdi-content-mail icon holder-i"></i>\n          <span class="p-email">' +
((__t = ( email )) == null ? '' : __t) +
'</span>\n        </div>\n        ';
 } ;
__p += '\n        ';
 if (webshopUrl) { ;
__p += '\n        <div class="vertical-divider col-lg-1"></div>\n        <div class="col-left">\n          <a class="btn btn-primary" href="' +
((__t = ( webshopUrl)) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( _.i18n('menu.account') )) == null ? '' : __t) +
'</a>\n        </div>\n        ';
 } ;
__p += '\n        <button class="btn btn-primary btn-convert-to-customer">' +
((__t = ( _.i18n('menu.convertToCustomer') )) == null ? '' : __t) +
'</button>\n        <div class="p-vat-valid" style="' +
((__t = ( (subjectToVat && !vatNumberChecked)?'display:inline':'display:none' )) == null ? '' : __t) +
'">\n          <div class="vertical-divider col-lg-1"></div>\n          <div class="col-left js-email-holder m-t-10 col-lg-2">\n            <i class="mdi-alert-warning icon holder-i"></i>\n            <span>' +
((__t = ( _.i18n("common.VATNumberToCheck") )) == null ? '' : __t) +
'</span>\n          </div>\n        </div>\n\n      \n        <div class="js-action-buttons-region orders-action-button f-r"></div>\n\n        <span class="back-to-list js-back-to-list p-r-15"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n        <i class="mdi-action-assignment-turned-in create-task f-r p-t-10"></i>\n      </div>\n      <div class="caccount-tabs-container">\n        <div class="navbar navbar-default navbar-md row ">\n          <div class="navbar-header">\n            <a class="btn btn-link navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">\n              <span class="icon-bar"></span>\n              <span class="icon-bar"></span>\n              <span class="icon-bar"></span>\n            </a>\n          </div>\n          <div class="p-l-10 navbar-collapse collapse">\n            <ul class="nav navbar-nav" id="sub-caccount-tabs">\n              ';
 _.each(leadsTabs, function(leadTab, index) { ;
__p += '\n              <li class="  js-tab-title customer-tab';
 if (index === 0) { ;
__p += ' active';
 } ;
__p += '"\n                  data-role="' +
((__t = ( leadTab.role )) == null ? '' : __t) +
'" data-name="' +
((__t = ( leadTab.name )) == null ? '' : __t) +
'" data-route="' +
((__t = ( leadTab.route )) == null ? '' : __t) +
'"><a\n                      href="#' +
((__t = ( leadTab.route )) == null ? '' : __t) +
'-tab" data-toggle="tab">' +
((__t = ( leadTab.title )) == null ? '' : __t) +
'</a></li>\n              ';
 }); ;
__p += '\n            </ul>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class="caccount-details-container">\n    <div id="customer-tab-content" class="tab-content content">\n      ';
 _.each(leadsTabs, function(leadTab, index) { ;
__p += '\n      <div class="tab-pane';
 if (index === 0) { ;
__p += ' active';
 } ;
__p += '" data-role="' +
((__t = ( leadTab.role )) == null ? '' : __t) +
'"\n           data-name="' +
((__t = ( leadTab.name )) == null ? '' : __t) +
'" data-route="' +
((__t = ( leadTab.route )) == null ? '' : __t) +
'" id="' +
((__t = ( leadTab.name )) == null ? '' : __t) +
'-tab"></div>\n      ';
 }); ;
__p += '\n    </div>\n  </div>\n</div>';

}
return __p
};});

