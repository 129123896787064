/**
 * Created by OLD on 25/01/2016.
 */
define('customerContactsTabController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'bootbox',
  'settings',
  'validate',
  'customerContactsView',
  'customerContactsPopupView',
  'emptyView',
  'entities/customers/contacts'
], function(
  module,
  _,
  Backbone,
  App,
  bootbox,
  Settings,
  validateUtils,
  View,
  PopupView,
  EmptyView
) {
  'use strict';

  var CustomersContactsTabController = {
    showTab: function(data) {
      if (!data.tab) {
        data.tab = 'contacts';
      }
      this.showCustomerContacts(data);
    },

    showCustomerContacts: function(data) {
      var emptyView = new EmptyView();

      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(emptyView);
      this.data = data;
      this.endUserId = this.data.model.get('secId');
      this.createView();
    },

    createView: function() {
      this.view = new View();
      this.view.listenTo(this.view, 'onAddNewContacts', _.bind(this._onAddNewContacts, this));
      this.view.listenTo(this.view, 'onDeleteItem', _.bind(this._onDeleteContactsItem, this));
      this.view.listenTo(this.view, 'onDetailItem', _.bind(this._onDetailItem, this));
      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion('tabContent').show(this.view);
    },

    onGridLoaded: function() {
      var data = {
          url: App.request('customers:contacts:get-url', this.endUserId),
          rowCountUrl: App.request('customers:contacts:row-count-url', this.endUserId)
        };

      this.view.displayGrid(data);
    },

    _onAddNewContacts: function() {
      var that = this;
      
      this.contactModel = App.request('customer:contacts:new-model');
      this.addNewPopup = new PopupView({model: this.contactModel});
      if  (!Settings.get('countries')) {        
        $.when(App.request('caccount:countries')).done(function(countries) {
          Settings.set('countries', countries.toJSON());
          that._onShowAddNewPopup();
        });
      } else {
        this._onShowAddNewPopup();
      }     
    },

    _onDeleteContactsItem: function(endUserSecId) {
      var that = this;

      bootbox.confirm(_.i18n('common.confirmDelete'), function (result) {
        if (result) {
          $.when(App.request('customers:contacts:delete-contact', endUserSecId))
            .done(function() {
              that.view.updatePagination('reloadGrid');
            });        
        }
      });      
    },

    _onDetailItem: function(secId) {
      App.navigate(App.getCurrentRoute() + '/' + secId + '/details/' + 'info', {trigger: true});
    },

    _onShowAddNewPopup: function() {
      var that = this;

      this.addNewPopup.show({title: _.i18n('customerContact.add.popup.title'),
        buttons: {
          cancel: {
            label: _.i18n('common.cancel'),
            className: 'btn-default btn-flat',
            callback: function() {return true;}
          },
          success: {
            label: _.i18n('common.confirm'),
            className: 'btn-primary',
            callback: function() {
              return that.onConfirmAddNewPopup(that.contactModel);
            }
          }
        }
      });
    },

    onConfirmAddNewPopup: function(model) {
      if (!this.validate()) { return false; }
      model.listenTo(model, 'save:event:done', _.bind(this._onSaveEventDone, this));
      model._saveModel(this.endUserId);
    },

    validate: function() {
      var fieldsToValidate = [
        {name: 'email', type: 'email'},
        {name: 'firstName', type: 'required'}
      ];

      return this.addNewPopup.validate(fieldsToValidate);
    },


    _onSaveEventDone: function() {
      this.addNewPopup.hide();
      this.view.updatePagination('reloadGrid');
    }
  };

  module.exports = CustomersContactsTabController;
});
