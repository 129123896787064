
define('template!caccountShareLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n\t<div class="col-md-12 col-lg-12 col-xxl-6">\n\t\t<div class="share-title">\n\t\t\t' +
((__t = ( _.i18n("cAccountDetail.share.from") )) == null ? '' : __t) +
'\n\t\t</div>\n\t\t<div class="share-from-container"></div>\n\t</div>\n\t<div class="col-md-12 col-lg-12 col-xxl-6">\n\t\t<div class="share-title">\n\t\t\t' +
((__t = ( _.i18n("cAccountDetail.share.to") )) == null ? '' : __t) +
'\n\t\t</div>\n\t\t<div class="share-to-container"></div>\n\t</div>\n</div>';

}
return __p
};});

