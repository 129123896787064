/**
 * Created by BFR on 07/10/2021.
 */
define('adminTasksCreateEditView',[
  'module',
  'underscore',
  'marionette',
  'backbone',
  'settings',
  'jquery',
  'dialogFormView',
  'template!adminTasksCreateEditTpl',
  'savingBehavior',
  'dateUtils',
  'autocompleteView',
  'template!graphicalRequestKanbanAssignUserFaceTpl',
  'template!graphicalRequestKanbanAssignAutocompleteTpl'
], function (
  module,
  _,
  Marionette,
  Backbone,
  Settings,
  $,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  dateConverter,
  AutocompleteView,
  faceTpl,
  autocompleteTpl
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      individualPrinting: '#individualPrinting',
      done: '.done',
      notifyOnDone: '.notifyOnDone',
      dateDue: '.date-due'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input': 'onFocus',
      'change @ui.input': 'onInputChange',
      'change .js-info-input-date': 'onDateInputChange',
      'click .js-confirm': 'onConfirm',
      'change @ui.done': 'onChangeDone',
      'change @ui.notifyOnDone': 'onChangeNotifyOnDone',
      'click .js-assign-to-me': 'onAssignToMe',
      'click .js-unassign': 'onUnAssign',
      'click .js-unassign-notify': 'onUnAssignNotify',
      'click .js-assign-to-me-notify': 'onAssignToMeNotify'
    },

    regions: {
      selectNotifyUsers: '.js-select-notify-users-region',
      selectUsers: '.js-select-users-region',
      userface: '.js-userface-region'
    },

    className: 'profile-create-edit',

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'comment', type: 'required'}
    ],

    onChangeDone: function () {
      this.model.set('done', this.ui.done.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeNotifyOnDone: function () {
      this.model.set('notifyOnDone', this.ui.notifyOnDone.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    initialize: function (options) {
      this.model = options.model;
    },

    onDateInputChange: function (e) {
      var $target = $(e.currentTarget),
        value = $target.val(),
        fieldName = $target.data('field-name');
      this.onChange(fieldName, dateConverter.formatTUtc(value));
      this.triggerMethod('enable:cancel:confirm');
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      if (templateData.dateDue) {
        templateData.dateDue = dateConverter.formatT(templateData.dateDue);
      }
      templateData.availableTaskTypes = [
        'COM_TEAM',
        'COMPTA_TEAM',
        'DEV_TEAM',
        'GRAPH_TEAM',
        'PRODUCT_TEAM',
        'PROOFSHEET_TEAM',
        'SAV_TEAM'
      ];
      return templateData;
    },

    onShow: function () {
      $.material.init();
      this.usersView = new AutocompleteView(
        this._getUsersAutocompleteOptions(this._onChangeUser, 'refUserpid')
      );
      this.getRegion('selectUsers').show(this.usersView);

      this.selectNotifyUsers = new AutocompleteView(
        this._getUsersAutocompleteOptions(this._onChangeUserNotify, 'refUserpidToNotify')
      );
      this.getRegion('selectNotifyUsers').show(this.selectNotifyUsers);
    },

    _getUsersAutocompleteOptions: function (change, property) {
      var assignedUser = this.model.get(property) ? this.model.get(property).logonId : null,
        autocompleteUser = _.findWhere(this.options.users, {logonId: assignedUser});

      return {
        data: this.options.users,
        valueKey: 'fullName',
        apiKey: 'secId',
        limit: this.options.users.length,
        placeholder: 'Unassigned',
        name: 'user',
        value: autocompleteUser ? this._getUserFullName(autocompleteUser) : null,
        callback: _.bind(change, this),
        childTemplate: autocompleteTpl,
        childViewSerializeData: this._autocompleteViewSerializeData
      };
    },

    _onChangeUser: function (fieldName, user) {
      this.model.set('refUserpid', user);
      this.triggerMethod('enable:cancel:confirm');
    },

    _onChangeUserNotify: function (fieldName, user) {
      this.model.set('refUserpidToNotify', user);
      this.triggerMethod('enable:cancel:confirm');
    },

    assignToMe: function (property, ui) {
      var _assignedUser = this.model.get(property) ? this.model.get(property).logonId : null,
        _currentUserLogonId = Settings.get('currentUser').logonId,
        _currentUserName = this._getUserFullName(Settings.get('currentUser'));

      if (_assignedUser === _currentUserLogonId) {
        if (!this.selectedUser) {
          return;
        } else if (_assignedUser === this.selectedUser.get('logonId')) {
          return;
        }
      }
      var currentSecId = Settings.get('currentUser').secId,
        currentUser = _.findWhere(this.options.users, {secId: currentSecId});

      ui.trigger('autocomplete:selected', new Backbone.Model(currentUser));
      ui.$el.find('input.autocomplete').val(_currentUserName);
    },

    onAssignToMeNotify: function () {
      this.assignToMe('refUserpidToNotify', this.selectNotifyUsers);
    },

    onAssignToMe: function () {
      this.assignToMe('refUserpid', this.usersView);
    },

    onUnAssignNotify: function () {
      this.selectNotifyUsers.trigger('autocomplete:selected', new Backbone.Model({}));
      this.selectNotifyUsers.$el.find('input.autocomplete').val(null);
    },

    onUnAssign: function () {
      this.usersView.trigger('autocomplete:selected', new Backbone.Model({}));
      this.usersView.$el.find('input.autocomplete').val(null);
    },

    _getUserFullName: function (_user) {
      if (_user.firstName && _user.lastName) {
        return _user.firstName + ' ' + _user.lastName;
      } else if (_user.firstName || _user.lastName) {
        return _user.firstName ? _user.firstName : _user.lastName;
      } else if (_user.nickName) {
        return _user.nickName;
      } else {
        return _user.logonId;
      }
    },

    _autocompleteViewSerializeData: function () {
      return {
        fileUrl: this.model.get('logo') ? Settings.url('file', this.model.get('logo'), {size: 'thumbnail'}) : null,
        name: this.model.get('fullName')
      };
    },

    onConfirm: function () {
      if (this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    onSave: function () {
      this.triggerMethod('saved');
      this.hide();
    },

    onSaveError: function (model, response) {
      if (response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    }
  });

  module.exports = CreateEditLayoutView;
});
