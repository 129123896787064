/**
 * Created by BFR on 16/12/2017.
 */
define('numbersListController',[
  'module',
  'underscore',
  'app',
  'numbersListView',
  'settings',
  'controllerMixin',
  'phoneNumberSelectDialogView',
  'entities/phone/numbers'
], function(
  module,
  _,
  App,
  View,
  Settings,
  ControllerMixin,
  PhoneNumberSelectDialog
) {
  'use strict';
  
  var NumbersListController = _.extend({

    show: function(data, ViewInstance) {
      this.createView(data, ViewInstance || View);
      this.subscribeEvents();
      this.showRegion();
    },

    createView: function(data, ViewInstance) {
      var params = data ? {
        endUserSecId: data.model.get('secId')
      } : undefined;
      var view = new ViewInstance(params);
      this.view = view;
      this.view.listenTo(this.view, 'addNewPhoneNumber', _.bind(this.onAddNewPhoneNumber, this));
      this.data = data;
    },

    showRegion: function() {
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    },

    subscribeEvents: function() {  
      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
   },

  onAddNewPhoneNumber: function() {
      this.phoneNumberSelectDialog = new PhoneNumberSelectDialog();
      this.phoneNumberSelectDialog.listenTo(this.phoneNumberSelectDialog, 'phonenumbers-selected',
          _.bind(this.onPhoneNumbersSelected, this));
      this.phoneNumberSelectDialog.show({title: _.i18n('dialog.phonenumber.select')});
  },

  onPhoneNumbersSelected: function (/*phonenumbers*/) {
      /*products = _.pluck(products, 'rowid');

      var request = App.request('products:add-multiple-products-to-catalogue', products, this.view.model.get('secId'));
      $.when(request).done(_.bind(function () {
          this.view.updatePagination('reloadGrid');
      }, this));*/
  },

  onGridLoaded: function() {
    this.initializeGrid();
  },

   initializeGrid: function() {
      var data = {
          url: App.request('phone-numbers:get-collection-url'),
          rowCountUrl: App.request('phone-numbers:row-count-url')
        };

      this.view.displayGrid(data);
    }
    
  }, ControllerMixin);

  module.exports = NumbersListController;
});
