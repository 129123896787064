define('openTasksView',[
  'module',
  'app',
  'marionette',
  'settings',
  'template!openTasksViewTpl',
  'dateUtils',
  'moment'
], function (
  module,
  App,
  Marionette,
  Settings,
  openTasksViewTpl,
  dateFormatter,
  moment
) {
  'use strict';

  var ShareRequestView = Marionette.ItemView.extend({
    tagName: 'li',
    template: openTasksViewTpl,

    ui: {
      currentShareRequest: '.currentShareRequest',
      acceptRequest: '.accept-request'
    },

    events: {
      'click @ui.currentShareRequest': 'onClickShareRequest',
      'click @ui.acceptRequest': 'onClickAcceptRequest',
      'click .open-target': 'onClickOpenTarget'
    },

    onClickAcceptRequest: function (e) {
      e.stopPropagation();
      e.preventDefault();
      this.triggerMethod('tasks:done');
    },

    onClickOpenTarget: function (e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.model.getRefCommandLine()) {
        App.navigate('orders/' + this.model.getRefCommandLine().purchaseOrderId, {trigger: true});
      }
      if (this.model.getRefEndUser()) {
        App.navigate('customers/' + this.model.getRefEndUser().secId, {trigger: true});
      }
      if (this.model.getRefProduct()) {
        App.navigate('product/' + this.model.getRefProduct().secId, {trigger: true});
      }
    },

    onClickShareRequest: function (e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.model.get('refTask')) {
        App.navigate('admin/tasks/' + this.model.get('refTask').secId, {trigger: true});
      } else {
        App.navigate('admin/tasks/' + this.model.get('secId'), {trigger: true});
      }
    },

    serializeData: function () {
      var tplData = this.model.toJSON();
      tplData.due = false;
      tplData.hasTarget = false;
      if (tplData.dateDue) {
        tplData.dateDue = dateFormatter.toDateTimeFormatString(tplData.dateDue);
        if (moment(new Date()).isAfter(this.model.get('dateDue'))) {
          tplData.due = true;
        }
      }

      if (tplData.refTask) {
        tplData.comment = tplData.refTask.comment;
        tplData.refCommandLine = tplData.refTask.refCommandLine;
        tplData.refEndUser = tplData.refTask.refEndUser;
        tplData.refProduct = tplData.refTask.refProduct;
        tplData.refUserpid = tplData.refTask.refCreator;
        tplData.refPhoneCall = tplData.refTask.refPhoneCall;
        tplData.refEndUserContact = tplData.refTask.refEndUserContact;
      }

      if (tplData.refCommandLine || tplData.refEndUser || tplData.refProduct) {
        tplData.hasTarget = true;
      }

      return tplData;
    }
  });

  module.exports = ShareRequestView;
});
