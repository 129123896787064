define('callsListView',[
    'module',
    'marionette',
    'underscore',
    'app',
    'settings',
    'jqgridView',
    'template!callsListTpl',
    'datePickerPopoverMixin',
    'plivoUtils'
  ], function (
    module,
    Marionette,
    _,
    App,
    Settings,
    JqGridView,
    viewTpl,
    datePickerPopoverMixin,
    PlivoUtils
  ) {
    'use strict';
  
    var CallsListView = JqGridView.extend({
      template: viewTpl,
      filtersName: 'callsListFilters',
  
      triggers: {
      },
  
      ui: function() {
        return _.extend({
          jqGrid: '#jq-grid-list',
          jqGridPager: '#jq-grid-pager-list',
          phoneNumber: '.cell-phone'
        }, this.gridUi);
      },
  
      events: function() {
        return _.extend({
           'click .download-link': 'onDownloadCalls',
          'click @ui.phoneNumber' : 'onPhoneNumberClick'
        }, this.gridEvents);
      },
  
      pagination: function() {
        return {
          first: 0,
          rowCount: 0,
          pageSize: Settings.get('perPage15'),
          currentPage: 1
        };
      },
      
      gridOptions: function(data) {
        var defaultsOptions = this.gridInitOptions(data);
        this.sidx = 'userTimestamp.creatDate';
        this.sord = 'desc';
        var options = {
            sortname: this.sidx,
            sortorder: this.sord,
            colModel: [
              {
                label: '',
                name: 'secId',
                key: true,
                hidden: true
              }, 
              {
                label: _.i18n('common.date'),
                name: 'callDate',
                classes: 'cell-link',
                formatter:  this.dateTimeFormatter,
                search: true,
                index: 'callDate',
                sorttype: 'callDate', 
                searchrules: {date: true},
                searchoptions: {
                  dataInit: datePickerPopoverMixin.jqGridDataInit
                }
              },
              {
                  label: _.i18n('common.user'),
                  name: 'refUserpid',
                  classes: 'cell-link',
                  formatter:  this.defaultFormatter,
                  index: 'refUserpid',
                  search: false,
                  jsonmap: 'refUserpid.logonId'
              },
              {
                label: _.i18n('common.endUser'),
                name: 'refEndUser',
                classes: 'cell-link',
                formatter:  this.defaultFormatter,
                index: 'refEndUser',
                search: false,
                jsonmap: 'refEndUser.code'
              },
              {
                label: _.i18n('common.contact'),
                name: 'refEndUserContact',
                classes: 'cell-link',
                formatter:  this.defaultFormatter,
                index: 'refEndUserContact',
                search: false,
                jsonmap: 'refEndUserContact.email'
              },
              {
                label: _.i18n('calls.accountPhoneNumber'),
                name: 'accountPhoneNumber',
                classes: 'cell-link',
                formatter:  this.defaultFormatter,
                index: 'accountPhoneNumber',
                search: false
              }, {
                label: _.i18n('calls.customerPhoneNumber'),
                name: 'customerPhoneNumber',
                classes: 'cell-link cell-phone',
                formatter:  this.phoneCallFormatter,
                index: 'customerPhoneNumber',
                search: false
              }, {
                label: _.i18n('calls.type'),
                name: 'type',
                classes: 'cell-link',
                formatter:  this.defaultFormatter,
                index: 'type',
                search: false
              }, {
                label: _.i18n('calls.agentInteractionDuration'),
                name: 'agentInteractionDuration',
                classes: 'cell-link',
                formatter:  this.secondsFormatter,
                index: 'agentInteractionDuration',
                search: false
              },
              {
                label: ' ',
                name: 'download',
                search: false,
                classes: 'cell-link download-link',
                formatter: this.downloadIconFormater,
                width: 35
              }
            ],
            serializeGridData: function(postData) {      
                if (postData.callDate) {
                  postData.dateFrom = datePickerPopoverMixin.parseDateFrom(postData.callDate);
                  postData.dateTo = datePickerPopoverMixin.parseDateTo(postData.callDate);
                } else {
                  delete postData.dateFrom;
                  delete postData.dateTo;
                  delete postData.date;
                }
      
                if (postData.sidx === 'callDate') {
                  postData.sidx = 'userTimestamp.creatDate';
                }
      
                defaultsOptions.serializeGridData.apply(this, arguments);
      
                return postData;
              }
          };
  
        options = _.defaults(options, defaultsOptions);
  
        return options;
      },
      
      onDownloadCalls: function(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
           this.trigger('calls:download', $(event.currentTarget).find('span').attr('data-row-id'));
      },

      phoneCallFormatter: function(cellvalue) {
          return '<span style="cursor:pointer">' + cellvalue +'</span>';
      },

      onPhoneNumberClick: function(e) {
        var target = $(e.currentTarget);
        PlivoUtils.callNumber(target.text());
      }
    });
  
    module.exports = CallsListView;
  });

