
define('template!caccountAttachsCollectTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\r\n\t<div class="row">\r\n\t\t<div class="loading-block js-loading-block">\r\n\t\t\t<span class="loading-message js-loading-message label"></span>\r\n\t\t</div>\r\n\t</div>\r\n\r\n\t<div class="scroll-container scrollable">\r\n\t\t<div class="attachments-container row caccount-details-container well shadow-1 container-fluid"></div>\r\n\t\t<div class="lazy-load-block">\r\n\t\t\t<!-- loader block -->\r\n\t\t\t<div class="loader lazy-load-loader">\r\n\t\t\t<svg class="circular">\r\n\t\t\t\t<circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>\r\n\t\t\t</svg>\r\n\t\t  </div>\r\n\t\t</div>\r\n\t</div>\r\n</div>';

}
return __p
};});

