
define('template!pimProductEditsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12 page-title">' +
((__t = ( _.i18n('pictures.catalogPicture') )) == null ? '' : __t) +
'</div>     \n</div>\n<div class="row">       \n    <div class="edit-image col-xs-12"></div>\n</div>        \n<div class="row">      \n    <h2 class="col-xs-12 text-left">Print areas</h2>\n</div>   \n<div class="row">\n    <div class="edit-printareas col-xs-12"></div>\n</div>\n<div class="row">\n    <h2 class="col-xs-12 text-left">Options categories</h2>\n</div>\n<div class="row">       \n    <div class="edit-cats col-xs-12"></div>\n</div>    ';

}
return __p
};});

