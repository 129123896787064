
define('template!openTasksViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="currentShareRequest task-notification ' +
((__t = ( refTask?'task-done-notification':'' )) == null ? '' : __t) +
'" style="height: 30px;">\n    <div class="col-xs-4 p-t-7">\n        <label class="clickable">' +
((__t = ( comment )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="col-xs-3 p-t-7">\n        <label class="clickable" style="' +
((__t = ( due?'color:red;':'')) == null ? '' : __t) +
'">' +
((__t = ( dateDue )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="col-xs-3 p-t-7">\n        ';
 if (refUserpid) { ;
__p += '\n        <i class="fa fa-user"></i>\n        ';
 } ;
__p += '\n        <label class="clickable">' +
((__t = ( taskType )) == null ? '' : __t) +
'</label>\n    </div>\n    <div class="col-xs-2">\n        <label class="pull-right remove-accept-icons">\n            ';
 if (hasTarget) { ;
__p += '\n            <i class="mdi-action-open-in-new open-target clickable"></i>\n            ';
 } ;
__p += '\n            <i class="mdi-action-done accept-request clickable"></i>\n        </label>\n    </div>\n</div>\n';

}
return __p
};});

