  /**
 * Created by Matteo on 11/07/2015.
 */
define('customerCommentsSidebarMain',[
  'module',
  'logger',
  'app'
], function(
  module,
  Logger,
  App
) {
  'use strict';
  
  var CustomerCommentsSidebar = {
    start: function() {
      Logger.log(module, 'starting CustomerCommentsSidebarModule');
      require(['customerCommentsSidebarShowController'], function(ShowController){
        ShowController.showCustomerCommentsSidebar();
      });
    },

    stop: function() {
      Logger.log(module, 'stopping CustomerCommentsSidebarModule');
    }
  };


  App.on('customercommentssidebar:show', function() {
    CustomerCommentsSidebar.start();
  });

  module.exports = CustomerCommentsSidebar;
});

