/**
 * Created by RKL on 26/08/2015.
 */
define('commandLineAttachsNewItemView',[
  'module',
  'marionette',
  'app',
  'settings',
  'template!commandLineAttachsNewItemTpl',
  'underscore',
  'entities/caccounts/attachments'
], function (
  module,
  Marionette,
  App,
  Settings,
  viewTpl,
  _
) {
  'use strict';

  var CommandLineDetailsTabAttachmentsNewItemView =  Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      uploadInput: '.js-upload-attachment'
    },

    events: {
      'change @ui.uploadInput': 'onUploadImage'
    },

    tagName: 'div',

    className: 'original-img-wrapper zoom-img template-item template-new-item',

    onUploadImage: function() {
      var formData = new FormData();

      if(!this.ui.uploadInput[0].files.length) {
        return;
      }

      _.each(this.ui.uploadInput[0].files, function(file) {
        formData.append('file', file);
        formData.append('filename', file.name);
      });
      this.ui.uploadInput.val('');
      App.trigger('caccount:upload-attachment', formData);
    }
  });

  module.exports = CommandLineDetailsTabAttachmentsNewItemView;
});
