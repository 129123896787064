define('caccountPriceFactorsView',[
  'module',
  'underscore',
  'app',
  'jquery',
  'backbone',
  'marionette',
  'settings',
  'template!caccountPriceFactorsTpl',
  'caccountSupplierFactorsView',
  'caccountPrintCodesView',
  'caccountSupplierFactorsV2View',
  'caccountTransportView',
  'entities/caccounts'
], function (
  module,
  _,
  App,
  $,
  Backbone,
  Marionette,
  Settings,
  viewTpl,
  CaccountSupplierFactorsView,
  CaccountPrintCodesView,
  CaccountSupplierFactorsV2View,
  CaccountTransportView
) {
  'use strict';

  var PriceFactorsView = Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      supplierFactors: '.supplier-factors-region',
      printCodesRegion: '.print-codes-region',
      supplierFactorsV2: '.supplier-factorsv2-region',
      transportRegion: '.transport-region'
    },

    ui: {
      ddlSuppliers: '.ddl-suppliers',
      ddlSuppliersFactorV2: '.ddl-suppliers-factorv2',
      ddlSupplierImprint: '.ddl-suppliers-imprint',
      ddlSupplierTransport: '.ddl-suppliers-transport',
      txtParam1: '.txt-param1',
      txtParam2: '.txt-param2',
      txtParam3: '.txt-param3',
      btnAddFactorsV2: '.btn-add-factorsV2'
    },

    events: {
      'change @ui.ddlSuppliers': 'showSupplierFactors',
      'change @ui.ddlSuppliersFactorV2': 'showSupplierFactorV2',
      'change @ui.ddlSupplierTransport': 'showSupplierTransport',
      'click .btn-add-factor': 'onAddFactorClick',
      'click .btn-delete-factor': 'onDeleteFactorClick',
      'change @ui.ddlSupplierImprint': 'onSupplierImprintFactorsChange',
      'change .txt-param': 'onTxtFactorChange',
      'click @ui.btnAddFactorsV2': 'onAddFactorsV2'
    },

    showSupplierFactorV2: function () {
      $.when(App.request('caccount:get-factors', this.ui.ddlSuppliersFactorV2.val())).done(_.bind(function (factors) {
        if (factors.scales) {
          this.showSupplierFactorsV2(factors);
        } else {
          if (this.supplierFactorsV2View) {
            this.supplierFactorsV2View.destroy();
          }
          this.ui.btnAddFactorsV2.show();
        }
      }, this));
    },

    showSupplierTransport: function () {
      $.when(App.request('caccount:get-transport', this.ui.ddlSupplierTransport.val())).done(_.bind(function (transport) {
        if (this.supplierTransportView) {
          this.supplierTransportView.destroy();
        }
        this.supplierTransportView = new CaccountTransportView({
          model: transport,
          collection: transport.get('transport').get('prices'),
          supplierUid: this.ui.ddlSupplierTransport.val()
        });
        this.getRegion('transportRegion').show(this.supplierTransportView);
      }, this));
    },

    onAddFactorsV2: function () {
      var factors = {
        param1: null,
        param2: null,
        param3: null,
        scales: []
      };
      for (var i = -7; i <= 7; i++) {
        factors.scales.push({
          idx: i,
          margin: 30 - i
        });
      }
      this.showSupplierFactorsV2(factors);
    },

    showSupplierFactorsV2: function (factors) {
      this.ui.btnAddFactorsV2.hide();
      this.supplierFactorsV2View = new CaccountSupplierFactorsV2View({
        model: new Backbone.Model(factors),
        collection: new Backbone.Collection(factors.scales),
        supplierUid: this.ui.ddlSuppliersFactorV2.val()
      });
      this.getRegion('supplierFactorsV2').show(this.supplierFactorsV2View);
    },

    onTxtFactorChange: function () {
      var factors = {
        param1: !isNaN(this.ui.txtParam1.val()) ? Number(this.ui.txtParam1.val()) : null,
        param2: !isNaN(this.ui.txtParam2.val()) ? Number(this.ui.txtParam2.val()) : null,
        param3: !isNaN(this.ui.txtParam3.val()) ? Number(this.ui.txtParam3.val()) : null
      };
      App.request('caccount:update-factors', factors);
    },

    onDeleteFactorClick: function () {
      var modelToDelete = this.supplierFactorsView.collection.models[this.supplierFactorsView.collection.length - 1];
      this.supplierFactorsView.collection.remove(modelToDelete);
      modelToDelete.destroy();
    },

    onAddFactorClick: function () {
      this.supplierFactorsView.collection.create({
        idx: this.supplierFactorsView.collection.length + 1,
        factor: 1.10,
        refCAccountSupplier: {
          secId: this.ui.ddlSuppliers.val()
        }
      });
    },

    onSupplierImprintFactorsChange: function () {
      var sup = App.request('caccount:get-supplier-print-factors', this.ui.ddlSupplierImprint.val());
      sup.done(_.bind(function (supplierPrintFactors) {
        this.supplierPrintFactors = supplierPrintFactors;
        this.printCodesView = new CaccountPrintCodesView({
          printFactors: supplierPrintFactors,
          currentPrintCode: supplierPrintFactors.length > 0 ? supplierPrintFactors.models[0] : null,
          currentSupplier: this.ui.ddlSupplierImprint.val()
        });
        this.getRegion('printCodesRegion').show(this.printCodesView);
      }, this));
    },

    showSupplierFactors: function () {
      var sup = App.request('caccount:get-supplier-factors', this.ui.ddlSuppliers.val());
      sup.done(_.bind(function (supplierFactors) {
        this.supplierFactorsView = new CaccountSupplierFactorsView({
          collection: supplierFactors
        });
        this.getRegion('supplierFactors').show(this.supplierFactorsView);
      }, this));
    },

    onShow: function () {
      this.showSupplierFactors();
      this.onSupplierImprintFactorsChange();
      this.showSupplierFactorV2();
      this.showSupplierTransport();
    },

    serializeData: function () {
      var templateData = {};
      templateData.suppliers = this.options.suppliers;
      if (this.options.factors) {
        templateData.factors = this.options.factors;
      } else {
        templateData.factors = {
          param1: '',
          param2: '',
          param3: ''
        };
      }
      return templateData;
    },

    onRender: function () {
      $.material.init();
    }

  });

  module.exports = PriceFactorsView;
});
