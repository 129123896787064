/**
 * Created by BFR on 24/02/2020.
 */
define('editPimOptionView',[
  'module',
  'marionette',
  'app',
  'jquery',
  'underscore',
  'template!editPimOptionTpl',
  'pimUtil',
  'colorUtils'
], function (
  module,
  Marionette,
  App,
  $,
  _,
  viewTpl,
  PimUtil,
  ColorUtils
) {
  'use strict';

  var EditPimOptionView = Marionette.LayoutView.extend({
    template: viewTpl,
    className: 'option',


    onShow: function () {
      _.each(this.$el.find('.image'), _.bind(function (image) {
        var content = '<div class="img-responsive"><img class="img-popover" src="' + $(image).find('img').attr('src') + '"/></div>';

        var target = $(image).find('img');

        target.attr('data-content', content);
        target.popover({
          trigger: 'hover',
          placement: 'left',
          html: 'true',
          container: $('#content')
        }).data('bs.popover').tip().addClass('jqgrid-popover');
      }, this));
    },

    onDestroy: function () {
      $('.jqgrid-popover').remove();
    },

    serializeData: function () {
      var templateData = {};

      templateData.code = this.model.get('code');

      var aNames = this.model.get('name').values;

      templateData.name = PimUtil.getTranslation(aNames);

      templateData.color = '';

      var color1 = this.model.get('color1');

      if (color1) {
        templateData.color = ColorUtils.decimalToHexa(color1['int']);
      }

      var aImages = [];

      if (this.model.get('picture1') !== '')
        aImages.push(this.model.get('picture1'));

      if (this.model.get('picture2') !== '')
        aImages.push(this.model.get('picture2'));

      if (this.model.get('picture3') !== '')
        aImages.push(this.model.get('picture3'));

      if (this.model.get('picture4') !== '')
        aImages.push(this.model.get('picture4'));

      _.each(this.model.get('addImages'), _.bind(function (image) {
        aImages.push(image.url);
      }, this));

      templateData.images = aImages;

      templateData.allowUpload = false;

      var currentResProduct = this.options.pimProduct.getCurrentResProduct();

      if (currentResProduct) {
        templateData.allowUpload = true;
        templateData.defaultIndexPicture = currentResProduct.get('defaultIndexPicture');
      } else {
        templateData.defaultIndexPicture = null;
      }

      return templateData;
    }
  });

  module.exports = EditPimOptionView;
});
