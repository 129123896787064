define('entities/orders_kanban',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore',
  'rolesMixin',
  'entities/product'
], function (
  App,
  Backbone,
  Settings,
  $,
  _,
  RolesMixin
) {
  'use strict';
  var Order = Backbone.Model.extend({
    defaults: {
      endUserId: '',
      date: null,
      payedByCustomer: null,
      payedToManufacturer: null,
      poi: null,
      productName: '',
      snapshotUid: '',
      status: '',
      flag: '',
      externalNumber: '',
      priority: null,
      grouped: false,
      groupedInvoice: false,
      toInvoice: false,
      nbrPackDelivered: 0,
      nbrPackSent: 0,
      expShippingDate: null,
      validationDate: null,
      outsideEurope: false,
      nbrOrderSameCustomer: 1,
      lastEmailResponse: null,
      difficultyLevel: null
    },
    idAttribute: 'poi'
  });

  var OrderCollection = Backbone.Collection.extend({
    model: Order
  });

  var API = {
    getKanbanStatuses: function () {
      var statuses = new OrderCollection({}),
        params = {
          locale: Settings.get('lang')
        },
        defer = $.Deferred();

      statuses.url = Settings.url('compuzz2', 'commands.json/command/status/kanban', params);
      statuses.fetch({
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getRowCountOrders: function (params) {
      var url = Settings.url('compuzz2', 'commands.json/advancedSearch/rowCount', params),
        defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function (rowCount) {
          defer.resolve(rowCount);
        }
      });

      return defer.promise();
    },

    getAllOrders: function (params) {
      var param = JSON.parse(JSON.stringify(params));
      var showOnlyMine = false;
      if (param.showOnlyMine && param.showOnlyMine[param.status]) {
        showOnlyMine = true;
      }
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        param.pageSize = 100;
      } else {
        param.pageSize = 50;
      }
      if (!Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        param.loadNbrSameCustomer = true;
      }
      param.showOnlyMine = showOnlyMine;
      var url = Settings.url('compuzz2', 'commands.json/lights', param),
        defer = $.Deferred(),
        collection = new OrderCollection();

      $.ajax({
        method: 'get',
        url: url,
        success: _.bind(function (orders) {
          var ordersGrouped = [];
          var ordersGroupedInvoice = [];
          _.each(orders, _.bind(function (o) {
            var item = _.find(ordersGrouped, _.bind(function (order) {
              return order.externalNumber &&
                order.externalNumber === o.externalNumber;
            }, this));
            if (item) {
              item.grouped = true;
              o.grouped = true;
              if (!Settings.get('orderColors')[item.externalNumber]) {
                Settings.get('orderColors')[item.externalNumber] = this.getRandomColor();
              }
              o.groupedColor = item.groupedColor = Settings.get('orderColors')[item.externalNumber];
              ordersGrouped.splice(ordersGrouped.indexOf(item) + 1, 0, o);
            } else {
              ordersGrouped.push(o);
            }
            item = _.find(ordersGroupedInvoice, _.bind(function (order) {
              return order.invoiceFileUid &&
                order.invoiceFileUid === o.invoiceFileUid;
            }, this));
            if (item) {
              item.groupedInvoice = true;
              o.groupedInvoice = true;
              if (!Settings.get('orderColorsInvoice')[item.invoiceFileUid]) {
                Settings.get('orderColorsInvoice')[item.invoiceFileUid] = this.getRandomColor();
              }
              o.groupedColorInvoice = item.groupedColorInvoice = Settings.get('orderColorsInvoice')[item.invoiceFileUid];
              ordersGroupedInvoice.splice(ordersGroupedInvoice.indexOf(item) + 1, 0, o);
            } else {
              ordersGroupedInvoice.push(o);
            }
          }, this));
          collection = new OrderCollection(ordersGrouped);

          var url2 = Settings.url('compuzz2', 'commands.json/lights/rowCount', param);
          $.ajax({
            method: 'get',
            url: url2,
            success: _.bind(function (count) {
              defer.resolve(collection, count);
            })
          });
        }, this)
      });

      return defer.promise();
    },

    getRandomColor: function () {
      var letters = '0123456789ABCDEF'.split('');
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    },

    getOrderComment: function (poi) {
      var url = Settings.url('compuzz2', 'commands.json/' + poi + '/comment'),
        defer = $.Deferred();
      $.ajax({
        method: 'GET',
        url: url,
        success: function (comment) {
          defer.resolve(comment);
        }
      });
      return defer.promise();
    },

    updateOrderComment: function (poi, comment) {
      var url = Settings.url('compuzz2', 'commands.json/' + poi + '/comment'),
        defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        url: url,
        data: JSON.stringify(comment),
        contentType: 'application/json',
        success: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    updateOrderCard: function (poi, status) {
      return $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'commands.json/' + poi + '/status', {
          status: status
        }),
        contentType: false,
        dataType: false
      });
    },

    getModel: function (content) {
      return new Order(content);
    },

    assignUser: function (poi, userSecId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'commands.json/' + poi + '/assign', {user: userSecId}),
        type: 'PUT',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    unassignUser: function (poi) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz2', 'commands.json/' + poi + '/unassign'),
        type: 'PUT',
        success: function (data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    getHistoryRowCount: function (purchaseOrderId, options) {
      options = options || {};
      _.defaults(options, {});
      var params = '';
      return 'commands.json/' + purchaseOrderId + '/history/rowCount' + params;
    },

    getHistory: function (purchaseOrderId, options) {
      options = options || {};
      return Settings.url('compuzz2', 'commands.json/' + purchaseOrderId + '/history', options);
    }
  };

  App.reqres.setHandler('order_kanban:statuses', function () {
    return API.getKanbanStatuses();
  });
  App.reqres.setHandler('order_kanban:orders', function (params) {
    return API.getAllOrders(params);
  });
  App.reqres.setHandler('order_kanban:row:count', function (params) {
    return API.getRowCountOrders(params);
  });
  App.reqres.setHandler('order-kanban:update:table', function (poi, status) {
    return API.updateOrderCard(poi, status);
  });
  App.reqres.setHandler('order-kanban:update:comment', function (poi, comment) {
    return API.updateOrderComment(poi, comment);
  });
  App.reqres.setHandler('order-kanban:get:comment', function (poi) {
    return API.getOrderComment(poi);
  });
  App.reqres.setHandler('order-kanban:new:model', function (content) {
    return API.getModel(content);
  });
  App.reqres.setHandler('order-kanban:assign-users', function (ticketSecId, userSecId) {
    return API.assignUser(ticketSecId, userSecId);
  });
  App.reqres.setHandler('order-kanban:unassigned-users', function (ticketSecId) {
    return API.unassignUser(ticketSecId);
  });
  App.reqres.setHandler('order-kanban:history-get', function (purchaseOrderId, params) {
    return API.getHistory(purchaseOrderId, params);
  });
  App.reqres.setHandler('order-kanban:history-row-count', function (purchaseOrderId, params) {
    return API.getHistoryRowCount(purchaseOrderId, params);
  });
  App.reqres.setHandler('order-kanban:update:color', function (poi, color) {
    return API.updateOrderCardColor(poi, color);
  });
});

