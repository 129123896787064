define( 'entities/customers/design_elements',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Models
   */
  var ObjectNameGroup = Backbone.Model.extend({
    defaults: {
      code: null,
      endUserId: null,
      name: null,
      refCAccountJson: null,
      type: null
    },

    postUrl: function() {
      var cAccountId = this.get('refCAccountJson').secId;

      return Settings.url(
        'compuzz',
        'v2/caccounts/' + cAccountId + '/objectnames'
      );
    },

    deleteUrl: function() {
      var cAccountId = this.get('refCAccountJson').secId,
        secId = this.get('secId');

      return Settings.url('compuzz', 'v2/caccounts/' +
        cAccountId + '/objectnames/' + secId);
    },

    idAttribute: 'secId'
  });

  var ObjectName = Backbone.Model.extend({
    defaults: {
      encodedValue: null,
      extension: null,
      fileName: null,
      objectName: null,
      value: '',
      secId: null
    },

    postUrl: function(secId) {
      return Settings.url(
        'compuzz',
        'v2/datasetvalues/' + secId
      );
    },

    deleteUrl: function() {
      return Settings.url('compuzz', 'v2/datasetvalues/' + this.get('secId'));
    },

    idAttribute: 'secId'
  });

  /**
   * Collections
   */
  var ObjectNameGroups = Backbone.Collection.extend({
    model: ObjectNameGroup
  });

  var ObjectNames = Backbone.Collection.extend({
    model: ObjectName
  });
  var LogosImages = Backbone.Collection.extend({
    model: Backbone.Model,

    getLogosImages: function(params) {
      var that = this,
        defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'v2/datasetvalues/objectnames/' + params.secId, {endUserId: params.endUserId}),
        type: 'GET',
        success: function(resp) {
          that.reset(resp);
          defer.resolve(that);
        },
        error: function(error) {
          that.trigger('error', error);
          defer.fail(error);
        }
      });

      return defer.promise();
    }
  });
  /**
   * REST API
   */
  var API = {
    getObjectNames: function(data) {
      var defer = $.Deferred(),
        collection = new ObjectNameGroups();

      collection.url = Settings.url('compuzz', 'v2/objectnames', data);
      collection.fetch().done(function() {
        defer.resolve(collection);
      });

      return defer.promise();
    },

    getObjectNamesCount: function(data) {
      var countModel = new ObjectName(),
        params = {
          type: data.type
        };

      countModel.url = Settings.url( 'compuzz', 'v2/datasetvalues/' +
        data.endUserId + '/objectnames/' + data.secId + '/rowCount', params);

      return countModel;
    },

    getObjectNamesGroupModel: function() {
      var model = new ObjectNameGroup(),
        cAccountId = Settings.get('currentCAccount').secId;

      model.set('refCAccountJson', {secId: cAccountId});
      model.url = model.postUrl();

      return model;
    },

    getObjectNameModel: function() {
      var model = new ObjectName();

      return model;
    },

    getDesignElements: function(data) {
      var collection = new ObjectNames(),
        pageSize = Settings.get('lazyLoadingSize'),
        params = {
          pageIndex: Math.ceil(data.first / pageSize).toString(),
          pageSize: pageSize
        };

      collection.url = Settings.url(
        'compuzz',
        'v2/datasetvalues/' + data.endUserId + '/objectnames/' + data.secId,
        params
      );

      return collection;
    },

    getDesignElementsCount: function(data) {
      var defer = $.Deferred(),
        url;

      url = Settings.url( 'compuzz', 'v2/datasetvalues/' + data.endUserId +
        '/objectnames/' + data.secId + '/rowCount');

      $.ajax({
        url: url,
        success: function(count) {
          defer.resolve(count);
        }
      });

      return defer.promise();
    },

    getFromTemplates: function(data) {
      var defer = $.Deferred(),
        params = {
          oniSecId: data.secId
        },
        url = Settings.url(
          'compuzz',
          'v2/datasetvalues/fromTemplate/' + data.endUserId +
            '/' + data.attachId,
          params
        );

      $.ajax({
        type: 'POST',
        url: url,
        success: function(resp) {
          defer.resolve(new ObjectName(resp));
        }
      });

      return defer.promise();
    },

    duplicateObjectName: function(data) {
      var defer = $.Deferred(),
        params = {
          oniSecId: data.secId
        },
        url = Settings.url(
          'compuzz',
          'v2/datasetvalues/duplicate/' + data.endUserId + '/' + data.attachId,
          params
        );

      $.ajax({
        type: 'POST',
        url: url,
        success: function(resp) {
          defer.resolve(new ObjectName(resp));
        }
      });

      return defer.promise();
    },

    getDatasetUrl: function(data) {
      return Settings.url(
        'compuzz',
        'v2/datasetvalues/' + data.endUserId + '/objectnames/' + data.secId
      );
    },

    getDatasetRowCountUrl: function(data) {
      return 'v2/datasetvalues/' + data.endUserId + '/objectnames/' +
        data.secId + '/rowCount';
    },

    getDatasets: function(data) {
      var datasets =  new ObjectNames(),
        defer = $.Deferred();

      datasets.url = Settings.url(
        'compuzz',
        'v2/datasetvalues/' + data.endUserId + '/objectnames/' + data.secId,
        data.params
      );

      datasets.fetch().done(function() {
        defer.resolve(datasets);
      });

      return defer.promise();
    },

    deleteDataset: function(itemId) {
      var defer = $.Deferred(),
        url = Settings.url(
          'compuzz',
          'v2/datasetvalues/' + itemId
        );

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function() {
          defer.resolve();
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('customers:objectnames', function(data) {
    return API.getObjectNames(data);
  });

  App.reqres.setHandler('customers:objectnames-count', function(data) {
    return API.getObjectNamesCount(data);
  });

  App.reqres.setHandler('customers:objectnames-group-model', function() {
    return API.getObjectNamesGroupModel();
  });

  App.reqres.setHandler('customers:design-elements', function(data) {
    return API.getDesignElements(data);
  });

  App.reqres.setHandler('customers:elements-count', function(data) {
    return API.getDesignElementsCount(data);
  });

  App.reqres.setHandler('customers:objectname-model', function() {
    return API.getObjectNameModel();
  });

  App.reqres.setHandler('logos-images:collection', function(data) {
    return new LogosImages(data);
  });

  App.reqres.setHandler('customers:objectname-from-template', function(data) {
    return API.getFromTemplates(data);
  });

  App.reqres.setHandler('customers:objectname-duplicate', function(data) {
    return API.duplicateObjectName(data);
  });

  App.reqres.setHandler('customers:dataset-url', function(data) {
    return API.getDatasetUrl(data);
  });

  App.reqres.setHandler('customers:dataset-row-count-url', function(data) {
    return API.getDatasetRowCountUrl(data);
  });

  App.reqres.setHandler('customers:get-datasets', function(data) {
    return API.getDatasets(data);
  });

  App.reqres.setHandler('customers:delete-dataset', function(itemId) {
    return API.deleteDataset(itemId);
  });
});
