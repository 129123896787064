/**
 * Created by RKL on 26/08/2015.
 */
define('caccountAttachsItemView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'bootbox',
  'template!caccountAttachsItemTpl'
], function (
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  bootbox,
  viewTpl
) {
  'use strict';

  var CAccountsAttachmentsTabItemView =  Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      imageHolder: '.js-image-holder',
      imageHolderContainer: '.js-image-holder-container',
      edit: '.js-edit-icon',
      remove: '.js-remove-icon',
      modify: '.js-modify-icon',
      zoom: '.js-zoom-icon',
      download: '.js-download-icon',
      imageCell: '.js-image-cell'
    },

    events: {
      'click @ui.remove': 'onRemove',
      'click @ui.download': 'onDownload',
      'click @ui.zoom' : 'onZoom',
      'click @ui.modify' : 'onModify'
    },

    tagName: 'div',

    className: 'original-img-wrapper zoom-img editor-holder template-item',

    onShow: function() {
      var that = this;
      $('<img/>').attr('src', Settings.url('base', that.model.get('fileLink'))).load(function() {
        $(this).remove();
        that.ui.imageCell.attr('data-link', Settings.url('base', that.model.get('fileLink')));
        that.ui.imageCell.css('background-image', 'url(' + Settings.url('base', that.model.get('fileLink')) + ')');
        that.ui.imageCell.css('height', '150px');
        that.ui.imageCell.css('background-repeat', 'no-repeat');
        that.ui.imageCell.show();
      }).error(function() {
        $(this).remove();
      });
    },

    onRemove: function() {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          App.trigger('caccount:delete-attachment', this.model);
        }
      }, this));
    },

    onZoom: function() {
      App.trigger('caccount:attachments-zoom', this.model.get('id'));
    },

    onModify: function() {
      App.trigger('caccount:attachments-modify', this.model.get('secId'));
    }
  });

  module.exports = CAccountsAttachmentsTabItemView;
});
