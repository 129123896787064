
define('template!mailsListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="loading-block js-loading-block loader"></div>\n<div class="content">\n\n    ';
 if (!connected) { ;
__p += '\n    <button class="googleConnect">Connect</button>\n    ';
 } else { ;
__p += '\n    <div>\n        <h2>' +
((__t = ( _.i18n('menu.mails') )) == null ? '' : __t) +
'</h2>\n        <div class="row">\n            <div class="col-xs-5">\n                <div class="row" style="text-align:center;margin-bottom: 20px;">\n                    <input type="text" size="80" class="mail-query" placeholder="' +
((__t = ( _.i18n('common.search'))) == null ? '' : __t) +
'" value="' +
((__t = ( query )) == null ? '' : __t) +
'" />\n                </div>\n                <div class="sub-caccounts-table" style="overflow-y: auto;height:600px;">\n                    <table>\n                        <thead>\n                        <th style="padding-left:10px;width:7%;">' +
((__t = ( _.i18n('common.date'))) == null ? '' : __t) +
'</th>\n                        <th style="padding-left:10px;width:13%;">' +
((__t = ( _.i18n('mails.from'))) == null ? '' : __t) +
'</th>\n                        <th style="padding-left:10px;width:10%;">' +
((__t = ( _.i18n('mails.subject'))) == null ? '' : __t) +
'</th>\n                        </thead>\n\n                        ';
 _.each(mails, function (mail) { ;
__p += '\n                        <tr style="cursor: pointer;padding-bottom:10px;">\n                            <td class= "email" rowId="' +
((__t = ( mail.id)) == null ? '' : __t) +
'"\n                                style="padding:4px 0 4px 10px;width:5%;border-top:1px solid lightgrey; ' +
((__t = ( mail.unread )) == null ? '' : __t) +
'">' +
((__t = ( mail.smartDate )) == null ? '' : __t) +
'</td>\n                            <td class= "email ellipsis" rowId="' +
((__t = ( mail.id)) == null ? '' : __t) +
'" title="' +
((__t = ( _.escape(mail.from) )) == null ? '' : __t) +
'" mailFrom="' +
((__t = ( _.escape(mail.from) )) == null ? '' : __t) +
'"\n                                style="padding:4px 0 4px 10px;max-width: 100px;width:25%;border-top:1px solid lightgrey;' +
((__t = ( mail.unread )) == null ? '' : __t) +
'">' +
((__t = ( mail.from )) == null ? '' : __t) +
'</td>\n                            <td class= "email ellipsis" rowId="' +
((__t = ( mail.id)) == null ? '' : __t) +
'" title="' +
((__t = ( _.escape(mail.subject) )) == null ? '' : __t) +
'" mailFrom="' +
((__t = ( _.escape(mail.from) )) == null ? '' : __t) +
'"\n                                style="padding:4px 0 4px 10px;max-width: 300px;width:70%;border-top:1px solid lightgrey;' +
((__t = ( mail.unread )) == null ? '' : __t) +
'">' +
((__t = ( mail.subject )) == null ? '' : __t) +
'</td>\n                        </tr>\n                        ';
 }); ;
__p += '\n                    </table>\n\n                </div>\n                <div class="row md-grey-cl text-center">\n                    <div class="pagination-control display-inline-block">\n                        <span class="ui-icon ui-icon-seek-first js-first pagination-active clickable" title="' +
((__t = ( _.i18n('first'))) == null ? '' : __t) +
'"></span>\n                        <span class="ui-icon ui-icon-seek-prev js-prev pagination-active clickable" title="' +
((__t = ( _.i18n('previous'))) == null ? '' : __t) +
'"></span>\n                        <span class="ui-icon ui-icon-seek-next js-next pagination-active clickable" title="' +
((__t = ( _.i18n('next'))) == null ? '' : __t) +
'"></span>\n                    </div>\n                </div>\n            </div>\n\n            <div class="col-xs-7 mail-detail" style="overflow-y: auto;overflow-x:auto; margin-top: -20px;">\n\n            </div>\n        </div>\n        ';
 }  ;
__p += '\n    </div>\n\n\n\n\n\n';

}
return __p
};});

