/**
 * Created by OLD on 28/01/2016.
 */
define('customerContactsDetailsController',[
  'module',
  'underscore',
  'backbone',
  'app',
  'settings',
  'rolesMixin',
  'customersContactsTabDetailsLayoutView',
  'emptyView',
  'customersContactsInfoTabController',
  'mailsController',
  'customerContactsCallsTabController',
  'entities/customers/contacts'
], function(
  module,
  _,
  Backbone,
  App,
  Settings,
  RolesMixin,
  DetailsLayoutView,
  EmptyView,
  infoTabController,
  mailsController,
  callsTabController
) {
  'use strict';

  var CustomersContactsDetailController = {
    
    fetchContact: function(data) {
      var that = this,
      emptyView = new EmptyView(),
      contentRegion = App.regions.getRegion('main').currentView.getRegion('content');
      
      contentRegion.show(emptyView);
      this.data = data;
      this.model = App.request('customers:contacts:get-model:item');
      this.model._fetchModel(data.id, data.secId).done(function() {
          if (contentRegion) {
            that.showContact(data);
          }
      });
    },

    showContact: function(data) {
      this.view = new DetailsLayoutView({
          data: this.data,
          model: this.model
        });
      this.view.listenTo(this.view, 'click:tab', _.bind(this.onTabClick, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
      this.displayTabs(data);
    },
    
      displayTabs: function(data) {
        var controllers = {};
 
       controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS_INFO] = infoTabController;
       controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS_CALLS] = callsTabController;
       controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS_MAILS] = mailsController;
 
       data.model = this.model;

       controllers[data.tab.role].showTab(data);
       this.tab = data.tab;
      },

    showConntact: function(data) {
      var itemModel = App.request('customers:contacts:get-model:item');
      itemModel._fetchModel(data.id, data.secId).done(function() {
      this.view = new DetailsLayoutView({
          model: itemModel
        });
      });
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    },
    
    onTabClick: function(tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;

        this.displayTabs(this.data);
        App.navigate('customers/' + this.data.id + '/contacts/' + 
          this.model.get('secId') + '/details/' + tab.route, {trigger: true});
      }
    }
  };

  module.exports = CustomersContactsDetailController;
});
