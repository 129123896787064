
define('template!editPimPrintAreaTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="printarea">\n    <div class="row">\n        <div class="col-xs-3">\n            <div class="pa">\n                <div class="pa-code">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n                <div class="row">\n                    <div class="col-xs-12">\n                        <div class="pa-info">\n                            <div class="pa-name">' +
((__t = ( name )) == null ? '' : __t) +
'</div>\n                            <div class="pa-size">' +
((__t = ( width + 'x' + height + 'mm' )) == null ? '' : __t) +
'</div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="col-xs-9">\n            <div class="pz">\n                <div class="pz-title">Print zones</div>\n                <div class="row pz-header">\n                    <div class="col-xs-1 imppCode">Imprint.</div>\n                    <div class="col-xs-4 name">Name</div>\n                    <div class="col-xs-2 size">Size</div>\n                    <div class="col-xs-2 setupCode">Setup</div>\n                    <div class="col-xs-1 unitCode">Code</div>\n                    <div class="col-xs-2 image">Image</div>\n                </div>\n                <div class="pz-region"></div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

