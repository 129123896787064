// Kick off the application.
require([
  'app',
  'settings',
  'require',
  'translation',
  'plivoUtils'
], function(
  App,
  Settings,
  require,
  Translation,
  PlivoUtils
) {
  'use strict';

  Translation.fetchTranslations().done(function() {
    require(['router'], function(){
      //start the application
      App.start();
      PlivoUtils.init();
    });
  } ).fail(function() {
      window.location.href = Settings.serverUrl + 'server-unavailable.html';
  } );
});

define("main", function(){});

