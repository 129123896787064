/**
 * Created by RKL on 26/08/2015.
 */
define('genericAttachmentView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'bootbox',
  'template!commandLineAttachsItemTpl'
], function (
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  bootbox,
  viewTpl
) {
  'use strict';

  module.exports = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      edit: '.js-edit-icon',
      remove: '.js-remove-icon',
      modify: '.js-modify-icon',
      zoom: '.js-zoom-icon',
      download: '.js-download-icon',
      imageCell: '.js-image-cell'
    },

    events: {
      'click @ui.remove': 'onRemove',
      'click @ui.download': 'onDownload',
      'click @ui.zoom': 'onZoom',
      'click @ui.modify': 'onModify'
    },

    tagName: 'div',

    className: 'original-img-wrapper zoom-img editor-holder template-item template-content-container ',

    onShow: function () {
      var that = this;
      var imgSrc;
      if (this.model.get('fileName') &&
        this.model.get('fileName').toLowerCase().indexOf('.svg') === (this.model.get('fileName').length - 4)) {
        imgSrc = Settings.url('base', that.model.get('downloadLink'));
      } else {
        imgSrc = Settings.url('base', that.model.get('fileLink'));
      }
      $('<img/>').attr('src', imgSrc).load(function () {
        $(this).remove();
        that.ui.imageCell.attr('data-link', imgSrc);
        that.ui.imageCell.css('background-image', 'url(' + imgSrc + ')');
        that.ui.imageCell.css('height', '150px');
        that.ui.imageCell.css('background-repeat', 'no-repeat');
        that.ui.imageCell.css('background-size', 'contain');
        that.ui.imageCell.show();
      }).error(function () {
        $(this).remove();
      });
    },

    onRemove: function () {
      bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
        if (result) {
          this.trigger('delete');
        }
      }, this));
    }
  });
});
