define('trackingCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'jquery',
  'urlUtil',
  'dialogFormView',
  'template!trackingCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app'
], function(
  module,
  Marionette,
  Backbone,
  $,
  UrlUtil,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      trackingNumberInput: '.js-info-input-tracking-number',
      urlInput: '.js-url-input',
      select: '.js-info-select',
      carrierSelect: '.js-carrier-select',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      displayInKanban: '#displayInKanban',
      validatedStatus: '#validatedStatus',
      commentStatus: '#commentStatus',
      assignUserToOrder: '#assignUserToOrder'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'focus @ui.trackingNumberInput' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'change @ui.trackingNumberInput': 'onInputChange',
      'change @ui.select': 'onInputChange',
      'click .js-confirm': 'onConfirm',
      'paste @ui.urlInput': 'onPasteUrl',
      'keyup @ui.urlInput': 'onPasteUrl'

    },

    regions: {
    },

    className: 'tracking-create-edit',

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'carrier', type: 'required'},
      {name: 'trackingNumber', type: 'required'}
    ],

    initialize: function(options) {
      this.model = options.trackingModel.clone();
      this.poi = options.poi;
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.model.save(this.poi);
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();

      this.options.trackingModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('tracking:header:rerender', modelJSON);
      App.navigate('orders/' + this.options.trackingModel.get('refCommandLine').poi, {trigger: false});
      App.navigate('orders/' + this.options.trackingModel.get('refCommandLine').poi + '/tracking', {trigger: true});
      this.hide();
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=endUserId').addClass('has-error');
    },

    onPasteUrl: function(e) {
      e.stopPropagation();
      var url,trackingNumber, carrier;
      if (e.type === 'paste') {
        url = new URL(e.originalEvent.clipboardData.getData('text/plain'));
      } else if (this.ui.urlInput.val()) {
        url = new URL(this.ui.urlInput.val());
      }

      e.preventDefault();
      if (url) {
        var params;
        if (url.host.indexOf('dpd') !== -1) {
          carrier = 'dpd';
          trackingNumber = url.toString().split('/').pop();

        } else if (url.host.indexOf('tnt') !== -1) {

          params = UrlUtil.parseQueryString(url.toString());
          carrier = 'tnt';
          if (params.cons) {
            trackingNumber = params.cons;
          }
        } else if (url.host.indexOf('dhl') !== -1) {
          params = UrlUtil.parseQueryString(url.toString());
          carrier = 'dhl';
          if (params.tc) {
            trackingNumber = params.tc;
          }
        } else if (url.host.indexOf('bpost') !== -1) {
          params = UrlUtil.parseQueryString(url.toString());
          carrier = 'bpost';
          if (params.itemCode) {
            trackingNumber = params.itemCode;
          }
        } else if (url.host.indexOf('gls-group') !== -1) {
          params = UrlUtil.parseQueryString(url.toString());
          carrier = 'gls';
          if (params.match) {
            trackingNumber = params.match;
          }
        }
        if (carrier && trackingNumber) {
          this.ui.carrierSelect.val(carrier);
          this.model.set('carrier', carrier);
          this.ui.trackingNumberInput.val(trackingNumber);
          this.ui.urlInput.val(url);
          this.ui.urlInput.removeClass('empty');
          this.ui.trackingNumberInput.removeClass('empty');
          this.model.set('trackingNumber', trackingNumber);
          this.model.set('trackingUrl',url.toString());
        }
      }
    }

  });

  module.exports = CreateEditLayoutView;
});

