define('entities/offers',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore',
  'entities/product'
], function (
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var Offer = Backbone.Model.extend({

    defaults: {
      pim: false,
      comment: '',
      agentId: '',
      proofsheetFileUid: '',
      exportFileUid: '',
      expShippingDate: null,
      nbrPackDelivered: 0,
      nbrPackSent: 0,
      internalComment: '',
      validationDate: null,
      paymentMethod: null,
      paymentTransactionNr: null,
      outsideEurope: false,
      difficultyLevel: null,
      externalNumber: null
    },

    fetch: function () {
      this.url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    getCode: function () {
      return this.get('endUserId');
    },

    getName: function () {
      return this.get('purchaseOrderId');
    },

    getImageUid: function () {
      return this.get('snapshotsUids');
    },

    getAvailableStatuses: function () {
      var that = this,
        params = {
          locale: Settings.get('lang')
        };

      if (this.get('commandType') === 'offer') {
        params.tag = 'offer';
      }

      that.trigger('statuses:request');
      return $.ajax({
        url: Settings.url('compuzz2', 'commands.json/command/status', params),
        success: function (resp) {
          that.trigger('statuses:sync', resp);
        }
      });
    },

    updateStatus: function () {
      return $.ajax({
        type: 'POST',
        url: Settings.url('compuzz2', 'commands.json/' + this.get('id') + '/status', {
          status: this.get('status')
        }),
        contentType: false,
        dataType: false
      });
    },

    duplicate: function () {
      return $.ajax({
        type: 'POST',
        url: Settings.url('compuzz', 'commands.json/duplicate/' + this.get('purchaseOrderId'), null),
        contentType: 'application/json',
        dataType: false
      });
    },

    resetPrice: function () {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2/reset');
      return $.ajax({
        method: 'POST',
        url: url
      });
    },

    changeProduct: function (productSecId) {
      var url = Settings.url('compuzz', 'commands.json/' + this.get('purchaseOrderId') + '/product', {
        productSecId: productSecId
      });
      return $.ajax({
        method: 'PUT',
        url: url
      });
    },

    changeQuantity: function (quantity) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/quantity', {
        quantity: quantity
      });
      return $.ajax({
        method: 'POST',
        url: url
      });
    },

    getPrice: function (margins, buyingPrice) {
      var url = Settings.url('compuzz2', 'commands.json/' + this.get('purchaseOrderId') + '/price/v2',
        {buyingPrice: buyingPrice});
      return $.ajax({
        method: 'POST',
        url: url,
        data: JSON.stringify(margins),
        contentType: 'application/json'
      });
    },

    getImageUrls: function (product) {
      var imageUrls = [];
      if (this.get('pim')) {
        var options = '';
        _.each(this.get('options'), function (option) {
          if (options === '') {
            options += option.id;
          } else {
            options += ',' + option.id;
          }
        });
        _.each(product.get('productPictureSecIds'), _.bind(function (productPictureSecId) {
          imageUrls.push(Settings.url('x2png', 'product/' + this.get('productBaseCode') + '/compuLogo/pim', {
            productPictureSecId: productPictureSecId,
            options: options
          }));
        }, this));
      } else {
        _.each(product.get('productPictureSecIds'), _.bind(function (productPictureSecId) {
          imageUrls.push(Settings.url('x2png', 'orders/' + this.get('purchaseOrderId') + '/products/' + this.get('productCode') +
            '/configurator', {
            productPictureSecId: productPictureSecId
          }));
        }, this));
      }

      this.trigger('image:urls:loaded', imageUrls);
    },

    idAttribute: 'purchaseOrderId'
  });

  var OfferCollection = Backbone.Collection.extend({
    url: function () {
      return Settings.url('compuzz', '/commands.json');
    },
    model: Offer
  });

  var API = {

    updateOffer: function (data) {
      var url = Settings.url('compuzz2', 'commands.json/update/lite'),
        defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        url: url,
        data: JSON.stringify(data),
        contentType: 'application/json',
        complete: function (response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    getOfferCollectionUrl: function (endUserId) {
      var params = {};
      params.types = 'offer';
      if (endUserId) {
        params.endUserId = endUserId;
      }

      return Settings.url('compuzz2', 'commands.json/advancedSearch', params);
    },

    getAllOffers: function (params) {
      var url = Settings.url('compuzz2', 'commands.json/advancedSearch?types=offer', params),
        defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function (offers) {
          var offersList = [];
          _.each(offers, function (offer) {
            offersList.push(offer.purchaseOrderId);
          });
          defer.resolve(offersList);
        }
      });

      return defer.promise();
    },

    getAllOffersMainInfo: function (params) {
      var url = Settings.url('compuzz2', 'commands.json/advancedSearch?types=offer', params),
        defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function (offers) {
          var offersList = [];
          _.each(offers, function (offer) {
            offersList.push({
              'purchaseOrderId': offer.purchaseOrderId,
              'productCode': offer.productCode,
              'productName': offer.productName,
              'status': offer.status,
              'createDate': offer.createDate
            });
          });
          defer.resolve(offersList);
        }
      });

      return defer.promise();
    },

    getOfferExcelUrl: function (params, endUserId) {

      params = params || {};

      if (endUserId) {
        params.endUserId = endUserId;
      }
      return Settings.url('compuzz', 'commands.json/excel', params);
    },

    getOfferPdfUrl: function (params) {

      params = params || {};

      return Settings.url('pdf', '', params);
    },

    getStatuses: function (offer) {
      var result = Settings.get('offerStatuses'),
        defer = $.Deferred();

      if (result) {
        defer.resolve(result);
      } else {
        var statuses = new OfferCollection({}),
          params = {
            locale: Settings.get('lang')
          };

        if (offer) {
          params.tag = 'offer';
        }

        statuses.url = Settings.url('compuzz2', 'commands.json/command/status', params);
        statuses.fetch({
          success: _.bind(function (data) {
            Settings.set('offerStatuses', data);
            defer.resolve(data);
          }, this)
        });
      }

      return defer.promise();
    },

    uploadCustomProofsheet: function (poi, fileInfo) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/customProofsheet/upload');
      $.ajax({
        type: 'POST',
        url: url,
        data: fileInfo.formData,
        processData: false,
        contentType: false,
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    deleteCustomProofsheet: function (poi) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + poi + '/customProofsheet');

      $.ajax({
        type: 'DELETE',
        url: url,
        complete: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    getOfferCountUrl: function (endUserId) {
      var params = '?types=offer';

      if (endUserId) {
        params += '&endUserId=' + endUserId;
      }
      return 'commands.json/advancedSearch/rowCount' + params;
    },

    updateFromConfiguration: function (purchaseOrderId, configuration) {
      var config = {
        quantity: configuration.quantity,
        options: [],
        printZonesColors: {}
      };

      for (var propertyName in configuration.options) {
        if (configuration.options.hasOwnProperty(propertyName)) {
          config.options.push(configuration.options[propertyName]);
        }
      }

      for (var propertyName1 in configuration.printZones) {
        if (configuration.printZones.hasOwnProperty(propertyName1)) {
          var pzConf = configuration.printZones[propertyName1];
          config.printZonesColors[pzConf.pzId] = pzConf.nbrColor;
        }
      }

      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/update/offer/' + purchaseOrderId);
      $.ajax({
        type: 'PUT',
        url: url,
        data: JSON.stringify(config),
        contentType: 'application/json',
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    createFromConfiguration: function (productSecId, endUserId, customerId, configuration) {
      var config = {
        quantity: configuration.quantity,
        options: [],
        printZonesColors: {},
        optionsMix: configuration.optionsMix,
        printZonesImages: {},
        attachments: configuration.attachments,
        comment: configuration.comment
      };

      for (var propertyName in configuration.options) {
        if (configuration.options.hasOwnProperty(propertyName)) {
          config.options.push(configuration.options[propertyName]);
        }
      }

      for (var propertyName1 in configuration.printZones) {
        if (configuration.printZones.hasOwnProperty(propertyName1)) {
          var pzConf = configuration.printZones[propertyName1];
          config.printZonesColors[pzConf.pzId] = pzConf.nbrColor;
        }
      }

      for (var propertyName2 in configuration.printZonesImages) {
        if (configuration.printZonesImages.hasOwnProperty(propertyName2)) {
          var pzConf2 = configuration.printZonesImages[propertyName2];
          config.printZonesImages[pzConf2.pzId] = pzConf2.fileContent;
        }
      }

      var params = {
        endUserId: endUserId,
        customerId: customerId
      };
      var defer = $.Deferred(),
        url = Settings.url('compuzz2', 'commands.json/create/offer/' + productSecId, params);
      $.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(config),
        contentType: 'application/json',
        complete: function (resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },
    updateOptionsFromConfiguration: function (purchaseOrderId, options) {

      var optionsArray = $.map(options, function (value) {
        return [value];
      });

      var index = 0;
      var params = {
        optionId: optionsArray[index]
      };
      var url = Settings.url('compuzz', 'commands.js/' + purchaseOrderId + '/option', params);
      return this.doOptionCall(purchaseOrderId, 0, params, url, optionsArray);

    },
    updateMixOptionsFromConfiguration: function (purchaseOrderId, mixOptions) {


      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'commands.json/' + purchaseOrderId + '/mixableoption');
      $.ajax({
        type: 'POST',
        url: url,
        data: JSON.stringify(mixOptions),
        contentType: 'application/json',
        complete: function (resp) {
          defer.resolve(resp);
        }
      });
      return defer.promise();
    },
    doOptionCall: function (poi, index, params, url, options) {
      var that = this,
        defer = $.Deferred();
      $.ajax({
        type: 'GET',
        url: url,
        complete: function (resp) {
          if (index < options.length - 1) {
            index++;
            var params = {
              optionId: options[index]
            };
            var url = Settings.url('compuzz', 'commands.js/' + poi + '/option', params);
            that.doOptionCall(poi, index, params, url, options).done(function (response) {
              defer.resolve(response);
            });

          } else {
            defer.resolve(resp);
          }
        }
      });
      return defer.promise();
    }
  };

  App.reqres.setHandler('offer:update', function (offerModel) {
    return API.updateOffer(offerModel);
  });

  App.reqres.setHandler('offer:get-collection-url', function (endUserId) {
    return API.getOfferCollectionUrl(endUserId);
  });

  App.reqres.setHandler('offer:get-collection-to-page', function (params) {
    return API.getAllOffers(params);
  });

  App.reqres.setHandler('offer:get-maininfo-collection-to-page', function (params) {
    return API.getAllOffersMainInfo(params);
  });

  App.reqres.setHandler('offer:get-excel-url', function (params, endUserId) {
    return API.getOfferExcelUrl(params, endUserId);
  });

  App.reqres.setHandler('offer:get-pdf-url', function (params) {
    return API.getOfferPdfUrl(params);
  });

  App.reqres.setHandler('offer:get-count-url', function (endUserId) {
    return API.getOfferCountUrl(endUserId);
  });

  App.reqres.setHandler('offer:statuses', function (formated) {
    return API.getStatuses(formated);
  });

  App.reqres.setHandler('offer:model', function (offerJSON) {
    return new Offer(offerJSON);
  });

  App.reqres.setHandler('offer:upload-custom-proofsheet', function (poi, fileinfo) {
    return API.uploadCustomProofsheet(poi, fileinfo);
  });

  App.reqres.setHandler('offer:delete-custom-proofsheet', function (poi) {
    return API.deleteCustomProofsheet(poi);
  });

  App.reqres.setHandler('offer:create-from-configuration', function (productSecId, endUserId, customerId, config) {
    return API.createFromConfiguration(productSecId, endUserId, customerId, config);
  });

  App.reqres.setHandler('offer:update-from-configuration', function (purchaseOrderId, config) {
    return API.updateFromConfiguration(purchaseOrderId, config);
  });

  App.reqres.setHandler('offer:update-options-from-configuration', function (purchaseOrderId, option) {
    return API.updateOptionsFromConfiguration(purchaseOrderId, option);
  });

  App.reqres.setHandler('offer:update-mixoptions-from-configuration', function (purchaseOrderId, option) {
    return API.updateMixOptionsFromConfiguration(purchaseOrderId, option);
  });

});

